import interceptor from '../../../helpers/API';
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from '../../../utils/localStorage';

export const fetchLogin = async (data) => {
  try {
    const response = await interceptor.post('admin/auth/login', data);
    setLocalStorage('accessToken', response?.data?.accessToken);
    setLocalStorage('refreshToken', response?.data?.refreshToken);
    setLocalStorage('tokenTime', new Date());
  } catch (e) {
    console.error(e);
  }
};

export const fetchToken = async () => {
  try {
    const refreshToken = getLocalStorage('refreshToken');
    const { data } = await interceptor.post('admin/auth/refresh', { refreshToken });
    setLocalStorage('accessToken', data?.accessToken);
    setLocalStorage('refreshToken', data?.refreshToken);
    setLocalStorage('tokenTime', new Date());
  } catch (e) {
    deleteLocalStorage('accessToken');
    deleteLocalStorage('refreshToken');
    deleteLocalStorage('tokenTime');
    console.error('@@@', e);
  }
};
