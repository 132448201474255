import { ACCESS, ARTICLE_STATUSES } from '../constants';

export const initialFilters = {
  status: ARTICLE_STATUSES.ALL,
  date: [null, null],
  access: ACCESS.ALL,
};

export const fieldNames = {
  title: 'title',
  status: 'status',
  access: 'access',
  text: 'text',
  documentType: 'documentType',
  file: 'file',
  type: 'type'
};

const filterNames = {
  status: 'status',
  date: 'date',
  access:'access'
};

export const filterList = Object.values(filterNames);

export const initialState = {
  title: '',
  status: '',
  date: new Date(),
  access: '',
  type: '',
  file: null,
  preview: '',
  text: '',
};

export const errorMsg = {
  requiredFile: 'Загрузите файл документа',
  required: (field) => `Заполните поле "${field}"`
};

export const acceptFormat = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
};
