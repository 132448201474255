import interceptor from "../../../../helpers/API";
import {format} from "date-fns";

export const fetchAddMaterial = async (data, id) => {
  try {
    const newData = {
      title: data.title,
      type: 'MATERIAL',
      externalLink: data.externalLink,
      fileId: id,
      accessType: data?.access,
      publishStatus: data?.publishStatus,
      publicationDate: new Date(data?.date).toISOString(),
      questions: null
    }
    await interceptor.post('admin/educations', newData);
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}

export const fetchEducation = async (params,filters) => {
  try {
    const newParams = {
      ...params,
      startDate: filters?.date?.[0] ? format(new Date(filters?.date?.[0]), 'yyyy-MM-dd') : '',
      endDate: filters?.date?.[1] ? format(new Date(filters?.date?.[1]), 'yyyy-MM-dd') : '',
    };
    const {data} = await interceptor.get('admin/educations',{
      params:newParams

    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}

export const deleteEducation = async (id) => {
  try {
    await interceptor.delete(`admin/educations/${id}`, {
      params: { id },
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchEducationById = async (id) => {
  try {
    const { data } = await interceptor.get(`admin/educations/${id}`, {
      params: { id },
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchEditEducation = async (id, data, fileId) => {
  try {
    const newData = {
      title: data?.title,
      type: 'MATERIAL',
      externalLink: data?.externalLink,
      fileId: fileId || data?.file?.id,
      accessType: data?.access,
      publishStatus: data?.publishStatus,
      publicationDate: new Date(data?.date).toISOString(),
      questions: null
    };
    await interceptor.put(`admin/educations/${id}`, newData, { params: id });
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
