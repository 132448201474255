import { getNormalizeDate } from '../../../../../utils';

export const getNormalizedFaqs = (item) => {
  return {
    access: item?.accessType,
    date: getNormalizeDate(item?.publicationDate),
    id: item?.id,
    title: item?.question,
    status: item?.publishStatus,
  };
};
