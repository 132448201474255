import React from 'react';
import { Box } from 'rebass';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

const CssTextField = withStyles({
  root: {
    '& label, & input': { fontFamily: 'BasisGrotesquePro,sans-serif' },
    '& label.Mui-focused': {
      color: '#90949B',
    },
    '& label.Mui-error': {
      color: '#90949B',
    },

    '& label .MuiInputLabel-asterisk': {
      color: '#7800FF',
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: '#7800FF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
        borderColor: 'rgba(0,0,0,0.38)',
      },

      '&:hover fieldset': {
        borderColor: '#7800FF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7800FF !important',
      },
    },
  },
})(TextField);

const Input = ({ mb, ...props }) => {
  return (
    <Box mb={mb}>
      <CssTextField id="standard-basic" fullWidth autoComplete="off" {...props} />
    </Box>
  );
};

export default Input;
