import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'atoms';
import styled from 'styled-components';
import { Text } from 'rebass';

const TextWrap = styled(Text)`
  p {
    margin-bottom: 1.875rem;
  }
`;

const Welcome = ({ onStart }) => {
  let history = useHistory();

  const handleStartQuiz = () => {
    onStart();
    history.push('/quiz/question/1');
  };
  return (
    <>
      <Text fontSize={['1.25rem', '1.25rem', '1.5rem']} pb={['8px', '8px', 16]} fontWeight="bold">
        Уважаемые коллеги!
      </Text>
      <TextWrap lineHeight={['1.125rem', '1.375rem']} fontSize={['0.875rem', '1rem']}>
        <p>
          По инициативе ООН&nbsp;<strong>9&nbsp;декабря</strong>&nbsp;отмечается&nbsp;
          <strong>Международный день борьбы с коррупцией</strong>. В связи с этим во многих странах
          мира проходят демонстрации, встречи, конференции, семинары и другие мероприятия,
          приуроченные к этому дню.
        </p>
        <p>
          Большое значение уделяется вопросам просвещения: силами общественных организаций,
          образовательных учреждений, представителей власти, и вообще всех неравнодушных граждан во
          многих российских городах в этот день проводятся самые разные акции и мероприятия,
          рассказывающие о вреде коррупции и методах борьбы с ней.&nbsp;
        </p>
        <p>
          В связи с этим предлагаем вам присоединиться к этой инициативе и ответить на вопросы
          викторины по антикоррупционной тематике.
        </p>
      </TextWrap>
      <Button variant="primary" title="Начать" onClick={handleStartQuiz} />
    </>
  );
};

export default memo(Welcome);
