import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;

  h1 {
    font-weight: 500;
    font-size: 20px;
  }

  .text {
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .title {
    font-weight: 500
  }

  .appeal {
    margin-bottom: 20px;
  }

  .anonymous {
    color: red;
    font-weight: 500;
    margin-bottom: 10px;
  }
`

export const Comment = styled.div`
  .comment {
    color: #90949B;
    margin-bottom: 20px;
  }
`

export const User = styled.div`

`

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 150px;

  .text {
    text-align: center;
    margin-bottom: 20px;
  }
`
