import * as S from './styles'
import {fetchFile} from "../../../../request";

export const FileElement = ({file}) => {

  const downloadFile = async () => {
    await fetchFile(file?.id, file?.originalFilename);
  };

  return (
    <S.Content onClick={downloadFile}>
      {file?.originalFilename} {file?.size && `(~${file?.size} Кб)`}
    </S.Content>
  )
}
