import {getNormalizeDate} from '../../../../../utils';

export const getNormalizedAsk = (data) => {
  return {
    date: getNormalizeDate(data?.createdDate),
    id: data?.id,
    name: data?.fullName,
    email: data?.email,
  };
};
