import {useEffect, useState} from 'react';
import {useAsyncFn} from 'react-use';
import {useDispatch} from 'react-redux';

import {deleteAsk, fetchAskList} from '../requests';
import {setStatus} from '../../../../../store/reducers/News.reducers';
import {LOADING} from '../../../../../store/statusConstants';
import {useStatuses} from '../../../../../hooks/useStatuses';
import {getNormalizedAsk} from "../utils";

export const useAskList = ( setAllPages, page, pageSize, setAllElements) => {
  const [{value, loading, error}, getAsk] = useAsyncFn(fetchAskList);
  const [, delAsk] = useAsyncFn(deleteAsk);
  const [askList, setAskList] = useState([]);
  const dispatch = useDispatch();
  const {status} = useStatuses(loading, value?.content, error, true);

  const params = {
    size: pageSize,
    page: page + 1,
  };

  useEffect(async () => {
    await getAsk(params);
  }, [pageSize, page]);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedAsk(item);
      });

      setAskList(normalizedData);
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);
    }
  }, [value]);

  const handleDeleteAsk = async (id) => {
    await delAsk(id);
    await getAsk(params);
  };

  const updateTableData = async () => {
    dispatch(setStatus(LOADING));
    await getAsk(params);
  };

  return {askList, handleDeleteAsk, updateTableData, getAsk, status};
};
