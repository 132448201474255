import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text, Box } from 'rebass';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/core/Alert';
import { isEmpty } from 'lodash';
import { Input, Select } from 'atoms';
import Button from './Button';
import TextTitle from './TextTitle';
import { registration, resetStatus } from '../../../store/reducers/Course.reducers';
import { LOADING, FULFILLED, ERROR } from '../../../store/statusConstants';

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: #f2f3f7;
`;

const validationSchema = yup.object({
  position: yup.string().required('Поле обязательно для заполнения'),
  companyName: yup.string().required('Поле обязательно для заполнения'),
  companyInn: yup
    .string('Некорректное значение')
    .matches(/^[0-9]+$/, 'Разрешен ввод только цифр')
    .min(10, 'Должно быть не меньше 10 символов')
    .max(12, 'Должно быть не больше 12 символов')
    .required('Поле обязательно для заполнения'),
});

const options = [
  {
    label: 'Рядовой сотрудник',
    value: 'ORDINARY',
  },
  {
    label: 'Руководитель среднего звена',
    value: 'MIDDLE',
  },
  {
    label: 'Руководитель высшего звена',
    value: 'SENIOR',
  },
  {
    label: 'Собственник/Совладелец',
    value: 'OWNER',
  },
];

const initialValues = {
  position: '',
  companyName: '',
  companyInn: '',
};

const Title = ({ children }) => {
  return (
    <Text fontSize="1.625rem" lineHeight="1.2em" mb={20} color="#6d12f5">
      {children}
    </Text>
  );
};

const useRegistration = (history) => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.course.status);

  const isLoading = status === LOADING;

  useEffect(() => {
    if (status === FULFILLED) {
      history.push('/course/video');
      dispatch(resetStatus());
    }
  }, [status]);

  const onSubmit = (formValues) => {
    dispatch(registration(formValues));
  };

  return { onSubmit, isLoading };
};

const useNotify = () => {
  const [open, setOpen] = React.useState(false);
  const status = useSelector((state) => state.course.status);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  useEffect(() => {
    if (status === ERROR) {
      setOpen(true);
    }
  }, [status]);

  return { open, handleClose };
};

const Registration = ({ history }) => {
  const { onSubmit, isLoading } = useRegistration(history);
  const { open, handleClose } = useNotify();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Wrapper>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert variant="filled" severity="error" onClose={handleClose}>
          Cервис недоступен, обновите страницу и попробуйте еще раз
        </Alert>
      </Snackbar>

      <Flex flexWrap="wrap" height={['auto', 'auto', '100%']}>
        <Flex width={[1, 1, 1 / 2]} flexDirection="column" p={[20]}>
          <Flex height={['auto', 'auto', '10%']}>
            <Button title="Регистрация" />
          </Flex>
          <Flex height={['auto', 'auto', '80%']} my={[40, 40, 40, 0]} alignItems="center">
            <TextTitle>
              Заполните поля,
              <br />
              чтобы начать курс
            </TextTitle>
          </Flex>
          <Flex height={['auto', 'auto', '10%']} />
        </Flex>
        <Flex width={[1, 1, 1 / 2]} p={[0, 0, 20]}>
          <Box width="100%" bg="#fff" py="40px" px={[20, 20, 40]}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Title>Участник</Title>
              <Select
                id="position"
                required
                name="position"
                value={formik.values.position}
                options={options}
                label="Должность"
                mb="32px"
                variant="outlined"
                onChange={formik.handleChange}
                error={formik.touched.position && Boolean(formik.errors.position)}
                helperText={formik.touched.position && formik.errors.position}
              />

              <Title>Компания</Title>
              <Input
                {...formik.getFieldProps('inn')}
                id="companyInn"
                name="companyInn"
                required
                label="ИНН"
                mb="32px"
                variant="outlined"
                error={formik.touched.companyInn && Boolean(formik.errors.companyInn)}
                helperText={formik.touched.companyInn && formik.errors.companyInn}
              />

              <Input
                {...formik.getFieldProps('companyName')}
                id="companyName"
                name="companyName"
                required
                label="Название"
                mb="32px"
                variant="outlined"
                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                helperText={formik.touched.companyName && formik.errors.companyName}
              />

              <Button
                margin="40px 0 0 0"
                disabled={!isEmpty(formik.errors)}
                title="Продолжить"
                type="submit"
                loading={isLoading}
              />
            </form>
          </Box>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default memo(Registration);
