import {useEffect, useState} from 'react';
import {useAsyncFn} from 'react-use';

import {fetchNews} from '../requests';
import {getNormalizedNews} from "../utils/getNormalizedNews";


const pageSize = 4;
const page = 1
export const useNews = () => {
  const [{value}, getNews] = useAsyncFn(fetchNews);
  const [newsList, setNews] = useState([]);

  useEffect(() => {
    const params = {
      size: pageSize,
      page: page,
      sort: "publicationDate,DESC",
    };
    getNews(params);
  }, []);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedNews(item);
      });
      setNews(normalizedData);
    }
  }, [value]);

  return {newsList};
};
