import React, { useState } from 'react';
import { Flex, Box, Text } from 'rebass';
import Paper from '@material-ui/core/Paper';
import Button from './Button';
import Select from './Select';
import TextField from './TextField';
import { FILTER_TYPES } from './constants';
import DateRange from './DateRange';
import { checkIsValidRangeDate, checkValidateDate } from './News/utils/checkValidDate';
import { Modal } from '../../../common/molecules';

const Filter = ({ items, accessFilter, resetFilters }) => {
  const [isOpeModal, setIsOpenModal] = useState(false);
  let errorDate = false;

  const handleAccessFilter = () => {
    if (errorDate) {
      setIsOpenModal(true);
    } else {
      accessFilter();
    }
  };

  const resetValues = async () => {
    await resetFilters();
  };

  const renderSelect = ({ name, opts, label, value, onChange }) => {
    return (
      <Select
        label={label}
        value={value || ''}
        options={opts}
        onChange={onChange}
        width="240px"
        name={name}
      />
    );
  };

  const renderDateRange = ({ name, value, onChange }) => {
    const startError = value?.[0] && !checkValidateDate(value?.[0]);
    const endError = value?.[1] && !checkValidateDate(value?.[1]);
    const { isValidStartDate, isValidEndDate } = checkIsValidRangeDate(value?.[0], value?.[1]);
    errorDate = startError || endError || !isValidStartDate || !isValidEndDate;

    return (
      <DateRange
        value={value || [null, null]}
        name={name}
        onChange={(date) => {
          onChange(name, date);
        }}
        startError={startError || !isValidStartDate}
        endError={endError || !isValidEndDate}
      />
    );
  };

  const renderTextField = ({ label, name, value, onChange }) => {
    return (
      <TextField
        value={value || ''}
        label={label}
        name={name}
        size="small"
        onChange={(value) => onChange(name, value)}
        style={{ width: '200px' }}
      />
    );
  };

  const renderFilterItem = (item) => {
    let component = null;
    switch (item.type) {
      case FILTER_TYPES.SELECT:
        component = renderSelect(item);
        break;
      case FILTER_TYPES.DATE_RANGE:
        component = renderDateRange(item);
        break;
      case FILTER_TYPES.TEXT_FIELD:
        component = renderTextField(item);
        break;
      default:
        break;
    }
    return component;
  };

  const renderModalContent = () => {
    return (
      <Box p="24px">
        <Flex justi1fyContent="center" flexDirection="column">
          <Text fontSize="24px" m="auto" mb="10px" >
            Ошибка
          </Text>
          <Text fontSize="18px" mb="24px">
            Некорректная дата
          </Text>
        </Flex>
        <Flex justifyContent="center">
          <Button
            variant="contained"
            label="Закрыть"
            margin="0 16px 0 0"
            onClick={() => setIsOpenModal(false)}
            sx={{ marginRight: '16px' }}
          />
        </Flex>
      </Box>
    );
  };

  return (
    <Paper elevation={2} sx={{ width: '100%', padding: '24px', marginBottom: '40px' }}>
      <Flex justi1fyContent="space-between" alignItems="start" flexWrap="wrap">
        {items.map((item) => (
          <Box key={item.name} mr="16px" mb={items.length > 2 ? '16px' : '0'}>
            {renderFilterItem(item)}
          </Box>
        ))}

        <Flex flexShrink="0" alignItems="center" mt="2px">
          <Button
            variant="contained"
            mr="16px"
            label="Применить фильтр"
            onClick={handleAccessFilter}
          />
          <Button variant="outlined" label="Очистить фильтр" onClick={resetValues} />
        </Flex>
      </Flex>
      <Modal
        isOpen={isOpeModal}
        onClose={() => setIsOpenModal(false)}
        body={renderModalContent()}
      />
    </Paper>
  );
};

export default Filter;
