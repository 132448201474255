import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import ModuleTheorySlider from './ModuleTheorySlider';
import ModuleTheoryText from './ModuleTheoryText';
import { saveAnswers } from '../../../store/reducers/Course.reducers';
import data from '../data';

const ModuleTheory = () => {
  const { moduleId, theoryId } = useParams();
  const dispatch = useDispatch();
  const module = find(data.modules, ['id', +moduleId]);
  const { isSlider } = find(module.theory, ['id', +theoryId || 1]);

  useEffect(() => {
    if (Number(theoryId) === 10 && Number(moduleId) === 6) {
      dispatch(saveAnswers());
    }
  }, []);

  if (isSlider) {
    return <ModuleTheorySlider />;
  }

  return <ModuleTheoryText />;
};

export default memo(ModuleTheory);
