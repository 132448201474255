import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import {
  ACCESS,
  ARTICLE_STATUSES,
  EDUCATION_TYPES,
} from '../../modules/Admin/components/constants';

export const fetchEducation = createAsyncThunk(
  'education/fetchEducation',
  async (arg, { getState }) => {
    const state = getState();
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return mokeEducation;
  },
);

export const createEducation = createAsyncThunk('education/createEducation', async (data) => {
  console.log(data);
});

export const deleteEducationById = createAsyncThunk('education/deleteEducationById', async (id) => {
  // отправляем данные
  return mokeEducation.filter((item) => item.id !== id);
});

const educationSlice = createSlice({
  name: 'education',
  initialState: {
    listOfEducation: [],
    dateRange: [1627761600, 1643107212],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: [1627761600, 1643107212],
      access: ACCESS.ALL,
      type: EDUCATION_TYPES.ALL,
    },
    status: IDLE,
    sort: {
      dateSort: true,
      titleSort: null,
      statusSort: null,
    },
    pageNum: 10,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchEducation.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchEducation.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfEducation = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchEducation.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createEducation.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createEducation.fulfilled]: (state, action) => {},
    [createEducation.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteEducationById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteEducationById.fulfilled]: (state, action) => {
      state.listOfEducation = action.payload;
      state.status = FULFILLED;
    },
    [deleteEducationById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = educationSlice.actions;

export default educationSlice.reducer;

const mokeEducation = [
  {
    id: 1,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'MATERIALS',
  },
  {
    id: 2,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'TESTS',
  },
  {
    id: 3,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'MATERIALS',
  },
  {
    id: 4,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'TESTS',
  },
  {
    id: 5,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'MATERIALS',
  },
  {
    id: 6,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'MATERIALS',
  },
  {
    id: 7,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'MATERIALS',
  },
  {
    id: 8,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'MATERIALS',
  },
  {
    id: 9,
    date: 1627761600,
    title: 'Курс деловой этики для поставщиков ПАО Ростелеком',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    type: 'MATERIALS',
  },
];
