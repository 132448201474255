import React from 'react';
import Checkbox from '@material-ui/core/Checkbox'

import * as S from './styles'

const TableElement = ({item, checked, onChecked}) => {

  return (
    <S.Content>
      <div>{item.title}</div>
      <div className='checkbox'>
        <Checkbox
          checked={checked(item.view)}
          onChange={() => onChecked(item.view)}
        />
      </div>
      <div
        className='checkbox'>
        {!item.isDisabledWrite && <Checkbox
          checked={checked(item.editing)}
          onChange={() => onChecked(item.editing)}
        />}
      </div>
    </S.Content>
  );
};

export default TableElement;
