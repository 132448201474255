import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.li`
  padding-left: 8px;
  margin-bottom: 0;
  margin-left: 16px;
  line-height: 1.5;
  list-style-type: '—';
  &:before {
    display: none;
  }
  &::marker {
    color: #101828;
  }
`;

const CustomLi = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default CustomLi;
