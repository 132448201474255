import {useEffect, useState} from "react";
import {useAsyncFn} from "react-use";
import {fetchListArticles} from "../requests";
import {getNormalizedArticle} from "../utils/getNormalizedArticle";

export const useArticlesList = (setAllPages,page,size) => {
  const [{value}, getListArticles] = useAsyncFn(fetchListArticles)

  const [listArticle, setListArticle] = useState([])

  useEffect(() => {
    getListArticles(page,size)
  }, [page])

  useEffect(() => {
    if (value) {
      const newValue = value?.content?.map((item)=>(getNormalizedArticle(item)))
      setListArticle(newValue)
      setAllPages(value?.pagination?.totalPages);
    }
  }, [value])

  return {listArticle}
}
