import API from '../helpers/API';

const quizRepository = {
  getActiveQuiz: () => API.get('/quiz/active?accessMode=2'),
  startActiveQuiz: (id) => API.get(`/quiz/createSessionWithoutRecipient?quizId=${id}`),
  getQuizResult: (data) => API.post('/quiz/state', data),
  saveAnswer: (data) => API.post('/quiz/saveAnswer', data),
};

export default quizRepository;
