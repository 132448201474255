import {useAsyncFn} from "react-use";
import {useEffect, useState} from "react";

import {fetchAskById} from "../requests";
import {useRouter} from "../../../../../hooks";

export const useAsk = () => {
  const [{value,}, getAsk] = useAsyncFn(fetchAskById)
  const [ask, setAck] = useState(null)
  const {
    query: {id},
  } = useRouter();

  useEffect(() => {
    if (id) {
      getAsk(id)
    }
  }, [id])

  useEffect(() => {
    if (value) {
      setAck(value)
    }
  }, [value])

  return {ask}
}
