import {useAsyncFn} from "react-use";
import {fetchFile} from "../requests";
import {useEffect, useState} from "react";

export const useImg = (file) => {
  const [{value}, execute] = useAsyncFn(fetchFile)
  const [link, setLink] = useState('')

  useEffect(() => {
    if (file) {
      execute(file?.id)
    }
  }, [file])

  useEffect(() => {
    if (value) {
      setLink(URL.createObjectURL(value))
    }
  }, [value])

  return {link}
}
