import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ARTICLE_STATUSES } from '../../modules/Admin/components/constants';

export const fetchAsk = createAsyncThunk('ask/fetchAsk', async () => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  // пример запроса!
  return mokeAsk;
});

export const createAsk = createAsyncThunk('ask/createAsk', async (data) => {
  console.log(data);
});

export const deleteAskById = createAsyncThunk('ask/deleteAskById', async (id) => {
  console.log(id);
  return mokeAsk.filter((item) => item.id !== id);
});

export const fetchAskById = createAsyncThunk('article/fetchAskById', async (id) => {
  //тут будет запрос на данные конкретной статьи
  return mokeData;
});

const askSlice = createSlice({
  name: 'ask',
  initialState: {
    listOfAsk: [],
    dateRange: [1627761600, 1643107212],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: [1627761600, 1643107212],
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      dateSort: true,
      titleSort: null,
      statusSort: null,
    },
    data: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchAsk.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchAsk.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfAsk = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchAsk.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createAsk.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createAsk.fulfilled]: (state, action) => {},
    [createAsk.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteAskById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteAskById.fulfilled]: (state, action) => {
      state.listOfAsk = action.payload;
      state.status = FULFILLED;
    },
    [deleteAskById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchAskById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchAskById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchAskById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = askSlice.actions;

export default askSlice.reducer;

const mokeData = {
  title: 'erererer',
  status: 'UNPUBLISHED',
  question: 'q?',
  answer: 'w!',
};

const mokeAsk = [
  { id: 1, date: 1627761600, title: 'test', status: 'UNPUBLISHED' },
  { id: 2, date: 1627761600, title: 'test', status: 'UNPUBLISHED' },
  { id: 3, date: 1627761600, title: 'test', status: 'UNPUBLISHED' },
  { id: 4, date: 1627761600, title: 'test', status: 'PUBLISHED' },
  { id: 5, date: 1627761600, title: 'test', status: 'PUBLISHED' },
  { id: 6, date: 1643107212, title: 'test', status: 'PUBLISHED' },
];
