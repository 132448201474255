import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Text, Box } from 'rebass';
import styled from 'styled-components';
import images from '../img';

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
  background-color: #f2f3f7;
`;

const ModuleEnd = () => {
  return (
    <Wrapper>
      <Flex
        flexWrap="wrap"
        height={['auto', 'auto', '100%']}
        minHeight={['100%']}
        sx={{ background: `url(${images.end}) no-repeat center`, backgroundSize: 'cover' }}
      >
        <Flex
          width={[1, 1, 1 / 2]}
          justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
          py="40px"
          px="16px"
          alignItems={['flex-start', 'flex-start', 'flex-end']}
        >
          <Flex maxWidth={['100%', '100%', '100%', 640]} flexDirection="column">
            <Box my={[40, 40, 40, 0]} pb="30px">
              <Text
                fontSize={['35px', '35px', '45px', '64px']}
                display="inline"
                bg="#f6c043"
                lineHeight="1.1em"
              >
                Давайте уважать друг друга!
              </Text>
            </Box>
            <Flex>
              <Link
                to='/feedback'
                target="_blank"
              >
                <Text
                  fontSize={['16px', '16px', '20px', '22px']}
                  color="#fff"
                  display="inline"
                  p="8px 4px"
                  mr="24px"
                  sx={{ textDecoration: 'underline' }}
                >
                  Форма обратной связи
                </Text>
              </Link>
              <Link
                target="_blank"
                to='/documents/company'
              >
                <Text
                  fontSize={['16px', '16px', '20px', '22px']}
                  color="#fff"
                  display="inline"
                  p="8px 4px"
                  sx={{ textDecoration: 'underline' }}
                >
                  Скачать кодекс
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default memo(ModuleEnd);
