import React, { memo } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Icon } from 'atoms';
import styled from 'styled-components';
import { getNormalizedFileSize } from '../utils/getNormalizedFileSize';
import { getNormalizedFileFormat } from '../utils/getNormalizedFileFormat';
import { fetchFile } from '../../../request';

const TextContent = styled.div`
  line-height: 1.5;
  font-size: 0.875rem;

  p {
    color: #707070;
  }

  li {
    color: #707070;
  }
`;

const downloadFile = async (id, name) => {
  await fetchFile(id, name);
};

const Document = ({ file, text, name }) => {
  return (
    <Box width="100%" mb="52px">
      {file && (      <Box
        p="20px"
        mb="24px"
        sx={{ border: '1px dashed #D8D8D8' ,cursor:"pointer"}}
        onClick={async () => {
          await downloadFile(file?.id, file?.originalFilename);
        }}
      >
        <Flex>
          <Icon name="document" m="0 8px 0 0" />
          <Box>
            <Text
              fontSize={['0.875rem', '0.875rem', '1.125rem']}
              lineHeight="1.5rem"
              fontWeight={500}
              mb="4px"
            >
              {name}
            </Text>
            <Text fontSize={['0.75rem', '0.875rem']} color="rgba(16, 24, 40, 0.7)">
              {getNormalizedFileFormat(file?.contentType)}, ~{getNormalizedFileSize(file?.size)} Мб
            </Text>
          </Box>
        </Flex>
      </Box>)}
      <TextContent dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
  );
};

export default memo(Document);
