import React from 'react';
import { Flex } from 'rebass';
import MainRouter from 'modules/Router/MainRouter';
import { ConnectedRouter } from 'connected-react-router';
import { GlobalStyle } from './App.styles';
import history from '../../store/history';

const App = () => (
  <Flex flexDirection="column" alignItems="center">
    <GlobalStyle />
    <ConnectedRouter history={history}>
      <MainRouter />
    </ConnectedRouter>
  </Flex>
);
export default App;
