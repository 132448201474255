import React from 'react';
import {Box, Flex, Text} from "rebass";
import Button from "../../../Button";
import {isEmpty} from "ramda";
import {CircularProgress} from "@material-ui/core";

export const ModalContent = ({errorsList, onClose, isLoading}) => {
  return (
    <Box p="24px">
      {isLoading && <CircularProgress/>}
      {!isEmpty(errorsList) && <>
        <Text fontSize="18px" mb="14px" style={{textAlign: 'center'}}>
          Ошибка
        </Text>
        <Text fontSize="14px" mb="24px">
          {errorsList}
        </Text>
      </>}
      {isEmpty(errorsList) && <Text fontSize="14px" mb="24px">
        Профиль успешно сохранен
      </Text>}
      <Flex justifyContent="center">
        <Button
          variant="contained"
          label="Закрыть"
          margin="0 16px 0 0"
          onClick={onClose}
          sx={{marginRight: '16px'}}
        />
      </Flex>
    </Box>
  );
};
