import React, {memo, useState} from 'react';
import {Flex, Box, Text} from 'rebass';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/core/Alert";

import {Button, Input} from 'atoms';
import {ReadMoreFaqs, Captcha, Select} from 'molecules';
import {TwoColumnsLayout} from 'layouts';
import {useQuestion} from "../hooks/useQuestion";

const Question = () => {
  const [isAlert, setIsAlert] = useState(false);
  const {formik, loading, isUpdateCaptcha, error,...rest} = useQuestion(setIsAlert)

  const opts = [
    {title: 'Нет', value: false},
    {title: 'Да', value: true},
  ];

  const renderLeft = () => {
    return (
      <>
        <Snackbar
          open={isAlert}
          autoHideDuration={3000}
          onClose={() => {
            setIsAlert(false)
          }}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          <Alert variant="filled" severity={error ? "error" : "success"} onClose={() => {
            setIsAlert(false)
          }}>
            {error ? " При обработке запроса,произошла ошибка" : "Запрос прошёл успешно"}
          </Alert>
        </Snackbar>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box>
            <Box mb={24} width={[1, 1, 300]}>
              <Input
                id="name"
                name="name"
                value={formik.values.name}
                label="Фамилия, Имя, Отчество"
                required
                mb="32px"
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <Input
                id="email"
                name="email"
                value={formik.values.email}
                label="Электронная почта"
                required
                mb="32px"
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>

            <Box width={[1, 1, 300]} mb={40}>
              <Select
                label="Сотрудник Ростелеком"
                required
                selected={formik.values.isEmployee}
                options={opts}
                onChange={({value}) => {
                  formik.setFieldValue('isEmployee', value);
                }}
              />
            </Box>

            <Box width="100%" mb={32}>
              <Input
                id="comment"
                name="comment"
                value={formik.values.comment}
                label="Текст сообщения"
                multiline
                rows={4}
                variant="outlined"
                required
                mb="32px"
                onChange={formik.handleChange}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                helperText={formik.touched.comment && formik.errors.comment}
              />
            </Box>
            <Box mt={24}>
              <Captcha
                {...rest}
                value={formik.values.captcha}
                name='captcha'
                onChange={formik.handleChange}
                error={formik.touched.captcha && Boolean(formik.errors.captcha)}
                helperText={formik.touched.captcha && formik.errors.captcha}
                isUpdateCaptcha={isUpdateCaptcha}
              />
            </Box>
            <Flex flexWrap="wrap" mb={24} alignItems="center">
              <Box width={[1, 200]} mb={[24, 0]}>
                <Button variant="primary" title="Отправить" loading={loading} disabled={loading}/>
              </Box>
              <Box width={[1, 'calc(100% - 200px)']}>
                <Text display="inline" color="#7800FF">
                  *
                </Text>
                <Text display="inline">- поля, обязательные для заполнения</Text>
              </Box>
            </Flex>
          </Box>
        </form>
      </>
    );
  };

  return (
    <>
      <TwoColumnsLayout title="Задать вопрос" left={renderLeft()} right={<ReadMoreFaqs/>}/>
    </>
  );
};

export default memo(Question);
