import React, {memo} from 'react';
import {Box, Flex} from "rebass";

import workplace from '../../../../img/workplace.jpeg'
import training from '../../../../img/training.jpeg'
import {PageTitle} from 'atoms';
import {Tabs} from 'molecules';
import * as S from './styles'
import {useCheckVpn} from "../../../../hooks/useCheckVpn";
import Tests from "../Course";

const tabs = [
  {title: 'Учебные материалы', path: '/education/materials'},
  {title: 'Курс деловой этики для поставщиков', path: '/education/tests'},
];

const Education = () => {
  const {accessible} = useCheckVpn()

  return (
    <>
      {accessible ? <>
        <Box width="100%" p="40px 0 0px">
          <Flex justifyContent="center">
            <Box width="100%" maxWidth={['100%', '100%', '100%', 1300]} px={[16, 32, 48]}>
              <PageTitle>Обучение</PageTitle>
              <Box mb="24px">
                {accessible && <Tabs tabs={tabs}/>}
              </Box>
            </Box>
          </Flex>
          <Flex bg="#fff" flexDirection="column" alignItems="center" pt={[24, 24, 40]} pb="80px">
            <S.Content>
              <S.Education>
                <div>
                  <img src={workplace}/>
                </div>
                <div className={'education'}>
                  <h1>Дистанционное обучение</h1>
                  <p>Дистанционный курс «Этический кодекс ПАО «Ростелеком», обучающий основным принципам Кодекса и
                    профилактики коррупции, обязательный для прохождения всеми сотрудниками компании, имеющими
                    автоматизированное рабочее место (АРМ) и адрес электронной почты, с последующим тестированием.</p>
                </div>
                <div>
                  <h4>Критерии успешности обучения</h4>
                  <p>Сотрудник считается прошедшим дистанционный курс обучения при наличии правильных ответов на не
                    менее
                    чем 75% тестовых вопросов. При меньшем количестве правильных ответов сотруднику дается 5 попыток на
                    повторное прохождение курса. Ссылка на прохождение обязательного дистанционного курса приходит на
                    корпоративную электронную почту новому сотруднику после появления у него электронного адреса.</p>
                </div>
                <div>
                  <h4>Периодичность проведения обучения</h4>
                  <p>Плановое обучение в дистанционной форме для существующих сотрудников компании проводится не менее 1
                    раза в 2 года с целью обучения всех сотрудников базовым ценностям и нормам ведения бизнеса и
                    информирования о нетерпимости к коррупционным проявлениям, а также поддержания их знаний в этой
                    области
                    на должном уровне.</p>
                </div>
              </S.Education>

              <S.CourseContent>
                <h1>
                  Содержание Дистанционного курса «Этический кодекс»
                </h1>
                <ul>
                  <li>Основные этические принципы компании.</li>
                  <li>Конфликт интересов.</li>
                  <li>Конфиденциальная и Инсайдерская информация.</li>
                  <li>Предоставление и получение подарков.</li>
                  <li>Противодействие коррупции и ненадлежащие платежи.</li>
                  <li>Раскрытие информации и внешние коммуникации.</li>
                  <li>Защита и использование активов Общества.</li>
                  <li>Пожертвования и спонсорство.</li>
                  <li>Закупки и взаимоотношения с поставщиками.</li>
                  <li>Противодействие мошенничеству, отмыванию денежных средств и финансированию терроризма.</li>
                  <li>Отношение к коллегам и взаимоотношения с деловыми партнерами и конкурентами.</li>
                  <li>Защита окружающей среды и охрана труда.</li>
                  <li>Соблюдение Кодекса и сообщения о нарушениях.</li>
                  <li>Тестирование.</li>
                </ul>
              </S.CourseContent>

              <S.Training>
                <div>
                  <img src={training}/>
                </div>
                <div className={'education'}>
                  <h1>Очное обучение</h1>
                  <p>Углубленный аудиторный курс «Предотвращение коррупции», назначаемый ДЗА сотрудникам компании,
                    выполняющих ключевые, подверженные наиболее высокому риску коррупции функции (независимо от их
                    должности), а также сотрудникам всех остальных подразделений компании на руководящих должностях (от
                    Руководителя направления).</p>
                </div>
                <div>
                  <h4>Для кого предназначен данный курс</h4>
                  <p>К «высоко-рисковым» функциям, например, относятся сотрудники, взаимодействующие с госорганами,
                    разрабатывающие/ведущие инвестиционные проекты, занимающиеся закупками и работой с поставщиками,
                    работающие с корпоративными клиентами, осуществляющие сделки с недвижимостью, строительством, МТО и
                    т.д.</p>
                </div>
                <div>
                  <h4>Условия проведения обучающего курса</h4>
                  <p>Данный обучающий курс назначается дополнительно к дистанционному курсу «Этический кодекс».</p>
                </div>
              </S.Training>
              <S.Corruption>
                <h1>Содержание курса «Предотвращение коррупции»</h1>
                <ul>
                  <li>Общие антикоррупционные принципы и нормы.</li>
                  <li>Особенности российского и международного законодательства (Закон США «О коррупции за рубежом»,
                    Британский закон «О взяточничестве»), примеры международных и российских коррупционных скандалов,
                    санкции и ответственность за коррупционные нарушения.
                  </li>
                  <li>Антимонопольное законодательство.</li>
                  <li>Рассмотрение процессов, наиболее подверженных риску коррупции: закупки, работа с посредниками и
                    деловыми партнерами, дарение и получение подарков, оказание гостеприимства, спонсорская и
                    благотворительная деятельность, конфликт интересов, запрет использования инсайдерской информации (с
                    использованием учебных ситуаций).
                  </li>
                  <li>Способы информирования о подозрениях в нарушении требований Этического кодекса или действующего
                    законодательства, внутренних правил и положений компании.
                  </li>
                </ul>
              </S.Corruption>
            </S.Content>
          </Flex>
        </Box>
      </> : <Tests/>}
    </>
  );
};

export default memo(Education);
