import React from 'react';
import {Flex, Box, Text} from 'rebass';
import {Modal} from 'molecules';
import {CircularProgress} from '@material-ui/core';

import Button from './Button';
import {IDLE, LOADING, FULFILLED, ERROR, VALIDATION} from '../../../store/statusConstants';
import {useRouter} from '../../../hooks';
import {isEmpty} from "ramda";
import styled from "styled-components";

const Validation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ModalStatus = ({
                       status, isOpen, setIsOpen, access, path, errorsList = [], clearErrors = () => {
  }
                     }) => {
  const {history} = useRouter();
  const contentMaper = {
    [LOADING]: () => (
      <Box p="24px">
        <Text>Подождите идет обработка...</Text>
        <Flex width="100%" justifyContent="center" pt="40px">
          <CircularProgress/>
        </Flex>
      </Box>
    ),
    [IDLE]: () => null,
    [FULFILLED]: () => <Text>{access}</Text>,
    [ERROR]: () => <Text>При обработке запроса произошла ошибка</Text>,
    [VALIDATION]: () => <Validation>{errorsList.map((item) => <span key={item}>{item}</span>)}</Validation>,
  };

  const closeModal = () => {
    clearErrors()
    setIsOpen(false);
    if (status !== ERROR && isEmpty(errorsList) && status !== LOADING) {
      history.push(path);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      body={
        <Flex p="12px 24px" flexDirection="column" alignItems="center">
          <Box mb="24px">{contentMaper[status]?.()}</Box>
          <Button
            variant="outlined"
            color="error"
            label="Закрыть"
            onClick={closeModal}
          />
        </Flex>
      }
    />
  );
};

export default ModalStatus;
