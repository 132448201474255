import React, {memo} from 'react';
import {Flex, Text, Box} from 'rebass';
import styled from 'styled-components';
import bg from '../img/welcome_bg.jpg';
import Button from "./Button";

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
  background: url(${bg}) no-repeat center;
  background-size: cover;
`;

const Footer = styled.div`
  width: 55%;
  display: flex;
  justify-content: space-between;

  .button {
    cursor: pointer;
  }

  .button:hover {
    background-color: rgba(140, 71, 255, 1);;
  }
`

const Welcome = ({history}) => {
  const handleClick = () => {
    history.push('/course/registration');
  };

  return (
    <Wrapper>
      <Flex
        height="100%"
        p={40}
        flexDirection="column"
        flex="1 0"
        justifyContent={['flex-start', 'flex-start', 'flex-end']}
      >
        <Text color="#fff" fontSize="1.25rem">
          Курс по деловой этике поставщиков
        </Text>
        <Box my={40}>
          <Text
            color="#101828"
            fontSize={['2.25rem', '2.25rem', '3.75rem']}
            lineHeight={['2.5rem', '2.5rem', '4rem']}
            display="inline"
            bg="#f6c043"
            fontWeight={500}
          >
            Как построить доверительные отношения и стать надежным партнером «Ростелекома»
          </Text>
        </Box>
        <Footer>
          <Text color="#fff" fontSize="1.75rem" mb={'10px'}>
            Долго и счастливо
          </Text>
          <Button title='Начать обучение' className="button" onClick={handleClick}/>
        </Footer>
      </Flex>
    </Wrapper>
  );
};

export default memo(Welcome);
