import interceptor from "../../../../../helpers/API";

export const fetchAccounts = async (page, size) => {
  try {
    const {data} = await interceptor.get("admin/users", {
      params: {
        page: page + 1, size
      }
    });
    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}

export const deleteAccount = async (id) => {
  try {
    await interceptor.delete(`admin/users/${id}`, {
      params: { id },
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const getProfile = async (id) => {
  try {
   const {data} =  await interceptor.get(`admin/users/${id}`, );
   return data
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}

export const editProfile = async (value,id,listPermissions) => {
  try {
    const newData = value?.password ? {
        email: value?.email?.trim(),
        fullName: value?.fio?.trim(),
        positionInCompany: value?.post?.trim(),
        password: value?.password?.trim(),
        permissions: listPermissions
      } :
      {
        email: value?.email?.trim(),
        fullName: value?.fio?.trim(),
        positionInCompany: value?.post?.trim(),
        permissions: listPermissions
      }
    await interceptor.patch(`admin/users/${id}`, newData);
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}

export const editPermissions = async (value,id) => {
  try {
    await interceptor.put(`admin/users/${id}/permissions`, value);
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}
