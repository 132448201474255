import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { fetchPublishedNews } from '../requests';
import { getNormalizedPublishedNews } from '../utils/getNormalizedPublishedNews';
import { useRouter } from '../../../hooks';

export const usePublishedNews = () => {
  const [{ value }, getNews] = useAsyncFn(fetchPublishedNews);
  const [publishedNews, setPublishedNews] = useState();

  const {
    query: { id },
  } = useRouter();

  useEffect(async () => {
    await getNews({ id });
  }, []);

  useEffect(() => {
    if (value) {
      setPublishedNews(getNormalizedPublishedNews(value));
    }
  }, [value]);

  return { publishedNews };
};
