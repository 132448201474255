import React, { memo } from 'react';
import { Box, Text } from 'rebass';
import { Icon } from 'atoms';
import styled from 'styled-components';

const PlayWrap = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
`;

const VideoPreview = ({ title, videoSrc, setPlayingVideo, fileId ,id}) => {
  return (
    <Box width={[1, 1 / 2, 1 / 2, 1 / 3]}>
      <Box
        mr={[0, 24]}
        mb="24px"
        bg="#fff"
        sx={{
          borderRadius: 10,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={() => setPlayingVideo(videoSrc, fileId,id)}
      >
        <PlayWrap>
          <Icon name="play" w="16" h="16" viewBox="0 0 320 320" />
        </PlayWrap>
        <Box p="24px 20px">
          <Box minHeight={[0, 112]}>
            <Text
              fontSize={['1.25rem', '1.25rem', '1.5rem']}
              lineHeight={['1.5rem', '1.75rem']}
              fontWeight="bold"
            >
              {title}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(VideoPreview);
