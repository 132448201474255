import { LIST_OF_MOUNTHS } from './constants';

export const getUniqYearsByNews = (news) =>
  [
    ...new Set(
      news.reduce((acc, elem) => {
        acc.push(elem.date.split('.')[2]);
        return acc;
      }, []),
    ),
  ].map((yaer) => ({
    title: yaer,
    value: yaer,
  }));

export const getUniqMounthsByNews = (news) =>
  [
    ...new Set(
      news.reduce((acc, elem) => {
        acc.push(elem.date.split('.')[1]);
        return acc;
      }, []),
    ),
  ].map((mounth) => ({
    title: LIST_OF_MOUNTHS[Number(mounth) - 1],
    value: mounth,
  }));

export const getQueryParams = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
  const query = new URLSearchParams(obj).toString();

  return query;
};
