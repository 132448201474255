import interceptor from '../../../helpers/API';

export const fetchListVideo = async () => {
  try {
    const {data} = await interceptor.get('/video');
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchListArticles = async (page, size) => {
  try {
    const {data} = await interceptor.get('/articles', {params: {page, size}});
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchArticle = async (id) => {
  try {
    const {data} = await interceptor.get(`/articles/${id}`);
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchFile = async (id) => {
  try {
    const { data } = await interceptor.get(`files/${id}`, { params: { id }, responseType: 'blob' });
    return data
  } catch (e) {
    throw new Error(e);
  }
};
