import { getNormalizeDate } from '../../../../../utils';

export const getNormalizedNews = (data) => {
  return {
    access: data?.accessType,
    date: getNormalizeDate(data?.publicationDate),
    id: data?.id,
    title: data?.title,
    status: data?.publishStatus ,
  };
};
