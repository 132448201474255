import React, { memo } from 'react';
import { Route } from 'react-router-dom';
import { Menu } from 'modules/Menu';
import { Flex, Box } from 'rebass';
import Footer from './Footer';

const MainLayout = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
          minHeight="100vh"
        >
          <Menu />
          <Flex flex="1 0" width="100%">
            <Box width="100%" bg="#FAFAFA">
              <Flex flexWrap="wrap" width="100%" height="100%" justifyContent="center">
                <Component {...props} />
              </Flex>
            </Box>
          </Flex>
          <Footer />
        </Flex>
      )}
    />
  );
};

export default memo(MainLayout);
