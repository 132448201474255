import React from 'react';
import styled from 'styled-components';

import {useDropZone} from "../../../hooks/useDropZone";

const Label = styled.label`
  width: ${({width = '180px'}) => width};
  margin: ${({margin = 0}) => margin};
  padding: 14px 8px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  line-height: 1;
  background: '#fff';
  color: #7800ff;
  border: 2px solid #7800ff;
  cursor: pointer;
  overflow: hidden;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Button = styled.div`
  display: flex;
  width: max-content;

  .text {
    font-size: 12px;
    color: #777;
    margin-bottom: 5px;
    margin-left: 10px;
  }
`

const File = ({file, changeFile, setFileError,accept,maxSize,isShowFile = true}) => {
  const {getInputProps, open} = useDropZone({
    onChange: changeFile,
    accept,
    multiple: false,
    maxSize,
    setFileError
  });

  return (
    <Button alignItems="center">
      <Label onClick={open} htmlFor="upload-button">{isShowFile && file?.name || 'Прикрепить файл'}</Label>
      <input {...getInputProps()} type="file"/>
      <div>
        <div className='text'>Максимальный размер файла 10 мб</div>
        <div className='text'>Допустимый формат файла: pdf, doc, docx</div>
      </div>
    </Button>
  );
};

export default File;

