import React, { memo } from 'react';
import { Box, Text } from 'rebass';
import { StyledLink } from 'atoms';

const ReadMoreFaqs = ({ withBoxShadow = false, link }) => {
  return (
    <Box
      bg="#fff"
      p="1.5rem"
      height="max-content"
      sx={{
        borderRadius: 10,
        boxShadow: withBoxShadow ? '0 0 10px rgb(0 0 0 / 10%)' : 'none',
        border: !withBoxShadow ? '1px solid #E2E2E2' : 'none',
      }}
    >
      <Text fontSize="1.5rem" fontWeight="bold" mb="16px">
        Узнайте больше!
      </Text>
      <Text fontSize="1rem" lineHeight="1.5rem" mb="16px">
        <p>Принципы и правила работы Ростелеком</p>
        <p>в теории и на практике.</p>
      </Text>
      <StyledLink to={link || '/faqs'}>Перейти</StyledLink>
    </Box>
  );
};

export default memo(ReadMoreFaqs);
