import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import DataLayout from '../DataLayout';
import {FILTER_TYPES, ARTICLE_STATUSES, ACCESS} from '../constants';
import {setSort} from '../../../../store/reducers/Faqs.reducers';
import {useFaqs} from './hooks/useFaqs';
import {useFilters} from "../../hooks/useFilter";
import {getInitFiltersParams} from "../../../../utils/getInitFiltersParams";
import {filterList, initialFilters} from "./constants";
import {usePagination} from "../../hooks/usePagination";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const Faqs = () => {
  const {sort, dateRange} = useSelector((state) => state.faqs);
  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();
  const initValues = getInitFiltersParams(filterList);
  const initDate = initValues?.date?.[0] ? [new Date(initValues?.date?.[0]), new Date(initValues?.date?.[1])] : null
  const actions = [{link: 'edit', label: 'Добавить вопрос', disabled:!checkedPermission(permissions.questionsAnswersWrite)}];

  const {filter, handleChangeFilters, resetFilters} = useFilters({...initValues, date: initDate}, initialFilters);
  const {faqsList, handleDeleteFaqs, updateTableData, getFaqs, status} = useFaqs(
    filter,
    setAllPages,
    currentPage,
    pageSize,
    setAllElements,
  );
  const dispatch = useDispatch();

  const filterItems = [
    {
      name: 'date',
      type: FILTER_TYPES.DATE_RANGE,
      value: filter.date,
      initialValue: dateRange,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'status',
      label: 'Статус',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ARTICLE_STATUSES.ALL},
        {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
        {title: 'Неопубликованные', value: ARTICLE_STATUSES.UNPUBLISHED},
      ],
      value: filter.status,
      initialValue: ARTICLE_STATUSES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'access',
      label: 'Доступ',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ACCESS.ALL},
        {title: 'Открытые', value: ACCESS.PUBLIC},
        {title: 'Закрытые', value: ACCESS.PRIVATE},
      ],
      value: filter.access,
      initialValue: ACCESS.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
  ];

  const headCells = [
    {
      id: 'dateSort',
      numeric: false,
      disablePadding: true,
      label: 'Дата публикации',
      status: sort.dateSort,
    },
    {
      id: 'titleSort',
      numeric: true,
      disablePadding: false,
      label: 'Заголовок',
      status: sort.titleSort,
    },
    {
      id: 'statusSort',
      numeric: true,
      disablePadding: false,
      label: 'Статус',
      status: sort.statusSort,
    },
  ];

  const handleResetFilters = async () => {
    await resetFilters();
    await getFaqs({
      startDate: '',
      endDate: '',
      accessType: '',
      publishStatus: '',
      page: 1,
      size: 10,
      sort: "publicationDate,DESC",
    });
    setPage(0)
  };

  return (
    checkedPermission(permissions.questionsAnswersRead) &&
    <DataLayout
      title="Вопрос-ответ"
      headCells={headCells}
      rows={faqsList}
      filterItems={filterItems}
      actions={actions}
      accessFilter={updateTableData}
      status={status}
      handleRequestSort={(name) => dispatch(setSort(name))}
      resetFilters={handleResetFilters}
      deleteElement={handleDeleteFaqs}
      pagination={pageSize}
      setPagination={setCountElements}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
    />
  );
};

export default Faqs;
