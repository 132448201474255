import React, {useState} from 'react';
import {Box, Text} from 'rebass';
import {Controller, useWatch} from "react-hook-form";
import {isEmpty} from "ramda";

import * as S from './styles'
import {Button, Input} from "../../../../common/atoms";
import {Captcha, Modal} from "../../../../common/molecules";
import File from "../File";
import {accept, maxSize} from "../../constants";
import FileElement from "../FileElement/FileElement";
import Comments from "../Comments/Comments";
import {useFormViolation} from "../../hooks/useFormViolation";

const fieldNames = {
  captcha: 'captcha',
  comment: 'comment'
}

const ViolationDetailed = ({status, isAlert, setIsAlert, setStatus, ...rest}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const {
    control,
    isValid,
    isDirty,
    setValue,
    reset,
    onSubmit,
    loading,
    error
  } = useFormViolation(status?.pinCode, rest.setIsUpdateCaptcha, setIsOpenModal)

  const formValues = useWatch({
    control,
  })

  const changeFile = (file) => {
    setValue('file', file?.[0])
  }

  const closeModal = () => {
    setIsOpenModal(false)
    setStatus(null)
    reset()
  }

  return (
    <>
      <Box>
        <form onSubmit={onSubmit} noValidate>
          <Box mb={32}>
            <S.Content>
              <Text mb={20}>
                <h1>Информация по обращению:</h1>
              </Text>
              <div className='text'><span>Номер обращения:</span><span>{status?.number}</span></div>
              <div className='text'><span>Состояние:</span><span>{status?.status}</span></div>
              <div className='text'><span>Дата создания:</span><span>{status?.date}</span></div>
              {status?.isAnonymous ? <div className="anonymous">Обращение подано анонимно</div> : <S.User>
                <div>
                  <div className='title'>Ваши контактные данные:</div>
                  <div className='text'><span>Имя:</span><span>{status?.fullName}</span></div>
                  <div className='text'><span>Телефон:</span><span>{status?.phoneNumber}</span></div>
                  <div className='text'><span>E-Mail:</span><span>{status?.email}</span></div>
                </div>
              </S.User>}

              <div>
                <div className='title'>Текст обращения:</div>
                <div className='appeal'>{status?.text}</div>
              </div>
            </S.Content>
            {!isEmpty(status?.comments) && <Comments comments={status?.comments}/>}
            <S.Comment>
              <div className={'comment'}>Добавить комментарий</div>
              <Controller
                name={fieldNames.comment}
                control={control}
                render={({field: {onChange, value,}, fieldState: {error, isTouched}}) => (
                  <Input
                    id="comment"
                    name="comment"
                    value={value}
                    multiline
                    rows={4}
                    variant="outlined"
                    required
                    mb="32px"
                    onChange={onChange}
                  />
                )}
              />
            </S.Comment>
            <File {...rest} file={formValues?.file} accept={accept} maxSize={maxSize} setIsAlert={setIsAlert}
                  isShowFile={false} changeFile={changeFile}/>
            {formValues?.file && <FileElement  {...rest} file={formValues?.file} changeFile={changeFile}/>}
            <Box mt={24}>
              <Controller
                name={fieldNames.captcha}
                control={control}
                render={({field: {onChange, value}}) => (
                  <Captcha
                    {...rest}
                    value={value}
                    name='captcha'
                    onChange={onChange}
                  />
                )}
              />
            </Box>
            <Box width={[1, 200]} mb={[24, 0]}>
              <Button variant="primary" title="Отправить" disabled={!isValid || !isDirty || loading} loading={loading}/>
            </Box>
          </Box>
        </form>
        <Modal
          isOpen={isOpenModal}
          onClose={closeModal}
          body={<S.Modal>
            <div
              className='text'>{error ? "При обработке запроса произошла ошибка" : "Ваше сообщение успешно отправлено"}</div>
            <Button variant="primary" title="Закрыть" onClick={closeModal}/>
          </S.Modal>}
          header="Ошибка"
        />
      </Box>
    </>
  );
};

export default ViolationDetailed;
