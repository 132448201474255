import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledButton = styled(Button)`
  margin-right: ${({ mr = 0 }) => mr};
  text-transform: none;
`;

const BasicButton = ({ label, link,disabled = false, ...rest }) => {
  if (link) {
    return (
      <Link to={!disabled && link} style={{cursor: disabled ?'auto': 'pointer'}}>
        <StyledButton {...rest} disabled={disabled}>{label}</StyledButton>
      </Link>
    );
  }

  return <StyledButton {...rest} disabled={disabled}>{label}</StyledButton>;
};

export default memo(BasicButton);
