import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';

import {ACCESS, ARTICLE_STATUSES, DOCUMENT_TYPES} from "../../constants";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {isEmpty} from "ramda";
import {VALIDATION} from "../../../../../store/statusConstants";

const initialValues = {
  title: '',
  status: ARTICLE_STATUSES.NOT_PUBLISHED,
  access: ACCESS.PRIVATE,
  text: '',
  documentType: DOCUMENT_TYPES.ANTI_CORRUPTION,
};

export const errorMsg = {
  requiredFile: 'Загрузите файл документа',
};

export const fieldNames = {
  title: 'title',
  status: 'status',
  access: 'access',
  text: 'text',
  documentType: 'documentType',
  file: 'file'
};

export const folderRules = Yup.object({
  [fieldNames.file]: Yup.mixed().required(errorMsg.requiredFile),
});

export const useFormValue = (defValue, handleEditDocs, addDocument, handleAddFile, setIsModalOpen) => {
  const {id} = useParams();

  const {
    control,
    handleSubmit,
    formState: {isValid, isDirty, errors},
    getValues,
    setValue,
    reset,
    clearErrors
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(folderRules),
  });

  const errorsList = Object.values(errors)?.map((item) => item?.message)

  const onSubmit = handleSubmit((values) => {
    setIsModalOpen(true);
    if (id) {
      handleEditDocs(values, handleAddFile);
    } else {
      addDocument(values);
    }
  });

  useEffect(() => {
    reset(defValue);
  }, [defValue]);

  useEffect(() => {
    if (!isEmpty(errorsList)) {
      setIsModalOpen(true)
    }
  }, [errorsList])

  const validationStatus = !isEmpty(errorsList) && VALIDATION

  return {control, isValid, isDirty, getValues, setValue, onSubmit, errorsList, validationStatus,clearErrors};
}
