import React, { memo } from 'react';
import { Box, Text } from 'rebass';

const Prohibited = () => {
  return (
    <Box
      bg="#fff"
      p="1.5rem"
      height="max-content"
      sx={{
        borderRadius: 10,
        border: ' 2px solid #ff6a00',
      }}
    >
      <Text fontSize="1.5rem" fontWeight="bold" mb="16px">
        Запрещается!
      </Text>
      <Text fontSize="1rem" lineHeight="1.5rem" mb="16px">
        <p>
          Использование телефонной «Линии Доверия» и других каналов обратной связи для
          предоставления ложной информации и сведения личных счетов.
        </p>
      </Text>
    </Box>
  );
};

export default memo(Prohibited);
