import React, { memo } from 'react';
import styled from 'styled-components';
import MaterialSlider from '@material-ui/core/Slider';

const Wrapper = styled.span`
  .MuiSlider-thumb {
    color: #f6c043;
  }
  .MuiSlider-track {
    color: #6d12f5;
  }
  .MuiSlider-rail {
    color: #6d12f5;
  }
  .MuiSlider-mark,
  .MuiSlider-markActive {
    background-color: #6d12f5;
    color: #6d12f5;
    width: 4px;
    height: 4px;
  }
`;

const Slider = (props) => {
  return (
    <Wrapper>
      <MaterialSlider aria-labelledby="discrete-slider" {...props} valueLabelDisplay="off" />
    </Wrapper>
  );
};

export default memo(Slider);
