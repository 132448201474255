import {useEffect, useState} from "react";
import {useAsyncFn} from "react-use";

import {deleteEducation, fetchEducation} from "../requests";
import {useStatuses} from "../../../../../hooks/useStatuses";
import {getNormalizedEducation} from "../utils";
import {saveParamsToUrl} from "../../../../../utils/saveParamsUrl";

export const useEducation = (filters, pageSize, page, setAllPages, setAllElements) => {
  const [{value, error, loading}, getEducation] = useAsyncFn(fetchEducation);
  const [listEducation, setListEducation] = useState([])
  const {status} = useStatuses(loading, value?.content, error, true);
  const [, delEducation] = useAsyncFn(deleteEducation);

  const params = {
    docCategory: filters.docCategory === 'ALL' ? '' : filters.docCategory,
    size: pageSize,
    page: page + 1,
    publishStatus: filters?.status === 'ALL' ? '' : filters?.status,
    accessType: filters?.access === 'ALL' ? '' : filters?.access,
  };

  const handleGetEducation = async () => {
    await getEducation(params, filters);
    saveParamsToUrl({...filters})
  };

  const handleDeleteDocs = async (id) => {
    await delEducation(id);
    await getEducation();
  };

  useEffect(() => {
    getEducation(params,filters);
  }, [page, pageSize]);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedEducation(item);
      });
      setListEducation(normalizedData);
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);
    }
  }, [value]);

  return {handleGetEducation, listEducation, getEducation, status,handleDeleteDocs};
}
