import React, { useState, memo } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Icon } from 'atoms';

const Filter = ({ children }) => {
  const [isOpened, toggleOpened] = useState(false);
  return (
    <Box
      width="100%"
      p="8px 16px"
      sx={{
        border: '1px solid #E4E4E4',
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        onClick={() => toggleOpened(!isOpened)}
      >
        <Box>
          <Text color="#4897DF">Показать фильтр</Text>
        </Box>
        <Box>
          <Icon name="filter" viewBox="0 0 459 459" />
        </Box>
      </Flex>
      {isOpened ? <Box width="100%">{children}</Box> : null}
    </Box>
  );
};

export default memo(Filter);
