import styled from "styled-components";

export const Education = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  p {
    margin-bottom: 21px;
    font-size: 16px;
    line-height: 1.38;
  }

  h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    margin-bottom: 13px;
  }

  img {
    width: calc(100% - 10px);
  }

  .education {
    h1 {
      margin-top: -4px;
    }
  }
`

export const Content = styled.div`
  width: 1300px;
  padding-left: 30px;
  padding-right: 30px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 16px;
  }
`

export const CourseContent = styled.div`

  ul {
    padding-left: 15px;
  }
`

export const Training = styled(Education)``

export const Corruption = styled(CourseContent)``
