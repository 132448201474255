import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ARTICLE_STATUSES } from '../../modules/Admin/components/constants';

export const fetchBanner = createAsyncThunk('Banner/fetchBanner', async () => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  return mokeBanners;
});

export const createBanner = createAsyncThunk('banner/createBanner', async (data) => {
  console.log(data);
});

export const fetchBannerById = createAsyncThunk('article/fetchBannerById', async (id) => {
  //тут будет запрос на данные конкретной статьи
  return mokeBanner;
});

export const deleteBannerById = createAsyncThunk('banner/deleteBannerById', async (id) => {
  console.log(id);
  return mokeBanners.filter((item) => item.id !== id);
});

const BannerSlice = createSlice({
  name: 'banner',
  initialState: {
    listOfBanner: [],
    filter: {
      status: ARTICLE_STATUSES.ALL,
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      dateSort: true,
      titleSort: null,
      statusSort: null,
    },
    data: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchBanner.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchBanner.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfBanner = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchBanner.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchBannerById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchBannerById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchBannerById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createBanner.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createBanner.fulfilled]: (state, action) => {},
    [createBanner.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteBannerById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteBannerById.fulfilled]: (state, action) => {
      state.listOfBanner = action.payload;
      state.status = FULFILLED;
    },
    [deleteBannerById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = BannerSlice.actions;

export default BannerSlice.reducer;

const mokeBanner = {
  title: 'QQQQQQ',
  status: 'UNPUBLISHED',
  files: [],
  link: 'https://redux-toolkit.js.org/tutorials/quick-start',
};

const mokeBanners = [
  { id: 1, date: 1627761600, title: 'Не закрывай глаза', status: 'UNPUBLISHED' },
  { id: 2, date: 1627761600, title: 'Не закрывай глаза', status: 'UNPUBLISHED' },
  { id: 3, date: 1627761600, title: 'Не закрывай глаза', status: 'UNPUBLISHED' },
  { id: 4, date: 1627761600, title: 'Не закрывай глаза', status: 'PUBLISHED' },
  { id: 5, date: 1627761600, title: 'Не закрывай глаза', status: 'PUBLISHED' },
  { id: 6, date: 1627761600, title: 'Не закрывай глаза', status: 'PUBLISHED' },
  { id: 7, date: 1627761600, title: 'Не закрывай глаза', status: 'PUBLISHED' },
];
