import React, { memo } from 'react';
import { Flex, Box } from 'rebass';
import { PageTitle } from 'atoms';

const TwoColumnsLayout = ({ title, tabs, left, right }) => {
  return (
    <Box width="100%" p="40px 0 0px">
      <Flex justifyContent="center">
        <Box width="100%" maxWidth={['100%', '100%', '100%', 1300]} px={[16, 32, 48]}>
          <PageTitle>{title}</PageTitle>
          {tabs ? <Box mb="24px">{tabs}</Box> : null}
        </Box>
      </Flex>
      <Flex bg="#fff" flexDirection="column" alignItems="center" pt={[24, 24, 40]} pb="80px">
        <Flex
          width="100%"
          flexWrap="wrap"
          maxWidth={['100%', '100%', '100%', 1300]}
          px={[16, 32, 48]}
        >
          <Flex width={[1, 1, 1, 2 / 3]} flexWrap="wrap">
            {left}
          </Flex>

          <Box width={[1, 1, 1, 1 / 3]} pl={[0, 0, 0, 24]}>
            {right}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default memo(TwoColumnsLayout);
