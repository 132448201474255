import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';

import { fetchNews, deleteNews } from '../requests';
import { getNormalizedNews } from '../utils';
import { setStatus } from '../../../../../store/reducers/News.reducers';
import { LOADING } from '../../../../../store/statusConstants';
import { useStatuses } from '../../../../../hooks/useStatuses';
import {saveParamsToUrl} from "../../../../../utils/saveParamsUrl";


export const useNews = (filters = {}, setAllPages, page, pageSize, setAllElements) => {
  const [{ value, loading, error }, getNews] = useAsyncFn(fetchNews);
  const [, delNews] = useAsyncFn(deleteNews);
  const [newsList, setNews] = useState([]);
  const dispatch = useDispatch();
  const { status } = useStatuses(loading, value?.content, error, true);

  const params = {
    size: pageSize,
    page: page + 1,
    publishStatus: filters?.status === 'ALL' ? '' : filters?.status,
    accessType: filters?.access === 'ALL' ? '' : filters?.access,
    sort:"publicationDate,DESC",
  };

  useEffect(async () => {
    await getNews(params, filters);
  }, [pageSize, page]);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedNews(item);
      });
      setNews(normalizedData);
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);
    }
  }, [value]);

  const handleDeleteNews = async (id) => {
    await delNews(id);
    await getNews(params, filters);
  };

  const updateTableData = async () => {
    dispatch(setStatus(LOADING));
    saveParamsToUrl({...filters})
    await getNews(params, filters);
  };

  return { newsList, handleDeleteNews, updateTableData, getNews, status };
};
