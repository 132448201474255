import {useAsyncFn} from "react-use";

import {fetchAddMaterial} from "../requests";
import {useStatuses} from "../../../../../hooks/useStatuses";

export const useAddedMaterial = (setIsModalOpen, handleAddFile) => {
  const [{loading, value, error}, execute] = useAsyncFn(fetchAddMaterial);

  const addMaterial = async (value) => {
    const resp = await handleAddFile();
    if (resp?.id) {
      await execute(value, resp?.id)
    } else {
      await execute(value);
    }
    setIsModalOpen(true)
  }

  const {status} = useStatuses(loading, value, error);

  const addStatus = status;

  return {addMaterial, addStatus, value, error}
}
