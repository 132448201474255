import React from 'react';
import { Flex } from 'rebass';

const NotFound = () => (
  <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
    <h1>Страница не найдена</h1>
  </Flex>
);

export default NotFound;
