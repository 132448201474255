import * as yup from "yup";

export const validationSchemaViolation = yup.object({
  name: yup.string().when('isAnonymous', ([isAnonymous], schema) => {
    return isAnonymous === 1 ?  yup.string().required('Поле обязательно для заполнения') : schema;
  }),
  email: yup.string().email().when('isAnonymous', ([isAnonymous], schema) => {
    return isAnonymous === 1 ?  yup.string().email().required('Поле обязательно для заполнения') : schema;
  }),
  comment: yup.string().required('Поле обязательно для заполнения'),
  captcha: yup.string().required('Поле обязательно для заполнения'),
  isRulesAccepted: yup.bool().oneOf([true], 'Поле обязательно для заполнения'),
  isPersonalDataAccepted: yup.bool().oneOf([true], 'Поле обязательно для заполнения'),
});

export const initialValuesViolation = {
  isRulesAccepted: false,
  isAnonymous: 1,
  isPersonalDataAccepted: false,
  name: '',
  email: '',
  phone: '',
  comment: '',
  file: null,
  captcha: ''
}

export const initialValuesStatus = {
  pin: '',
  captcha: '',
  file:'',
  comment:''
}

export const validationSchemaStatus = yup.object({
  pin: yup.string().required('Поле обязательно для заполнения'),
  captcha: yup.string().required('Поле обязательно для заполнения'),
});

export const accept = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
};

export const maxSize = 10485760

export const anonymousId = 2
