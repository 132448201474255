import React from 'react';

import * as S from './styles'

import Button from "../../../Button";
import {Modal} from "molecules";

const ModalWindow = ({isOpen, onClose}) => {

  const getContent = () =>
    <S.Content>
      <h1>Ошибка</h1>
      <p>При обработке запроса произошла ошибка,<br/>повторите попытку позже</p>
      <Button
        variant="outlined"
        color="error"
        label="Закрыть"
        onClick={onClose}
      >
        Закрыть
      </Button>
    </S.Content>

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      body={getContent()}
      header="Ошибка"
    />
  );
};

export default ModalWindow;
