import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Zoom from '@material-ui/core/Zoom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom in ref={ref} {...props} />;
});

export default function CustomizedDialogs({ isOpen, onClose, body }) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen || false}
      TransitionComponent={Transition}
    >
      <MuiDialogContent>{body}</MuiDialogContent>
    </Dialog>
  );
}
