import styled from "styled-components";

export const Table = styled.div`
`

export const Header = styled.div`
  display: grid;
  font-weight: normal;
  grid-template-columns:340px  240px 240px;


  grid-gap: 2px;

  .td {
    box-sizing: border-box;
    padding: 10px;
    background: #ddecf3;
    color: #065d88;
    border-color: #ecf4f8 #87afc8 #87afc8 #ecf4f8;
    border: solid 1px;
  }
`
