import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {useAsyncFn} from "react-use";

import {setComment} from "../requests";
const initVal = {
  captcha: '',
  file: '',
  comment: ''
}

const validationSchemaStatus = yup.object({
  comment: yup.string().required('Поле обязательно для заполнения'),
  captcha: yup.string().required('Поле обязательно для заполнения'),
});


export const useFormViolation = (pinCode,setIsUpdateCaptcha,setIsOpenModal) => {
  const [{value,loading,error}, execute] = useAsyncFn(setComment)

  const {
    control,
    handleSubmit,
    formState: {isValid, isDirty, errors},
    getValues,
    setValue,
    reset,
    clearErrors
  } = useForm({
    mode: 'onChange',
    defaultValues: initVal,
    resolver: yupResolver(validationSchemaStatus),
  });

  const onSubmit = handleSubmit((values) => {
    execute(pinCode,values)
  });

  useEffect(() => {
    if (value) {
      setIsUpdateCaptcha(true)
      setIsOpenModal(true)
    }
    if (error) {
      setIsOpenModal(true)
    }
  }, [value, error])

  return {
    control,
    handleSubmit,
    isValid,
    isDirty,
    errors,
    getValues,
    setValue,
    reset,
    clearErrors,
    onSubmit,
    loading,
    error
  }
}
