import React from 'react';
import {Controller} from "react-hook-form";

import * as S from './styles'
import {fields} from "./constants";
import Table from "../Table";
import Button from "../../../Button";

export const Form = ({control, onSubmit,disabled, permissions,...rest}) => {
  return (
    <>
      <S.Content>
        {fields.map(({name, label, component, disabled, required, ...rest}) => (
          <S.Field key={name}>
            <S.Label>
              {required && <div className='required'>*</div>}
              {label}
            </S.Label>
            <Controller
              name={name}
              control={control}
              render={({field, fieldState: {isTouched, error}}) =>
                component({...field, ...rest, name, isTouched, error, disabled})
              }
            />
          </S.Field>
        ))}
        <div className={'table'}>
          <span>Права доступа</span>
          <div>
            <Table {...rest} permissions={permissions}/>
            <Button
              variant="contained"
              label="Сохранить изменения"
              className='button'
              onClick={onSubmit}
              disabled={disabled}
            />
          </div>
        </div>
      </S.Content>
    </>
  );
};
