import React, { memo } from 'react';
import { Flex, Box } from 'rebass';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import LayoutConclusion from './LayoutConclusion';
import TextTitle from './TextTitle';
import TextContent from './TextContent';
import images from '../img';
import data from '../data';

const ModuleConclusion = () => {
  const { moduleId, conclusionId } = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const { title, text, nextLink, bg } = find(module.conclusion, ['id', +conclusionId || 1]);

  const renderRight = () => {
    return (
      <Box>
        <TextTitle color="#fff">{title}</TextTitle>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1, 1 / 2]} pr="20px">
            <TextContent color="#fff">{text[0]}</TextContent>
          </Box>
          <Box width={[1, 1, 1, 1 / 2]} pr="20px">
            <TextContent color="#fff">{text[1]}</TextContent>
          </Box>
        </Flex>
      </Box>
    );
  };

  return (
    <LayoutConclusion
      pagetTitle={`Модуль ${module.id}. ${module.id === 2 || module.id === 3 ? '' : 'Кейс'}`}
      leftContent={null}
      rightContent={renderRight()}
      bgImage={images[bg]}
      nextLink={nextLink}
    />
  );
};

export default memo(ModuleConclusion);
