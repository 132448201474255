import React from 'react';

import * as S from './styles'
import {listAccess} from "../../constants";
import TableElement from "../TableElement";

const Table = ({checked, onChecked,permissions}) => {

  return (
    <S.Table>
      <S.Header>
        <div className='td'>Право</div>
        <div className='td'>Просмотр</div>
        <div className='td'>Редактирование</div>
      </S.Header>
      {listAccess?.map((item) => <TableElement key={item.id} item={item} permissions={permissions} checked={checked}
                                               onChecked={onChecked}/>)}
    </S.Table>
  );
};

export default Table;
