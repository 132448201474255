import React, { memo } from 'react';
import { Box, Text } from 'rebass';
import { Link } from 'react-router-dom';

const NewsPreviewItem = ({ item: { id, title, date } }) => {
  return (
    <Box
      width={[1, 1, 'calc(50% - 20px)', 'calc(25% - 20px)']}
      p="24px"
      mr="20px"
      mb="20px"
      bg="#fff"
      sx={{ boxShadow: '0 0 10px rgb(0 0 0 / 10%)', borderRadius: 10 }}
    >
      <Link to={`/news/${id}`}>
        <Text fontSize="0.875rem" color="rgba(0, 0, 0, 0.5)" mb="16px">
          {date}
        </Text>
        <Text fontSize="1.125rem" lineHeight="1.5rem" fontWeight="bold">
          {title}
        </Text>
      </Link>
    </Box>
  );
};

export default memo(NewsPreviewItem);
