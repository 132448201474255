import styled, {keyframes} from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;

  button {
    width: max-content;
  }
`

export const Header = styled.h1`
  font-size: 24px;
  color: #333;
  font-weight: 500;
  border-bottom: 2px solid #ccc;
  padding: 0 0 24px 0;
`

export const Date = styled.div`
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
`
const spin = keyframes`
  to {
    -webkit-transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: deepskyblue;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
  margin-bottom: 40px;
`;
