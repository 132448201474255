import React from 'react';

import * as S from './styles'
import CommentElement from "../CommentElement/CommentElement";

const Comments = ({comments}) => {
  return (
    <S.Content>
      <h1>Коментарии к обращению</h1>
      {comments?.map((item) => <CommentElement key={item?.id} comment={item}/>)}
    </S.Content>
  );
};

export default Comments;
