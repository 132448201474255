import React, { memo } from 'react';
import { Text, Box } from 'rebass';
import ReactAccordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';
import vendorCss from './Accordion.styled';
import {FileElement} from "../../../modules/Faqs/components/FileElement";

const Wrapper = styled.div`
  ${vendorCss}
  line-height: 1.5rem;
  &&& .accordion-button {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
    outline: 0;
    box-shadow: none;
    @media (max-width: 768px) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  &&& .accordion-button::after {
    position: absolute;
    left: 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
`;

const Accordion = ({ items }) => {
  return (
    <Wrapper>
      <ReactAccordion flush>
        {items?.map(({ id, title, text ,file}) => (
          <ReactAccordion.Item key={id} eventKey={id}>
            <ReactAccordion.Header>
              <Text fontSize={['1rem', '1rem', '1.5rem']} fontWeight="bold" lineHeight="1.17">
                {title}
              </Text>
            </ReactAccordion.Header>
            <ReactAccordion.Body>
              <Box pl="20px" pb="24px" dangerouslySetInnerHTML={{ __html: text }} />
              <FileElement file={file}/>
            </ReactAccordion.Body>
          </ReactAccordion.Item>
        ))}
      </ReactAccordion>
    </Wrapper>
  );
};

export default memo(Accordion);
