import * as Yup from "yup";
import {errorMsg, fieldNames} from "../Profile/components/Form/constants";

export const initialValues = {
  name: '',
  fio: '',
  email: '',
  post: '',
  password: '',
};

export const listAccess = [
  {id: 1, title: 'Новости', view: 'NEWS_READ', editing: 'NEWS_WRITE'},
  {id: 2, title: 'Интересные материалы', view: 'ARTICLES_READ', editing: 'ARTICLES_WRITE'},
  {id: 3, title: 'Вопрос-ответ', view: 'QUESTIONS_ANSWERS_READ', editing: 'QUESTIONS_ANSWERS_WRITE'},
  {id: 4, title: 'Документы', view: 'DOCS_READ', editing: 'DOCS_WRITE'},
  {id: 5, title: 'Обучение', view: 'EDUCATION_READ', editing: 'EDUCATION_WRITE'},
  {id: 6, title: 'Обратная связь', view: 'FEEDBACKS_READ', editing: 'FEEDBACKS_WRITE', isDisabledWrite: true},
  {id: 7, title: 'Задать вопрос', view: 'QUESTIONS_READ', editing: 'QUESTIONS_WRITE'},
  {id: 8, title: 'Учетные записи', view: 'USERS_READ', editing: 'USERS_WRITE'},
  {id: 9, title: 'Скачать отчёт', view: 'REPORT_READ', editing: 'REPORT_WRITE', isDisabledWrite: true},
]

export const folderRules = Yup.object({
  [fieldNames.fio]: Yup.string().required(errorMsg.required('Ф.И.О')),
  [fieldNames.email]: Yup.string().email('Некорректный формат электронной почты').required(),
  [fieldNames.post]: Yup.string().required(errorMsg.required('Должность')),
});
