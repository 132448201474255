import React, {memo, useState} from 'react';

import {TwoColumnsLayout} from 'layouts';
import ViolationDetailed from "./ViolationDetailed/ViolationDetailed";
import StatusForm from "./StatusForm/StatusForm";

const ViolationStatus = () => {
  const [isUpdateCaptcha, setIsUpdateCaptcha] = useState(false)
  const [status, setStatus] = useState(null)
  const [isAlert, setIsAlert] = useState(false)

  const renderLeft = () => {
    return (
      <>
        {status &&
          <ViolationDetailed
            status={status}
            isAlert={isAlert}
            setIsAlert={setIsAlert}
            isUpdateCaptcha={isUpdateCaptcha}
            setIsUpdateCaptcha={setIsUpdateCaptcha}
            setStatus={setStatus}
          />
        }
        {!status && <StatusForm setStatus={setStatus} setIsAlert={setIsAlert} isAlert={isAlert}/>}
      </>
    )
      ;
  };

  return (
    <>
      <TwoColumnsLayout title="Статус Обращения" left={renderLeft()}/>
    </>
  );
};

export default memo(ViolationStatus);
