import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  /* other styles */

@font-face {
  font-family: "BasisGrotesquePro";
  font-weight: normal;
  font-style: normal;
  src: url("/fonts/BasisGrotesquePro-Regular-Web.woff") format("woff");
  }

@font-face {
  font-family: "BasisGrotesquePro";
  font-weight: 300;
  font-style: normal;
  src: url("/fonts/BasisGrotesquePro-Light-Web.woff") format("woff");
  }

@font-face {
  font-family: "BasisGrotesquePro";
  font-weight: 500;
  font-style: normal;
  src: url("/fonts/BasisGrotesquePro-Medium-Web.woff") format("woff");
  }

@font-face {
  font-family: "BasisGrotesquePro";
  font-weight: bold;
  font-style: normal;
  src: url("/fonts/BasisGrotesquePro-Bold-Web.woff") format("woff");
  }



   span, p, div {
        font-family: 'BasisGrotesquePro', sans-serif;
    }

  * {
    box-sizing: border-box;
  }

  a, a:hover, a:active, a:visited {
    text-decoration:none;
  }

  a:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  //TODO раскомментировать при необходимости

  //li {
  //  position: relative;
  //  margin-bottom: 15px;
  //  padding-left: 20px;
  //  &::marker {
  //    color: transparent;
  //  }
  //  &:before {
  //    content: '';
  //    display: block;
  //    position: absolute;
  //    transform: translateY(2px);
  //    width: 6px;
  //    height: 6px;
  //    border: 1px solid #7800FF;
  //    border-radius: 50%;
  //    left: 0;
  //    top: 7px;
  //  }
  //}

  strong {
    font-weight: bold;
  }

`;

export { GlobalStyle };
