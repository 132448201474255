import React from 'react';
import {Text} from "rebass";

import * as S from './styles'
import {useAsk} from "../../hooks/useAsk";

const DetailedAsk = () => {
  const {ask} = useAsk()
  const employee = ask?.isRostelecomEmployee ? 'Да' : 'Нет'

  return (
    <S.Content>
      <Text fontSize="24px" color="#333" fontWeight="500" mb='20px'>
        Вопрос
      </Text>
      <S.Line/>
      <div className={'ask'}><span>Ф.И.О:</span> <span>{ask?.fullName ? ask?.fullName : ''}</span></div>
      <div className={'ask'}><span>Эл.Почта:</span> <span>{ask?.email ? ask?.email : ''}</span></div>
      <div className={'ask'}><span>Текст вопроса:</span> <span>{ask?.text ? ask?.text : ''}</span></div>
      <div className={'ask'}><span>Сотрудник Ростелекома:</span>
        <span>{ask?.id ? employee : ''}</span>
      </div>
    </S.Content>
  );
};

export default DetailedAsk;
