import {useEffect, useState} from "react";

export const useAlert = (error) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (error) {
      setIsOpen(true)
    }
  }, [error])

  const closeAlert = () => {
    setIsOpen(false)
  }
  return {isOpen, closeAlert}
}
