import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import { Box } from 'rebass';
import LayoutCase from './LayoutCase';
import TextTitle from './TextTitle';
import TextContent from './TextContent';
import images from '../img';
import data from '../data';

const ModuleCase = () => {
  const { moduleId, caseId } = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const { title, text, nextLink, bg } = find(module.case, ['id', +caseId || 1]);

  const renderRight = () => {
    return (
      <Box>
        <TextTitle color="#fff">{title}</TextTitle>
        <TextContent color="#fff">{text}</TextContent>
      </Box>
    );
  };

  return (
    <LayoutCase
      pagetTitle={`Модуль ${module.id}. Кейс`}
      leftContent={null}
      rightContent={renderRight()}
      nextButtonDisabled={false}
      bgImage={images[bg]}
      nextLink={nextLink}
    />
  );
};

export default memo(ModuleCase);
