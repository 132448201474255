import {useAsyncFn} from "react-use";

import {fetchReport} from "../requests";
import {useEffect} from "react";

export const useReport = (date, setDate, openModal) => {
  const [{value, error,loading}, getRep] = useAsyncFn(fetchReport);

  const getReport = () => {
    getRep(date)
  }

  useEffect(() => {
    if (value && !error) {
      setDate(['', ''])
    }
  }, [value, error])

  useEffect(() => {
    if (error) {
      openModal()
    }
  }, [error])

  return {getReport,loading }
}
