import React from 'react';
import {Text} from "rebass";
import { Link } from 'react-router-dom';

import * as S from './styles'
import {useFormValue} from "../../hooks/useForm";
import {Form} from "../Form";
import {useProfile} from "../../hooks/useProfile";
import {useModal} from "../../hooks/useModal";
import {ModalContent} from "../Modal";
import {Modal} from "../../../../../../common/molecules";
import {useChecked} from "../../hooks/useChecked";
import {checkedPermission} from "../../../../../../utils/checkedPermission";
import {permissions} from "../../../../constants";

const EditProfile = () => {
  const {isOpeModal, closeModal, openModal} = useModal()
  const {profile, getUserData} = useProfile()

  const {listPermissions, ...rest} = useChecked(profile?.permissions)

  const {
    control, errorsList, onSubmit, isValid,
    isDirty,loading
  } = useFormValue(profile, openModal, getUserData,listPermissions)

  return (
    <S.Content>
      <Text fontSize="24px" color="#333" fontWeight="500" mb='20px'>
        Учетная запись
      </Text>
      <S.Line/>
      <Form
        {...rest}
        control={control} onSubmit={onSubmit} isValid={isValid}
            isDirty={isDirty} permissions={profile?.permissions}
        disabled={!isValid || !checkedPermission(permissions.usersWrite)}
      />
      <Link to={'/admin/accounts'}>Перейти к списку учетных записей</Link>
      <Modal
        isOpen={isOpeModal}
        onClose={closeModal}
        body={<ModalContent errorsList={errorsList} onClose={closeModal} isLoading={loading}/>}
      />
    </S.Content>
  );
};

export default EditProfile;
