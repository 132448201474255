import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Text, Box } from 'rebass';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';

const Header = ({ title }) => {
  let history = useHistory();

  const goBackToList = () => {
    history.goBack()
  };

  return (
    <Flex
      p="40px 0 24px 0"
      mb="40px"
      width="100%"
      alignItems="center"
      sx={{ borderBottom: '2px solid #ccc' }}
    >
      <ArrowBackIcon
        onClick={goBackToList}
        style={{ marginRight: '16px', fill: '#777', cursor: 'pointer' }}
      />
      <Text fontSize="24px" color="#333" fontWeight="500">
        {title}
      </Text>
    </Flex>
  );
};

const EditLayout = ({ title, children }) => {
  return (
    <Flex minHeight="calc(100vh - 60px)" flexDirection="column" p="0 40px 40px 40px" bg="#f9f9f9">
      <Header title={title} />
      <Box width="100%">
        <Paper elevation={2} sx={{ width: '100%', padding: '24px', marginBottom: '40px' }}>
          {children}
        </Paper>
      </Box>
    </Flex>
  );
};

export default EditLayout;
