import {useEffect, useState} from 'react';
import {useAsyncFn} from 'react-use';

import {useRouter} from '../../../../../hooks';
import {fetchDocumentById, fetchEditDocument} from '../requests';
import {ACCESS, ARTICLE_STATUSES, DOCUMENT_TYPES} from '../../constants';
import {useStatuses} from '../../../../../hooks/useStatuses';

const initialState = {
  title: '',
  status: ARTICLE_STATUSES.NOT_PUBLISHED,
  access: ACCESS.PRIVATE,
  text: '',
  documentType: DOCUMENT_TYPES.ANTI_CORRUPTION,
};

export const useEditDocument = (setIsModalOpen) => {
  const [{value}, getDocs] = useAsyncFn(fetchDocumentById);
  const [state, editDocs] = useAsyncFn(fetchEditDocument);
  const {status} = useStatuses(state?.loading, state?.value, state?.error);
  const editStatus = status

  const {
    query: {id},
  } = useRouter();
  const [document, setDocument] = useState(initialState);

  useEffect(() => {
    if (id) {
      getDocs(id);
    }
  }, [id]);

  const handleEditDocs = async (data, handleAddFie) => {
    setIsModalOpen(true);
    const resp = await handleAddFie();
    if (resp?.id || resp) {
      await editDocs(id, data, resp?.id);
    } else {
      await editDocs(id, data);
    }
  };

  useEffect(() => {
    if (value) {
      setDocument({
        title: value?.docName,
        status: value?.publishStatus,
        date: Date.now(value?.createdDate),
        access: value?.accessType,
        type: value?.newsType,
        file: {path: value?.file?.originalFilename, id: value?.file?.id},
        documentType: value?.docCategory,
        text: value?.docDescription,
      });
    }
  }, [value]);

  return {document, editDocs, handleEditDocs, editStatus};
};
