import {useAsyncFn} from "react-use";

import {useEffect, useState} from "react";
import {getNormalizedAccount} from "../utils";
import {useStatuses} from "../../../../../hooks/useStatuses";
import {deleteAccount, fetchAccounts} from "../requests";


export const useAccounts = (page, pageSize,setAllPages,setAllElements) => {
  const [{value,loading,error}, getAccounts] = useAsyncFn(fetchAccounts)
  const [, delAccounts] = useAsyncFn(deleteAccount)
  const [listAccounts, setListAccounts] = useState([])

  const { status } = useStatuses(loading, value?.content, error, true);

  const handleDeleteAccount = async (id) => {
    await delAccounts(id);
    await getAccounts(page, pageSize);
  };

  useEffect(() => {
    getAccounts(page, pageSize)
  }, [page, pageSize])

  useEffect(() => {
    if (value) {
      const newAccount = value?.content.map((item) => getNormalizedAccount(item))
      setListAccounts(newAccount)
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);
    }
  }, [value])

  return {listAccounts,status,handleDeleteAccount}
}
