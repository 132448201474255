import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactCheckbox from 'react-custom-checkbox';
import { Box } from 'rebass';

const Wrapper = styled.div`
  user-select: none;
  cursor: pointer;
  img,
  span {
    cursor: pointer;
  }
`;

const RaioIcon = styled.div`
  width: 10px;
  height: 10px;
  background-color: #7800ff;
  border-radius: 50%;
`;

const RadioGroup = ({ items, onChange,checked }) => {
  const [checkedId, setCheckedId] = useState(checked);

  useEffect(() => {
    onChange(checkedId);
  }, [checkedId]);

  useEffect(() => {
    setCheckedId(checked);
  }, [checked]);

  return (
    <Wrapper>
      {items.map(({ id, label }) => (
        <Box mb={16} key={id}>
          <ReactCheckbox
            icon={<RaioIcon />}
            checked={checkedId === id}
            label={label}
            style={{ cursor: 'pointer' }}
            labelStyle={{ marginLeft: 8, cursor: 'pointer' }}
            borderRadius="50%"
            borderColor="#D8D8D8"
            borderStyle="solid"
            borderWidth={1}
            size={20}
            disabled={checkedId === id}
            onChange={() => {
              setCheckedId(id);
            }}
          />
        </Box>
      ))}
    </Wrapper>
  );
};

export default memo(RadioGroup);
