import React from 'react';

import DataLayout from '../DataLayout';
import {FILTER_TYPES, ARTICLE_STATUSES, ACCESS} from '../constants';
import {useEducation} from "./hooks";
import {filterList, initialFilters} from "./constants";
import {getInitFiltersParams} from "../../../../utils/getInitFiltersParams";
import {useFilters} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const Education = () => {
  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();
  const initValues = getInitFiltersParams(filterList);
  const initDate = initValues?.date?.[0] ? [new Date(initValues?.date?.[0]), new Date(initValues?.date?.[1])] : null
  const {filter, handleChangeFilters, resetFilters} = useFilters({...initValues, date: initDate}, initialFilters);

  const {
    handleGetEducation,
    handleDeleteDocs,
    listEducation,
    getEducation,
    status
  } = useEducation(filter, pageSize, currentPage, setAllPages, setAllElements)

  const filterItems = [
    {
      name: 'date',
      type: FILTER_TYPES.DATE_RANGE,
      value: filter.date,
      initialValue: null,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'status',
      label: 'Статус',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ARTICLE_STATUSES.ALL},
        {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
        {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
      ],
      value: filter.status,
      initialValue: ARTICLE_STATUSES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'access',
      label: 'Доступ',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ACCESS.ALL},
        {title: 'Открытые', value: ACCESS.PUBLIC},
        {title: 'Закрытые', value: ACCESS.PRIVATE},
      ],
      value: filter.access,
      initialValue: ACCESS.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
  ];

  const headCells = [
    {id: 'dateSort', disablePadding: true, label: 'Дата публикации',},
    {id: 'titleSort', disablePadding: false, label: 'Заголовок',},
    {id: 'statusSort', disablePadding: false, label: 'Статус',},
  ];

  const actions = [
    {link: 'edit', label: 'Добавить материал', disabled:!checkedPermission(permissions.educationWrite)},
    // { link: 'test/edit', label: 'Добавить тест' },
  ];

  const handleResetFilters = async () => {
    await resetFilters();
    await getEducation({
      accessType: '',
      publishStatus: '',
      page: 1,
      size: 10,
      startDate: '',
      endDate: '',
      sort: "publicationDate,DESC",
    });
  };

  return (
    checkedPermission(permissions.educationRead) &&
    <DataLayout
      title="Обучение"
      headCells={headCells}
      rows={listEducation}
      filterItems={filterItems}
      actions={actions}
      deleteElement={handleDeleteDocs}
      accessFilter={handleGetEducation}
      status={status}
      setPagination={setCountElements}
      pagination={pageSize}
      resetFilters={handleResetFilters}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
    />
  );
};

export default Education;
