export const getDate = (date) => {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getUTCDate();
  const newDay = String(day)?.length < 2 ? `0${day}` : day;
  const newMonth = String(month)?.length < 2 ? `0${month}` : month;
  return `${newDay}.${newMonth}.${year}`;
};

export const checkValidateDate = (date) => {
  const length = getDate(date)?.length;
  const isValidDate = Object.prototype.toString.call(date) === '[object Date]' && isFinite(date);

  if (length < 10) {
    return false;
  }
  if (!isValidDate) {
    return false;
  }
  return true;
};

export const checkIsValidRangeDate = (startDate, endDate) => {
  let start = Date.parse(startDate);
  let end = Date.parse(endDate);
  let isValidStartDate = true;
  let isValidEndDate = true;

  if (start > end) {
    isValidStartDate = false;
  } else {
    isValidStartDate = true;
  }

  if (end < start) {
    isValidEndDate = false;
  } else {
    isValidEndDate = true;
  }

  return { isValidStartDate, isValidEndDate };
};
