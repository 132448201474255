import API from "../../../helpers/API";
import interceptor from "../../../helpers/API";

export const fetchMaterials = async (params) => {
  try {
    const {data} = await API.get('educations', {
      params,
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const fetchFile = async (id, name) => {
  try {
    const { data } = await interceptor.get(`files/${id}`, { params: { id }, responseType: 'blob' });
    await saveAs(data, name);
  } catch (e) {
    throw new Error(e);
  }
};
