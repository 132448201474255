import interceptor from "../../../helpers/API";
import {anonymousId} from "../constants";
import {convertBlobToBase64} from "../../../utils/convertBlobToBase64";

const uploadChunk = async (fileId, file) => {
  const CHUNK_SIZE = 1024 * 1024 * 4; // размер чанка в байтах
  const fileSize = file?.size;
  const chunks = Math.ceil(fileSize / CHUNK_SIZE);
  let currentChunk = 0;

  const upload = async () => {
    const start = currentChunk * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, fileSize);

    try {
      const chunk = file?.slice(start, end);
      const base64 = await convertBlobToBase64(chunk)
      await interceptor.put(`/files/${fileId}`, {data: base64})
      currentChunk++;
      if (currentChunk < chunks) {
        await upload();
      }
    } catch (e) {
      throw new Error(e?.response?.data?.message);
    }
  }
  await upload()
}

export const setQuestion = async (data, captcha) => {
  try {
    await interceptor.post('questions', data, {
      headers: {
        'CAPTCHA': captcha
      }
    });
    return {}
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const getCaptcha = async () => {
  try {
    const {data} = await interceptor.post('captcha', {}, {
      responseType: 'blob',
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchAddFile = async (file) => {
  try {
    const newFile = {
      originalFilename: file?.name,
      contentType: file?.type,
      size: file?.size
    }
    const {data} = await interceptor.post('files', newFile);
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const setViolation = async (value) => {
  try {

    const newData = value?.isAnonymous === anonymousId ? {
      email: value?.email,
      phoneNumber: value?.phone,
      text: value?.comment,
      filesId: [],
    } : {
      fullName: value?.name,
      email: value?.email,
      phoneNumber: value?.phone,
      text: value?.comment,
      filesId: [],
    }

    if (value?.file) {
      const resp = await fetchAddFile(value?.file)
      await uploadChunk(resp?.id, value.file);
      const {data} = await interceptor.post('feedbacks', {...newData, filesId: [resp?.id]}, {
        headers: {
          'CAPTCHA': value?.captcha
        }
      });
      return data
    }
    const {data} = await interceptor.post('feedbacks', newData, {
      headers: {
        'CAPTCHA': value?.captcha
      }
    });
    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchStatus = async (value) => {
  try {
    const pinCode = value?.pin
    const {data} = await interceptor.get(`feedbacks/${pinCode}`, {
      headers: {
        'CAPTCHA': value?.captcha
      }
    });
    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchFile = async (id, name, pinCode) => {
  try {
    const {data} = await interceptor.get(`feedbacks/${pinCode}/files/${id}`, {params: {id}, responseType: 'blob'});
    await saveAs(data, name);
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const setComment = async (pinCode, value) => {
  try {
    if (!value?.file) {
      await interceptor.post(`feedbacks/${pinCode}/comments`, {
        text: value.comment,
        filesId: []
      }, {
        headers: {
          'CAPTCHA': value?.captcha
        }
      })
      return {}
    }
    const resp = await fetchAddFile(value?.file)
    await uploadChunk(resp?.id, value.file);
    await interceptor.post(`feedbacks/${pinCode}/comments`, {
      text: value.comment,
      filesId: [resp?.id]
    }, {
      headers: {
        'CAPTCHA': value?.captcha
      }
    })
    return {}
  } catch (e) {

    throw new Error(e?.response?.data?.message);
  }
}


