import React, { memo } from 'react';
import styled from 'styled-components';
import ReactCheckbox from 'react-custom-checkbox';
import checkImg from './img/check.svg';

const Wrapper = styled.div`
  user-select: none;
  cursor: pointer;
  img,
  span {
    cursor: pointer;
  }
  label {
    align-items: baseline !important;
  }

  label span:first-child {
    position: absolute;
  }
  label span:last-child {
    padding-left: 24px;
    line-height: 1.4rem;
  }
`;

const Checkbox = ({ label, onChange, ...props }) => (
  <Wrapper>
    <ReactCheckbox
      {...props}
      icon={<img src={checkImg} alt="" />}
      label={label}
      style={{ cursor: 'pointer' }}
      labelStyle={{ marginLeft: 8, cursor: 'pointer' }}
      borderRadius={3}
      borderColor="#D8D8D8"
      borderStyle="solid"
      borderWidth={1}
      size={20}
      onChange={(value) => onChange(value)}
    />
  </Wrapper>
);
export default memo(Checkbox);
