import React, { memo } from 'react';
import { Flex, Box, Image, Text } from 'rebass';
import images from './img';

const infoBlocks = [
  {
    id: 1,
    title: 'Департамент защиты активов',
    text: 'Департамент защиты активов создан в целях реализации мероприятий, направленных на повышение уровня корпоративной культуры сотрудников компании по вопросам противодействия и профилактики коррупции, соблюдения этических норм и защите собственности для улучшения имиджа/репутации и инвестиционной привлекательности компании. Мы предоставляем сотрудникам информацию о том, как предупреждать коррупционные нарушения, какие этические нормы действуют в компании, как их соблюдать. Мы стараемся сохранить репутацию компании и улучшить ее имидж.',
    imgSrc: 'img1',
  },
  {
    id: 2,
    title: 'Профилактика коррупции',
    text: 'Мы обучаем и консультируем сотрудников «Ростелекома» по вопросам противодействия коррупции и хищения имущества, соблюдения Этического кодекса компании, предотвращения и урегулирования конфликта интересов. Наша телефонная «Линия доверия» открыта для обращений о фактах коррупции и мошенничества, нарушения законодательства и Этического кодекса компании.',
  },
  {
    id: 3,
    title: 'Защита от коррупции',
    text: 'Мы защищаем активы компании и противодействуем коррупции. Мы разрабатываем нормативную документацию, создаем и внедряем процессы, помогающие бороться с правонарушениями, оцениваем риски этих процессов. Стоим на защите деловой репутации.',
  },
  {
    id: 4,
    title: 'Контроль и мониторинг',
    text: `Мы проводим проверки и контрольные мероприятия на соответствие существующим требованиям антикоррупционного законодательства и мониторинг их эффективности (антикоррупционный мониторинг). Разрабатываем и внедряем контроли по ПОД/ФТ в целях минимизации рисков нарушения требований противолегализационного законодательства.`,
  },
];

const renderBlock = ({ title, text }) => (
  <>
    <Box width={[1, 1, 1 / 3]} pr={[0, 0, 24]} mb="40px">
      <Text fontSize={['1.25', '1.25', '1.5rem']} fontWeight="bold" mb="16px" mt={[16, 16, 0]}>
        {title}
      </Text>
      <Text lineHeight="1.38">{text}</Text>
    </Box>
  </>
);

const Department = () => {
  return (
    <Box width="100%" maxWidth={['100%', '100%', '100%', 1300]} px={[16, 32, 48]} pt="40px">
      <Flex flexWrap="wrap" pb="40px">
        <Box width={[1, 1, 1 / 2]}>
          <Image src={images[infoBlocks[0].imgSrc]} />
        </Box>
        <Box width={[1, 1, 1 / 2]} pl={[0, 0, 16]}>
          <Text
            fontSize={['1.25rem', '1.25rem', '2.25rem']}
            fontWeight="bold"
            mb="16px"
            mt={[16, 16, 0]}
          >
            {infoBlocks[0].title}
          </Text>
          <Text lineHeight="1.38">{infoBlocks[0].text}</Text>
        </Box>
      </Flex>

      <Flex flexWrap="wrap">{infoBlocks.slice(1).map(renderBlock)}</Flex>
    </Box>
  );
};

export default memo(Department);
