import React, { memo } from 'react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import TextTitle from './TextTitle';
import TextContent from './TextContent';
import contentBg from '../img/content_bg.jpg';

const modules = [
  { id: 1, title: 'Модуль 1. Устав дома', text: 'Этичное ведение бизнеса' },
  { id: 2, title: 'Модуль 2. Может, договоримся?', text: 'Коррупция и ненадлежащие платежи' },
  { id: 3, title: 'Модуль 3. А кто сосед?', text: 'Подарки и конфликт интересов' },
  {
    id: 4,
    title: 'Модуль 4. По секрету всему свету',
    text: 'Конфиденциальная и инсайдерская информация',
  },
  {
    id: 5,
    title: 'Модуль 5. Не бросайте мусор с балкона',
    text: 'Защита окружающей среды и охрана труда',
  },
  {
    id: 6,
    title: 'Модуль 6. С кем дружбу водить?',
    text: 'Образцовый деловой партнер ПАО «Ростелеком»',
  },
];

const ModuleTitle = styled.div`
  font-weight: bold;
`;
const ModuleText = styled.div``;

const ModuleLinkWrap = styled(Link)`
  padding: 10px 0;
  line-height: 24px;
  display: block;
  border-bottom: solid 1px #f2f3f7;
  &:hover ${ModuleTitle}, &:hover ${ModuleText} {
    color: #6d12f5;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Content = () => {
  const onPrev = () => {};

  const renderLeft = () => {
    return (
      <Box>
        <TextTitle>Как проходить курс</TextTitle>
        <TextContent>
          Курс состоит из 6 модулей, в каждом из них есть теоретическая часть и тестирование.
        </TextContent>
        <TextContent>Всего вам понадобится 15-25 минут.</TextContent>
        <TextContent>
          Проходите модули по порядку. Если вы захотите перечитать какой-то раздел, в конце вы
          сможете к нему вернуться.
        </TextContent>
      </Box>
    );
  };
  const renderRight = () => {
    return (
      <Flex flexDirection="column" justifyContent="center">
        <Box
          m={[16, 16, 0]}
          p="20px 40px"
          bg="#fff"
          sx={{
            boxShadow: '0 0 7px rgb(0 0 0 / 17%)',
            maxWidth: '500px',
          }}
        >
          {modules.map(({ id, title, text }) => (
            <ModuleLinkWrap key={id} to={`/course/module/${id}`}>
              <ModuleTitle>{title}</ModuleTitle>
              <ModuleText>{text}</ModuleText>
            </ModuleLinkWrap>
          ))}
        </Box>
      </Flex>
    );
  };

  return (
    <Layout
      pagetTitle="Содержание"
      leftContent={renderLeft()}
      rightContent={renderRight()}
      bgImage={contentBg}
      onPrev={onPrev}
      nextLink="/course/module/1"
    />
  );
};

export default memo(Content);
