import {getNormalizeDate} from "../../../utils";

export const getNormalizedDetailedArticle = (data) => {
  return {
    id: data?.id,
    date: getNormalizeDate(data?.publicationDate),
    title: data?.title,
    text:data?.content,
    link: data?.sourceLink,
    file: data?.file
  }
}
