import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {isEmpty} from "ramda";

import {VALIDATION} from "../../../../../store/statusConstants";
import {folderRules, initialState} from "../constants";

const initialValues = {
  title: '',
  status: '',
  access: '',
  text: '',
  documentType: '',
};

export const useFormValue = (defValue,handleEditArticle, handleAddedArticle, handleAddFile, setIsModalOpen) => {
  const {id} = useParams();
  const {
    control,
    handleSubmit,
    formState: {isValid, isDirty, errors},
    getValues,
    setValue,
    reset,
    clearErrors
  } = useForm({
    mode: 'onChange',
    defaultValues: initialState,
    resolver: yupResolver(folderRules),
  });

  const errorsList = Object.values(errors)?.map((item) => item?.message)

  const onSubmit = handleSubmit((values) => {
    if (id) {

      handleEditArticle(values, handleAddFile,);
    } else {
      handleAddedArticle(values);
    }
  });

  useEffect(() => {
    reset(defValue);
  }, [defValue]);

  useEffect(() => {
    if (!isEmpty(errorsList)) {
      setIsModalOpen(true)
    }
  }, [errorsList])

  const validationStatus = !isEmpty(errorsList) && VALIDATION

  return {control, isValid, isDirty, getValues, setValue, onSubmit, errorsList, validationStatus, clearErrors};
}
