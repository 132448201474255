import {format} from "date-fns";

import interceptor from '../../../../../helpers/API';
import {SOURCE_TYPE} from "../../constants";

export const fetchListVideo = async (params, filters = null) => {

  try {
    const newParams = {
      ...params,
      startDate: filters?.date?.[0] ? format(new Date(filters?.date?.[0]), 'yyyy-MM-dd') : '',
      endDate: filters?.date?.[1] ? format(new Date(filters?.date?.[1]), 'yyyy-MM-dd') : '',
    };
    const url = filters.type === "VIDEO" ? '/admin/video' : '/admin/articles'
    const {data} = await interceptor.get(url, {
      params: newParams,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const deleteVideo = async (id, filters) => {
  try {
    const url = filters.type === "VIDEO" ? `admin/video/${id}` : `admin/articles/${id}`
    await interceptor.delete(url, {
      params: {id},
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const setVideo = async (data,fileId) => {
  const newData = {
    title: data?.title,
    publishStatus: data?.status,
    publicationDate: new Date().toISOString(),
    accessType: data?.access,
    externalLink: data?.link,
    fileId: fileId || data?.file?.id,
  }
  try {
    await interceptor.post('/admin/video', newData);
    return {}
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}

export const fetchVideo = async (id) => {
  try {
    const {data} = await interceptor.get(`/admin/video/${id}`);
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchEditingVideo = async (id, value, fileId) => {
  const isFileType = value?.fileSourceType === SOURCE_TYPE.LOCAL;
  const newData = {
    title: value?.title,
    publishStatus: value?.status,
    publicationDate: new Date().toISOString(),
    accessType: value?.access,
    fileId: !isFileType ? null : fileId || value?.file?.id,
    externalLink: !isFileType ? value?.link : '',
  }
  try {
    await interceptor.put(`/admin/video/${id}`, newData, {
      params: {id}
    });
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}


export const setArticle = async (data, id = null) => {
  try {
    const newData = {
      title: data?.title,
      content: data?.text,
      sourceLink: data?.link,
      fileId: id,
      publicationDate: new Date(data?.date)?.toISOString(),
      publishStatus: data?.status,
      accessType: data?.access,
    }
    await interceptor.post('/admin/articles', newData);
    return {}
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
}

export const deleteArticle = async (id) => {
  try {
    await interceptor.delete(`admin/article/${id}`, {
      params: {id},
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchArticleById = async (id) => {
  try {
    const {data} = await interceptor.get(`admin/articles/${id}`, {
      params: {id},
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchEditArticle = async (id, value, fileId) => {
  try {
    const data = {
      title: value?.title,
      content: value?.text,
      sourceLink: value?.link,
      fileId: fileId || value?.fileId,
      publicationDate: new Date(value?.date)?.toISOString(),
      publishStatus: value?.status,
      accessType: value?.access,
    };
    await interceptor.put(`admin/articles/${id}`, data, {
      params: {id},
    });
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
