import React, {useEffect, useState} from 'react';
import {Flex, Box, Text} from 'rebass';
import {useParams} from 'react-router-dom';
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {ru} from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import {DesktopDatePicker} from "@material-ui/lab";

import EditLayout from '../EditLayout';
import {ARTICLE_STATUSES, ACCESS, NEWS_TYPE} from '../constants';
import Button from '../Button';
import Select from '../Select';
import CustomTextField from '../TextField';
import TextField from '@material-ui/core/TextField';
import File from '../File';
import TextEditor from '../TextEditor';
import ModalStatus from '../ModalStatus';
import {useEditNews} from './hooks/useEditNews';
import {useAddedNews} from './hooks/useAddedNews';
import {useFile} from '../../../../hooks/useFile';

import {Controller, useWatch} from "react-hook-form";
import {useFormValue} from "./hooks/useForm";
import {LOADING} from "../../../../store/statusConstants";
import {isEmpty} from "ramda";
import {acceptFormat} from "./constants";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const statuses = [
  {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
  {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
];

const access = [
  {title: 'Закрытый', value: ACCESS.PRIVATE},
  {title: 'Открытый', value: ACCESS.PUBLIC},
];

const types = [
  {title: 'Текст', value: NEWS_TYPE.TEXT},
  {title: 'Файл', value: NEWS_TYPE.FILE},
];


const NewsEdit = () => {
  const {id} = useParams();
  const [isModalOpen, setIsModalOpen] = useState();
  const {news, handleEditNews, editStatus} = useEditNews(setIsModalOpen);
  const {file, setFile, handleAddFile} = useFile(news?.file);

  const {handleAddedNews, addStatus} = useAddedNews(
    setIsModalOpen,
    handleAddFile,
  );

  const {
    control,
    getValues,
    setValue,
    onSubmit,
    errorsList,
    validationStatus,
    clearErrors
  } = useFormValue(news, handleEditNews, handleAddedNews, handleAddFile, setIsModalOpen)


  const formValues = getValues();
  useWatch({
    control,
    name: 'type',
  })

  const changeValue = (name, value) => {
    setValue(name, value)
  };

  useEffect(() => {
    if (formValues.type !== NEWS_TYPE.FILE) {
      setFile( null)
    }
  }, [formValues.type])

  useEffect(() => {
    if (!isEmpty(file)) {
      changeValue( 'file',file?.[0])
    }
  }, [file])

  return (
    <EditLayout title={id ? 'Редактировать новость' : 'Добавить новость'}>
      <form onSubmit={onSubmit}>
        <Flex mb="24px">
          <Box width="50%" pr="16px">
            <Controller
              name={'title'}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomTextField
                  value={value || ''}
                  label="Заголовок"
                  size="small"
                  sx={{width: '100%', marginBottom: '24px'}}
                  onChange={onChange}
                />
              )}
            />
            <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                mask="__.__.____"
                inputFormat="dd.MM.yyyy"
                value={formValues?.date || ''}
                label='Дата публикации'
                onChange={(value) => changeValue('date', value)}
                disableOpenPicker
                renderInput={(params) => <TextField  {...params} size="small"
                                                     sx={{width: '100%', marginBottom: '24px'}}
                                                     helperText={params.error && "Некорректный формат даты"}
                />}
              />
            </LocalizationProvider>
            <Controller
              name={'type'}
              control={control}
              render={({field: {onChange, value}}) => (
                <Select
                  required
                  label="Тип"
                  value={value || ''}
                  options={types}
                  onChange={(name, value) => changeValue(name, value)}
                  width="100%"
                  name="type"
                />
              )}
            />
          </Box>
          <Flex flexDirection="column" width="50%">
            <Box mb="24px">
              <Controller
                name={'status'}
                control={control}
                render={({field: {onChange, value}}) => (
                  <Select
                    required
                    label="Статус"
                    value={value || ''}
                    options={statuses}
                    onChange={(name, value) => changeValue(name, value)}
                    width="100%"
                    name="status"
                  />
                )}
              />

            </Box>
            <Box mb="24px">
              <Controller
                name={'access'}
                control={control}
                render={({field: {onChange, value}}) => (
                  <Select
                    required
                    label="Доступ"
                    value={value || ''}
                    options={access}
                    onChange={(name, value) => changeValue(name, value)}
                    width="100%"
                    name="access"
                  />
                )}
              />
            </Box>
            {formValues.type === NEWS_TYPE.FILE ? (
              <>
                <File onChange={setFile} files={file} validFormats={acceptFormat}/>
                <Text mt="8px" fontSize="14px" color="#777">
                  допустимый формат файла: pdf, doc, docx
                </Text>
              </>
            ) : null}
          </Flex>
        </Flex>
        {formValues.type === NEWS_TYPE.TEXT ? (
          <Box mb="40px">
            <Controller
              name={'preview'}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomTextField
                  value={value || ''}
                  label="Анонс"
                  size="small"
                  sx={{width: '100%', marginBottom: '24px'}}
                  onChange={onChange}
                />
              )}
            />

            <Text mb="16px">Текст статьи:</Text>
            <TextEditor
              value={formValues?.text}
              onBlur={(value) => changeValue('text', value)}
            />
          </Box>
        ) : null}

        <Flex>
          <Button variant="contained" label="Сохранить" type='onsubmit' disabled={!checkedPermission(permissions.newsWrite)}/>
        </Flex>
      </form>
      <ModalStatus
        status={addStatus || editStatus || validationStatus || LOADING}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        access="Новость успешно добавлена"
        path="/admin/news"
        errorsList={errorsList}
        clearErrors={clearErrors}
      />
    </EditLayout>
  );
};

export default NewsEdit;
