import React, { memo } from 'react';
import { Button } from 'atoms';
import { ReadMoreFaqs } from 'molecules';
import { Flex, Box, Text, Image } from 'rebass';
import images from './img';

// colors

const InfoBlock = ({ item: { id, title, text, imgSrc, imageFirst } }) => {
  return (
    <Flex flexWrap="wrap">
      <Flex
        width={[1, imgSrc ? 1 : 1 / 2]}
        flexWrap="wrap"
        mb="54px"
        flexDirection={imageFirst ? 'row' : 'row-reverse'}
      >
        <Box width={[1, 1, imgSrc ? 1 / 2 : 1]}>
          {imgSrc ? <Image src={images[imgSrc]} width="100%" /> : null}
        </Box>
        <Box width={[1, 1, imgSrc ? 1 / 2 : 1]} pl={[0, 0, imageFirst ? 16 : 0]}>
          <Text fontSize="1.5rem" fontWeight="bold" mb="14px" mt={['1.5rem', '1.5rem', 0, 0]}>
            {title}
          </Text>

          <Text lineHeight="1.38" mr={[0, imageFirst ? 0 : 16]}>
            {text}
          </Text>

          {id === 2 ? (
            <Box mt="24px">
              <Button title="Подробнее" variant="secondary" link="/department" />
            </Box>
          ) : null}
          {id === 3 ? (
            <Box mt="24px">
              <Button title="Сообщить" variant="primary" link="/feedback" />
            </Box>
          ) : null}
        </Box>
      </Flex>
      {id === 4 ? (
        <Flex width={[1, 1 / 2]} justifyContent="flex-end" mt={[0, '1.5rem', 0, 0]}>
          <ReadMoreFaqs withBoxShadow />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default memo(InfoBlock);
