import React, { memo } from 'react';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const BasicSelect = ({ label, value, options, onChange, width, name, placeholder, required }) => {
  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>
          {label}
          {required ? '*' : ''}
        </InputLabel>
        <Select
          value={value}
          label={label}
          placeholder={placeholder}
          onChange={handleChange}
          size="small"
          sx={{ width }}
          name={name}
        >
          {options.map((opt) => (
            <MenuItem value={opt.value} key={opt.value}>
              {opt.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default memo(BasicSelect);
