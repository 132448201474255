import {useAsyncFn} from 'react-use';

import {useEffect, useState} from 'react';
import {fetchListVideo} from '../requests';
import {getNormalizedVideo} from '../utils/getNormalizedVideo';
import {getFile} from '../../../request';

export const useVideo = () => {
  const [{value}, getListVideo] = useAsyncFn(fetchListVideo);
  const [listVideo, setListVideo] = useState([]);
  const [playingVideo, setPlayingVideo] = useState(null);

  const [, execute] = useAsyncFn(getFile);

  useEffect(() => {
    getListVideo();
  }, []);

  const getVideoFiles = (id) => {
    execute(id);
  };

  const playVideo = async (videoSrc, fileId = null, id = null) => {
    if (fileId) {
      setPlayingVideo(`/api/video/${id}/stream`)
    } else {
      setPlayingVideo(videoSrc);
    }
  };

  useEffect(() => {
    if (value) {
      const newVideo = value?.content.map((item) => getNormalizedVideo(item));
      setListVideo(newVideo);
    }
  }, [value]);

  return {listVideo, getVideoFiles, playingVideo, playVideo};
};
