import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { fetchNews } from '../requests';
import { getNormalizedNews } from '../utils';
import {initFilterYear} from "../constants";

const pageSize = 10;

export const useNews = (filters, setAllPages, page) => {
  const [{ value }, getNews] = useAsyncFn(fetchNews);
  const [newsList, setNews] = useState([]);

  useEffect(async () => {
    const params = {
      month: filters?.month?.value,
      year: filters?.year?.value || initFilterYear.value,
      size: pageSize,
      page: page,
      sort:"publicationDate,DESC",
      accessType: filters?.newsType
    };
    await getNews(params);
  }, [filters, page]);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedNews(item);
      });
      setNews(normalizedData);
      setAllPages(value?.pagination?.totalPages);
    }
  }, [value]);

  return { newsList };
};
