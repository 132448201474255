import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import getAdminState from '../../store/reducers/Admin/getter';
import { getLocalStorage } from '../../utils/localStorage';
import { changeIsAuthorized } from '../../store/reducers/Admin/Admin';
import { checkTokenLifetime } from '../../utils/getTimeTokenUpdates';
import { fetchToken } from '../Login/request';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthorized } = useSelector(getAdminState);
  const dispatch = useDispatch();
  const token = getLocalStorage('accessToken');

  useEffect(() => {
    if (token) {
      dispatch(changeIsAuthorized(true));
    } else {
      dispatch(changeIsAuthorized(false));
    }
  }, [token]);

  useEffect(() => {
    const isExpired = checkTokenLifetime();
    if (isExpired) {
      fetchToken();
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
