import React from 'react';

import DataLayout from "../DataLayout";
import {useAccounts} from "./hooks/useAccounts";
import {usePagination} from "../../hooks/usePagination";


const Accounts = () => {
  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();

  const {listAccounts, status, handleDeleteAccount} = useAccounts(currentPage, pageSize, setAllPages, setAllElements)

  const headCells = [
    {
      id: 'nameSort',
      disablePadding: false,
      label: 'Ф.И.О.',
      // status: sort.nameSort,
    },
    {
      id: 'emailSort',
      disablePadding: false,
      label: 'Эл. почта',
      // status: sort.emailSort,
    },
    {
      id: 'positionSort',
      disablePadding: false,
      label: 'Должность',
      // status: sort.positionSort,
    },
  ];

  return (
    <DataLayout
      title="Учетные записи"
      headCells={headCells}
      rows={listAccounts}
      status={status}
      pagination={pageSize}
      setPagination={setCountElements}
      deleteElement={handleDeleteAccount}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
    />
  );
};

export default Accounts;
