export const permissions = {
  newsRead: 'NEWS_READ',
  newsWrite: 'NEWS_WRITE',
  questionsAnswersRead: 'QUESTIONS_ANSWERS_READ',
  questionsAnswersWrite: 'QUESTIONS_ANSWERS_WRITE',
  materialsRead: 'ARTICLES_READ',
  materialsWrite: 'ARTICLES_WRITE',
  docsRead: 'DOCS_READ',
  docsWrite: 'DOCS_WRITE',
  educationRead: 'EDUCATION_READ',
  educationWrite: 'EDUCATION_WRITE',
  questionsRead: 'QUESTIONS_READ',
  questionsWrite: 'QUESTIONS_WRITE',
  usersRead: 'USERS_READ',
  usersWrite: 'USERS_WRITE',
  feedBackRead:'FEEDBACKS_READ',
  articlesRead:'ARTICLES_READ',
  articlesWrite:'ARTICLES_WRITE',
  reportRead:'REPORT_READ',
  reportWrite:'REPORT_WRITE'
}
