import { getNormalizeDate } from '../../../../../utils';

export const getNormalizedDocuments = (document) => {
  return {
    access: document?.accessType,
    date: getNormalizeDate(document?.publicationDate),
    docCategory: '',
    id: document?.id,
    title: document?.docName,
    status: document?.publishStatus,
  };
};
