import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const CustomButton = styled.button`
  display: flex;
  align-items: center;
  height: fit-content;
  margin: ${({ margin = 0 }) => margin};
  padding: 14px 28px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #fff;
  background: #6d12f5;
  outline: none;
  user-select: none;
  transition: opacity, background-color 0.4s ease;
  border-style: none;
  opacity: ${({ disabled }) => (disabled ? '0.49' : '1')};
`;

const spin = keyframes`
 to { -webkit-transform: rotate(360deg); }
`;

const Loader = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
`;

const Button = ({ title, link, loading, ...rest }) => {
  if (link) {
    return (
      <Link to={link}>
        <CustomButton {...rest}>{title}</CustomButton>
      </Link>
    );
  }

  return <CustomButton {...rest}>{loading ? <Loader /> : title}</CustomButton>;
};

export default Button;
