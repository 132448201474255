import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import Test from './Test';
import TestImages from './TestImages';
import data from '../data';

const ModuleCheck = () => {
  const { moduleId, checkId } = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const { testType } = find(module.check, ['id', +checkId || 1]);

  return testType === 'images' ? <TestImages /> : <Test />;
};

export default memo(ModuleCheck);
