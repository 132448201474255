import interceptor from '../../../../../helpers/API';
import {format} from "date-fns";

export const fetchAddDocuments = async (data, id) => {
  const newData = {
    docName: data.title,
    publishStatus: data.status,
    docDescription: data.text,
    accessType: data.access,
    docCategory: data.documentType,
    fileId: id,
    publicationDate: new Date().toISOString(),
  };
  try {
    await interceptor.post('admin/docs', newData);
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchDocuments = async (params,filters) => {
  try {
    const newParams = {
      ...params,
      startDate: filters?.date?.[0] ? format(new Date(filters?.date?.[0]), 'yyyy-MM-dd') : '',
      endDate: filters?.date?.[1] ? format(new Date(filters?.date?.[1]), 'yyyy-MM-dd') : '',
    };
    const { data } = await interceptor.get('admin/docs', {
      params:newParams,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const deleteDocument = async (id) => {
  try {
    await interceptor.delete(`admin/docs/${id}`, {
      params: { id },
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchDocumentById = async (id) => {
  try {
    const { data } = await interceptor.get(`admin/docs/${id}`, {
      params: { id },
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchEditDocument = async (id, data, fileId) => {
  const newData = {
    docName: data.title,
    publishStatus: data.status,
    docDescription: data.text,
    accessType: data.access,
    docCategory: data.documentType,
    fileId: fileId || data?.file?.id,
    publicationDate: new Date().toISOString(),
  };
  try {
    await interceptor.put(`admin/docs/${id}`, newData, { params: id });
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
