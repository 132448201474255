import {useState} from "react";

export const useModal = () => {
  const [isOpeModal, setIsOpenModal] = useState(false)

  const closeModal = () => {
    setIsOpenModal(false)
  }

  const openModal = () => {
    setIsOpenModal(true)
  }

  return {isOpeModal,closeModal,openModal}
}
