import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { fetchMonthList } from '../requests';
import { defaultFilterValue } from '../constants';

export const useMonth = (year,type) => {
  const [listMonth, setListMonth] = useState([]);
  const [{ value }, getListMonth] = useAsyncFn(fetchMonthList);

  useEffect(() => {
    getListMonth(year?.value,type);
  }, [year,type]);

  useEffect(() => {
    const newValue = value?.map((item) => ({ title: item?.name, value: item?.id })) || [];
    setListMonth([...newValue, defaultFilterValue]);
  }, [value]);

  return { listMonth };
};
