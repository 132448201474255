import {useState, useCallback, useMemo, useEffect} from 'react';
import {assoc, mergeDeepRight} from 'ramda';
import {useUpdateEffect} from 'react-use';

import {saveParamsToUrl} from '../../../utils/saveParamsUrl';

/**
 * Хук для работы с фильтрами
 * @param initParams Изначальные параметры фильтров
 * @returns
 */
export const useFilters = (initParams, setPage) => {
  const [params, setParams] = useState(initParams || {});

  const handleParamChange = useCallback(
    (values, name) => {
      setPage(1);
      return setParams((prev) => mergeDeepRight(prev, {[name]: values}));
    },
    [setParams, assoc],
  );

  const filters = useMemo(() => params, [params]);

  useUpdateEffect(() => {
    saveParamsToUrl(params);
  }, [filters.year, filters.month,filters.newsType]);

  useEffect(() => {
    setParams({...params, month: null});
  }, [filters.year]);

  return {
    handleParamChange,
    filters,
  };
};
