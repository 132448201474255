import {ACCESS, ARTICLE_STATUSES, DOCUMENT_TYPES, NEWS_TYPE, SOURCE_TYPE} from "../constants";
import * as Yup from "yup";

export const statuses = [
  { title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED },
  { title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED },
  // { title: 'Ожидает публикации', value: ARTICLE_STATUSES.POSTPONED },
];

export const fileSourceTypes = [
  { title: 'Локальный файл', value: SOURCE_TYPE.LOCAL },
  { title: 'Ссылка на файл', value: SOURCE_TYPE.EXTERNAL },
];

export const access = [
  {title: 'Закрытый', value: ACCESS.PRIVATE},
  // {title: 'Открытый', value: ACCESS.PRIVATE},
  {title: 'Открытый', value: ACCESS.PUBLIC},
];

export const fieldNames = {
  title:'title',
  content:'content',
  type:'type',
  externalLink:'externalLink',
  file:'file',
  imageId:'imageId',
  publishStatus:'publishStatus',
  publicationDate:'publicationDate',
  access:'access',
  date:'date'
};

export const  initialValues = {
  title:'',
  content:'',
  type:SOURCE_TYPE.LOCAL,
  externalLink:'',
  fileId:'',
  imageId:'',
  publishStatus: ARTICLE_STATUSES.NOT_PUBLISHED,
  publicationDate:'',
  date: new Date(),
  access:ACCESS.PRIVATE
}

export const initialFilters = {
  status: ARTICLE_STATUSES.ALL,
  date: [null,null],
  access: ACCESS.ALL,
  docCategory: DOCUMENT_TYPES.ALL,
};

export const errorMsg = {
  requiredFile: 'Загрузите файл документа',
  required: (field) => `Заполните поле "${field}"`
};

export const folderRules = Yup.object({
  [fieldNames.title]: Yup.string().required(errorMsg.required('Название')),
  [fieldNames.file]: Yup.mixed().when(fieldNames.type, (type, schema) =>
    type?.[0] === SOURCE_TYPE.LOCAL ? schema.required(errorMsg.requiredFile) : schema.notRequired()
  ),
  [fieldNames.externalLink]: Yup.string().when(fieldNames.type, (type, schema) =>
    type?.[0] === SOURCE_TYPE.EXTERNAL ? schema.required(errorMsg.required('Ссылка')) : schema.notRequired()
  ),
});

export const acceptFormat = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
};

const filterNames = {
  status: 'status',
  date: 'date',
  access:'access',
};

export const filterList = Object.values(filterNames);
