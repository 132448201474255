import {useAsyncFn} from "react-use";
import {useEffect, useState} from "react";

import {fetchMaterials} from "../requests";
import {normalizedMaterials} from "../utils";

const pageSize = 9
export const useMaterials = (setAllPages, currentPage) => {
  const [{value}, execute] = useAsyncFn(fetchMaterials)
  const [listMaterials, setListMaterials] = useState([])

  useEffect(() => {
    const params = {
      size: pageSize,
      page: currentPage,
      sort: "publicationDate,DESC",
    };
    execute(params)
  }, [currentPage])

  useEffect(() => {
    if (value) {
      const newMaterials = value?.content?.map((item) => (normalizedMaterials(item)))
      setListMaterials(newMaterials)
      setAllPages(value?.pagination?.totalPages);
    }
  }, [value])

  return {listMaterials}
}
