import React, { memo } from 'react';
import { Flex, Text } from 'rebass';
import { ReadMoreFaqs } from 'molecules';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TwoColumnsLayout } from 'layouts';

const ContentWrapper = styled(Text)`
  p {
    margin-bottom: 1.5rem;
    &:last-child {
      padding-bottom: 34px;
      margin-bottom: 32px;
      border-bottom: 1px solid #e2e2e2;
    }
  }
`;

const Tests = () => {
  const renderLeft = () => {
    return (
      <ContentWrapper
        fontSize={['1rem', '1rem', '1.125rem']}
        lineHeight={['1.25rem', '1.25rem', '1.5rem']}
        fontWeight="500"
      >
        <p>
          ПАО «Ростелеком» поддерживает открытые и прозрачные деловые отношения со всеми
          поставщиками и стремится развивать сотрудничество на взаимовыгодной основе. В свою
          очередь, компания ожидает, что ее поставщики будут точно так же следовать высоким
          этическим нормам и отказываться от любой деятельности, которая может рассматриваться как
          нарушение этих норм. Чтобы познакомить поставщиков с основными требованиями ПАО
          «Ростелекома», был разработан интерактивный онлайн-курс. Курс предназначен для
          руководителей организации поставщика/подрядчика и ответственных лиц по договорам с
          «Ростелекомом».
        </p>
        <p>
          <Flex>
            <Link to="/course">
              <Text color="#7800ff" sx={{ textDecoration: 'underline' }}>
                Пройти курс
              </Text>
            </Link>
          </Flex>
        </p>
        <p>
          Курс состоит из теоретической части и тестирования. Для прохождения рекомендуем
          использовать наушники, так как в курсе есть видеоматериалы.
        </p>
        <p>Время прохождения: 20-30 минут.</p>
        <p>Спасибо за ответственное отношение и этичное поведение!</p>
        <p>
          Возникла техническая сложность при работе с курсом? Напишите нам на адрес: В случае
          вопросов по содержанию курса, обращайтесь на адрес электронной почты:
          <Flex>
            <a href="mailto:compliance@rt.ru">
              <Text color="#7800ff" sx={{ textDecoration: 'underline' }}>
                compliance@rt.ru
              </Text>
            </a>
          </Flex>
        </p>
      </ContentWrapper>
    );
  };

  return (
    <TwoColumnsLayout
      title="Курс деловой этики для поставщиков ПАО Ростелеком"
      left={renderLeft()}
      right={<ReadMoreFaqs link="/documents" />}
    />
  );
};

export default memo(Tests);
