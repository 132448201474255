import React, {memo} from 'react';
import {Flex, Box, Text} from 'rebass';
import {StyledLink} from 'atoms';

import {useArticle} from "../hooks/useArticle";

const ArticleDetail = () => {
  const {article} = useArticle()

  return (
    <Box bg="#fff" width="100%">
      <Flex justifyContent="center">
        <Box width="100%" px={[24, 60]} py={[24, 40]} maxWidth={['100%', '100%', '100%', 1300]}>
          <Box pb="24px">
            <StyledLink to="/materials/articles">Назад к списку статей</StyledLink>
          </Box>
          <Text
            fontSize={['1.875rem', '1.875rem', '3rem']}
            lineHeight={['2.25rem', '2.25rem', '3.75rem']}
            fontWeight="bold"
            mb="32px"
          >
            {article?.title}
          </Text>
          <Flex alignItems="center" mb="24px">
            <Text fontSize="0.875rem" color="rgba(0, 0, 0, 0.5)" mr="24px">
              {article?.date}
            </Text>
            <Text>
              <StyledLink target="_blank" to={{pathname: article?.link}}>
                Ссылка на источник
              </StyledLink>
            </Text>
          </Flex>
          <Text lineHeight="1.375rem" mb="25px" dangerouslySetInnerHTML={{ __html: article?.text }} />
        </Box>
      </Flex>
    </Box>
  );
};

export default memo(ArticleDetail);
