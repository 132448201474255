import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';

import { fetchFaqs, deleteFaqs } from '../requests';
import { getNormalizedFaqs } from '../utils';
import { setStatus } from '../../../../../store/reducers/News.reducers';
import { LOADING } from '../../../../../store/statusConstants';
import { useStatuses } from '../../../../../hooks/useStatuses';
import {saveParamsToUrl} from "../../../../../utils/saveParamsUrl";

export const useFaqs = (filters = {}, setAllPages, page, pageSize, setAllElements) => {
  const [{ value, loading, error }, getFaqs] = useAsyncFn(fetchFaqs);
  const [, delFaqs] = useAsyncFn(deleteFaqs);
  const [faqsList, setFaqs] = useState([]);
  const dispatch = useDispatch();
  const { status } = useStatuses(loading, value?.content, error, true);

  const params = {
    size: pageSize,
    page: page + 1,
    publishStatus: filters?.status === 'ALL' ? '' : filters?.status,
    accessType: filters?.access === 'ALL' ? '' : filters?.access,
    sort:"publicationDate,DESC",
  };

  useEffect(async () => {
    await getFaqs(params,filters);
  }, [pageSize, page]);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedFaqs(item);
      });
      setFaqs(normalizedData);
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);
    }
  }, [value]);

  const handleDeleteFaqs = async (id) => {
    await delFaqs(id);
    await getFaqs(params,filters);
  };

  const updateTableData = async () => {
    dispatch(setStatus(LOADING));
    saveParamsToUrl({...filters})
    await getFaqs(params,filters);
  };

  return { faqsList, handleDeleteFaqs, updateTableData, getFaqs, status };
};
