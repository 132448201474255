import {useEffect, useState} from "react";
import {useAsyncFn} from "react-use";

import {getProfile} from "../requests";
import {useParams} from "react-router-dom";

export const useProfile = () => {
  const params = useParams()
  const [{value}, execute] = useAsyncFn(getProfile)
  const [profile, setProfile] = useState('')

  const getUserData = async () => {
    await execute(params?.id)
  }

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    if (value) {
      setProfile({
        id: value?.id,
        name: '',
        fio: value?.fullName,
        email: value?.email,
        post: value?.positionInCompany,
        password: '',
        permissions: value?.permissions
      })
    }
  }, [value])

  return {profile, getUserData}
}
