import React, {useEffect, useState, memo} from 'react';
import {Box, Text, Image} from 'rebass';

import styled from 'styled-components';
import slides from './img';
import logoPart from './img/logo-part.svg';

const arrSlides = Object.values(slides);

const slidesOb = [
  {
    id: 0,
    title: ['Играй', 'по правилам'],
    colors: ['#57d9b7', '#ffbe08'],
  },
  {
    id: 1,
    title: ['Проясни все', 'нюансы права'],
    colors: ['#ffbe08', '#ffbfc3'],
  },
  {
    id: 2,
    title: ['Не закрывай глаза', 'на факты коррупции'],
    colors: ['#bc104b', '#65b8df'],
  },
];

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
`

const Content = styled.div`
  height: 500px;
  padding: 0 48px 0 48px;
  max-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ImgWrapper = styled.div`
  height: 350px;
  @media (max-width: 1024px) {
    display: none;
  }

`

const renderTitle = (titleArr = []) => {
  return titleArr.map((title, index) => {
    return (
      <Box key={title} mb="-4px">
        <Text
          display="block"
          fontSize={['1.75rem', '3.5rem']}
          color="#fff"
          fontWeight="bold"
          p="4px 8px"
          bg="#7800FF"
          width="max-content"
          sx={{
            position: 'relative',
          }}
        >
          {title}
          {index === titleArr.length - 1 ? (
            <Image
              src={logoPart}
              width="40px"
              alt=""
              sx={{
                position: 'absolute',
                top: '100%',
                right: '20px',
              }}
            />
          ) : null}
        </Text>
      </Box>
    );
  });
};

const Banner = () => {
  const [currentSlide, nextSlide] = useState(slidesOb[0]);
  const [timer, setTimer] = useState(slidesOb[0]);

  const setNextSlide = () => {
    clearTimeout(timer);
    const nextSlideIndex = slidesOb[currentSlide.id + 1] ? currentSlide.id + 1 : 0;
    nextSlide(slidesOb[nextSlideIndex]);
  };

  useEffect(() => {
    const timerId = setTimeout(setNextSlide, 4000);
    setTimer(timerId);
    return () => {
      clearTimeout(timer);
    };
  }, [currentSlide]);

  return (
    <Wrapper
      onClick={setNextSlide}
      style={{background: `linear-gradient(110deg, ${currentSlide.colors[0]} 0 32.5%, ${currentSlide.colors[1]} 32.5% 100% )`}}
    >
      <Content>
        <Box>{renderTitle(currentSlide.title)}</Box>
        <ImgWrapper>
          <img src={arrSlides[currentSlide.id]} height="100%"/>
        </ImgWrapper>
      </Content>
    </Wrapper>
  );
};

export default memo(Banner);
