import React from 'react';

import DataLayout from '../DataLayout';
import {useAskList} from "./hooks/useAskList";
import {usePagination} from "../../hooks/usePagination";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";


const Ask = () => {

  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();

  const {askList, updateTableData, handleDeleteAsk,status} = useAskList(
    setAllPages,
    currentPage,
    pageSize,
    setAllElements,
  )

  const headCells = [
    {id: 'dateSort', disablePadding: true, label: 'Дата публикации', },
    {id: 'titleSort', disablePadding: false, label: 'Имя', },
    {id: 'statusSort', disablePadding: false, label: 'Эл.почта', },
  ];

  return (
    checkedPermission(permissions.questionsRead) &&
    <DataLayout
      title="Задать вопрос"
      headCells={headCells}
      rows={askList}
      status={status}
      accessFilter={updateTableData}
      pagination={pageSize}
      setPagination={setCountElements}
      deleteElement={handleDeleteAsk}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
    />
  );
};

export default Ask;
