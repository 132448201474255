import { useAsyncFn } from 'react-use';
import { useEffect, useState } from 'react';

import { deleteDocument, fetchDocuments } from '../requests';
import { getNormalizedDocuments } from '../utils';
import { useStatuses } from '../../../../../hooks/useStatuses';
import {saveParamsToUrl} from "../../../../../utils/saveParamsUrl";

export const useDocuments = (filters, pageSize, page, setAllPages, setAllElements) => {
  const [{ value, error, loading }, getDocuments] = useAsyncFn(fetchDocuments);
  const [, delDocument] = useAsyncFn(deleteDocument);
  const [listDocuments, setListDocuments] = useState([]);
  const { status } = useStatuses(loading, value?.content, error, true);

  const params = {
    docCategory: filters.docCategory === 'ALL' ? '' : filters.docCategory,
    size: pageSize,
    page: page + 1,
    publishStatus: filters?.status === 'ALL' ? '' : filters?.status,
    accessType: filters?.access === 'ALL' ? '' : filters?.access,
    sort:"publicationDate,DESC",
  };

  const handleGetDocuments = async () => {
    await getDocuments(params,filters);
    saveParamsToUrl({...filters})
  };

  const handleDeleteDocs = async (id) => {
    await delDocument(id);
    await getDocuments();
  };

  useEffect(() => {
    getDocuments(params,filters);
  }, [page, pageSize]);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedDocuments(item);
      });
      setListDocuments(normalizedData);
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);
    }
  }, [value]);

  return { handleGetDocuments, listDocuments, getDocuments, status, handleDeleteDocs };
};
