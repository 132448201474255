import React, { memo } from 'react';
import { AskMoreQuestions, Accordion } from 'molecules';
import { Box } from 'rebass';
import { TwoColumnsLayout } from 'layouts';

import { useFaqs } from '../hooks/useFaqs';
import { usePagination } from '../../../hooks';
import { Pagination } from './styles';
import { Paginator } from '../../../common/molecules';

const Faqs = () => {
  const { currentPage, setAllPages, handlePageChange, allPages } = usePagination();
  const { faqs } = useFaqs(currentPage, setAllPages);
  return (
    <>
      <TwoColumnsLayout
        title="Вопрос-ответ"
        left={
          <Box mb={40}>
            <Accordion items={faqs} />
          </Box>
        }
        right={<AskMoreQuestions />}
      />
      <Pagination>
        <Paginator
          initialPage={currentPage - 1}
          currentPage={currentPage}
          pageCount={allPages}
          onPageChange={(value) => {
            handlePageChange(value);
          }}
        />
      </Pagination>
    </>
  );
};

export default memo(Faqs);
