import React from 'react';

import DataLayout from '../DataLayout';
import {useFeedback} from "./hooks/useFeedback";
import {filterList, initialFilters} from "./constants";
import {usePagination} from "../../hooks/usePagination";
import {getInitFiltersParams} from "../../../../utils/getInitFiltersParams";
import {useFilters} from "../../hooks/useFilter";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const Feedback = () => {
  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();
  const initValues = getInitFiltersParams(filterList);
  const {filter, handleChangeFilters, resetFilters} = useFilters(initValues,initialFilters);

  const {listFeedback, status, getFeedback, updateTableData} = useFeedback(filter,
    setAllPages,
    currentPage,
    pageSize,
    setAllElements)

  //TODO  раскомментировать при необходимости

  // const filterItems = [
  //   {
  //     name: 'pin',
  //     label: '№ сообщения',
  //     type: FILTER_TYPES.TEXT_FIELD,
  //     value: filter.pin,
  //     initialValue: '',
  //     onChange: (name, value) => {
  //       handleChangeFilters(name, value)
  //     },
  //   },
  //   {
  //     name: 'fio',
  //     label: 'Ф.И.О.',
  //     type: FILTER_TYPES.TEXT_FIELD,
  //     value: filter.fio,
  //     initialValue: '',
  //     onChange: (name, value) => {
  //       handleChangeFilters(name, value)
  //     },
  //   },
  //   {
  //     name: 'mail',
  //     label: 'Эл. почта',
  //     type: FILTER_TYPES.TEXT_FIELD,
  //     value: filter.mail,
  //     initialValue: '',
  //     onChange: (name, value) => {
  //       handleChangeFilters(name, value)
  //     },
  //   },
  //   {
  //     name: 'phone',
  //     label: 'Телефон',
  //     type: FILTER_TYPES.TEXT_FIELD,
  //     value: filter.phone,
  //     initialValue: '',
  //     onChange: (name, value) => {
  //       handleChangeFilters(name, value)
  //     },
  //   },
  // ];

  const headCells = [
    {id: 'numberSort', disablePadding: true, label: '#'},
    {id: 'phoneSort', disablePadding: false, label: 'Дата публикации'},
    {id: 'nameSort', disablePadding: false, label: 'Автор'},
    {id: 'emailSort', disablePadding: false, label: 'Статус'},

  ];
  const handleResetFilters = async () => {
    await resetFilters();
    await getFeedback({
      ...initialFilters,
      page: currentPage + 1,
      size: pageSize,
    });
  };

  return (
    checkedPermission(permissions.feedBackRead) &&
    <DataLayout
      title="Обратная связь"
      headCells={headCells}
      rows={listFeedback}
      // filterItems={filterItems}
      accessFilter={updateTableData}
      resetFilters={handleResetFilters}
      status={status}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
      pagination={pageSize}
      setPagination={setCountElements}
      isDelete={false}
    />
  );
};

export default Feedback;
