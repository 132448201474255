import styled from "styled-components";

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin-bottom: 10px;
`
export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .text {
    text-align: center;
  }

  .error {
    text-align: center;
    margin-bottom: 30px;
    color: red;
  }

  span {
    font-weight: bold;
  }

  .pin {
    font-weight: bold;
  }
`
