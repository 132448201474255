import {useAsyncFn} from 'react-use';
import {useEffect, useState} from 'react';

import {fetchDocuments} from '../requests';
import {getNormalizedDocuments} from '../utils/getNormalizedDocuments';

const mapCategories = {
  company: 'COMPANY',
  laws: 'ANTI_CORRUPTION',
};

const pageSize = 8;

export const useDocuments = (section, setAllPages, page) => {
  const [{ value }, getDocuments] = useAsyncFn(fetchDocuments);
  const [listDocuments, setListDocuments] = useState([]);

  const param = {
    docCategory: mapCategories[section],
    size: pageSize,
    page,
  };

  const handleGetDocuments = async () => {
    await getDocuments(param);
  };

  useEffect(() => {
    getDocuments(param);
  }, [page, pageSize, section]);

  useEffect(() => {
    if (value) {
      const normalizedData = value?.content?.map((item) => {
        return getNormalizedDocuments(item);
      });
      setListDocuments(normalizedData);
      setAllPages(value?.pagination?.totalPages);
    }
  }, [value]);

  return { handleGetDocuments, listDocuments, getDocuments };
};
