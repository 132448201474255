import {TextField} from "@material-ui/core";
import * as Yup from "yup";

export const initialValues = {
  name: '',
  fio: '',
  email: '',
  post: '',
  password: '',
};

export const fieldNames = {
  name: 'name',
  fio: 'fio',
  email: 'email',
  post: 'post',
  password: 'password',
};

export const errorMsg = {
  required: (name) => `Поле ${name} не заполнено`,
};

export const folderRules = Yup.object({
  [fieldNames.fio]: Yup.string().required(errorMsg.required('Ф.И.О')),
  [fieldNames.email]: Yup.string().email('Некорректный формат электронной почты').required(),
  [fieldNames.post]: Yup.string().required(errorMsg.required('Должность')),
});

export const fields = [
  {
    name: fieldNames.name,
    label: 'Логин',
    component: (props) => <TextField {...props} size="small" sx={{width: '300px', background: '#f3f3f3'}}/>,
    disabled: true,
    required: true
  },
  {
    name: fieldNames.fio,
    label: 'Ф.И.О',
    required: true,
    component: (props) => <TextField {...props} size="small" sx={{width: '300px', cursor: 'pointer'}}
                                     helperText={props.error && 'обязательное поле'}/>
  },
  {
    name: fieldNames.email,
    label: 'Эл.почта',
    component: (props) => <TextField {...props} size="small" sx={{width: '300px'}}/>
  },
  {
    name: fieldNames.post,
    label: 'Должность',
    component: (props) => <TextField {...props} size="small" sx={{width: '300px'}}/>
  },
  {
    name: fieldNames.password,
    label: 'Пароль',
    component: (props) => <TextField {...props} size="small" sx={{width: '300px'}}/>,
    hint: 'укажите новый пароль для смены текущего пароля'
  },
]
