import {useAsyncFn} from "react-use";

import {useStatuses} from "../../../../../hooks/useStatuses";
import {setArticle} from "../requests";

export const useAddedArticle = (setIsModalOpen, handleAddFile) => {
  const [{loading, value, error}, addArticle] = useAsyncFn(setArticle);

  const handleAddedArticle = async (data) => {
    const resp = await handleAddFile();
    if (resp?.id) {
      await addArticle(data, resp?.id);
    } else {
      await addArticle(data);
    }
    setIsModalOpen(true);
  };

  const {status} = useStatuses(loading, value, error);

  const addStatus = status;
  return {handleAddedArticle, addStatus};
}
