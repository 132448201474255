import React, { memo } from 'react';
import { Flex, Box, Text } from 'rebass';
import { StyledLink } from 'atoms';

import NewsPreviewItem from '../NewsPreviewItem';

const NewsPreviews = ({listNews}) => {
  return (
    <Box mb="54px">
      <Flex justifyContent="space-between" alignItems="center" mb="20px">
        <Text fontSize="2.25rem" fontWeight="bold">
          Новости
        </Text>
        <StyledLink to="/news">Все новости</StyledLink>
      </Flex>
      <Flex flexWrap="wrap">
        {listNews.map((item) => (
          <NewsPreviewItem key={item.id} item={item} />
        ))}
      </Flex>
    </Box>
  );
};

export default memo(NewsPreviews);
