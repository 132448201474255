import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { fetchEditArticle, fetchArticleById } from '../requests';
import { useRouter } from '../../../../../hooks';
import { useStatuses } from '../../../../../hooks/useStatuses';

const initialState = {
  title: '',
  status: '',
  file: null,
  access: '',
  link: '',
  text: '',
  dataType: 'article',
  date: new Date(),
};

export const useEditArticle = (setIsModalOpen) => {
  const [{ value }, getArticle] = useAsyncFn(fetchArticleById);
  const [state, editArticle] = useAsyncFn(fetchEditArticle);
  const {
    query: { id },
  } = useRouter();
  const [article, setArticle] = useState(initialState);

  const handleEditArticle = async (data, handleAddFie) => {
    const resp = await handleAddFie();
    if (resp?.id || resp) {
      await editArticle(id, data, resp?.id);
    } else {
      await editArticle(id, data);
    }
    setIsModalOpen(true);
  };

  const { status } = useStatuses(state?.loading, state?.value, state?.error);

  useEffect(() => {
    if (id) {
      getArticle(id);
    }
  }, [id]);

  useEffect(() => {
    if(value){
      setArticle({
        title: value?.title,
        status: value?.publishStatus,
        date: value?.createdDate,
        access: value?.accessType,
        type: value?.newsType,
        file: value?.file ? { path: value?.file?.originalFilename, id: value?.file?.id }: null,
        preview: value?.previewText,
        text: value?.content,
        fileId: value?.file?.id,
        link: value?.sourceLink,
      });
    }
  }, [value]);

  return { article, editArticle, handleEditArticle, editStatus: status };
};
