import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import {Box, Text} from 'rebass';
import {StyledLink} from 'atoms';

import {useImg} from "../hooks/useImg";

const ArticlePreview = ({id, title, file}) => {
  const {link} = useImg(file)
  return (
    <Box width={[1, 1 / 2, 1 / 2, 1 / 3]}>
      <Box
        mr={[0, 24]}
        mb="24px"
        bg="#fff"
        sx={{borderRadius: 10, overflow: 'hidden', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}
      >
        <Link to={`/materials/articles/${id}`}>
          <Box>
            {link && <img src={link} style={{width: '100%', height: '200px'}}/>}
          </Box>
          <Box p="24px 20px">
            <Box minHeight={[0, 112]}>
              <Text
                fontSize={['1.25rem', '1.25rem', '1.5rem']}
                lineHeight={['1.5rem', '1.75rem']}
                fontWeight="bold"
              >
                {title}
              </Text>
            </Box>
            <Box mt="16px">
              <StyledLink to={`/materials/articles/${id}`}>Читать</StyledLink>
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default memo(ArticlePreview);
