import React, {useEffect, useState} from 'react';
import {Flex, Box, Text} from 'rebass';
import {Controller, useWatch} from "react-hook-form";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {ru} from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import {DesktopDatePicker} from "@material-ui/lab";
import {isEmpty} from "ramda";
import {useParams} from "react-router-dom";

import {useEditEducation} from "./hooks/useEditEducation";
import EditLayout from '../EditLayout';
import {SOURCE_TYPE} from '../constants';
import Button from '../Button';
import Select from '../Select';
import CustomTextField from '../TextField';
import TextField from '@material-ui/core/TextField';
import File from '../File';
import {useFormValue, useAddedMaterial} from "./hooks";
import {fieldNames, fileSourceTypes, statuses, access, acceptFormat} from "./constants";
import {useFile} from "../../../../hooks/useFile";
import ModalStatus from "../ModalStatus";
import {LOADING} from "../../../../store/statusConstants";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const MaterialEdit = () => {
  const {id} = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {education, handleEditEducation, editStatus} = useEditEducation(setIsModalOpen)
  const {file, setFile, handleAddFile} = useFile(education?.file);

  const {addMaterial, addStatus} = useAddedMaterial(setIsModalOpen, handleAddFile)
  const {
    control,
    setValue,
    onSubmit,
    errorsList,
    clearErrors,
    validationStatus,
    getValues
  } = useFormValue(education, handleEditEducation, addMaterial, handleAddFile, setIsModalOpen)


  const formValues = useWatch({
    control,
  })

  const changeValue = (name, value) => {
    setValue(name, value)
  };

  const changeType = (name, value) => {
    setValue(name, value)
    if (value !== SOURCE_TYPE.LOCAL) {
      setFile(null)
    }
  };

  useEffect(() => {
    if (!isEmpty(file)) {
      changeValue('file', file?.[0])
    }
  }, [file])

  return (
    <EditLayout title={id ? "Редактировать материал" : "Добавить материал"}>
      <form onSubmit={onSubmit}>
        <Flex mb="24px">
          <Box width="50%" pr="16px">
            <Controller
              name={fieldNames.title}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomTextField
                  name={fieldNames.title}
                  value={value || ''}
                  label="Название"
                  size="small"
                  sx={{width: '100%', marginBottom: '24px'}}
                  onChange={(value) => changeValue(fieldNames.title, value)}
                />
              )}
            />

              <Controller
                name={fieldNames.date}
                control={control}
                render={({field: {onChange, value}}) => (
                  <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      mask="__.__.____"
                      inputFormat="dd.MM.yyyy"
                      value={value || ''}
                      label='Дата публикации'
                      onChange={(value) => changeValue('date', value)}
                      disableOpenPicker
                      renderInput={(params) => <TextField  {...params} size="small"
                                                           name={fieldNames.date}
                                                           sx={{width: '100%', marginBottom: '24px'}}
                                                           helperText={params.error && "Некорректный формат даты"}
                      />}
                    />
                  </LocalizationProvider>
                )}
              />


          </Box>
          <Flex flexDirection="column" width="50%">
            <Box mb="24px">
              <Controller
                name={fieldNames.publishStatus}
                control={control}
                render={({field: {value}}) => (
                  <Select
                    label="Статус"
                    value={value || ''}
                    options={statuses}
                    onChange={(name, value) => changeValue(name, value)}
                    width="100%"
                    name={fieldNames.publishStatus}
                  />
                )}
              />
            </Box>
            <Box mb="24px">
              <Controller
                name={fieldNames.access}
                control={control}
                render={({field: {onChange, value}}) => (
                  <Select
                    label="Доступ"
                    value={value || ''}
                    options={access}
                    onChange={(name, value) => changeValue(name, value)}
                    width="100%"
                    name={fieldNames.access}
                  />
                )}
              />
            </Box>
          </Flex>

        </Flex>
        <Flex mb="40px">
          <Box width="50%" pr="16px">
            <Controller
              name={fieldNames.type}
              control={control}
              render={({field: {value}}) => (
                <Select
                  label="Тип видео"
                  value={value || ''}
                  options={fileSourceTypes}
                  onChange={(name, value) => changeType(name, value)}
                  width="100%"
                  name={fieldNames.type}
                />
              )}
            />

          </Box>
          <Box width="50%">
            {formValues.type === SOURCE_TYPE.LOCAL ? (
              <>
                <File onChange={setFile} files={file} validFormats={acceptFormat}/>
                <Text mt="8px" fontSize="14px" color="#777">
                  допустимый формат файла: pdf, doc, docx
                </Text>
              </>
            ) : (
              <>
                <Controller
                  name={fieldNames.externalLink}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <TextField
                      value={value || ''}
                      label="Ссылка"
                      size="small"
                      sx={{width: '100%'}}
                      onChange={onChange}
                    />
                  )}
                />
              </>
            )}
          </Box>
        </Flex>
        <Flex>
          <Button variant="contained" label="Сохранить" type='onsubmit' disabled={!checkedPermission(permissions.educationWrite)}/>
        </Flex>
      </form>
      <ModalStatus
        status={addStatus || editStatus || validationStatus || LOADING}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        access="Материал успешно добавлен"
        path="/admin/education"
        errorsList={errorsList}
        clearErrors={clearErrors}
      />
    </EditLayout>
  );
};

export default MaterialEdit;
