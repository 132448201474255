import styled from "styled-components";

export const Content = styled.div`
  border: 1px dashed #D8D8D8;
  padding: 20px 20px 18px;
  margin-top: 40px;
  position: relative;
  margin-bottom: 10px;

  .name {
    color: #101828;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 3px;
  }

  .size {
    padding-left: 28px;
    font-size: 14px;
    color: rgba(16, 24, 40, 0.7);
  }

  .header {
    display: grid;
    grid-template-columns: max-content 90%;
    grid-gap: 5px;
  }

  .trash {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .fileName {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
  }

  .wrapper {
    cursor: pointer;
  }
`
