import styled from "styled-components";

export const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  padding: 50px 40px 40px 40px;
  flex-direction: column;

  .ask {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 200px 500px;
    white-space: pre-wrap
  }
`
export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ccc;
`
