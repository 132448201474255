import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';
import {Text} from 'rebass';

const Wrapper = styled.button`
  width: ${({width = '180px'}) => width};
  height: ${({height = 'auto'}) => height};
  margin: ${({margin = 0}) => margin};
  padding: ${({height}) => (!height ? '14px 0' : '0')};
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  line-height: 1;
  background: ${({variant = 'primary', disabled}) =>
    disabled ? 'rgba(0, 0, 0, 0.1)' : variant === 'primary' ? '#7800ff' : '#fff'};
  border-width: 2px;
  border-style: solid;
  border-color: ${({disabled = false, variant}) =>
    disabled ? 'transparent' : variant === 'warn' ? '#ff6a00' : '#7800ff'};
  cursor: pointer;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const spin = keyframes`
  to {
    -webkit-transform: rotate(360deg);
  }
`;

 const Loader = styled.div`
  display: inline-block;
  width: 13px;
  height: 13px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: deepskyblue;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
`;

const Button = ({title, type, variant, width, height, margin, disabled, onClick, loading}) => (
  <Wrapper
    width={width}
    height={height}
    variant={variant}
    margin={margin}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {loading && <Loader/>}
    {!loading && <Text
      color={
        variant === 'primary' || disabled ? '#fff' : variant === 'warn' ? '#ff6a00' : '#7800ff'
      }
    >
      {title}
    </Text>}

  </Wrapper>
);

const ButtonWrapper = (props) => {
  const {link} = props;
  if (link) {
    return (
      <Link to={link}>
        <Button {...props} />
      </Link>
    );
  }

  return <Button {...props} />;
};

export default memo(ButtonWrapper);
