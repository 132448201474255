import {useState, useCallback} from 'react';
import {useUpdateEffect} from 'react-use';

import { toString, omit } from 'ramda';
import { saveParamsToUrl } from '../utils/saveParamsUrl';
import { useRouter } from './useRouter';

export const INITIAL_PAGE = 1;
export const INITIAL_PAGE_SIZE = 6;

/**
 * Хук для обработки  пагинации
 * @returns
 */
export const usePagination = () => {
  const location = useRouter();
  const [currentPage, setPage] = useState(Number(location.query.page) || INITIAL_PAGE);
  const [allPages, setAllPages] = useState(0);
  const [countElements, setCountElements] = useState(
    Number(location.query?.countElements) || INITIAL_PAGE_SIZE,
  );

  useUpdateEffect(() => {
    setPage(INITIAL_PAGE);
  }, [toString(omit(['page'], location.query))]);

  useUpdateEffect(() => {
    saveParamsToUrl({ page: currentPage });
  }, [currentPage]);

  const handlePageChange = useCallback((selected) => setPage(selected.selected + 1), [setPage]);

  return {
    currentPage,
    handlePageChange,
    setAllPages,
    allPages,
    setPage,
    countElements,
    setCountElements,
  };
};
