import React from 'react';
import { Text } from 'rebass';

const TextContent = ({ children, color }) => {
  return (
    <Text mb={16} fontSize={[16, 16, 18]} lineHeight={['24px', '24px', '27px']} color={color}>
      {children}
    </Text>
  );
};

export default TextContent;
