/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {checkedToken} from "../../../utils/checkedToken";

const initialState = {
  isAuthorized: checkedToken(),
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    authUser() {},
    checkAuthUser() {},
    logoutUser() {},
    changeIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { authUser, setUser, checkAuthUser, changeIsAuthorized, logoutUser } =
  adminSlice.actions;

export default adminSlice.reducer;
