import { useAsyncFn } from 'react-use';

import { useEffect, useState } from 'react';
import { fetchFaqs } from '../requests';
import { getNormalizedFaqs } from '../utils/getNormalizedFaqs';

const pageSize = 5;
export const useFaqs = (page, setAllPages) => {
  const [{ value }, getFaqs] = useAsyncFn(fetchFaqs);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    getFaqs({ page, size: pageSize });
  }, [page]);

  useEffect(() => {
    if (value) {
      const newFaqs = value?.content.map((item) => getNormalizedFaqs(item));
      setFaqs(newFaqs);
      setAllPages(value?.pagination?.totalPages);
    }
  }, [value]);

  return { faqs };
};
