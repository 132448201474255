import {useEffect, useState} from "react";
import {useAsyncFn} from "react-use";

import {getCaptcha} from "../requests";

export const useCaptcha = (isUpdateCaptcha,setIsUpdateCaptcha) => {
  const [captchaLink, setCaptchaLink] = useState('')
  const [{value, loading}, execute] = useAsyncFn(getCaptcha)

  const updateCaptcha = () => {
    execute()
  }

  useEffect(() => {
    if (value) {
      setCaptchaLink(URL.createObjectURL(value))
    }
  }, [value])

  useEffect(() => {
    if (isUpdateCaptcha) {
      execute()
      setIsUpdateCaptcha(false)
    }
    execute()
  }, [isUpdateCaptcha])

  return {captchaLink, updateCaptcha, loadingCaptcha: loading}
}
