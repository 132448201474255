import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ACCESS, ARTICLE_STATUSES, MATERIAL_TYPES } from '../../modules/Admin/components/constants';
import QR from '../../repositories/quizRepository';
export const fetchQuiz = createAsyncThunk('Quiz/fetchQuiz', async (arg, { getState }) => {
  const state = getState();
  await new Promise((resolve) => setTimeout(resolve, 2000));
  return mokeQuiz;
});

export const createQuiz = createAsyncThunk('Quiz/createQuiz', async (data) => {
  console.log(data);
});

export const deleteQuizById = createAsyncThunk('Quiz/deleteQuizById', async (id) => {
  // отправляем данные
  return mokeQuiz.filter((item) => item.id !== id);
});

export const getActiveQuiz = createAsyncThunk('Quiz/getActiveQuiz', async () => {
  const data = await QR.getActiveQuiz();
  return data.json();
});

export const startQuizById = createAsyncThunk('Quiz/startQuizById', async (arg, { getState }) => {
  const state = getState();

  const resp = await QR.startActiveQuiz(state.quiz.activeQuiz.id);
  const result = await resp.json();

  return result;
});

export const getQuizData = createAsyncThunk('Quiz/getQuizData', async () => {
  const data = { sessionToken: localStorage.quizToken };

  const resp = await QR.getQuizResult(data);
  const result = await resp.json();

  return result;
});

export const saveAnswer = createAsyncThunk('Quiz/saveAnswer', async (arg) => {
  const data = {
    sessionToken: localStorage.quizToken,
    questionId: arg.questionId,
    answerId: arg.answerId,
  };

  const resp = await QR.saveAnswer(data);
  const result = await resp.json();

  return result;
});

const QuizSlice = createSlice({
  name: 'Quiz',
  initialState: {
    activeQuiz: {
      id: null,
      title: '',
      remainingAnswersData: [],
      remainingQuestions: [],
      totalQuestions: 0,
    },
    listOfQuiz: [],
    dateRange: [1627761600, 1643107212],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: [1627761600, 1643107212],
      access: ACCESS.ALL,
      type: MATERIAL_TYPES.ALL,
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      dateSort: true,
      titleSort: null,
      statusSort: null,
    },
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchQuiz.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchQuiz.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfQuiz = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchQuiz.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createQuiz.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createQuiz.fulfilled]: (state, action) => {},
    [createQuiz.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteQuizById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteQuizById.fulfilled]: (state, action) => {
      state.listOfQuiz = action.payload;
      state.status = FULFILLED;
    },
    [deleteQuizById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [getActiveQuiz.pending]: (state, action) => {
      state.status = LOADING;
    },
    [getActiveQuiz.fulfilled]: (state, action) => {
      state.activeQuiz = { ...state.activeQuiz, ...action.payload.body };
      state.status = FULFILLED;
    },
    [getActiveQuiz.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [startQuizById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [startQuizById.fulfilled]: (state, action) => {
      localStorage.setItem('quizToken', action.payload.body);
      state.status = FULFILLED;
    },
    [startQuizById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [getQuizData.pending]: (state, action) => {
      state.status = LOADING;
    },
    [getQuizData.fulfilled]: (state, action) => {
      state.activeQuiz = { ...state.activeQuiz, ...action.payload.body };
      console.log(action.payload.body.remainingQuestions.length);
      if (action.payload.body.remainingQuestions.length) {
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [getQuizData.rejected]: (state, action) => {
      console.log('error');
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = QuizSlice.actions;

export default QuizSlice.reducer;

const mokeQuiz = [
  {
    id: 1,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 2,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 3,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 4,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 5,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 6,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 7,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 8,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 9,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 10,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 11,
    date: 1627761600,
    title: 'Комплаенс-викторина для сотрудников',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
];
