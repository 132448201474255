import React, { memo } from 'react';
import { Flex, Text, Box } from 'rebass';
import styled from 'styled-components';
import Button from './Button';
import ArrowButton from './ArrowButton';

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
  background-color: #f2f3f7;
`;

const LayoutCase = ({
  pagetTitle,
  leftContent,
  rightContent,
  bgImage,
  nextButtonDisabled,
  nextLink,
}) => {
  return (
    <Wrapper>
      <Flex
        flexWrap="wrap"
        height={['auto', '100vh', '100%']}
        minHeight="100%"
        sx={{
          background: [`url(${bgImage}) no-repeat center`],
          backgroundSize: 'cover',
        }}
      >
        <Flex flex={['auto', 'auto', 'auto', '1.6']} pt="40px" px="40px">
          <Flex maxWidth={['100%', '100%', '100%', 640]} flexDirection="column">
            <Flex height={['auto', 'auto', '10%']}>
              <Button title={pagetTitle} />
            </Flex>
            <Flex height={['auto', 'auto', '80%']} my={[0, 40, 40, 0]} alignItems="center">
              {leftContent}
            </Flex>
            <Box display={['none', 'none', 'none', 'flex']}>
              <Flex height={['auto', 'auto', 'auto', 125]}>
                <ArrowButton isLeft />
                <ArrowButton link={nextLink} disabled={nextButtonDisabled} />
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Flex flex={['auto', 'auto', 'auto', '1']} p="40px">
          {rightContent}
        </Flex>

        <Flex
          height={['auto', 'auto', 'auto', 125]}
          pl={[0, '40px']}
          pt="40px"
          ml={[16, 24, 40]}
          display={['flex', 'flex', 'flex', 'none']}
        >
          <ArrowButton isLeft />
          <ArrowButton link={nextLink} disabled={nextButtonDisabled} />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default memo(LayoutCase);
