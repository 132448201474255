import React, { useState, memo } from 'react';
import { Flex, Box } from 'rebass';
import { CancelIconAnimated } from 'atoms';

const Burger = ({ children }) => {
  const [isOpened, toggleOpened] = useState(false);
  return (
    <Flex>
      <CancelIconAnimated
        isOpened={isOpened}
        color="#fff"
        onClick={() => toggleOpened(!isOpened)}
      />

      {isOpened ? (
        <Flex
          sx={{
            paddingBottom: 24,
            top: 24,
            left: 0,
            position: 'absolute',
            width: '400%',
            background: '#272F3D',
            zIndex: 10,
            margin: '24px 0 0 -300%',
            height: [270, 200],
          }}
        >
          <Flex>
            <Box onClick={() => toggleOpened(!isOpened)}>{children}</Box>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default memo(Burger);
