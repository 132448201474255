import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(33% - 20px);
  border-radius: 10px;
  border: 1px solid #E2E2E2;
  padding: 24px 20px;
  margin-bottom: 20px;
  margin-right: 20px;

  .button {
    width: 100%;
    display: inline-block;
    border: 2px solid #7800FF;
    background-color: #ffffff;
    color: #7800FF;
    text-decoration: none;
    padding-top: 14px;
    padding-bottom: 13px;
    line-height: 1;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    height: max-content;
  }
`

export const Title = styled.h5`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 22px;
`

export const Date = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 24px;
  margin-bottom: 14px;
`

export const File = styled.div`
  display: flex;
  margin-bottom: 30px;
`
