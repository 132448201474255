import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ACCESS, ARTICLE_STATUSES, DOCUMENT_TYPES } from '../../modules/Admin/components/constants';

export const fetchDocuments = createAsyncThunk(
  'Documents/fetchDocuments',
  async (arg, { getState }) => {
    const state = getState();
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return mokeDocuments;
  },
);

export const createDocuments = createAsyncThunk('Documents/createDocuments', async (data) => {
  console.log(data);
});

export const fetchDocumentById = createAsyncThunk('article/fetchDocumentById', async (id) => {
  //тут будет запрос на данные конкретной статьи
  return mokeDocument;
});

export const deleteDocumentsById = createAsyncThunk('Documents/deleteDocumentsById', async (id) => {
  // отправляем данные
  return mokeDocuments.filter((item) => item.id !== id);
});

const DocumentsSlice = createSlice({
  name: 'documents',
  initialState: {
    listOfDocuments: [],
    dateRange: [1627761600, 1643107212],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: [1627761600, 1643107212],
      access: ACCESS.ALL,
      docCategory: DOCUMENT_TYPES.ALL,
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      dateSort: true,
      titleSort: null,
      statusSort: null,
    },
    data: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchDocuments.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchDocuments.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfDocuments = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchDocuments.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createDocuments.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createDocuments.fulfilled]: (state, action) => {},
    [createDocuments.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteDocumentsById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteDocumentsById.fulfilled]: (state, action) => {
      state.listOfDocuments = action.payload;
      state.status = FULFILLED;
    },
    [deleteDocumentsById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchDocumentById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchDocumentById.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchDocumentById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = DocumentsSlice.actions;

export default DocumentsSlice.reducer;

const mokeDocument = {
  title: 'qwqqwqwqw',
  status: 'UNPUBLISHED',
  files: [],
  access: 'PRIVATE',
  text: 'asasasaasa',
  documentType: 'LAWS',
};

const mokeDocuments = [
  {
    id: 1,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 2,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 3,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 4,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 5,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 6,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 7,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 8,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 9,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 10,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
  {
    id: 11,
    date: 1627761600,
    title: 'Антикоррупционная хартия российского бизнеса',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    docCategory: 'LAWS',
  },
];
