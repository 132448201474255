import React, {memo} from 'react';
import {Route, NavLink, useHistory} from 'react-router-dom';
import {Flex, Box, Text} from 'rebass';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import styled from 'styled-components';

import {useDispatch} from 'react-redux';
import {deleteLocalStorage} from '../../utils/localStorage';
import {changeIsAuthorized} from '../../store/reducers/Admin/Admin';
import {checkedPermission} from "../../utils/checkedPermission";
import {permissions} from "../../modules/Admin/constants";

const StyledNavLink = styled(NavLink)`
  padding: 16px 32px;
  color: #fff;
  border-bottom: 1px solid #656565;

  &:hover {
    color: #aaa;
  }
`;

const menuItems = [
  // { id: 1, title: 'Главная страница', url: 'main' },
  // { id: 2, title: 'Баннеры', url: 'banners' },
  {id: 3, title: 'Новости', url: 'news', permission: permissions.newsRead},
  {id: 4, title: 'Интересные материалы', url: 'materials', permission: permissions.materialsRead},
  {id: 5, title: 'Вопрос-ответ', url: 'faqs', permission: permissions.questionsAnswersRead},
  {id: 6, title: 'Документы', url: 'documents', permission: permissions.docsRead},
  {id: 7, title: 'Обучение', url: 'education', permission: permissions.educationRead},
  // { id: 8, title: 'Викторина', url: 'quiz' ,permission:''},
  {id: 9, title: 'Обратная связь', url: 'feedback', permission: permissions.feedBackRead},
  {id: 10, title: 'Задать вопрос', url: 'ask', permission: permissions.questionsRead},
  {id: 11, title: 'Учетные записи', url: 'accounts', permission: permissions.usersRead},
  {id: 12, title: 'Скачать отчёт', url: 'report', permission: permissions.reportRead},
];

const Menu = () => {
  return (
    <Flex flexDirection="column" bg="#565656" width="248px">
      {menuItems.map((item) => (
        checkedPermission(item.permission) && <StyledNavLink
          key={item.id}
          to={`/admin/${item.url}`}
          activeStyle={{
            background: '#4a4a4a',
          }}
        >
          {item.title}
        </StyledNavLink>
      ))}
    </Flex>
  );
};

const AdminLayout = ({component: Component, layout: Layout, ...rest}) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const logout = () => {
    deleteLocalStorage('accessToken');
    deleteLocalStorage('tokenTime');
    dispatch(changeIsAuthorized(false));
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <Flex
          alignItems="center"
          flexDirection="column"
          width="100%"
          minHeight="100vh"
          bg="#f3f3f3"
        >
          <Box width="1240px">
            <Flex
              bg="#01abe8"
              minHeight="60px"
              alignItems="center"
              justifyContent="space-between"
              p="0 32px"
            >
              <Text fontSize="24px" color="#fff">
                Система управления контентом
              </Text>
              <Flex alignItems="center">
                <Text fontSize="16px" color="#fff" mr="8px" sx={{cursor: 'pointer'}} onClick={() => {
                  history.push('/admin/profile')
                }}>
                  Администратор
                </Text>
                <ExitToAppOutlinedIcon style={{fill: '#fff', cursor: 'pointer'}} onClick={logout}/>
              </Flex>
            </Flex>

            <Flex minHeight="calc(100vh - 60px)">
              <Menu/>
              <Box bg="#fff" width="100%">
                <Component {...props} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      )}
    />
  );
};

export default memo(AdminLayout);
