import React, { memo } from 'react';
import { Box, Text } from 'rebass';
import { Button } from 'atoms';

const AskMoreQuestions = () => {
  return (
    <Box
      bg="#fff"
      p="1.5rem"
      height="max-content"
      sx={{
        borderRadius: 10,
        border: '1px solid #E2E2E2',
      }}
    >
      <Text fontSize="1.5rem" fontWeight="bold" mb="16px">
        Остались вопросы?
      </Text>
      <Text fontSize="1rem" lineHeight="1.5rem" mb="16px">
        <p>Если вы не нашли ответ на свой вопрос,</p>
        <p>мы рады будем помочь вам лично.</p>
      </Text>

      <Button variant="primary" title="Задать вопрос" link="/feedback/question" />
    </Box>
  );
};

export default memo(AskMoreQuestions);
