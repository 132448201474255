import debounce from 'lodash.debounce';
import { mergeDeepRight } from 'ramda';

import history from '../store/history';
import { generateQueryString, parseQueryString } from './queryString';

/**
 * Утилита для сохранения данных в строку url
 * @param params Параметры которые нужно сохранить
 */
export const saveParamsToUrl = debounce((params) => {
  const { pathname, state, search } = window.location;

  const prevQueryParams = parseQueryString(search);
  const queryParams = mergeDeepRight(prevQueryParams, params);

  const newState = mergeDeepRight(state, {
    shallow: true,
  });

  history.replace(`${pathname}${generateQueryString(queryParams)}`, newState);
}, 300);
