import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ARTICLE_STATUSES } from '../../modules/Admin/components/constants';
import { getQueryParams } from '../../helpers/helpers';
export const fetchArticle = createAsyncThunk('article/fetchArticle', async (arg, { getState }) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  const { filter, sort, pageNum } = getState().article;

  // преобразование данных в строку для запроса
  console.log(getQueryParams({ ...filter, ...sort, pageNum }));
  return mokeArticles;
});

export const createArticle = createAsyncThunk('article/createArticle', async (data) => {
  console.log(data);
});

export const fetchArticleById = createAsyncThunk('article/fetchArticleById', async (id) => {
  //тут будет запрос на данные конкретной статьи
  return mokeArticle;
});

export const deleteArticleById = createAsyncThunk('article/deleteArticleById', async (id) => {
  // отправляем данные
  return mokeArticles.filter((item) => item.id !== id);
});

const articleSlice = createSlice({
  name: 'article',
  initialState: {
    listOfArticle: [],
    filter: {
      status: ARTICLE_STATUSES.ALL,
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      categorySort: false,
      titleSort: null,
      statusSort: null,
    },
    data: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchArticle.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchArticle.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfArticle = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchArticle.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchArticleById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchArticleById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchArticleById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createArticle.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createArticle.fulfilled]: (state, action) => {},
    [createArticle.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteArticleById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteArticleById.fulfilled]: (state, action) => {
      state.listOfArticle = action.payload;
      state.status = FULFILLED;
    },
    [deleteArticleById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = articleSlice.actions;

export default articleSlice.reducer;

const mokeArticle = {
  title: 'QWERTY',
  status: 'UNPUBLISHED',
  files: [],
  blockId: null,
  text: 'qwqwqwqq',
};

const mokeArticles = [
  {
    id: 1,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'UNPUBLISHED',
  },
  {
    id: 2,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 3,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 4,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 5,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 6,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 7,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 8,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 9,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 10,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 11,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 12,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 13,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 14,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 15,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 16,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 17,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
  {
    id: 18,
    category: 'Главная страница: Блок 1',
    title: 'Честный бизнес — единые правила!',
    status: 'PUBLISHED',
  },
];
