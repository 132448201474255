import React, {memo, useState} from 'react';
import {Flex, Box, Text} from 'rebass';

import {Button, Checkbox, RadioGroup, Input} from 'atoms';
import {ReadMoreFaqs, Captcha, Modal} from 'molecules';
import {TwoColumnsLayout} from 'layouts';
import {useViolation} from "../hooks/useViolation";
import * as S from '../styles'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/core/Alert";
import File from "./File";
import {accept,maxSize} from "../constants";

const Violation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const radioItems = [
    {id: 1, label: 'Нет, я согласен назвать себя и предоставить свои контактные данные'},
    {id: 2, label: 'Да, я хочу сообщить анонимно'},
  ];

  const {
    formik, pin,
    loading, isAlert,
    setIsAlert, fileError,closeAlert, ...rest
  } = useViolation(setIsModalOpen)

  const renderModalBody = () => {
    return (
      <S.Modal>
        <h1>Сообщение</h1>
        <S.Line/>
        <div className='text'>
          Подробную информацию о текущем статусе <br/>
          обработки Вашего обращения Вы сможете <br/>
          получить, введя ПИН-код обращения:  <span className='pin'>{pin}</span> в <br/>
          разделе "Обратная связь" по кнопке "Узнать статус <br/>обращения".
        </div>
        <div className='error' style={{color: 'red'}}>
          Обращаем Ваше внимание, что в случае утери <br/><span>ПИН-кода</span>, обратная связь по решению
          проблемы <br/>не сможет
          быть
          предоставлена!
        </div>
        <Button
          variant="primary"
          title="Закрыть"
          onClick={() => setIsModalOpen(false)}
        />
      </S.Modal>
    );
  };

  const renderLeft = () => {
    return (
      <Box>
        <Snackbar
          open={isAlert}
          autoHideDuration={3000}
          onClose={closeAlert}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          <Alert
            variant="filled"
            severity='error'
            onClose={closeAlert
          }>
            {fileError ? fileError : 'При обработке запроса,произошла ошибка'}
          </Alert>
        </Snackbar>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box mb={32}>
            <Text fontSize={['1rem', '1rem', '1.5rem']} fontWeight="bold" mb={16}>
              Правила подачи обращения
            </Text>
            <Text lineHeight={['1.125rem', '1.125rem', '1.5rem']} mb={20}>
              <ul>
                <li>
                  Если Вы сообщаете{' '}
                  <strong>
                    <u>анонимно</u>
                  </strong>
                  , то сотрудники, получающие обращение и участвующие в его рассмотрении, не знают
                  Ваше ФИО, контактные данные и т.д.;
                </li>
                <li>
                  Персональные данные заявителя, а также сообщенные сведения будут использоваться{' '}
                  <strong>
                    <u>конфиденциально</u>
                  </strong>{' '}
                  – то есть только в целях проведения служебной проверки и только теми лицами,
                  которые непосредственно участвуют в проведении необходимых мероприятий;
                </li>
                <li>
                  Обратную связь по результатам рассмотрения обращений Вы можете получить с помощью
                  индивидуального <strong>ПИН-кода</strong>, который будет сгенерирован после
                  отправки Вами обращения.
                  <br/>
                  <u>Если Вы обращаетесь анонимно</u>, то Вам необходимо сохранить данный{' '}
                  <strong>ПИН-код</strong>, иначе Вы не сможете узнать результаты рассмотрения либо
                  сообщить дополнительные сведения.
                  <br/>
                  <u>Если Вы обращаетесь не анонимно</u>, то <strong>ПИН-код</strong> Вашего
                  обращения будет отправлен на адрес Вашей электронной почты.
                </li>
              </ul>
            </Text>

            <Checkbox
              id="isRulesAccepted"
              name="isRulesAccepted"
              checked={formik.values.isRulesAccepted}
              onChange={(val) => formik.setFieldValue('isRulesAccepted', val)}
              label="Я прочел(-ла) и понял(-ла) правила работы с обращениями и принимаю данные условия."
            />
          </Box>
          <Box mb={24}>
            <Text fontSize={['1rem', '1rem', '1.125rem']} fontWeight="500" mb={16}>
              Будет ли Ваше обращение анонимным?
            </Text>

            <RadioGroup
              id="isAnonymous"
              name="isAnonymous"
              items={radioItems}
              checked={formik.values.isAnonymous}
              onChange={(val) => {
                formik.setFieldValue('isAnonymous', val);
              }}
            />

            <Text lineHeight={['1.125rem', '1.125rem', '1.5rem']} mb={20}>
              В случае анонимного обращения у нас не будет возможности уточнить, при необходимости,
              важные для рассмотрения детали, что может увеличить сроки и снизить вероятность его
              успешного рассмотрения. В этом случае обратная связь по решению проблемы не
              предоставляется.
            </Text>
          </Box>

          {formik.values.isAnonymous === 1 ? (
            <Box mt={32} mb={24} width={[1, 1, 300]}>
              <Input
                id="name"
                name="name"
                value={formik.values.name}
                label="Фамилия, Имя, Отчество"
                required
                mb="32px"
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <Input
                id="email"
                name="email"
                value={formik.values.email}
                label="Электронная почта"
                required
                mb="32px"
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Input
                id="phone"
                name="phone"
                value={formik.values.phone}
                label="Телефон (с указанием кода города)"
                mb="32px"
                onChange={formik.handleChange}
              />
            </Box>
          ) : null}

          <Box width="100%" mb={32}>
            <Input
              id="comment"
              name="comment"
              value={formik.values.comment}
              label="Текст сообщения"
              required
              multiline
              rows={4}
              variant="outlined"
              fullwith="true"
              mb="32px"
              onChange={formik.handleChange}
            />
          </Box>
          <File {...rest} accept={accept} maxSize={maxSize} setIsAlert={setIsAlert}/>
          <Box mt={24}>
            <Captcha
              {...rest}
              value={formik.values.captcha}
              name='captcha'
              onChange={formik.handleChange}
            />
          </Box>
          <Text lineHeight={['1.125rem', '1.125rem', '1.5rem']} mb={20}>
            <Checkbox
              id="isPersonalDataAccepted"
              name="isPersonalDataAccepted"
              checked={formik.values.isPersonalDataAccepted}
              onChange={(val) => formik.setFieldValue('isPersonalDataAccepted', val)}
              label="Отправляя обращение, Вы подтверждаете свое согласие на обработку Ваших персональных данных в соответствии с требованиями Федерального закона № 152-ФЗ от 27.07.2006 года «О персональных данных» для цели проверки изложенных Вами сведений и обеспечения обратной связи с Вами."
            />
          </Text>

          <Flex flexWrap="wrap" mb={24} alignItems="center">
            <Box width={[1, 200]} mb={[24, 0]}>
              <Button variant="primary" title="Отправить" disabled={!formik.isValid || !formik.dirty || loading}
                      loading={loading}/>
            </Box>
            <Box width={[1, 'calc(100% - 200px)']}>
              <Text display="inline" color="#7800FF">
                *
              </Text>
              <Text display="inline">- поля, обязательные для заполнения</Text>
            </Box>
          </Flex>
        </form>
      </Box>
    );
  };

  return (
    <>
      <TwoColumnsLayout
        title="Сообщить о нарушениях"
        left={renderLeft()}
        right={<ReadMoreFaqs/>}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        body={renderModalBody()}
      />
    </>
  );
};

export default memo(Violation);
