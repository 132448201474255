import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import DataLayout from '../DataLayout';
import {FILTER_TYPES, ARTICLE_STATUSES, ACCESS} from '../constants';
import {setSort} from '../../../../store/reducers/News.reducers';
import {useNews} from './hooks';
import {getInitFiltersParams} from "../../../../utils/getInitFiltersParams";
import {filterList, initialFilters} from "./constants";
import {useFilters} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";


const News = () => {
  const {sort} = useSelector((state) => state?.news);
  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();
  const initValues = getInitFiltersParams(filterList);
  const initDate = initValues?.date?.[0] ? [new Date(initValues?.date?.[0]), new Date(initValues?.date?.[1])] : null

  const {filter, handleChangeFilters, resetFilters} = useFilters({...initValues, date: initDate},initialFilters);
  const dispatch = useDispatch();
  const {newsList, handleDeleteNews, updateTableData, getNews, status} = useNews(
    filter,
    setAllPages,
    currentPage,
    pageSize,
    setAllElements,
  );

  const filterItems = [
    {
      name: 'date',
      type: FILTER_TYPES.DATE_RANGE,
      value: filter?.date,
      // initialValue: dateRange,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'status',
      label: 'Статус',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ARTICLE_STATUSES.ALL},
        {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
        {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
      ],
      value: filter.status,
      // initialValue: ARTICLE_STATUSES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'access',
      label: 'Доступ',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ACCESS.ALL},
        {title: 'Открытые', value: ACCESS.PUBLIC},
        {title: 'Закрытые', value: ACCESS.PRIVATE},
      ],
      value: filter.access,
      // initialValue: ARTICLE_STATUSES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
  ];

  const headCells = [
    {
      id: 'dateSort',
      disablePadding: true,
      label: 'Дата публикации',
      status: sort.dateSort,
    },
    {
      id: 'titleSort',
      disablePadding: false,
      label: 'Заголовок',
      status: sort.titleSort,
    },
    {
      id: 'statusSort',
      disablePadding: false,
      label: 'Статус',
      status: sort.statusSort,
    },
  ];

  const actions = [{link: 'edit', label: 'Добавить новость',disabled:!checkedPermission(permissions.newsWrite)}];

  const handleResetFilters = async () => {
    await resetFilters();
    await getNews({
      startDate: '',
      endDate: '',
      accessType: '',
      publishStatus: '',
      page: 1,
      size: 10,
      sort: "publicationDate,DESC",
    });
    setPage(0)
  };

  return (
    checkedPermission(permissions.newsRead) &&
    <DataLayout
      title="Новости"
      headCells={headCells}
      rows={newsList}
      filterItems={filterItems}
      actions={actions}
      accessFilter={updateTableData}
      status={status}
      handleRequestSort={(name) => dispatch(setSort(name))}
      pagination={pageSize}
      setPagination={setCountElements}
      deleteElement={handleDeleteNews}
      resetFilters={handleResetFilters}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
    />
  );
};

export default News;
