import React, { memo } from 'react';
import { Box, Flex } from 'rebass';
import { Paginator } from 'molecules';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Icon } from 'atoms';
import VideoPreview from './VideoPreview';
import { useVideo } from '../hooks/useVideo';

const Player = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 10;
`;

const CancelIconWrap = styled.div`
  position: absolute;
  display: flex;
  top: -20px;
  right: -20px;
  padding: 6px;
  border: 2px solid #7800ff;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  z-index: 100;
`;

const Videos = () => {
  const { listVideo, playingVideo, playVideo } = useVideo();

  return (
    <Box mt="24px" width="100%">
      <Flex flexWrap="wrap" width="100%" mb="32px">
        {listVideo.map((video) => (
          <VideoPreview key={video.id} {...video} setPlayingVideo={playVideo} />
        ))}
      </Flex>
      <Box>
        <Paginator />
      </Box>

      {playingVideo ? (
        <Player>
          <CancelIconWrap onClick={() => playVideo(null)}>
            <Icon name="cancel" fill="#7800ff" w="24px" h="24px" viewBox="0 0 512 512" />
          </CancelIconWrap>
          <ReactPlayer
            url={playingVideo}
            playing controls width="100%" height="100%" style={{backgroundColor:'black'}}/>
        </Player>
      ) : null}
    </Box>
  );
};

export default memo(Videos);
