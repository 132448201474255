import React, { memo, useState } from 'react';
import { Flex, Text, Box } from 'rebass';
import styled from 'styled-components';
import { Modal } from 'molecules';
import Button from './Button';
import ArrowButton from './ArrowButton';
import {red} from "@material-ui/core/colors";

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
  background-color: #f2f3f7;
  @media (max-width: 767px) {
    height: auto;
  }
`;

const WrapperHandler = styled.div``;

const Layout = ({
  flex,
  pagetTitle,
  leftContent,
  rightContent,
  bgImage,
  nextButtonDisabled,
  hideNextButton,
  nextLink,
  modalContent,
                  isNotBlockBackground
}) => {
  const [modalOpened, setModalVisibility] = useState(false);

  const handleClick = (e) => {
    if (
      e.target.parentElement.classList.contains('modalOpener') ||
      e.target.classList.contains('modalOpener')
    ) {
      setModalVisibility(true);
    }
    if (e.target.classList.contains('modalHider')) {
      setModalVisibility(false);
    }
  };
const isBlockBackground = isNotBlockBackground ? "":"none"

  return (
    <WrapperHandler onClick={handleClick} >
      <Wrapper>
        <Flex
          flexWrap="wrap"
          height={['auto', 'auto', '100%']}
          minHeight="100vh"
          sx={{
            background: [`url(${bgImage}) no-repeat top`],
            backgroundSize: '100%',
            '@media screen and (max-width: 1300px)': {
              background: [`url(${bgImage}) no-repeat right`],
              backgroundSize: '80%',
            },
            '@media screen and (max-height: 580px)': {
              background: [`url(${bgImage}) no-repeat top-right`],
              backgroundSize: '70%',
            },
            '@media screen and (max-width: 1023px)': {
              background: isBlockBackground,
            },
          }}
        >
          <Flex
            width={[1, 1, 1, 1 / 2]}
            justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
            pt="40px"
            px="16px"
            flex={['auto', 'auto', 'auto', flex || 'auto']}
            style={{paddingLeft:'40px'}}
          >
            <Flex maxWidth={['100%', '100%', '100%', flex ? 900 : 640]} flexDirection="column">
              <Flex height={['auto', 'auto', '10%']}>
                <Button title={pagetTitle} />
              </Flex>
              <Flex
                height={['auto', 'auto', '80%']}
                mt={[40, 40, 40, 0]}
                mb={[0, 0, 40, 0]}
                alignItems="center"
              >
                {leftContent}
              </Flex>

              <Box display={['none', 'none', 'none', 'flex']}>
                <Flex height={['auto', 'auto', 125]} ml="40px">
                  <ArrowButton isLeft />
                  {!hideNextButton ? (
                    <ArrowButton link={nextLink} disabled={nextButtonDisabled} />
                  ) : null}
                </Flex>
              </Box>
            </Flex>
          </Flex>

          <Flex
            width={[1, 1, 1, 1 / 2]}
            mt={[24, 24, 0]}
            flex={['auto', 'auto', 'auto', flex ? 1 : 'auto']}
          >
            {rightContent}
          </Flex>

          <Flex
            height={['auto', 'auto', 'auto', 125]}
            pl={[0, '40px']}
            pt="40px"
            ml={[16, 24, 40]}
            display={['flex', 'flex', 'flex', 'none']}
          >
            <ArrowButton isLeft />
            {!hideNextButton ? <ArrowButton link={nextLink} disabled={nextButtonDisabled} /> : null}
          </Flex>
        </Flex>
      </Wrapper>
      <Modal isOpen={modalOpened} onClose={() => setModalVisibility(false)} body={modalContent} />
    </WrapperHandler>
  );
};

export default memo(Layout);
