import React, { useState } from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

const Label = styled.label`
  width: ${({ width = '180px' }) => width};
  margin: ${({ margin = 0 }) => margin};
  padding: 14px 8px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  line-height: 1;
  background: '#fff';
  color: #7800ff;
  border: 2px solid #7800ff;
  cursor: pointer;
  overflow: hidden;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const File = ({file,changeFile}) => {
  const handleChange = (e) => {
    if (e.target.files.length) {
      changeFile(e.target.files[0])
    } else {
      changeFile(null)
    }
  };

  return (
    <Flex alignItems="center">
      <Label htmlFor="upload-button">{file?.name || 'Прикрепить файл'}</Label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
        onCancel={() => changeFile(null)}
      />
    </Flex>
  );
};

export default File;
