import React from 'react';

import * as S from './style'
import paper from './paper.svg'
import trash from './trash.svg'
import {fetchFile} from "../../../../request";

const FileElement = ({file, changeFile, isDownload}) => {
  const fileSize = file?.size / 1024 / 1024

  const deleteFile = () => {
    changeFile(null)
  }

  const downloadFile = async () => {
    await fetchFile(file?.id, file?.originalFilename)
  };

  return (
    <S.Content>
      {isDownload ? <div className='wrapper' onClick={downloadFile}>
          <div className='header'>
            <img src={paper} width={20} height={20}/>
            <div className='fileName'>{file?.originalFilename}</div>
          </div>
          <div className='size'>~ {file?.size ? `${fileSize.toFixed(2)} мб` : ''}</div>
        </div>
        : <>
          <div className='header'>
            <img src={paper} width={20} height={20}/>
            <div className='name'>{file?.name}</div>
          </div>
          <div className='size'>Размер файла: ~ {file?.size ? `${fileSize.toFixed(2)} мб` : ''}</div>
          <img className='trash' src={trash} width={20} height={20} onClick={deleteFile}/></>}

    </S.Content>
  );
};

export default FileElement;
