import {useState} from "react";
import {checkIsValidRangeDate, checkValidateDate} from "../../News/utils/checkValidDate";

export const useDate = ()=>{

  const [date,setDate] = useState(['',''])
  const startError = date?.[0] && !checkValidateDate(date?.[0]);
  const endError = date?.[1] && !checkValidateDate(date?.[1]);
  const {isValidStartDate, isValidEndDate} = checkIsValidRangeDate(date?.[0], date?.[1]);

  return {date,startError,endError,isValidStartDate,isValidEndDate,setDate}
}
