import React, { memo } from 'react';
import { Flex, Box, Text } from 'rebass';
import { StyledLink } from 'atoms';

const monthes = {
  '01': 'января',
  '02': 'февраля',
  '03': 'марта',
  '04': 'апреля',
  '05': 'мая',
  '06': 'июня',
  '07': 'июля',
  '08': 'августа',
  '09': 'сентября',
  10: 'октября',
  11: 'ноября',
  12: 'декабря',
};

const NewsPreview = ({ item: { id, title, date, text } }) => {
  return (
    <Flex
      width="100%"
      mt="24px"
      pb="20px"
      sx={{ borderBottom: '1px solid #E2E2E2' }}
      flexWrap="wrap"
    >
      <Box
        width="85px"
        height="85px"
        bg="#57D9B7"
        color="#fff"
        mr="20px"
        sx={{
          borderRadius: '50%',
        }}
      >
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Box>
            <Text
              fontSize="2.25rem"
              lineHeight="1"
              fontWeight="bold"
              textAlign="center"
              color="#fff"
            >
              {date.split('.')[0] * 1}
            </Text>
            <Text fontSize="0.75rem" lineHeight="1" textAlign="center" color="#fff">
              {monthes[date.split('.')[1]]}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex flexDirection="column" width={[1, '80%']} pt={[16, 0]}>
        <Text fontSize="1.5rem" lineHeight="1.17" fontWeight="bold" mb="16px">
          {title}
        </Text>
        <Text lineHeight="1.5">{text}</Text>
        <StyledLink to={`/news/${id}`}>Подробнее</StyledLink>
      </Flex>
    </Flex>
  );
};

export default memo(NewsPreview);
