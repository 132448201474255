import {useAsyncFn} from "react-use";
import {fetchFeedback} from "../requests";
import {useEffect, useState} from "react";
import {getNormalizeDate} from "../../../../../utils";

export const useStatus = (pin) => {
  const [{value,error}, getFeedback] = useAsyncFn(fetchFeedback)
  const [status, setStatus] = useState(null)

  useEffect(() => {
    getFeedback(pin)
  }, [])

  useEffect(() => {
    if (value) {
      setStatus({
        isAnonymous: value?.isAnonymous,
        fullName: value?.fullName,
        email: value?.email,
        phoneNumber: value?.phoneNumber,
        text: value?.text,
        status: value?.status,
        date: getNormalizeDate(value?.createdDate),
        number: value?.complianceNumber
      })
    }
  }, [value])

  return {status,error}
}
