import { getNormalizeDate } from '../../../utils/getNormalizedDate';

export const getNormalizedPublishedNews = (data) => {
  return {
    id: data?.id,
    date: getNormalizeDate(data?.publicationDate),
    title: data?.title,
    text: data?.content,
    file: data?.file,
  };
};
