import React, { memo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Tabs, ReadMoreFaqs } from 'molecules';
import { TwoColumnsLayout } from 'layouts';
import Document from './Document';
import { useDocuments } from '../hooks/useDocuments';
import { Paginator } from '../../../common/molecules';
import { usePagination } from '../../../hooks';
import { Pagination } from './styles';

const tabs = [
  { title: 'Антикоррупционное законодательство', path: '/documents/laws' },
  { title: 'Документы компании', path: '/documents/company' },
];

const Documents = () => {
  let { section } = useParams();
  const { currentPage, setAllPages, handlePageChange, allPages } = usePagination();
  const { listDocuments } = useDocuments(section, setAllPages, currentPage);

  if (!section) {
    return <Redirect to="/documents/laws" />;
  }

  const renderLeft = () => {
    return listDocuments.map((document) => <Document key={document.id} {...document} />);
  };

  return (
    <>
      <TwoColumnsLayout
        title="Документы"
        tabs={<Tabs tabs={tabs} />}
        left={renderLeft()}
        right={<ReadMoreFaqs />}
      />
      <Pagination>
        <Paginator
          initialPage={currentPage}
          currentPage={currentPage}
          pageCount={allPages}
          onPageChange={(value) => {
            handlePageChange(value);
          }}
        />
      </Pagination>
    </>
  );
};

export default memo(Documents);
