import { ACCESS, ARTICLE_STATUSES, DOCUMENT_TYPES } from '../constants';

export const initialFilters = {
  status: ARTICLE_STATUSES.ALL,
  date: [null,null],
  access: ACCESS.ALL,
  docCategory: DOCUMENT_TYPES.ALL,
};

export const accept = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
};

const filterNames = {
  status: 'status',
  date: 'date',
  access:'access',
  docCategory: 'docCategory'
};

export const filterList = Object.values(filterNames);
