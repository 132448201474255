import React, {memo} from 'react';
import {Flex, Text} from 'rebass';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  border-bottom: 3px solid transparent;
  padding-bottom: 16px;
  margin-right: 40px;
  display: inline-flex;
  font-weight: 500;
`;

const Tab = styled.div`
  border-bottom: ${props => props?.active ? '3px solid black' : ''};
  padding-bottom: 16px;
  margin-right: 40px;
  display: inline-flex;
  font-weight: 500;
`
const Tabs = ({tabs, onClick, filters }) => {

  return (
    <Flex>
      {tabs.map(({title, path}) => (
        onClick ? <Tab active={filters?.newsType === path} key={path} onClick={() => {
            onClick(path)
          }}>
            <Text color="#101828" fontSize={['1rem', '1rem', '1.125rem']} sx={{cursor:'pointer', borderBottom: '3px solid transparent'}}>
              {title}
            </Text>
          </Tab> :
          <StyledNavLink key={path} to={path} activeStyle={{borderColor: '#101828'}}>
            <Text color="#101828" fontSize={['1rem', '1rem', '1.125rem']}>
              {title}
            </Text>
          </StyledNavLink>
      ))}
    </Flex>
  );
};

export default memo(Tabs);
