import React, { useState, memo } from 'react';
import { CancelIconAnimated } from 'atoms';
import { Flex, Box } from 'rebass';

const Burger = ({ children }) => {
  const [isOpened, toggleOpened] = useState(false);
  return (
    <Box>
      <CancelIconAnimated
        isOpened={isOpened}
        color="#101828"
        onClick={() => toggleOpened(!isOpened)}
      />
      {isOpened ? (
        <Flex
          sx={{
            padding: '16px 24px',
            top: [78, 78, 126],
            left: 0,
            position: 'absolute',
            width: '100%',
            background: '#fff',
            boxShadow: 'inset 0 0 5px rgb(0 0 0 / 22%)',
            transition: 'height 1s',
          }}
        >
          <Flex>
            <Box onClick={() => toggleOpened(!isOpened)}>{children}</Box>
          </Flex>
        </Flex>
      ) : null}
    </Box>
  );
};

export default memo(Burger);
