import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {isEmpty} from "ramda";

import {VALIDATION} from "../../../../../store/statusConstants";
import {folderRules, initialValues} from "../components/Form/constants";
import {editProfile} from "../request";
import {fetchToken} from "../../../../Login/request";

export const useFormValue = (profile) => {
  const {
    control,
    formState: {isValid, isDirty, errors},
    getValues,
    setValue,
    clearErrors,
    reset,
    handleSubmit
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(folderRules),
  });

  const errorsList = Object.values(errors)?.map((item) => item?.message)
  const validationStatus = !isEmpty(errorsList) && VALIDATION

  const onSubmit = handleSubmit(async (values) => {
   await editProfile(values)
   await fetchToken()
    reset()
  });

  useEffect(() => {
    reset(profile);
  }, [profile]);

  return {
    control,
    isValid,
    isDirty,
    getValues,
    setValue,
    onSubmit,
    errorsList,
    validationStatus,
    clearErrors,
  };
}
