import {useEffect, useState} from "react";

import {getLocalStorage} from "../../../../../utils/localStorage";

export const useProfile = () => {
  const token = getLocalStorage('accessToken')

  function parseJwt(token) {
    let base64Payload = token?.split('.')[1];
    let payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload?.toString());
  }

  const parsToken = parseJwt(token);

  const [profile, setProfile] = useState()

  useEffect(() => {
    if(parsToken){
      setProfile({
        name: '',
        fio: parsToken?.full_name,
        email: parsToken?.sub,
        post: parsToken?.position_in_company,
        password: '',
      })
    }
  }, [token])

  return {profile}
}
