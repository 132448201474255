import React, { memo } from 'react';
import { Flex, Text } from 'rebass';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import intro from '../video/intro_video.mp4';

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
`;

const Video = ({ history }) => {
  const onEnded = () => {
    history.push('/course/content');
  };

  return (
    <Wrapper>
      <Flex height="100%" p={[0, 16, 40]} flexDirection="column" flex="1 0" justifyContent="center">
        <ReactPlayer url={intro} playing controls width="100%" height="100%" onEnded={onEnded} />
      </Flex>
    </Wrapper>
  );
};

export default memo(Video);
