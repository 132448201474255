import React, { memo } from 'react';
import { Flex, Box } from 'rebass';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { PageTitle } from 'atoms';
import { Tabs } from 'molecules';
import Articles from './Articles';
import Videos from './Videos';

const tabs = [
  { title: 'Статьи', path: '/materials/articles' },
  { title: 'Видеоролики', path: '/materials/videos' },
];

const Materials = () => {
  let { section } = useParams();
  return (
    <Box width="100%" p="40px 0 80px">
      <Flex justifyContent="center">
        <Box width="100%" maxWidth={['100%', '100%', '100%', 1300]} px={[16, 32, 48]} mb="24px">
          <PageTitle>Интересные материалы</PageTitle>
          <Tabs tabs={tabs} />
        </Box>
      </Flex>
      <Flex height="100%" justifyContent="center">
        <Flex
          width="100%"
          flexWrap="wrap"
          maxWidth={['100%', '100%', '100%', 1300]}
          pl={[16, 24, 48]}
          pr={[16, 0, 32]}
        >
          <Switch>
            {!section ? <Redirect to="/materials/articles" /> : null}
            <Route exact path="/materials/articles">
              <Articles />
            </Route>
            <Route path="/materials/videos">
              <Videos />
            </Route>
          </Switch>
        </Flex>
      </Flex>
    </Box>
  );
};

export default memo(Materials);
