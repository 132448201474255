import React from 'react';
import {Controller} from "react-hook-form";

import * as S from './styles'
import {fields} from "./constants";
import Button from "../../../Button";

export const Form = ({control, onSubmit, isDirty, isValid}) => {
  return (
    <S.Content>
      {fields.map(({name, label, component, disabled, required, ...rest}) => (
        <S.Field key={name}>
          <S.Label>
            {required && <div className='required'>*</div>}
            {label}
          </S.Label>
          <Controller
            name={name}
            control={control}
            render={({field, fieldState: {isTouched, error}}) =>
              component({...field, ...rest, name, isTouched, error, disabled})
            }
          />
        </S.Field>
      ))}
      <Button
        variant="contained"
        label="Сохранить изменения"
        className='button'
        onClick={onSubmit}
        disabled={!isValid || !isDirty}
      />
    </S.Content>
  );
};
