import React, {memo} from 'react';
import {Flex, Box, Text} from 'rebass';
import {StyledLink} from 'atoms';
import {Snackbar} from "@material-ui/core";
import Alert from '@material-ui/core/Alert';
import {usePublishedNews} from '../hooks/usePublishedNews';

import history from '../../../store/history';
import {Link} from '../styles';
import {useFile} from "../hooks/useFile";
import {useAlert} from "../hooks/useAlert";

const NewsDetail = () => {
  const {publishedNews} = usePublishedNews();
  const {downLoadFile, fileError} = useFile(publishedNews?.file?.id, publishedNews?.file?.originalFilename);
  const {closeAlert, isOpen} = useAlert(fileError)

  const goBack = () => {
    history.goBack();
  };

  return (<>
      <Box bg="#fff" width="100%">
        <Snackbar
          open={isOpen}
          autoHideDuration={3000}
          onClose={closeAlert}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          <Alert variant="filled" severity="error" onClose={closeAlert}>
            Не удалось скачать файл
          </Alert>
        </Snackbar>
        <Flex justifyContent="center">
          <Box width="100%" p={[24, 60]} maxWidth={['100%', '100%', '100%', 1300]}>
            <Box pb="24px">
              <StyledLink to="/#" onClick={goBack}>Назад к списку новостей</StyledLink>
            </Box>
            <Text
              fontSize={['1.875rem', '1.875rem', '3rem']}
              lineHeight={['2.25rem', '2.25rem', '3.75rem']}
              fontWeight="bold"
              mb="32px"
            >
              {publishedNews?.title}
            </Text>

            <Text fontSize="0.875rem" mb="24px" color="rgba(0, 0, 0, 0.5)">
              {publishedNews?.date}
            </Text>
            <div
              style={{lineHeight: '1.375 rem', marginBottom: '25px'}}
              dangerouslySetInnerHTML={{__html: publishedNews?.text}}
            />
            {publishedNews?.file && (
              <Flex>
                <Link onClick={downLoadFile}>{publishedNews?.file?.originalFilename}</Link>
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default memo(NewsDetail);
