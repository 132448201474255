import interceptor from '../../../helpers/API';

export const fetchFaqs = async (params) => {
  try {
    const { data } = await interceptor.get('/questions-answers', {
      params,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
