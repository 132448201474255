import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ACCESS, ARTICLE_STATUSES, MATERIAL_TYPES } from '../../modules/Admin/components/constants';

export const fetchFeedback = createAsyncThunk(
  'Feedback/fetchFeedback',
  async (arg, { getState }) => {
    const state = getState();
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return mokeFeedback;
  },
);

export const createFeedback = createAsyncThunk('Feedback/createFeedback', async (data) => {
  console.log(data);
});

export const deleteFeedbackById = createAsyncThunk('Feedback/deleteFeedbackById', async (id) => {
  console.log(id);
});

const FeedbackSlice = createSlice({
  name: 'Feedback',
  initialState: {
    listOfFeedback: [],
    filter: {
      number: '',
      name: '',
      email: '',
      phone: '',
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      numberSort: true,
      nameSort: null,
      emailSort: null,
      phoneSort: null,
    },
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchFeedback.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchFeedback.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfFeedback = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchFeedback.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createFeedback.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createFeedback.fulfilled]: (state, action) => {},
    [createFeedback.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteFeedbackById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteFeedbackById.fulfilled]: (state, action) => {
      state.status = FULFILLED;
    },
    [deleteFeedbackById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = FeedbackSlice.actions;

export default FeedbackSlice.reducer;

const mokeFeedback = [
  {
    id: 1,
    number: 1,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 2,
    number: 2,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 3,
    number: 3,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 4,
    number: 4,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 5,
    number: 5,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 6,
    number: 6,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 7,
    number: 7,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 8,
    number: 8,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
  {
    id: 9,
    number: 9,
    name: 'Иванов И.И.',
    email: 'ivanov@domain.com',
    phone: '1111111111',
  },
];
