import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'rebass';
import styled from 'styled-components';

const Wrapper = styled(Link)`
  display: inline-block;
  margin-right: 16px;
  color: #6d12f5;
  font-size: 16px;
  text-decoration: underline;
`;

const CustomLink = ({ title, link, ...rest }) => {
  return (
    <Wrapper to={{ pathname: link }} target="_blank">
      <Text color="#6d12f5" {...rest}>
        {title}
      </Text>
    </Wrapper>
  );
};

export default CustomLink;
