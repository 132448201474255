import styled from 'styled-components';

export const Img = styled.div`
  @media screen and (max-height: 800px) {
    .img1 {
      max-height: 300px;
    }
    .img2 {
      max-height: 300px;
      margin-top: -10px;
    }
  }
`;

export const SliderBox = styled.div`
  @media screen and (max-width: 1300px) {
    .img {
      max-height: 600px;
    }
  }
  @media screen and (max-height: 650px) {
    .img {
      max-height: 450px;
    }
  }
  @media screen and (max-height: 550px) {
    .img {
      max-height: 400px;
    }
  }
  @media screen and (max-height: 500px) {
    .img {
      max-height: 350px;
    }
  }
  @media screen and (max-height: 450px) {
    .img {
      max-height: 300px;
    }
  }
  @media screen and (max-height: 400px) {
    .img {
      max-height: 250px;
    }
  }
`
