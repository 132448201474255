import React from 'react';
import {Box, Text} from "rebass";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/core/Alert";
import {Controller} from "react-hook-form";

import {Button, Input} from 'atoms';
import {useForms} from "../../hooks/useForm";
import {Captcha} from "../../../../common/molecules";

const fieldNames = {
  pin: 'pin',
  captcha: 'captcha'
}

const StatusForm = ({isAlert, setIsAlert,setStatus, ...rest}) => {

  const {
    control,
    isValid,
    isDirty,
    onSubmit,
    loading
  } = useForms(setStatus,setIsAlert)

  return (
    <Box>
      <Snackbar
        open={isAlert}
        autoHideDuration={3000}
        onClose={() => {
          setIsAlert(false)
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <Alert
          variant="filled"
          severity='error'
          onClose={() => {
            setIsAlert(false)
          }
          }>
          При обработке запроса,произошла ошибка
        </Alert>
      </Snackbar>
      <form onSubmit={onSubmit} noValidate>
        <Box mb={32}>
          <Text mb={40}>
            Чтобы узнать статус вашего обращения введите ПИН-код, полученный после создания
            обращения
          </Text>

          <Box width={[1, 1, 300]}>
            <Controller
              name={fieldNames.pin}
              control={control}
              render={({field: {onChange, value}}) => (
                <Input
                  label="ПИН код"
                  id="pin"
                  name="pin"
                  value={value}
                  required
                  mb="32px"
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box mt={24}>
            <Controller
              name={fieldNames.captcha}
              control={control}
              render={({field: {onChange, value}}) => (
                <Captcha
                  {...rest}
                  value={value}
                  name='captcha'
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box width={[1, 200]} mb={[24, 0]}>
            <Button variant="primary" title="Отправить" disabled={!isValid || !isDirty} loading={loading}/>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default StatusForm;
