import React, {memo, useRef} from 'react';
import {Flex, Box} from 'rebass';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {ReadMoreFaqs} from 'molecules';
import {TwoColumnsLayout} from 'layouts';
import NewsPreview from './NewsPreview';
import Filter from './Filter';
import {useNews} from '../hooks/UseNews';
import {useFilters} from '../hooks/useFilter';
import {getInitFiltersParams} from '../../../utils/getInitFiltersParams';
import {filterList, initFilterYear} from '../constants';
import {usePagination} from '../../../hooks';
import {NewSelect, Paginator} from '../../../common/molecules';
import {Pagination} from '../styles';
import {useMonth} from '../hooks/useMonth';
import {useYears} from '../hooks/useYears';
import {Tabs} from 'molecules';
import {useCheckVpn} from "../../../hooks/useCheckVpn";

const tabs = [
  {title: 'Новости компании', path: 'PRIVATE'},
  {title: 'Новости', path: 'PUBLIC'},
];

const News = () => {
  const initValues = getInitFiltersParams(filterList);
  const {currentPage, setAllPages, handlePageChange, allPages, setPage} = usePagination();
  const {filters, handleParamChange} = useFilters(initValues, setPage);
  const {newsList} = useNews(filters, setAllPages, currentPage);
  const {listMonth} = useMonth(filters?.year,filters?.newsType);
  const {listYears} = useYears(filters,handleParamChange);
  const nodeRef = useRef(null);
  const {accessible} = useCheckVpn()

  const changeNewsType = (path) => {
    handleParamChange(path, 'newsType')
  }

  const renderFilterItems = () => {
    return (
      <Flex width="100%" flexWrap="wrap">
        <Box width={[1, 1, 1 / 3, 1 / 4]} my={[16, 16, 0]}>
          <NewSelect
            label="Показать за месяц"
            selected={filters?.month}
            name="month"
            options={listMonth}
            onChange={handleParamChange}
            margin="0 16px 0 0"
          />
        </Box>
        <Box width={[1, 1, 1 / 3, 1 / 4]} my={[16, 16, 0]}>
          <NewSelect
            label="Показать за год"
            name="year"
            selected={filters?.year || initFilterYear}
            options={listYears}
            onChange={handleParamChange}
            margin="0 16px 0 0"
          />
        </Box>
      </Flex>
    );
  };

  const renderLeft = () => {
    return (
      <>
        <Box mb="24px">

        </Box>
        <Box display={['block', 'block', 'none']} width="100%">
          <Filter> {renderFilterItems()}</Filter>
        </Box>

        <Box display={['none', 'none', 'block']} width="100%">
          {renderFilterItems()}
        </Box>
        {newsList.length ? (
          <Box width="100%" minHeight="500px">
            <TransitionGroup className="todo-list">
              {newsList.map((news) => (
                <CSSTransition key={news.id} timeout={500} classNames="item" nodeRef={nodeRef}>
                  <NewsPreview key={news.id} item={news}/>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Box>
        ) : (
          <Box width="100%">По вашему запросу ниего не найдено</Box>
        )}
        <Pagination>
          <Paginator
            initialPage={currentPage}
            currentPage={currentPage}
            pageCount={allPages}
            onPageChange={(value) => {
              handlePageChange(value);
            }}
          />
        </Pagination>
      </>
    );
  };

  return <TwoColumnsLayout
    title="Новости" tabs={<Tabs filters={filters} tabs={accessible && tabs || []} onClick={changeNewsType}/>}
    left={renderLeft()}
    right={<ReadMoreFaqs/>}/>;
};

export default memo(News);
