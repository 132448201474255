import React, { memo } from 'react';
import { Switch, Route, Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
// import MainPage from './MainPage/MainPage';
// import MainPageEdit from './MainPage/MainPageEdit';
// import Banners from './Banners/Banners';
// import BannersEdit from './Banners/BannersEdit';
import News from './News/News';
import NewsEdit from './News/NewsEdit';
import Materials from './Materials/Materials';
import ArticleEdit from './Materials/ArticleEdit';
import VideoEdit from './Materials/VideoEdit';
import Faqs from './Faqs/Faqs';
import FaqsEdit from './Faqs/FaqsEdit';
import Documents from './Documents/Documents';
import DocumentsEdit from './Documents/DocumentsEdit';
import Education from './Education/Education';
import MaterialEdit from './Education/MaterialEdit';
// import Quiz from './Quiz';
import Ask from './Ask/Ask';
import AskEdit from './Ask/AskEdit';
import Feedback from './FeedBack/Feedback';
import Profile from "./Profile";
import Report from "./Report";
import Accounts from "./Accounts/Accounts";
import EditProfile from "./Accounts/components/EditProfile";
import DetailedAsk from "./Ask/components/DetailedAsk";
import DetailedFeedBack from "./FeedBack/components/DetailedFeedback/DetailedFeedBack";

const GlobalStyle = createGlobalStyle`
  li:before {
    display: none;
  }
`;

const AdminRouter = () => {
  let { section } = useParams();
  const { path } = useRouteMatch();

  return (
    <>
      <GlobalStyle />
      <Switch>
        {/*{!section ? <Redirect to={`${path}/main`} /> : null}*/}
        {/*<Route exact path={`${path}/main/edit/:id?`} component={MainPageEdit} />*/}
        {/*<Route exact path={`${path}/main`} component={MainPage} />*/}
        {/*<Route exact path={`${path}/banners/edit/:id?`} component={BannersEdit} />*/}
        {/*<Route exact path={`${path}/banners`} component={Banners} />*/}
        {!section ? <Redirect to={`${path}/news`} /> : null}
        <Route exact path={`${path}/news/edit/:id?`} component={NewsEdit} />
        <Route exact path={`${path}/news`} component={News} />
        <Route exact path={`${path}/materials/edit/article/:id?`} component={ArticleEdit} />
        <Route exact path={`${path}/materials/edit/video/:id?`} component={VideoEdit} />
        <Route exact path={`${path}/materials`} component={Materials} />
        <Route exact path={`${path}/faqs/edit/:id?`} component={FaqsEdit} />
        <Route exact path={`${path}/faqs`} component={Faqs} />
        <Route exact path={`${path}/documents/edit/:id?`} component={DocumentsEdit} />
        <Route exact path={`${path}/documents`} component={Documents} />
        <Route exact path={`${path}/education/edit/:id?`} component={MaterialEdit} />
        {/*<Route exact path={`${path}/education/test/:id?`} component={MaterialEdit} />*/}
        <Route exact path={`${path}/education`} component={Education} />
        {/*<Route exact path={`${path}/quiz`} component={Quiz} />*/}
        <Route exact path={`${path}/ask`} component={Ask} />
        <Route exact path={`${path}/ask/:id?`} component={DetailedAsk} />
        <Route exact path={`${path}/ask/edit/:id?`} component={AskEdit} />
        <Route exact path={`${path}/feedback`} component={Feedback} />
        <Route exact path={`${path}/feedback/:id?`} component={DetailedFeedBack} />
        <Route exact path={`${path}/accounts`} component={Accounts} />
        <Route exact path={`${path}/accounts/:id?`} component={EditProfile} />
        <Route exact path={`${path}/profile`} component={Profile} />
        <Route exact path={`${path}/report`} component={Report} />
      </Switch>
    </>
  );
};

export default memo(AdminRouter);
