import { format } from 'date-fns';
import interceptor from '../../../../../helpers/API';
import { NEWS_TYPE } from '../../constants';

// eslint-disable-next-line consistent-return
export const fetchNews = async (params, filters = null) => {
  try {
    const newParams = {
      ...params,
      startDate: filters?.date?.[0] ? format(new Date(filters?.date?.[0]), 'yyyy-MM-dd') : '',
      endDate: filters?.date?.[1] ? format(new Date(filters?.date?.[1]), 'yyyy-MM-dd') : '',
    };
    const { data } = await interceptor.get('admin/news', {
      params: newParams,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchNewsById = async (id) => {
  try {
    const { data } = await interceptor.get(`admin/news/${id}`, {
      params: { id },
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchEditNews = async (id, value, fileId) => {
  const isFileType = value?.type === NEWS_TYPE.FILE;

  const data = {
    title: value?.title,
    publishStatus: value?.status,
    publicationDate: new Date(value?.date).toISOString(),
    accessType: value?.access,
    newsType: value?.type,
    previewText: !isFileType ? value?.preview : '',
    content: !isFileType ? value?.text : '',
    fileId: !isFileType ? null : fileId || value?.fileId,
  };
  try {
    await interceptor.put(`admin/news/${id}`, data, {
      params: { id },
    });
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const deleteNews = async (id) => {
  try {
    await interceptor.delete(`admin/news/${id}`, {
      params: { id },
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const addedNews = async (value, id) => {
  const data = {
    title: value?.title,
    publishStatus: value?.status,
    publicationDate: new Date(value?.date).toISOString(),
    accessType: value?.access,
    newsType: value?.type,
    previewText: value?.preview,
    content: value?.text,
    fileId: id,
  };
  try {
    await interceptor.post('admin/news', data);
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
