import interceptor from "../../../../helpers/API";

export const editProfile = async (value) => {
  try {
    const newData = value?.password ? {
        email: value?.email?.trim(),
        fullName: value?.fio?.trim(),
        positionInCompany: value?.post?.trim(),
        password: value?.password?.trim()
      } :
      {
        email: value?.email?.trim(),
        fullName: value?.fio?.trim(),
        positionInCompany: value?.post?.trim(),
      }
    await interceptor.patch('admin/users', newData);
  } catch (e) {
    console.error('@@@e', e)
  }
}
