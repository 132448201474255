import React from 'react';

import * as S from './styles'
import Button from "../../../Admin/components/Button";
import {fetchFile} from "../../requests";
import {Icon} from "common/atoms";
import {getNormalizedFileFormat} from "../../../Documents/utils/getNormalizedFileFormat";
import {getNormalizedFileSize} from "../../../Documents/utils/getNormalizedFileSize";

const MaterialElement = ({material}) => {
  const {title, date, file, link} = material

  const downloadFile = async () => {
    await fetchFile(file?.id, file?.originalFilename)
  }

  return (
    <S.Content>
      <S.Date>
        {date}
      </S.Date>
      <S.Title>
        {title}
      </S.Title>
      {file && <S.File>
        <Icon name="document" m="0 8px 0 0"/>
        <> {getNormalizedFileFormat(file?.contentType)}, ~{getNormalizedFileSize(file?.size)} Мб</>
      </S.File>}
      {file && <Button label="Скачать" className="button" onClick={downloadFile}/>}
      {link && <a href={link} className="button">Перейти</a>}
    </S.Content>
  );
};

export default MaterialElement;
