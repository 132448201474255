import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ARTICLE_STATUSES, ACCESS } from '../../modules/Admin/components/constants';

export const fetchNews = createAsyncThunk('News/fetchNews', async () => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  return mokeNews;
});

export const createNews = createAsyncThunk('News/createNews', async (data) => {
  console.log(data);
});

export const deleteNewsById = createAsyncThunk('News/deleteNewsById', async (id) => {
  console.log(id);
  return mokeNews.filter((item) => item.id !== id);
});

export const fetchNewsById = createAsyncThunk('News/fetchNewsById', async (id) => {
  return moke;
});

const NewsSlice = createSlice({
  name: 'news',
  initialState: {
    listOfNews: [],
    dateRange: [1627761600, 1643107212],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: [1627761600, 1643107212],
      access: ACCESS.ALL,
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      dateSort: false,
      titleSort: null,
      statusSort: null,
    },
    data: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchNews.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchNews.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfNews = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchNews.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchNewsById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchNewsById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchNewsById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createNews.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createNews.fulfilled]: (state, action) => {},
    [createNews.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteNewsById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteNewsById.fulfilled]: (state, action) => {
      state.listOfNews = action.payload;
      state.status = FULFILLED;
    },
    [deleteNewsById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination, setStatus } = NewsSlice.actions;

export default NewsSlice.reducer;

const moke = {
  title: '',
  status: 'PUBLISHED',
  date: Date.now(),
  access: 'PRIVATE',
  type: 'TEXT',
  file: [],
  preview: 'qwqw',
  text: '32323',
};

const mokeNews = [
  { id: 1, date: 1627761600, title: 'Не закрывай глаза', status: 'UNPUBLISHED', access: 'PRIVATE' },
  { id: 126, date: 1627761600, title: 'Не закрывай глаза', status: 'UNPUBLISHED', access: 'PUBLIC' },
  { id: 3, date: 1627761600, title: 'Не закрывай глаза', status: 'UNPUBLISHED', access: 'PRIVATE' },
  { id: 4, date: 1627761600, title: 'Не закрывай глаза', status: 'PUBLISHED', access: 'PUBLIC' },
  { id: 5, date: 1627761600, title: 'Не закрывай глаза', status: 'PUBLISHED', access: 'PRIVATE' },
  { id: 6, date: 1643107212, title: 'Не закрывай глаза', status: 'PUBLISHED', access: 'PUBLIC' },
  {
    id: 7,
    date: 1643107212,
    title: 'Не закрывай глаза',
    status: 'PUBLISHED',
    access: 'PRIVATE',
  },
];
