import React from 'react';
import { Text } from 'rebass';

const PageTitle = ({ children }) => (
  <Text
    fontSize={['1.875rem', '1.875rem', '3rem']}
    fontWeight="bold"
    mb={[20, 20, 32]}
    lineHeight={['2.25rem', '2.25rem', '3.75rem']}
  >
    {children}
  </Text>
);

export default PageTitle;
