import React, { memo } from 'react';
import { Box, Text } from 'rebass';
import { StyledLink } from 'atoms';

const ReadMoreFeedback = () => {
  return (
    <Box
      bg="#fff"
      p="1.5rem"
      height="max-content"
      sx={{
        borderRadius: 10,
        border: ' 2px solid #ff6a00',
      }}
    >
      <Text fontSize="1.5rem" fontWeight="bold" mb="16px">
        Узнайте больше!
      </Text>
      <Text fontSize="1rem" lineHeight="1.5rem" mb="16px">
        <p>
          Жалобы на&nbsp;абонентское обслуживание, а&nbsp;также претензии, связанные
          с&nbsp;качеством абонентских услуг не&nbsp;рассматриваются в&nbsp;рамках работы данных
          каналов связи. Пожалуйста, для этих целей заполняйте форму Обратной связи на&nbsp;сайте
          компании
          <Box display="inline" mx="4px">
            <StyledLink to={{ pathname: 'https://www.rt.ru' }} target="_blank">
              www.rt.ru
            </StyledLink>
          </Box>
          или звоните по телефону:
          <Text display="inline">
            <Box display={['none', 'none', 'inline']}>
              <Text fontWeight="bold" display="inline" ml="4px">
                8&nbsp;800&nbsp;100 08&nbsp;00
              </Text>
            </Box>
            <Box display={['inline', 'inline', 'none']} ml="4px">
              <a href="tel:8-800-100-08-00">8&nbsp;800&nbsp;100 08&nbsp;00</a>
            </Box>
          </Text>
          .
        </p>
      </Text>
    </Box>
  );
};

export default memo(ReadMoreFeedback);
