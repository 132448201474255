import React, { memo } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import Welcome from './Welcome';
import Registration from './Registration';
import Video from './Video';
import Content from './Content';
import ModuleHome from './ModuleHome';
import ModuleEnd from './ModuleEnd';
import ModuleIntro from './ModuleIntro';
import ModuleCheck from './ModuleCheck';
import ModuleTheory from './ModuleTheory';
import ModuleCase from './ModuleCase';
import ModuleConclusion from './ModuleConclusion';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f2f3f7;
  }
`;

const ProtectedRoute = ({ isAllowed, redirectRoute, children }) => {
  if (!isAllowed) {
    return <Redirect to={redirectRoute} replace />;
  }

  return children || <></>;
};

const CourseRouter = () => {
  const { path } = useRouteMatch();
  const { token } = useSelector((state) => state.course);

  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route exact path={`${path}/registration`} component={Registration} />
        <Route exact path={`${path}/`} component={Welcome} />
        <ProtectedRoute isAllowed={token} redirectRoute={`${path}/registration`}>
          <Route exact path={`${path}/video`} component={Video} />
          <Route exact path={`${path}/content`} component={Content} />
          <Route exact path={`${path}/module/:moduleId/intro/:introId?`} component={ModuleIntro} />
          <Route
            exact
            path={`${path}/module/:moduleId/theory/:theoryId`}
            component={ModuleTheory}
          />
          <Route exact path={`${path}/module/:moduleId/check/:checkId?`} component={ModuleCheck} />
          <Route exact path={`${path}/module/:moduleId/case/:caseId?`} component={ModuleCase} />
          <Route exact path={`${path}/module/:moduleId/end`} component={ModuleEnd} />
          <Route
            exact
            path={`${path}/module/:moduleId/conclusion/:conclusionId?`}
            component={ModuleConclusion}
          />
          <Route exact path={`${path}/module/:moduleId`} component={ModuleHome} />
        </ProtectedRoute>
      </Switch>
    </>
  );
};

export default memo(CourseRouter);
