import { useAsyncFn } from 'react-use';
import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { deleteVideo, fetchListVideo} from '../requests';
import { getNormalizedVideo } from '../utils/getNormalizedVideo';
import { setStatus } from '../../../../../store/reducers/News.reducers';
import { LOADING } from '../../../../../store/statusConstants';
import {useStatuses} from "../../../../../hooks/useStatuses";
import {saveParamsToUrl} from "../../../../../utils/saveParamsUrl";

export const useVideo = (filters = {}, setAllPages, page, pageSize, setAllElements) => {
  const [{ value,loading ,error}, getListVideo] = useAsyncFn(fetchListVideo);
  const [, delVideo] = useAsyncFn(deleteVideo);
  const [listOfMaterials, setListVideo] = useState([]);
  const dispatch = useDispatch();
  const { status } = useStatuses(loading, value?.content, error, true);

  const params = {
    size: pageSize,
    page: page + 1,
    publishStatus: filters?.status === 'ALL' ? '' : filters?.status,
    accessType: filters?.access === 'ALL' ? '' : filters?.access,
    sort:"publicationDate,DESC",
  };

  const handleDeleteVideo = async (id) => {
    await delVideo(id,filters);
    await getListVideo(params,filters);
  };

  useEffect(() => {
    getListVideo(params,filters);
  }, [pageSize, page]);

  useEffect(() => {
    if (value) {
      const newValue = value?.content.map((item) => getNormalizedVideo(item,filters));
      setListVideo(newValue);
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);
    }
  }, [value]);

  const updateTableData = async () => {
    dispatch(setStatus(LOADING));
    await getListVideo(params,filters);
    saveParamsToUrl({...filters})
  };

  return { listOfMaterials, handleDeleteVideo, updateTableData, getListVideo ,status};
};
