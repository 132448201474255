export const initialFilters = {
  pin: '',
  fio: '',
  phone: '',
  mail: '',
};

const filterNames = {
  pin: 'pin',
  fio: 'fio',
  phone: 'phone',
  mail: 'mail'
};

export const filterList = Object.values(filterNames);
