import {useAsyncFn} from "react-use";
import {setQuestion} from "../requests";
import {useFormik} from "formik";
import * as yup from "yup";
import {useEffect, useState} from "react";

const validationSchema = yup.object({
  name: yup.string().required('Поле обязательно для заполнения'),
  email: yup.string().email('Некорректное значение').required('Поле обязательно для заполнения'),
  comment: yup.string().required('Поле обязательно для заполнения'),
  captcha: yup.string().required('Поле обязательно для заполнения'),
});

export const useQuestion = (setIsAlert) => {
  const [{value, error, loading}, execute] = useAsyncFn(setQuestion)
  const [isUpdateCaptcha, setIsUpdateCaptcha] = useState(false)

  const sendQuestion = async (value) => {
    const newValue = {
      fullName: value?.name,
      email: value?.email,
      isRostelecomEmployee: value?.isEmployee,
      text: value?.comment,
    }
    execute(newValue, value?.captcha)
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      comment: '',
      isEmployee: false,
      captcha: ''
    },
    validationSchema,
    onSubmit: (values) => {
      sendQuestion(values)
    },
  });

  useEffect(() => {
    if (value) {
      formik.resetForm()
      setIsUpdateCaptcha(true)
      setIsAlert(true)
    }
    if (error) {
      setIsAlert(true)
    }
  }, [value, error])

  return {sendQuestion, formik, loading,isUpdateCaptcha,error,setIsUpdateCaptcha}
}
