import {useAsyncFn} from "react-use";
import {checkVpn} from "../request";
import {useEffect, useState} from "react";

export const useCheckVpn = () => {
  const [{value}, execute] = useAsyncFn(checkVpn)
  const [accessible, setAccessible] = useState(null)

  useEffect(() => {
    execute()
  }, [])

  useEffect(() => {
    if (value) {
      setAccessible(value?.access)
    }
  }, [value])

  return {accessible}
}
