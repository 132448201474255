import React from 'react';

import * as S from './styles'
import FileElement from "../FileElement/FileElement";
import {getNormalizeDateAndTime} from "../../../../utils/getNormalizedDate";
import {isEmpty} from "ramda";

const CommentElement = ({comment}) => {
  return (
    <S.Comment isTorned={comment?.author === "Обратившийся"} isBackground={comment?.author === "Обратившийся"}>
      <S.Content >
        <div className='left'>
          <div className='date'>
            {getNormalizeDateAndTime(comment?.createdDate)}
          </div>
          <div className='status'>
            {comment?.status}
          </div>
        </div>
        <div>
          <div className='text'>
            {comment?.author}
          </div>
          <div className='text'>
            {comment?.text}
          </div>
        </div>
      </S.Content>
      {!isEmpty(comment?.files) && comment?.files.map((file) => <FileElement isDownload file={file}/>)}
    </S.Comment>);
};

export default CommentElement;
