import React from 'react';
import styled from 'styled-components';
import { Text, Flex, Box } from 'rebass';
import { Link } from 'react-router-dom';
import TextContent from '../components/TextContent';
import Points from '../components/Points';
import images from '../img';

const CustomText = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
`;

const TitleModule = styled.div`
  @media (max-width: 1024px) {
    font-size: 2rem;
    line-height: 30px;
  }
`

const IntroTextWrap = styled.div`
  position: relative;
  margin: 0 24px;
  &:before {
    content: '«';
    display: block;
    position: absolute;
    top: -16px;
    left: -42px;
    font-size: 140px;
    line-height: 52px;
    color: #d9d9d9;
    z-index: 1;
  }
`;

const IntroText = styled.div`
  position: relative;
  z-index: 2;
`;

const module6 = {
  id: 6,
  title: 'Модуль 6. С кем дружбу водить?',
  home: [
    {
      id: 1,
      title: 'Образцовый деловой партнёр ПАО «Ростелеком»',
      text: 'Модуль 6. С кем дружбу водить?',
      bg: 'm6home1',
      nextLinkTitle: 'Шестой модуль',
      nextLink: '/course/module/6/intro/1',
    },
  ],
  intro: [
    {
      id: 1,
      title: (
        <IntroTextWrap>
          <IntroText>
            Колыбель личного успеха в жизни — это сохранение дружбы, доверия и уважения со стороны
            ближайшего вашего соседа
          </IntroText>
        </IntroTextWrap>
      ),
      text: [
        <>
          <Text fontWeight="bold">Букер Тальяферро Вашингтон,</Text>
          <Text>оратор, политик, писатель</Text>
        </>,
      ],
      bg: 'm6intro1',
      nextLink: '/course/module/6/intro/2',
    },
    {
      id: 2,
      title: 'Главное — чтоб человек хороший был',
      text: [
        'Хороший сосед поможет затащить пианино на 9 этаж, уважает ваш сон и не сверлит в 7 утра, несёт ответственность за все свои действия и будет стараться жить рядом в мире и согласии.',
        'С партнёрами в бизнесе похожая история. Очень важно не только быть на одной волне — понимать идеи друг друга с полуслова, предлагать лучшее в данной ситуации решение — но и быть в одной системе ценностей.',
      ],
      bg: 'm6intro2',
      nextLink: '/course/module/6/intro/3',
    },
    {
      id: 3,
      title: 'Главное — чтоб человек хороший был',
      text: [
        'У нас получилось создать портрет нашего идеального соседа. В этом модуле расскажем, каким мы видим образцового поставщика «Ростелекома», а после предложим решить кейсы.',
      ],
      bg: 'm6intro2',
      nextLink: '/course/module/6/theory/1',
    },
    {
      id: 10,
      title: <TitleModule>Если вы знаете или догадываетесь о нарушениях, сообщите об этом нам</TitleModule>,
      text: [
        <>
          <Text fontSize="18px" mb="24px">
            Звоните на «Линию доверия»{' '}
            <Link target="_blank" to={{ pathname: 'tel:8-800-1-811-811' }}>
              <Text fontWeight="bold" color="#6d12f5">
                8 800 1 811 811
              </Text>
            </Link>
          </Text>
          <Text fontSize="18px" mb="24px">
            Пишите через форму обратной связи на антикоррупционном портале{' '}
            <Link target="_blank" to="/feedback">
              <Text fontWeight="bold" color="#6d12f5">
                nocorruption.rt.ru
              </Text>
            </Link>
          </Text>
        </>,
        <>
          <Text fontSize="18px" mb="24px">
            И не забывайте про Этическую линию{' '}
            <Link target="_blank" to={{ pathname: 'mailto:ethics@rt.ru' }}>
              <Text fontWeight="bold" color="#6d12f5">
                ethics@rt.ru
              </Text>
            </Link>
          </Text>
          <Text fontSize="18px" mb="24px">
            Гарантируем анонимность.
          </Text>
        </>,
      ],
      bg: 'contentBg',
      nextLink: '/course/module/6/end',
    },
  ],
  check: [
    {
      id: 1,
      title: 'Кейс 1',
      text: (
        <>
          <TextContent>
            Для прокладки линии связи подрядчик должен был согласовать использование земли с гос.органами и получить
            разрешение до начала работ. Менеджер компании Идейкин, зная о проблеме, всё же одобрил начало проведения
            работ, пообещав чиновнику вознаграждение за выдачу разрешения «задним числом». Увы, подрядчик оказался в
            центре скандала со взяточничеством и халатностью. Это ударило по имиджу компании в регионе. Можно ли было
            избежать этой ситуации?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title:
            'Да. Подрядчик должен был обеспечить, чтобы при возникновении скандала проект с «Ростелекомом» нигде не упоминался.',
        },
        {
          id: 2,
          title:
            'Нет, невозможно. Имидж компании пострадал не по вине сотрудника подрядчика. Всё произошло из-за сложного процесса выдачи органами исполнительной власти необходимых разрешений.',
        },
        {
          id: 3,
          title:
            'Да, можно. Нужно было согласовать начало выполнения работ после получения всех разрешительных документов и уведомить сотрудника филиала компании о причинах задержки получения необходимых документов.',
        },
      ],
      correctAnswers: [3],
      correctText:
        'Верно. Ивану стоило добросовестно выполнять свои обязанности и соблюдать закон.',
      errorTextOnAttemptsOver: 'Правильные ответы: 3',
      nextLink: '/course/module/6/check/2',
    },
    {
      id: 2,
      title: 'Кейс 2',
      text: (
        <>
          <TextContent>
            Для ремонта в офисе «Ростелеком» заключил договор с подрядчиком. Тот привлек
            субподрядчика для прокладки новой электропроводки. До начала электромонтажных работ
            только один менеджер подрядчика ознакомился с требованиями безопасности. Во время
            выполнения работ произошло возгорание. Зданию и имуществу «Ростелекома» был причинен
            ущерб. Как можно было снизить риск возникновения подобной ситуации?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title:
            'Надлежаще проинструктировать персонал подрядчика. Всё равно нельзя полность исключить риск возгорания при выполнении работ.',
        },
        {
          id: 2,
          title:
            'Обеспечить прохождение сотрудниками субподрядчика противопожарного инструктажа и их ознакомление с требованиями «Ростелекома» к порядку выполнения работ на объекте',
        },
        {
          id: 3,
          title:
            'Предусмотреть в договоре с субподрядчиком, что при причинении последним ущерба объекту или иному имуществу компании субподрядчик возмещает подрядчику затраты на выплаченную компании компенсацию',
        },
      ],
      correctAnswers: [2],
      correctText:
        'Верно. Это поможет уменьшить риск возгорания. Кроме того, эта обязанность возложена на подрядчика в договоре с «Ростелекомом»',
      errorTextOnAttemptsOver: 'Правильные ответы: 2',
      nextLink: '/course/module/6/check/3',
    },
    {
      id: 3,
      title: 'Кейс 3',
      text: (
        <>
          <TextContent>
            Вы участвуете в тендере. Инициатор закупки из «Ростелекома» узнал, что в вашей компании
            есть вакансия, и попросил вас устроить на работу своего родственника. Как правильно
            поступить?
          </TextContent>
          <Text color="#6d12f5">Выберите несколько ответов.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'Согласиться, ведь это может помочь в дальнейшей работе',
        },
        {
          id: 2,
          title:
            'Отказать, так как, по сути, накануне и во время проведения тендера, это скрытый «откат».',
        },
        {
          id: 3,
          title:
            'После подведения итогов тендера предложить предоставить резюме кандидата для участия, на общих основаниях, в конкурсах на вакансии, никак не связанные с вашим отделом, с тендерами «Ростелекома» и продажами «Ростелекому»',
        },
      ],
      correctAnswers: [2, 3],
      correctText: 'Все верно',
      errorTextOnAttemptsOver: 'Правильные ответы: 2,3',
      nextLink: '/course/module/6/check/4',
    },
    {
      id: 4,
      title: 'Кейс 4',
      text: (
        <>
          <TextContent>
            Вы работаете в компании, привлечённой «Ростелекомом» для изготовления продукции,
            подлежащей обязательной сертификации. Вы планируете поздравить с Новым годом сотрудника
            министерства, отвечающего за выдачу этого документа. Что подарите?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'Любой подарок не дороже 3 000 рублей',
        },
        {
          id: 2,
          title: 'Ничего не буду дарить',
        },
        {
          id: 3,
          title: 'Сувенирную продукцию с логотипом своей компании или «Ростелекома»',
        },
      ],
      correctAnswers: [2],
      correctText:
        'Верно! Нельзя выполнять действия, которые могут быть расценены как попытка повлиять на принятие решения.',
      errorTextOnAttemptsOver: 'Правильные ответы: 2',
      nextLink: '/course/module/6/check/5',
    },
    {
      id: 5,
      title: 'Кейс 5',
      text: (
        <>
          <TextContent>
            Вы узнали, что генеральный директор субподрядной организации по совместительству
            работает в «Ростелекоме». Что нужно сделать в таком случае?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'Сообщить в «Ростелеком»',
        },
        {
          id: 2,
          title: 'Незамедлительно расторгнуть договор с субподрядчиком',
        },
        {
          id: 3,
          title:
            'Продолжить взаимодействие, так как  это не имеет отношения к вашему договору с «Ростелекомом»',
        },
      ],
      correctAnswers: [1],
      correctText:
        'Верно. Важно сразу сообщить о ситуации конфликта интересов, а не скрывать её. Только так можно построить доверительные отношения',
      errorTextOnAttemptsOver: 'Правильные ответы: 1',
      nextLink: '/course/module/6/check/6',
    },
    {
      id: 6,
      title: 'Кейс 6',
      text: (
        <>
          <TextContent>
            «Ростелеком» обратился к подрядчику-консультанту, чтобы оценить стоимость затрат на
            перспективный проект. Сотрудник подрядчика решил посоветоваться с другом и переслал ему
            часть рабочих материалов, содержащих коммерческую тайну «Ростелекома». Он не знал, что
            друг в то же время консультировал компанию-конкурента «Ростелекома» по этому же проекту.
            Компания-конкурент узнала о планах «Ростелекома» и воплотила их в жизнь. «Ростелеком»
            подал в суд на подрядчика. Как подрядчик мог избежать подобной ситуации? Выберите
            несколько правильных ответов.
          </TextContent>
          <Text color="#6d12f5">Выберите несколько ответов.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title:
            'Ознакомить сотрудников с установленным у подрядчика режимом коммерческой тайны в соответствии с законодательством РФ',
        },
        {
          id: 2,
          title:
            'Сообщить своим сотрудникам, что полученная информация является коммерческой тайной «Ростелекома»',
        },
        {
          id: 3,
          title:
            'Соблюдать положения заключённого Соглашения о передаче информации, составляющей коммерческую тайну «Ростелекома»',
        },
      ],
      correctAnswers: [1, 2, 3],
      correctText: 'Верно. Мы ценим ваши усилия по сохранению в тайне нашей информации.',
      partialCorrectText:
        'Да, это верно, но подумайте ещё. Кажется, вы забыли отметить какой-то вариант.',
      errorTextOnAttemptsOver: 'Все варианты правильные',
      nextLink: '/course/module/6/check/7',
    },
    {
      id: 7,
      title: 'Кейс 7',
      text: (
        <>
          <TextContent>
            Вы сотрудник строительной организации. Ваша задача — заменить потолочную плитку в
            коридоре офиса «Ростелекома». Для этого нужно включить дрель на 3 минуты. Ближайшая
            розетка дымит. Другая розетка — в противоположном углу коридора, если воткнуть туда
            провод, люди не смогут пройти. Ещё одна — в кабинете отдела продаж. Но в вашем контракте
            прописано, что рабочая зона — коридор. И при выполнении работ её нужно ограждать 2x3
            метра, так, чтобы оставалось место для прохода. Что будете делать?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'Использую дымящуюся розетку — за 3 минуты ничего не случится',
        },
        {
          id: 2,
          title: 'Подключусь к розетке на противоположной стене — это быстро, никому не помешаю',
        },
        {
          id: 3,
          title:
            'Договорюсь с сотрудниками отдела продаж и воспользуюсь их розеткой с помощью удлинителя',
        },
        {
          id: 4,
          title:
            'Приостановлю работы и позвоню руководителю в «Ростелекоме», который отвечает за работу с нами. Сообщу о неисправности, попрошу её устранить, чтобы приступить в работе в ближайшее время',
        },
      ],
      correctAnswers: [4],
      correctText:
        'Верно. Сотрудник «Ростелекома» поможет разобраться в этой ситуации, и вы сможете снова приступить к работе',
      errorTextOnAttemptsOver: 'Правильный ответ: 4',
      nextLink: '/course/module/6/theory/10',
    },
  ],
  theory: [
    {
      id: 1,
      title: 'Образцовый деловой партнёр «Ростелекома» — какой он?',
      text: (
        <CustomText>
          Кликните на фиолетовые кружки, чтобы прочитать про образцового партнёра
        </CustomText>
      ),
      rightText: (
        <Box
          width="100%"
          height="100vh"
          sx={{
            background: `url(${images.m6t1}) no-repeat center`,
            position: 'relative',
          }}
        >
          <Points />
        </Box>
      ),
      nextLink: '/course/module/6/theory/2',
    },
    {
      id: 2,
      title: 'Пока все дома',
      text: 'Вот мы и почти закончили наш курс. Осталось только проверить знания и подвести итоги. Впереди — 7 кейсов. Если останутся вопросы, вы сможете вернуться к нужному модулю.',
      bg: 'm6t2',
      nextLink: '/course/module/6/check/1',
    },
    {
      id: 10,
      title: 'Поздравляем, вы завершили курс по деловой этике для поставщиков',
      text: (
        <CustomText>
          Теперь вы знаете всё о том, как выстраивать долгие и доверительные отношения с
          «Ростелекомом». Давайте повторим основные правила.
        </CustomText>
      ),
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center" p={[16, 16, 0]}>
          <Box
            p="40px"
            maxWidth="640px"
            sx={{ background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)' }}
          >
            <CustomText mb="16px">
              1) Вести бизнес честно, открыто и добросовестно. Решать задачи только в рамках закона.
            </CustomText>
            <CustomText mb="16px">
              2) Не мириться с любыми проявлениями коррупции. Не давать взятки и не использовать
              ненадлежащие платежи в отношениях с сотрудниками «Ростелекома», госслужащими, иными
              партнёрами и субподрядчиками.
            </CustomText>
            <CustomText mb="16px">
              3) Не дарить подарки, которые не соответствуют нашим принципам, сотрудникам
              «Ростелекома» или другим лицам от имени компании.
            </CustomText>
            <CustomText mb="16px">
              4) Транслировать субподрядчикам этические нормы «Ростелекома».
            </CustomText>
            <CustomText>5) Сообщать«Ростелекому» о ситуации конфликта интересов.</CustomText>
          </Box>
        </Flex>
      ),
      nextLink: '/course/module/6/theory/11',
    },
    {
      id: 11,
      title: 'Поздравляем, вы завершили курс по деловой этике для поставщиков',
      text: (
        <CustomText>
          Теперь вы знаете всё о том, как выстраивать долгие и доверительные отношения с
          «Ростелекомом». Давайте повторим основные правила.
        </CustomText>
      ),
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center" p={[16, 16, 0]}>
          <Box
            p="40px"
            maxWidth="640px"
            sx={{ background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)' }}
          >
            <CustomText mb="16px">
              6) С осторожностью обращаться с инсайдерской и иной конфиденциальной информацией,
              принадлежащей «Ростелекому».
            </CustomText>
            <CustomText mb="16px">
              7) Бережно относиться к природе и соблюдать нормы охраны труда.
            </CustomText>
            <CustomText mb="16px">
              8) Предоставлять и публиковать только достоверную и проверенную информацию.
            </CustomText>
            <CustomText mb="16px">
              9) Соблюдать условия договора и вовремя предоставлять качественные услуги.
            </CustomText>
            <CustomText>10) Быть надёжным в работе и уважать других.</CustomText>
          </Box>
        </Flex>
      ),
      nextLink: '/course/module/6/intro/10',
    },
  ],
};

export default module6;
