import API from '../../../helpers/API';

export const fetchNews = async (params) => {
  try {
    const {data} = await API.get('news', {
      params,
    });
    return data;
  } catch (e) {
    console.error('@@@e', e);
  }
};
