import { useAsyncFn } from 'react-use';

import { fetchAddDocuments } from '../requests';
import { useStatuses } from '../../../../../hooks/useStatuses';

export const useAddedDocument = (setIsModalOpen, handleAddFile) => {
  const [{ loading, value, error }, addDoc] = useAsyncFn(fetchAddDocuments);

  const addDocument = async (data) => {
    const resp = await handleAddFile();
    if (resp?.id) {
      await addDoc(data, resp?.id);
    }
    else {
      await addDoc(data);
    }
    setIsModalOpen(true);
  };

  const { status } = useStatuses(loading, value, error);

  const addStatus = status;
  return { addDocument, addDoc, addStatus };
};
