import qs from 'qs';
import { keys, isNil, mergeDeepRight } from 'ramda';

export const generateQueryString = (value) => {
  if (typeof value === 'object' && !isNil(value)) {
    /**
     * Свойства, для которых установлено значение undefined, будут полностью опущены
     */
    const formattedValue = keys(value).reduce(
      (acc, key) => mergeDeepRight(acc, { [key]: value[key] || undefined }),
      {},
    );
    return qs.stringify(formattedValue, { addQueryPrefix: true });
  }
  return qs.stringify(value, { addQueryPrefix: true });
};

export const generateUrl = (value) =>
  `${value.url}${qs.stringify(value?.params, { addQueryPrefix: true })}`;

/**
 * Утилита для парсинга значений из урл
 * возвращает корректные примитивные типы
 */
function decoder(str, _, charset) {
  const strWithoutPlus = str.replace(/\+/g, ' ');
  if (charset === 'iso-8859-1') {
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }

  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    return parseFloat(str);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };
  if (str in keywords) {
    return keywords[str];
  }

  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
}

export const parseQueryString = (value) =>
  qs.parse(value, {
    ignoreQueryPrefix: true,
    decoder,
  });
