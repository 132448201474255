import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text, Box } from 'rebass';
import styled from 'styled-components';
import { find } from 'lodash';
import ArrowButton from './ArrowButton';
import data from '../data';
import images from '../img';

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
  background-color: #f2f3f7;
`;

const ModuleHome = () => {
  const { moduleId, homeId } = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const { title, text, nextLink, nextLinkTitle, bg } = find(module.home, ['id', +homeId || 1]);

  return (
    <Wrapper>
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        height={['auto', 'auto', '100%']}
        minHeight={['100%']}
        sx={{ background: `url(${images[bg]}) no-repeat center`, backgroundSize: 'cover' }}
      >
        <Flex
          width={[1, 1, 1 / 2]}
          justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
          py="40px"
          px="16px"
          alignItems="flex-end"
          // minHeight={['100vh']}
        >
          <Flex maxWidth={['100%', '100%', '100%', 640]} flexDirection="column">
            <Box my={[40, 40, 40, 0]} pb="30px">
              <Text
                fontSize={['35px', '45px', '64px']}
                display="inline"
                bg="#f6c043"
                lineHeight="1.1em"
              >
                {title}
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={['16px', '16px', '20px', '22px']}
                bg={['#6d12f5', '#6d12f5', 'transparent']}
                color={['#fff', '#fff', '#101828']}
                display="inline"
                lineHeight="1.5"
                p="8px 4px"
              >
                {text}
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          width={[1, 1, 1 / 2]}
          alignItems="flex-end"
          pl={[0, '40px']}
          pt="40px"
          ml={[16, 24, 40]}
        >
          <Flex>
            <ArrowButton isLeft />
            <ArrowButton link={nextLink} title={nextLinkTitle} />
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default memo(ModuleHome);
