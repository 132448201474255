import {useEffect, useState} from 'react';
import {useAsyncFn} from 'react-use';

import {useRouter} from '../../../../../hooks';
import {fetchEducationById, fetchEditEducation} from '../requests';
import {ACCESS, ARTICLE_STATUSES, DOCUMENT_TYPES, SOURCE_TYPE} from '../../constants';
import {useStatuses} from '../../../../../hooks/useStatuses';

const initialState = {
  title: '',
  status: ARTICLE_STATUSES.NOT_PUBLISHED,
  access: ACCESS.PRIVATE,
  text: '',
  documentType: DOCUMENT_TYPES.ANTI_CORRUPTION,
  date: new Date(),
};

export const useEditEducation = (setIsModalOpen) => {
  const [{value}, getEducation] = useAsyncFn(fetchEducationById);
  const [state, editEducation] = useAsyncFn(fetchEditEducation);
  const {status} = useStatuses(state?.loading, state?.value, state?.error);
  const editStatus = status

  const {
    query: {id},
  } = useRouter();
  const [education, setEducation] = useState(initialState);

  useEffect(() => {
    if (id) {
      getEducation(id);
    }
  }, [id]);

  const handleEditEducation = async (data, handleAddFile) => {
    setIsModalOpen(true);
    const resp = await handleAddFile();
    if (resp?.id || resp) {
      await editEducation(id, data, resp?.id);
    } else {
      await editEducation(id, data);
    }
  };

  useEffect(() => {
    if (value) {
      setEducation({
        title:value?.title,
        content:'',
        type: value?.file ? SOURCE_TYPE.LOCAL : SOURCE_TYPE.EXTERNAL ,
        externalLink:value?.externalLink,
        file: {path: value?.file?.originalFilename, id: value?.file?.id},
        publishStatus: value?.publishStatus,
        publicationDate:value?.publicationDate,
        access: value?.accessType
      });
    }
  }, [value]);

  return {education, editEducation, handleEditEducation, editStatus};
};
