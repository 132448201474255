import React, {memo} from 'react';
import {Flex, Text} from 'rebass';
import styled from 'styled-components';
import Button from './Button';
import ArrowButton from './ArrowButton';

const Wrapper = styled(Text)`
  width: 100vw;
  height: 100vh;
  background-color: #f2f3f7;
  @media (max-width: 767px) {
    height: auto;
  }
`;

const LayoutIntro = ({pagetTitle, leftContent, bgImage, nextLink,isNotBlockBackground}) => {
  const isBlockBackground = isNotBlockBackground ? "":"none"
  return (
    <Wrapper>
      <Flex
        flexWrap="wrap"
        height={['auto', 'auto', '100%']}
        minHeight="100vh"
        sx={{
          background: [`url(${bgImage}) no-repeat top`],
          backgroundSize: '100%',
          '@media screen and (max-width: 1300px)': {
            background: [`url(${bgImage}) no-repeat right`],
            backgroundSize: '80%',
          },
          '@media screen and (max-height: 580px)': {
            background: [`url(${bgImage}) no-repeat top-right`],
            backgroundSize: '70%',
          },
          '@media screen and (max-width: 1023px)': {
            background: isBlockBackground,
          },
        }}
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <Flex flex={['1', '1', '1', '1.8']} justifyContent="flex-end" pt="40px" px="16px">
          <Flex maxWidth={['100%', '100%', '100%', '900px']} flexDirection="column">
            <Flex height={['auto', 'auto', '10%']}>
              <Button title={pagetTitle}/>
            </Flex>
            <Flex
              height={['auto', 'auto', '80%']}
              my={[40, 40, 40, 0]}
              pr={[0, 0, 0, 20]}
              alignItems="center"
            >
              {leftContent}
            </Flex>
            <Flex height={['auto', 'auto', 125]}>
              <ArrowButton isLeft/>
              <ArrowButton link={nextLink}/>
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={['1']} sx={{
          '@media screen and (max-width: 1023px)': {
            display: "none",
          },
        }
        }/>
      </Flex>
    </Wrapper>
  );
};

export default memo(LayoutIntro);
