import React, {useState, useEffect} from 'react';
import {Flex, Box, Text} from 'rebass';
import {useParams} from 'react-router-dom';
import {Controller, useWatch} from "react-hook-form";
import {isEmpty} from "ramda";

import EditLayout from '../EditLayout';
import {ARTICLE_STATUSES, ACCESS} from '../constants';
import Button from '../Button';
import Select from '../Select';
import File from '../File';
import TextEditor from '../TextEditor';
import ModalStatus from '../ModalStatus';
import {useAddedFaqs} from './hooks/useAddedFaqs';
import {useFile} from '../../../../hooks/useFile';
import {useEditFags} from './hooks/useEditFaqs';
import {useFormValue} from "./hooks/useForm";
import {LOADING} from "../../../../store/statusConstants";
import CustomTextField from "../TextField";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const statuses = [
  {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
  {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
];

const access = [
  {title: 'Закрытый', value: ACCESS.PRIVATE},
  {title: 'Открытый', value: ACCESS.PUBLIC},
];

const FaqsEdit = () => {
  const {id} = useParams();
  const [isModalOpen, setIsModalOpen] = useState();
  const {fags, handleEditFaqs, editStatus} = useEditFags(setIsModalOpen);
  const {file, setFile, handleAddFile} = useFile(fags?.file);
  const {handleAddedFaqs, addStatus} = useAddedFaqs(setIsModalOpen, handleAddFile);

  const {
    getValues,
    control,
    setValue,
    errorsList,
    clearErrors,
    validationStatus,
    onSubmit
  } = useFormValue(fags, handleEditFaqs, handleAddedFaqs, handleAddFile, setIsModalOpen)

  const formValues = getValues();

  useWatch({
    control,
    name: ['status','access'],
  })

  const changeValue = (name, value) => {
    setValue(name, value)
  };

  useEffect(() => {
    if (!isEmpty(file)) {
      changeValue('file', file?.[0])
    } else {
      changeValue('file', null)
    }
  }, [file])

  return (
    <EditLayout title={id ? 'Редактировать вопрос' : 'Добавить вопрос'}>
      <form onSubmit={onSubmit}>
        <Flex mb="24px">
          <Box width="50%" pr="16px">
            <Controller
              name={'title'}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomTextField
                  value={value || ''}
                  label="Вопрос"
                  size="small"
                  sx={{width: '100%', marginBottom: '24px'}}
                  onChange={onChange}
                />
              )}
            />
            <File onChange={setFile} files={file}/>
          </Box>
          <Flex flexDirection="column" width="50%">
            <Box mb="24px">
              <Select
                required
                label="Статус"
                value={formValues.status || ''}
                options={statuses}
                onChange={(name, value) => changeValue(name, value)}
                width="100%"
                name="status"
              />
            </Box>
            <Box mb="24px">
              <Select
                required
                label="Доступ"
                value={formValues.access || ''}
                options={access}
                onChange={(name, value) => changeValue(name, value)}
                width="100%"
                name="access"
              />
            </Box>
          </Flex>
        </Flex>
        <Box mb="40px">
          <Text mb="16px">Текст ответа:</Text>
          <TextEditor value={formValues.text} onBlur={(value) => changeValue('text', value)}/>
        </Box>
        <Flex>
          <Button variant="contained" label="Сохранить" type="onSubmit" disabled={!checkedPermission(permissions.questionsAnswersWrite)}/>
        </Flex>
      </form>
      <ModalStatus
        status={addStatus || editStatus || validationStatus || LOADING}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        access="Вопрос-ответ успешно добавлен"
        path="/admin/faqs"
        errorsList={errorsList}
        clearErrors={clearErrors}
      />
    </EditLayout>
  );
};

export default FaqsEdit;
