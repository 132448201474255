import React, { memo, useState } from 'react';
import { Flex, Text, Box } from 'rebass';
import { useParams } from 'react-router-dom';
import { concat, find } from 'lodash';
import { Slider } from 'molecules';
import Layout from './Layout';
import TextTitle from './TextTitle';
import images from '../img';
import data from '../data';
import {SliderBox} from "../styles";

const ModuleTheorySlider = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [viewedSlides, setViewedSlides] = useState([1]);

  const { moduleId, theoryId } = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const { title, slides, nextLink } = find(module.theory, ['id', +theoryId || 1]);

  const getSlideImg = () => {
    const { img } = find(slides, ['id', currentSlide]);
    return images[img];
  };

  const sliderChange = (e, val) => {
    if (currentSlide !== val) {
      if (!viewedSlides.includes(val)) {
        setViewedSlides(concat(viewedSlides, val));
      }
      setCurrentSlide(+val);
    }
  };

  const renderLeft = () => {
    return (
      <Box>
        <TextTitle>{title}</TextTitle>
      </Box>
    );
  };
  const renderRight = () => {
    return (
      <Flex flexDirection="column" width="100%" px={[16, 24]} pt={[24, 24, 0]}>
        <Box>
          <SliderBox>
            <img src={getSlideImg()} alt="" width="100%" className="img"/>
          </SliderBox>
        </Box>

        <Box my={[16, 24]}>
          <Slider
            defaultValue={1}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={slides.length}
            onChange={sliderChange}
          />
        </Box>

        <Text color="#6d12f5">Чтобы продолжить курс, пройдите весь слайдер</Text>
      </Flex>
    );
  };

  return (
    <Layout
      pagetTitle={module.title}
      leftContent={renderLeft()}
      rightContent={renderRight()}
      nextButtonDisabled={viewedSlides.length !== slides.length}
      nextLink={nextLink}
    />
  );
};

export default memo(ModuleTheorySlider);
