import {useAsyncFn} from "react-use";
import {fetchEditingVideo, fetchVideo} from "../requests";
import {useEffect, useState} from "react";
import {useRouter} from "../../../../../hooks";
import {useStatuses} from "../../../../../hooks/useStatuses";
import {SOURCE_TYPE} from "../../constants";

const initialState = {
  title: '',
  status: '',
  fileSourceType: '',
  files: null,
  access: '',
  link: '',
  dataType: '',
};

export const useEditVideo = (fileSourceTypes) => {
  const {
    query: {id},
  } = useRouter();

  const [{value}, getVideo] = useAsyncFn(fetchVideo)
  const [state, editingVideo] = useAsyncFn(fetchEditingVideo)
  const [video, setVideo] = useState(initialState)

  const handleEditVideo = async (data, type, handleAddFie) => {
    if (type === SOURCE_TYPE.LOCAL) {
      const resp = await handleAddFie();
      if (resp?.id || resp) {
        await editingVideo(id, data, resp?.id);
      }
    } else {
      await editingVideo(id, data);
    }
  };

  useEffect(() => {
    if (id) {
      getVideo(id)
    }
  }, [])

  useEffect(() => {
    if (value) {
      const newValue = {
        access: value?.accessType,
        fileSourceType: value?.externalLink ? fileSourceTypes[1].value : fileSourceTypes[0].value,
        file: { path: value?.file?.originalFilename, id: value?.file?.id },
        link: value?.externalLink,
        status: value?.publishStatus,
        title: value?.title
      }
      setVideo(newValue)
    }
  }, [value])

  const { status } = useStatuses(state?.loading, state?.value, state?.error);

  return {video, editVideo:handleEditVideo,editStatus: status}

}
