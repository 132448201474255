import { useState, useCallback } from 'react';
import {useUpdateEffect} from "react-use";

import {saveParamsToUrl} from "../../../utils/saveParamsUrl";
import {useRouter} from "../../../hooks";

export const INITIAL_PAGE = 0;
export const INITIAL_ALL_PAGES = 1;
export const INITIAL_PAGE_SIZE = 10;

/**
 * Хук для обработки  пагинации
 * @returns
 */
export const usePagination = () => {
  const { query } = useRouter();
  const [currentPage, setPage] = useState(Number(query?.page) || INITIAL_PAGE);
  const [allPages, setAllPages] = useState(INITIAL_ALL_PAGES);
  const [pageSize, setCountElements] = useState( Number(query?.pageSize) || INITIAL_PAGE_SIZE);
  const [allElements, setAllElements] = useState(0);

  const handlePageChange = useCallback((page) => setPage(page + 1), [setPage]);

  useUpdateEffect(() => {
    saveParamsToUrl({ page: currentPage, pageSize });
  }, [currentPage, pageSize]);

  return {
    currentPage,
    handlePageChange,
    setAllPages,
    allPages,
    setPage,
    pageSize,
    setCountElements,
    allElements,
    setAllElements,
  };
};
