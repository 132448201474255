import React, {memo} from 'react';
import {Flex, Box, Text, Image} from 'rebass';
import {NavLink, Link} from 'react-router-dom';
import {Icon} from 'atoms';
import styled from 'styled-components';
import Burger from './Burger';

const StyledIcon = styled(Icon)`
  display: block;
  margin-right: 8px;
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    display: none;
    margin-right: 0;
  }
`;

const StyledNavLink = styled(NavLink)`
  border-bottom: 3px solid transparent;
  padding: 16px 0;
  display: inline-flex;
`;

const Wrapper = styled(Box)`
  &:last-child {
    margin-right: 0;
  }
`;

const menuItems = [
  {id: 1, title: 'Новости', path: '/news', iconName: 'menu-news'},
  {id: 2, title: 'Интересные материалы', path: '/materials', iconName: 'menu-materials'},
  {id: 3, title: 'Вопрос-ответ', path: '/faqs', iconName: 'menu-faqs'},
  {id: 4, title: 'Документы', path: '/documents', iconName: 'menu-documents'},
  {id: 5, title: 'Обучение', path: '/education', iconName: 'menu-education'},
  {id: 6, title: 'Обратная связь', path: '/feedback', iconName: 'menu-feedback'},
  // { id: 7, title: 'Викторина', path: '/quiz', iconName: 'menu-quiz' },
];

const renderMenuItem = ({id, title, path, iconName}) => {
  return (
    <Wrapper key={id} mr="32px">
      <StyledNavLink
        to={path}
        activeStyle={{
          borderColor: '#101828',
        }}
      >
        <Flex key={id} alignItems="center" justifyContent="center" color="#101828">
          <StyledIcon name={iconName}/>

          <Text fontWeight="500" sx={{whiteSpace: 'nowrap'}}>
            {title}
          </Text>
        </Flex>
      </StyledNavLink>
    </Wrapper>
  );
};

const Menu = () => {
  return (
    <Flex
      width="100%"
      justifyContent={['flex-start', 'flex-start', 'flex-start', 'center']}
      sx={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.22)', zIndex: '2'}}
      px="16px"
      py={[16, 16, 16, 0]}
    >
      <Flex
            sx={{maxWidth: ' 1300px', width: '100%', padding: '0 48px 0 48px'}}>
        <Flex>
          <Box mr={[16, 16, 20]} mt={[0, 0, 16]}>
            <Link to="/">
              <Image src="/rt-logo.svg" width={[25, 25, 50]}/>
            </Link>
          </Box>
        </Flex>
        <Flex width="100%" flexDirection="column" alignItems="center">
          <Box
            py={[0, 0, 16]}
            sx={{
              width: '100%',
              borderBottom: ['none', 'none', 'none', '2px solid #D9D9D9'],
            }}
          >
            <Text fontSize={['0.875rem', '0.875rem', '1.25rem']} fontWeight="500">
              Антикоррупционный сайт
            </Text>
          </Box>

          <Flex width="100%">
            <Flex display={['flex', 'flex', 'flex', 'none']} width="100%" justifyContent="flex-end">
              <Burger>{menuItems.map(renderMenuItem)}</Burger>
            </Flex>
            <Box display={['none', 'none', 'none', 'flex']}>{menuItems.map(renderMenuItem)}</Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(Menu);
