import interceptor from "../../../../../helpers/API";

export const fetchListFeedback = async (params,) => {
  try {
    const newParams = {
      ...params
    };
    const {data} = await interceptor.get('admin/feedbacks', {
      params: newParams,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchFeedback = async (pinCode) => {
  try {
    const {data} = await interceptor.get(`admin/feedbacks/${pinCode}`);
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
