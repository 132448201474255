import React, {useState, useEffect} from 'react';
import {Flex, Box, Text} from 'rebass';
import {useParams} from 'react-router-dom';

import EditLayout from '../EditLayout';
import {ARTICLE_STATUSES, ACCESS, SOURCE_TYPE} from '../constants';
import Button from '../Button';
import Select from '../Select';
import TextField from '../TextField';
import File from '../File';
import ModalStatus from '../ModalStatus';
import {useAddedVideo} from "./hooks/useAddedVideo";
import {useFile} from "../../../../hooks/useFile";
import {useEditVideo} from "./hooks/useEditVideo";
import {LOADING} from "../../../../store/statusConstants";
import {acceptFormatVideo} from "./constants";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const statuses = [
  {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
  {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
  // {title: 'Ожидает публикации', value: ARTICLE_STATUSES.POSTPONED},
];

const access = [
  {title: 'Закрытый', value: ACCESS.PRIVATE},
  {title: 'Открытый', value: ACCESS.PUBLIC},
];

const fileSourceTypes = [
  {title: 'Локальный файл', value: SOURCE_TYPE.LOCAL},
  {title: 'Видео в итернете', value: SOURCE_TYPE.EXTERNAL},
];


const VideoEdit = () => {
  const {id} = useParams();
  const [isModalOpen, setIsModalOpen] = useState();
  const {video, editVideo, editStatus} = useEditVideo(fileSourceTypes)

  const [formValues, setFormValues] = useState(video);
  const {file, setFile, handleAddFile,isFileLoading} = useFile(video?.file);

  const fileStatus = isFileLoading ? LOADING : ""

  useEffect(() => {
      setFormValues(video)
    }, [video]
  )
  const {addedVideo, addStatus} = useAddedVideo(handleAddFile,isFileLoading)

  const changeValue = (name, value) => {
    const newValues = {...formValues};
    newValues[name] = value;
    setFormValues(newValues);
  };

  const handleCreate = async () => {
    setIsModalOpen(true);
    if (id) {
      await editVideo(formValues, formValues.fileSourceType, handleAddFile,isFileLoading);
    } else {
      await addedVideo(formValues)
    }
  };

  return (
    <EditLayout title={id ? 'Редактировать видео' : 'Добавить видео'}>
      <Box>
        <Flex mb="24px">
          <Box width="50%" pr="16px">
            <TextField
              required
              value={formValues.title}
              label="Заголовок"
              size="small"
              sx={{width: '100%', marginBottom: '24px'}}
              onChange={(value) => changeValue('title', value)}
            />
            <Box mb="24px">
              <Select
                required
                label="Тип видео"
                value={formValues.fileSourceType}
                options={fileSourceTypes}
                onChange={(name, value) => changeValue(name, value)}
                width="100%"
                name="fileSourceType"
              />
            </Box>
          </Box>
          <Flex flexDirection="column" width="50%">
            <Box mb="24px">
              <Select
                required
                label="Статус"
                value={formValues.status}
                options={statuses}
                onChange={(name, value) => changeValue(name, value)}
                width="100%"
                name="status"
              />
            </Box>
            <Box mb="24px">
              <Select
                required
                label="Доступ"
                value={formValues.access}
                options={access}
                onChange={(name, value) => changeValue(name, value)}
                width="100%"
                name="access"
              />
            </Box>
            {formValues.fileSourceType && <>{formValues.fileSourceType === SOURCE_TYPE.LOCAL ? (
              <>
                {/*<File onChange={setFile} files={file} validFormats={['.mp4', '.flv', '.webm', '.rtmp']} />*/}
                <File onChange={setFile} files={file} validFormats={acceptFormatVideo} />
                <Text mt="8px" fontSize="14px" color="#777">
                  допустимый формат файла: mp4, flv, webm, rtmp
                </Text>
              </>
            ) : (
              <>
                <TextField
                  required
                  value={formValues.link}
                  label="Ссылка"
                  size="small"
                  sx={{width: '100%'}}
                  onChange={(value) => changeValue('link', value)}
                />
                <Text mt="8px" fontSize="14px" color="#777">
                  укажите ссылку на видео, например, http://www.youtube.com/watch?v=CMD0CCq6Jo8
                </Text>
              </>
            )}</>}

          </Flex>
        </Flex>
        <Flex>
          <Button variant="contained" label="Сохранить" onClick={handleCreate} disabled={!checkedPermission(permissions.materialsWrite)}/>
        </Flex>
      </Box>
      <ModalStatus
        status={fileStatus || addStatus || editStatus}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        access="Видео успешно добавлено"
        path="/admin/materials"
      />
    </EditLayout>
  );
};

export default VideoEdit;
