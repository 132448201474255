import React, { memo } from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

const StyledReactPaginate = styled.div`
  .pagination {
    display: flex;
    flex-wrap: wrap;
  }

  .prev_page,
  .next_page {
    display: none;
  }

  li {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 250ms ease;
    list-style-type:none;

    a {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      line-height: 36px;
      font-weight: 500;
    }
    &.active {
      background: #7800ff;
      a,
      a:hover {
        color: #fff;
      }
    }
    &:hover {
      border-color: #7800ff;
    }
  }

  li:before {
    display: none;
  }


`;

const Paginator = ({ currentPage, pageCount = 0, onPageChange = () => {} }) => {
  return (
    <StyledReactPaginate>
      <ReactPaginate
        page={currentPage}
        previousClassName="prev_page"
        nextClassName="next_page"
        breakLabel="..."
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        disableInitialCallback
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={currentPage - 1}
      />
    </StyledReactPaginate>
  );
};

export default memo(Paginator);
