import {useEffect, useState} from "react";
import {useAsyncFn} from "react-use";

import {fetchListFeedback} from "../requests";
import {getNormalizedFeedback} from "../utils/getNormalizedFeedback";
import {useStatuses} from "../../../../../hooks/useStatuses";
import {saveParamsToUrl} from "../../../../../utils/saveParamsUrl";

export const useFeedback = (filters, setAllPages, page, pageSize, setAllElements) => {
  const [{value, loading, error}, getFeedback] = useAsyncFn(fetchListFeedback)
  const [listFeedback, setListFeedback] = useState([])
  const {status} = useStatuses(loading, value?.content, error, true);

  const params = {
    pin: filters?.pin,
    fio: filters?.fio,
    phone: filters?.phone,
    mail: filters?.mail,
    page: page + 1,
    size: pageSize,
  }
  useEffect(async () => {
    await getFeedback(params, filters);
  }, [pageSize, page]);

  useEffect(() => {
    if (value) {
      const normalizedList = value?.content?.map((item) => {
        return getNormalizedFeedback(item);
      });
      setListFeedback(normalizedList)
      setAllPages(value?.pagination?.totalPages);
      setAllElements(value?.pagination?.totalElements);

    }
  }, [value])

  const updateTableData = async () => {
    await getFeedback(params, filters);
    saveParamsToUrl({...filters})
  };

  return {listFeedback, updateTableData, getFeedback, status}
}
