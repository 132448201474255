import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ACCESS, MATERIAL_TYPES, ARTICLE_STATUSES } from '../../modules/Admin/components/constants';

export const fetchMaterials = createAsyncThunk(
  'Materials/fetchMaterials',
  async (arg, { getState }) => {
    const state = getState();
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return mokeMaterials;
  },
);

export const createMaterials = createAsyncThunk('Materials/createMaterials', async (data) => {
  console.log(data);
});

export const fetchArticleById = createAsyncThunk('article/fetchArticleById', async (id) => {
  //тут будет запрос на данные конкретной статьи
  return mokeArticle;
});

export const fetchVideoById = createAsyncThunk('article/fetchVideoById', async (id) => {
  //тут будет запрос на данные конкретной статьи
  return mokeVideo;
});

export const deleteMaterialsById = createAsyncThunk('Materials/deleteMaterialsById', async (id) => {
  // отправляем данные
  return mokeMaterials.filter((item) => item.id !== id);
});

const MaterialsSlice = createSlice({
  name: 'materials',
  initialState: {
    listOfMaterials: [],
    dateRange: [1627761600, 1643107212],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: [1627761600, 1643107212],
      access: ACCESS.ALL,
      type: MATERIAL_TYPES.ALL,
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      dateSort: false,
      titleSort: null,
      statusSort: null,
    },
    data: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchMaterials.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchMaterials.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfMaterials = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchMaterials.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createMaterials.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createMaterials.fulfilled]: (state, action) => {},
    [createMaterials.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteMaterialsById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteMaterialsById.fulfilled]: (state, action) => {
      state.listOfMaterials = action.payload;
      state.status = FULFILLED;
    },
    [deleteMaterialsById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchArticleById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchArticleById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchArticleById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchVideoById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchVideoById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchVideoById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setSort, setPagination } = MaterialsSlice.actions;

export default MaterialsSlice.reducer;

const mokeArticle = {
  title: 'QQQQQQ',
  status: 'UNPUBLISHED',
  files: [],
  link: 'https://redux-toolkit.js.org/tutorials/quick-start',
  text: 'asasas',
  access: 'PRIVATE',
};

const mokeVideo = {
  title: 'QQQQQQ',
  status: 'UNPUBLISHED',
  fileSourceType: 'LOCAL',
  files: [],
  link: 'https://redux-toolkit.js.org/tutorials/quick-start',
  access: 'PRIVATE',
};

const mokeMaterials = [
  {
    id: 1,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    materialType: 'ARTICLE',
    dataType: 'article',
  },
  {
    id: 2,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    materialType: 'ARTICLE',
    dataType: 'video',
  },
  {
    id: 3,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    materialType: 'ARTICLE',
    dataType: 'article',
  },
  {
    id: 4,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    materialType: 'ARTICLE',
  },
  {
    id: 5,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    materialType: 'ARTICLE',
  },
  {
    id: 6,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'PUBLISHED',
    access: 'PUBLIC',
    materialType: 'ARTICLE',
  },
  {
    id: 7,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'PUBLISHED',
    access: 'PRIVATE',
    materialType: 'ARTICLE',
  },
  {
    id: 8,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PRIVATE',
    materialType: 'ARTICLE',
  },
  {
    id: 9,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PRIVATE',
    materialType: 'ARTICLE',
  },
  {
    id: 10,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    materialType: 'VIDEO',
  },
  {
    id: 11,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'PUBLISHED',
    access: 'PUBLIC',
    materialType: 'ARTICLE',
  },
  {
    id: 12,
    date: 1627761600,
    title: 'ФАС обещает скидку по штрафам компаниям',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
    materialType: 'VIDEO',
  },
];
