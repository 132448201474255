import React, {useEffect, useState} from 'react';
import {Flex, Box, Text} from 'rebass';
import {useParams} from 'react-router-dom';
import {Controller} from 'react-hook-form';

import EditLayout from '../EditLayout';
import {ARTICLE_STATUSES, ACCESS, DOCUMENT_TYPES} from '../constants';
import Button from '../Button';
import Select from '../Select';
import File from '../File';
import TextEditor from '../TextEditor';
import ModalStatus from '../ModalStatus';
import {useAddedDocument} from './hooks/useAddedDocuments';
import {useEditDocument} from './hooks/useEditDocument';
import {useFile} from '../../../../hooks/useFile';
import {fieldNames, useFormValue} from "./hooks/useForm";
import {TextField} from "@material-ui/core";
import {LOADING} from "../../../../store/statusConstants";
import {accept} from "./constants";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const statuses = [
  {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
  {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
];

const access = [
  {title: 'Закрытый', value: ACCESS.PRIVATE},
  {title: 'Открытый', value: ACCESS.PUBLIC},
];

const documentTypes = [
  {title: 'Антикоррупционное законодательство', value: DOCUMENT_TYPES.ANTI_CORRUPTION},
  {title: 'Документы компании', value: DOCUMENT_TYPES.COMPANY},
];

const DocumentsEdit = () => {
  const {id} = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {document, handleEditDocs, editStatus} = useEditDocument(setIsModalOpen);


  const {file, setFile, handleAddFile} = useFile(document?.file);
  const {addDocument, addStatus} = useAddedDocument(setIsModalOpen, handleAddFile);
  const {
    control,
    getValues,
    setValue,
    onSubmit,
    errorsList,
    validationStatus,
    clearErrors
  } = useFormValue(document, handleEditDocs, addDocument, handleAddFile,setIsModalOpen)
  const formValues = getValues()

  const changeValue = (name, value) => {
    setValue(name, value)
  };

  useEffect(() => {
    if (file) {
      setValue(fieldNames.file, file?.[0]);
    }
  }, [file]);


  return (
    <EditLayout title={id ? 'Редактировать документ' : 'Добавить документ'}>
      <form onSubmit={onSubmit}>
        <Box>
          <Flex mb="24px">
            <Box width="50%" pr="16px">
              <Controller
                name={fieldNames.title}
                control={control}
                render={({field: {onChange, value}}) => (
                  <TextField onChange={onChange} value={value} label={"Название"} size="small"
                             sx={{width: '100%', marginBottom: '24px'}}/>
                )}
              />
              <File onChange={setFile} files={file} validFormats={accept}/>
              <Text mt="8px" fontSize="14px" color="#777" >
                допустимый формат файла: pdf, doc, docx
              </Text>
            </Box>
            <Flex flexDirection="column" width="50%">
              <Box mb="24px">
                <Controller
                  name={fieldNames.status}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Select
                      required
                      label="Статус"
                      value={value}
                      options={statuses}
                      onChange={(name, value) => changeValue(name, value)}
                      width="100%"
                      name={fieldNames.status}
                    />
                  )}
                />
              </Box>
              <Box mb="24px">
                <Controller
                  name={fieldNames.access}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Select
                      required
                      label="Доступ"
                      value={value}
                      options={access}
                      onChange={(name, value) => changeValue(name, value)}
                      width="100%"
                      name={fieldNames.access}
                    />
                  )}
                />
              </Box>
              <Box mb="24px">
                <Controller
                  name={fieldNames.documentType}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Select
                      required
                      label="Категория"
                      value={value}
                      options={documentTypes}
                      onChange={(name, value) => changeValue(name, value)}
                      width="100%"
                      name="documentType"
                    />
                  )}
                />
              </Box>
            </Flex>
          </Flex>
          <Box mb="40px">
            <Text mb="16px">Описание документа:</Text>
            <TextEditor value={formValues.text} onBlur={(value) => changeValue('text', value)}/>
          </Box>
          <Flex>
            <Button variant="contained" label="Сохранить" type={'onsubmit'} disabled={!checkedPermission(permissions.docsWrite)}/>
          </Flex>
        </Box>
        <ModalStatus
          status={addStatus || editStatus || validationStatus || LOADING}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          access="Документ успешно добавлен"
          path="/admin/documents"
          errorsList={errorsList}
          clearErrors={clearErrors}
        />
      </form>
    </EditLayout>
  );
};

export default DocumentsEdit;
