import {getNormalizeDate} from "../../utils";

export const normalizedMaterials = (materials) => {
  return {
    id: materials?.id,
    date: getNormalizeDate(materials?.publicationDate),
    title: materials?.title,
    file: materials?.file,
    link: materials?.externalLink
  }
}
