import React, { memo, useState, useEffect } from 'react';
import { Flex, Text, Box } from 'rebass';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { find, concat, sortBy } from 'lodash';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { Checkbox } from 'atoms';
import { Modal } from 'molecules';
import Button from './Button';
import Layout from './Layout';
import TextTitle from './TextTitle';
import { setCorrectAnswers } from '../../../store/reducers/Course.reducers';
import data from '../data';

const Test = ({ history }) => {
  const dispatch = useDispatch();
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  const [modalOpened, setModalVisibility] = useState(false);
  const [attempts, incrementAttempts] = useState(0);

  const { moduleId, checkId } = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const { title, text, nextLink, questions, correctAnswers, correctText, errorTextOnAttemptsOver } =
    find(module.check, ['id', +checkId || 1]);

  const handleCheckAnswer = (id, state) => {
    const isMultipleAnswers = correctAnswers.length > 1;
    let nextItems = [];
    if (state) {
      nextItems = isMultipleAnswers ? concat(checkedAnswers, id) : [id];
    } else {
      nextItems = checkedAnswers.filter((itemId) => itemId !== id);
    }

    setCheckedAnswers(nextItems);
  };

  const check = () => {
    if (checkedAnswers.length) {
      incrementAttempts(attempts + 1);
    }
    setModalVisibility(true);
  };

  const nextButtonHandler = () => {
    setModalVisibility(false);

    const isAllAnswersCorrect = sortBy(checkedAnswers).join() === sortBy(correctAnswers).join();

    if (isAllAnswersCorrect) {
      dispatch(
        setCorrectAnswers({
          moduleId: module.id,
          checkId: checkId || 1,
          // correctCount: correctAnswers.length,
          correctCount: 1,
        }),
      );
    } else if (attempts === 3 && !isAllAnswersCorrect) {
      const intersection = checkedAnswers.filter((answer) => correctAnswers.includes(answer));
      dispatch(
        setCorrectAnswers({
          moduleId: module.id,
          checkId: checkId || 1,
          correctCount: intersection.length,
        }),
      );
    }

    if (attempts === 3 || isAllAnswersCorrect) {
      history.push(nextLink);
    }
  };

  useEffect(() => {
    setCheckedAnswers([]);
    incrementAttempts(0);
  }, [checkId]);

  const renderModalBody = () => {
    const status = !checkedAnswers.length
      ? 'empty'
      : sortBy(checkedAnswers).join() !== sortBy(correctAnswers).join()
      ? 'error'
      : 'correct';

    const modalContent = {
      empty: {
        title: null,
        text: 'Необходимо ответить на вопрос перед отправкой',
        icon: <ErrorOutlineIcon style={{ fill: '#f6c043', width: '80px', height: '80px' }} />,
        buttonText: 'ОК',
      },
      error: {
        title: 'Неправильно',
        text: attempts < 3 ? 'Попробуйте еще раз!' : errorTextOnAttemptsOver,
        icon: <HighlightOffIcon style={{ fill: '#ff3333', width: '80px', height: '80px' }} />,
        buttonText: attempts < 3 ? 'Повторить попытку' : 'Продолжить',
      },
      correct: {
        title: 'Правильно!',
        text: correctText,
        icon: (
          <CheckCircleOutlineRoundedIcon
            style={{ fill: '#008000', width: '80px', height: '80px' }}
          />
        ),
        buttonText: 'Продолжить',
      },
    };

    return (
      <Flex
        width={['100%', '400px']}
        he1ight="280px"
        p="8px"
        flexDirection="column"
        alignItems="center"
      >
        <Box>{modalContent[status].icon}</Box>
        <Text mt="24px" textAlign="center" color="#666666" fontSize="30px" fontWeight="700">
          {modalContent[status].title}
        </Text>
        {text ? (
          <Text my="40px" textAlign="center" lineHeight="22px" color="#666666">
            {modalContent[status].text}
          </Text>
        ) : (
          <Box mt="30px" />
        )}
        <Box wi1dth="80px">
          <Button title={modalContent[status].buttonText} onClick={nextButtonHandler} />
        </Box>
      </Flex>
    );
  };

  const renderLeft = () => {
    return (
      <Box>
        <TextTitle>{title}</TextTitle>
        {text}
      </Box>
    );
  };
  const renderRight = () => {
    return (
      <Flex flexDirection="column" justifyContent="center" width="100%">
        <Box
          mx={[16, 24, 24, 0]}
          p={[24, 24, 64]}
          bg="#fff"
          sx={{
            boxShadow: '0 0 7px rgb(0 0 0 / 17%)',
            maxWidth: '640px',
          }}
        >
          {questions.map(({ id, title }) => (
            <Box mb="16px" key={`${moduleId}${checkId}${id}`}>
              <Checkbox
                checked={checkedAnswers.includes(id)}
                label={title}
                onChange={(state) => handleCheckAnswer(id, state)}
              />
            </Box>
          ))}

          <Button title="Проверить" margin="40px 0 0 0" onClick={check} />
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Layout
        pagetTitle={`Модуль ${module.id}. Тест`}
        leftContent={renderLeft()}
        rightContent={renderRight()}
        hideNextButton
      />
      <Modal
        isOpen={modalOpened}
        onClose={() => setModalVisibility(false)}
        body={renderModalBody()}
        header="Ошибка"
      />
    </>
  );
};

export default memo(withRouter(Test));
