import API from '../../../helpers/API';

// eslint-disable-next-line consistent-return
export const fetchNews = async (params) => {
  try {
    const { data } = await API.get('news', {
      params,
    });
    return data;
  } catch (e) {
    console.error('@@@e', e);
  }
};

// eslint-disable-next-line consistent-return
export const fetchPublishedNews = async (params) => {
  const { id } = params;
  try {
    const { data } = await API.get(`news/${id}`, {
      params,
    });
    return data;
  } catch (e) {
    console.error('@@@e', e);
  }
};

export const fetchMonthList = async (year,accessType) => {
  try {
    const { data } = await API.get('news/months', { params: { year ,accessType} });
    return data;
  } catch (e) {
    console.error('@@@e', e);
  }
};

export const fetchYearsList = async (accessType) => {
  try {
    const { data } = await API.get('news/years',{params:{accessType}});
    return data;
  } catch (e) {
    console.error('@@@e', e);
  }
};
