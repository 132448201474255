import {useEffect, useState} from "react";

export const useChecked = (permissions) => {
  const [listPermissions, setListPermissions] = useState(permissions || [])

  useEffect(() => {
    if (permissions) {
      setListPermissions(permissions)
    }
  }, [permissions])

  const checked = (name) => {
    return listPermissions.some((item) => item === name)
  }

  const onChecked = (name) => {
    if (listPermissions.some((item) => item === name)) {

      setListPermissions((prevState) => {
        if (prevState.filter((item) => item === name)){
          return  prevState.filter((elem) => elem !== name)
        }
      })

    } else {
      setListPermissions((prevState) => ([
        ...prevState,
        name
      ]))
    }
  }

  return {checked, onChecked,listPermissions}
}
