import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {isEmpty} from "ramda";

import {VALIDATION} from "../../../../../store/statusConstants";
import {errorMsg, fieldNames, initialState} from "../constants";

export const folderRules = Yup.object({
  [fieldNames.title]: Yup.string().required(errorMsg.required('Вопрос')),
  [fieldNames.status]: Yup.string().required(errorMsg.required('Статус')),
  [fieldNames.access]: Yup.string().required(errorMsg.required('Доступ')),
  [fieldNames.text]: Yup.string().required(errorMsg.required('Текст ответа')),

});

export const useFormValue = (defValue, handleEditFaqs, handleAddedFaqs, handleAddFile, setIsModalOpen) => {
  const {id} = useParams();

  const {
    control,
    handleSubmit,
    formState: {isValid, isDirty, errors},
    getValues,
    setValue,
    reset,
    clearErrors
  } = useForm({
    mode: 'onSubmit',
    defaultValues: initialState,
    resolver: yupResolver(folderRules),
  });

  const errorsList = Object.values(errors)?.map((item) => item?.message)

  const onSubmit = handleSubmit((values) => {
    if (id) {
      handleEditFaqs(values, handleAddFile);
    } else {
      handleAddedFaqs(values);
    }
  });

  useEffect(() => {
    reset(defValue);
  }, [defValue]);

  useEffect(() => {
    if (!isEmpty(errorsList)) {
      setIsModalOpen(true)
    }
  }, [errorsList])

  const validationStatus = !isEmpty(errorsList) && VALIDATION

  return {control, isValid, isDirty, getValues, setValue, onSubmit, errorsList, validationStatus, clearErrors};
}
