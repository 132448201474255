import React, {memo, useState} from 'react';
import {Flex, Text, Box, Image} from 'rebass';
import {useParams} from 'react-router-dom';
import {find, concat} from 'lodash';
import Button from './Button';
import Layout from './Layout';
import TextTitle from './TextTitle';
import data from '../data';
import images from '../img';

const questions = [
  {
    id: 1,
    img: 'm2check1',
  },
  {
    id: 2,
    img: 'm2check2',
  },
  {
    id: 3,
    img: 'm2check3',
  },
  {
    id: 4,
    img: 'm2check4',
  },
];

const Testimages = () => {
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  const [checked, setChecked] = useState(false);

  const {moduleId, checkId} = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const {title, text, nextLink} = find(module.check, ['id', +checkId || 1]);

  const select = (id) => {
    const nextItems = !checkedAnswers.includes(id)
      ? concat(checkedAnswers, id)
      : checkedAnswers.filter((itemId) => itemId !== id);

    setCheckedAnswers(nextItems);
  };

  const renderLeft = () => {
    return (
      <Box>
        <TextTitle>{title}</TextTitle>
        {text}
      </Box>
    );
  };
  const renderRight = () => {
    return (
      <Flex flexDirection="column" justifyContent={checked ? 'center' : 'center'} width="100%">
        {!checked ? (
          <Flex p={[16, 16, '64px']} flexWrap="wrap" maxWidth="900px">
            {questions.map(({id, img}) => (
              <Box mb="16px" mr="16px" key={id} onClick={() => select(id)}>
                <Image
                  src={images[img]}
                  alt=""
                  sx={{
                    border: '4px solid',
                    borderColor: checkedAnswers.includes(id) ? 'blue' : 'transparent',
                    background: checkedAnswers.includes(id) ? 'blue' : 'transparent',
                    cursor: 'pointer',
                    '@media screen and (max-width: 1300px) and (max-height: 900px)': {
                      height: '200px',
                      width: '200px'
                    },
                    '@media screen and (max-width: 1440px) and (max-height: 900px)': {
                      height: '240px',
                      width: '240px'
                    },
                    '@media screen and (max-width: 1152px) and (max-height: 864px)': {
                      height: '170px',
                      width: '170px'
                    },
                    '@media screen and (max-width: 1200px) and (max-height: 800px)': {
                      height: '200px',
                      width: '200px'
                    },
                    '@media screen and (max-width: 1300px) and (max-height: 600px)': {
                      height: '150px',
                      width: '150px'
                    },
                  }}
                />
              </Box>
            ))}
            <Box width="100%">
              <Button title="Проверить" margin="40px 0 0 0" onClick={() => setChecked(true)}/>
            </Box>
          </Flex>
        ) : (
          <Box
            maxWidth="640px"
            m={[16, 24, 40]}
            pt={[40, 40, 160]}
            pb={[40, 40, 160]}
            px={[40, 40, 80]}
            bg="#fff"
            sx={{boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
          >
            <Text fontSize="20px" color="#6d12f5" lineHeight="30px">
              Не всё так просто :))
            </Text>
            <Text fontSize="20px" color="#6d12f5" lineHeight="30px">
              Переходите к следующему
            </Text>
            <Text fontSize="20px" color="#6d12f5" lineHeight="30px">
              слайду — он вас удивит.
            </Text>
          </Box>
        )}
      </Flex>
    );
  };

  return (
    <Layout
      pagetTitle={`Модуль ${module.id}. Тест`}
      leftContent={renderLeft()}
      rightContent={renderRight()}
      hideNextButton={!checked}
      nextLink={nextLink}
    />
  );
};

export default memo(Testimages);
