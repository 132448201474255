import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {isEmpty} from "ramda";

import {VALIDATION} from "../../../../../store/statusConstants";
import {folderRules, initialValues} from "../constants";
import {editProfile} from "../requests";
import {useAsyncFn} from "react-use";

export const useFormValue = (profile,openModal,getUserData,listPermissions) => {
  const {
    control,
    formState: {isValid, isDirty},
    getValues,
    setValue,
    clearErrors,
    reset,
    handleSubmit
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(folderRules),
  });
  const [{error,loading}, editUser] = useAsyncFn(editProfile)
  const errorsList = error ? ['При обработке запроса произошла ошибка'] : ''
  const validationStatus = !isEmpty(errorsList) && VALIDATION

  const onSubmit = handleSubmit(async (values) => {
    openModal()
    await editUser(values,values.id,listPermissions)
    await getUserData()
  });

  useEffect(() => {
    reset(profile);
  }, [profile]);

  useEffect(()=>{
    if(error){
      openModal()
    }
  },[error])

  return {
    control,
    isValid,
    isDirty,
    getValues,
    setValue,
    onSubmit,
    errorsList,
    validationStatus,
    clearErrors,
    loading
  };
}
