import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  padding: 50px 40px 40px 40px;
  flex-direction: column;
`

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ccc;
`
