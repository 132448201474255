import { useAsyncFn } from 'react-use';

import { addedNews } from '../requests';
import { useStatuses } from '../../../../../hooks/useStatuses';
import { NEWS_TYPE } from '../../constants';

export const useAddedNews = (setIsModalOpen, handleAddFile) => {
  const [{ loading, value, error }, addNews] = useAsyncFn(addedNews);

  const handleAddedNews = async (data) => {
    const resp = await handleAddFile();
    if (data?.type === NEWS_TYPE.FILE) {
      if (resp?.id) {
        await addNews(data, resp?.id);
      }
    } else {
      await addNews(data);
    }
    setIsModalOpen(true);
  };

  const { status } = useStatuses(loading, value, error);

  const addStatus = status;
  return { handleAddedNews, addStatus };
};
