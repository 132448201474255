import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import Feedback from './Feedback';
import Violation from './Violation';
import ViolationStatus from './ViolationStatus';
import Question from './Question';

const FeedbackRouter = () => {
  return (
    <Switch>
      <Route exact path="/feedback/violation-status" component={ViolationStatus} />
      <Route exact path="/feedback/violation" component={Violation} />
      <Route exact path="/feedback/question" component={Question} />
      <Route exact path="/feedback" component={Feedback} />
    </Switch>
  );
};

export default memo(FeedbackRouter);
