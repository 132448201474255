import {useEffect, useState} from 'react';
import {useAsyncFn} from 'react-use';

import {fetchEditNews, fetchNewsById} from '../requests';
import {useRouter} from '../../../../../hooks';
import {NEWS_TYPE} from '../../constants';
import {useStatuses} from '../../../../../hooks/useStatuses';
import {initialState} from '../constants'

export const useEditNews = (setIsModalOpen) => {
  const [{value}, getNews] = useAsyncFn(fetchNewsById);
  const [state, editNews] = useAsyncFn(fetchEditNews);
  const {
    query: {id},
  } = useRouter();
  const [news, setNews] = useState(initialState);

  const handleEditNews = async (data, type, handleAddFie) => {
    if (type === NEWS_TYPE.FILE) {
      const resp = await handleAddFie();
      if (resp?.id || resp) {
        await editNews(id, data, resp?.id);
      }
    } else {
      await editNews(id, data);
    }
    setIsModalOpen(true);
  };

  const {status} = useStatuses(state?.loading, state?.value, state?.error);

  useEffect(() => {
    if (id) {
      getNews(id);
    }
  }, [id]);

  useEffect(() => {
    if (value) {
      const newsType = value?.file ? NEWS_TYPE.FILE : NEWS_TYPE.TEXT
      setNews({
        title: value?.title,
        status: value?.publishStatus,
        date: value?.publicationDate,
        access: value?.accessType,
        type: value?.newsType || newsType,
        file: {path: value?.file?.originalFilename, id: value?.file?.id},
        preview: value?.previewText,
        text: value?.content,
        fileId: value?.file?.id,
      })
    }
  }, [value]);

  return {news, editNews, handleEditNews, editStatus: status};
};
