import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;

  .text {
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .title {
    font-weight: 500
  }

  .appeal {
    margin-bottom: 20px;
  }

  .anonymous {
    color: red;
    font-weight: 500;
    margin-bottom: 10px;
  }
`

export const Comment = styled.div`
  .comment {
    color: #90949B;
    margin-bottom: 20px;
  }
`

export const User = styled.div`

`

export const Header = styled.h1`
  font-size: 24px;
  color: #333;
  font-weight: 500;
  border-bottom: 2px solid #ccc;
  padding: 0 0 24px 0;
`
export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
