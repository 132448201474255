import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ARTICLE_STATUSES, ACCESS } from '../../modules/Admin/components/constants';

export const fetchAccounts = createAsyncThunk('Accounts/fetchAccounts', async () => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  // пример запроса!
  return mokeAccounts;
});

export const createAccount = createAsyncThunk('account/createAccount', async (data) => {
  console.log(data);
});

export const deleteAccountById = createAsyncThunk('account/deleteAccountById', async (id) => {
  console.log(id);
  return mokeAccounts.filter((item) => item.id !== id);
});

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    listOfAccounts: [],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: '',
    },
    status: IDLE,
    sort: {
      loginSort: null,
      nameSort: null,
      emailSort: null,
      positionSort: null,
    },
    pageNum: 10,
  },
  reducers: {
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
  },
  extraReducers: {
    [fetchAccounts.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchAccounts.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfAccounts = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchAccounts.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createAccount.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createAccount.fulfilled]: (state, action) => {},
    [createAccount.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteAccountById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteAccountById.fulfilled]: (state, action) => {
      state.listOfAccounts = action.payload;
      state.status = FULFILLED;
    },
    [deleteAccountById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setSort, setPagination } = accountSlice.actions;

export default accountSlice.reducer;

const mokeAccounts = [
  {
    id: 1,
    login: 'admin',
    name: 'Администратор',
    email: 'Aleksandr.Gontar@rt.ru',
    position: 'Главный специалист',
  },
  { id: 2, login: 'feedback', name: 'ПАО Ростелеком', email: '-', position: '-' },
];
