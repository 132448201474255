import styled from "styled-components";

export const Comment = styled.div`
  background: rgba(255, 205, 170, .1);
  border-left: 2px solid;
  border-color: ${props => props.isTorned ? "#ff6a00" : "#e1baf7"};
  background: ${props => props.isBackground ? "rgba(255, 205, 170, .1)" : "rgba(98, 9, 171, .1)"};
  margin: 10px 0;
  padding: 10px;

  .date {
    font-size: 12px;
  }

  .status {
    color: #959595;
  }

  .text {
    background: white;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 4px 0;
    font-weight: 100;
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

`
