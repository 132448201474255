/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { merge, values } from 'lodash';
import { LOADING, FULFILLED, ERROR, IDLE } from '../statusConstants';
import CR from '../../repositories/courseRepository';

export const registration = createAsyncThunk(
  'Course/registration',
  async (formValues, { rejectWithValue }) => {
    const data = { ...formValues };
    try {
      const resp = await CR.registration(data);
      return resp;
    } catch (error) {
      return rejectWithValue(error?.message || '');
    }
  },
);

export const saveAnswers = createAsyncThunk(
  'Course/saveAnswers',
  async (_, { rejectWithValue, getState }) => {
    const { correctAnswers, token } = getState().course;

    const correctCount = values(correctAnswers).reduce(
      (acc, item) => (acc += values(item).reduce((sum, el) => (sum += el), 0)),
      0,
    );

    const data = {
      correctCount,
    };

    try {
      const resp = await CR.saveAnswers(data, token);
      if (!resp.ok) {
        throw new Error();
      }
      const result = await resp.json();

      return result;
    } catch (error) {
      return rejectWithValue(error?.message || '');
    }
  },
);

const CourseSlice = createSlice({
  name: 'course',
  initialState: {
    status: IDLE,
    token: '',
    correctAnswers: {
      /**
       * [moduleId] : {
       *      [checkId]: correctAnswersCount
       * }
       */
    },
  },
  reducers: {
    resetStatus: (state) => {
      state.status = IDLE;
    },
    setCorrectAnswers: (state, { payload }) => {
      state.correctAnswers[payload.moduleId] = merge(state.correctAnswers[payload.moduleId], {
        [payload.checkId]: payload.correctCount,
      });
    },
  },
  extraReducers: {
    [registration.pending]: (state) => {
      state.correctAnswers = {};
      state.token = '';
      state.status = LOADING;
    },
    [registration.fulfilled]: (state, { payload }) => {
      state.correctAnswers = {};
      state.token = payload.data;
      state.status = FULFILLED;
    },
    [registration.rejected]: (state) => {
      state.correctAnswers = {};
      state.token = '';
      state.status = ERROR;
    },
    [saveAnswers.pending]: (state) => {
      state.status = LOADING;
    },
    [saveAnswers.fulfilled]: (state) => {
      state.status = FULFILLED;
    },
    [saveAnswers.rejected]: (state) => {
      state.status = ERROR;
    },
  },
});

export const { resetStatus, setCorrectAnswers } = CourseSlice.actions;

export default CourseSlice.reducer;
