import {getNormalizeDate} from "../../../../utils";

export const getNormalizedEducation = (education) => {
  return {
    access: education?.accessType,
    date: getNormalizeDate(education?.publicationDate),
    id: education?.id,
    title: education?.title,
    status: education?.publishStatus,
    type: ''
  }
}
