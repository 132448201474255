import React from 'react';
import styled from 'styled-components';
import { Text, Flex, Box } from 'rebass';
import TextContent from '../components/TextContent';
import CustomLi from '../components/CustomLi';

const CustomText = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
`;

const slides = [
  {
    id: 1,
    img: 'm4t3slide1',
  },
  {
    id: 2,
    img: 'm4t3slide2',
  },
  {
    id: 3,
    img: 'm4t3slide3',
  },
  {
    id: 4,
    img: 'm4t3slide4',
  },
  {
    id: 5,
    img: 'm4t3slide5',
  },
];

const module4 = {
  id: 4,
  title: 'Модуль 4. По секрету всему свету',
  home: [
    {
      id: 1,
      title: 'Конфиденциальная и инсайдерская информация',
      text: 'Модуль 4. По секрету всему свету',
      bg: 'm4home1',
      nextLinkTitle: 'Четвертый модуль',
      nextLink: '/course/module/4/intro',
    },
  ],
  intro: [
    {
      id: 1,
      title: 'Только между нами',
      text: [
        'Секрет остаётся секретом только тогда, когда о нём никто не знает. Иногда чужие раскрытые тайны сильно влияют на жизни людей. В компаниях тоже есть информация, которую не всем можно знать.',
        'Мы называем её конфиденциальной и инсайдерской. Важно понимать, что это такое, чтобы случайно не разболтать секретов тем, кому о них знать не положено. Сейчас обо всём расскажем.',
      ],
      bg: 'm4intro1',
      nextLink: '/course/module/4/theory/1',
    },
  ],
  check: [
    {
      id: 1,
      title: 'Проверьте себя',
      text: (
        <>
          <TextContent>
            Срок действия вашего договора с ПАО «Ростелеком» истёк. За время работы вы собрали
            большую клиентскую базу и планируете использовать её для развития собственного бизнеса.
            Какие последствия могут быть?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'Никаких, мы же сами наработали базу',
        },
        {
          id: 2,
          title:
            '«Ростелеком» может обратиться в суд, поскольку прописанные в договоре правила использования конфиденциальной информации нарушены',
        },
        {
          id: 3,
          title:
            '«Ростелеком» может сделать нам замечание, но договор закончился, и компания ничего не сможет нам сделать',
        },
      ],
      correctAnswers: [2],
      correctText: 'Все правильно.',
      errorTextOnAttemptsOver: 'Правильные ответы: 2',
      nextLink: '/course/module/4/conclusion',
    },
  ],
  theory: [
    {
      id: 1,
      flex: 0.7,
      title: 'Что такое конфиденциальная и инсайдерская информация?',
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center" padding={[16, 16, 16, 0]}>
          <Box
            p="40px"
            maxWidth="500px"
            sx={{ background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)' }}
          >
            <Box mb="16px" pb="16px" sx={{ borderBottom: '1px solid #e0e0e0' }}>
              <Text fontSize="22px" mb="20px">
                Конфиденциальная информация
              </Text>
              <CustomText>
                та, которая имеет действительную или потенциальную коммерческую ценность именно
                из-за того, что неизвестна третьим лицам
              </CustomText>
            </Box>
            <CustomText>
              <Text fontSize="22px" mb="20px">
                Например:
              </Text>
              <ul>
                <CustomLi>данные о переговорах и условиях договоров</CustomLi>
                <CustomLi>стоимость услуг, в том числе в контрактах</CustomLi>
                <CustomLi>сведения о кадровом составе, штатном расписании</CustomLi>
                <CustomLi>данные об уровне заработной платы сотрудников</CustomLi>
                <CustomLi>персональные данные работников компании и контрагентов</CustomLi>
              </ul>
            </CustomText>
          </Box>
        </Flex>
      ),
      bg: 'm4t1',
      nextLink: '/course/module/4/theory/2',
    },
    {
      id: 2,
      flex: 0.7,
      title: 'Что такое конфиденциальная и инсайдерская информация?',
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center" padding={[16, 16, 16, 0]}>
          <Box
            p="40px"
            maxWidth="500px"
            sx={{ background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)' }}
          >
            <Box mb="16px" pb="16px" sx={{ borderBottom: '1px solid #e0e0e0' }}>
              <Text fontSize="22px" mb="20px">
                Инсайдерская информация
              </Text>
              <CustomText>
                конкретная конфиденциальная информация, распространение или предоставление которой
                может оказать существенное влияние на рыночную стоимость ценных бумаг или
                производных от них финансовых инструментов
              </CustomText>
            </Box>
            <CustomText>
              <Text fontSize="22px" mb="20px">
                Например:
              </Text>
              <ul>
                <CustomLi>сведения о предстоящем слиянии или поглощении</CustomLi>
                <CustomLi>финансовые данные о будущих крупных сделках</CustomLi>
                <CustomLi>годовой доход компании до его официального обнародования</CustomLi>
                <CustomLi>рецепт «Кока-колы», хранящийся в строжайшей тайне</CustomLi>
                <CustomLi>
                  данные о предстоящем изменении во внутреннем устройстве компании
                </CustomLi>
              </ul>
            </CustomText>
          </Box>
        </Flex>
      ),
      bg: 'm4t2',
      nextLink: '/course/module/4/theory/3',
    },
    {
      id: 3,
      title: 'Есть 5 основных правил при работе с такой информацией',
      isSlider: true,
      slides,
      nextLink: '/course/module/4/case',
    },
  ],
  case: [
    {
      id: 1,
      title: 'Кейс: а сколько ты зарабатываешь?',
      text: 'Работник подрядчика скопировал на флешку данные о зарплате сотрудников компании. Он не входил в список лиц, которые имели право обрабатывать персональные данные, поэтому компания подала в суд на подрядчика.',
      bg: 'm4case1',
      nextLink: '/course/module/4/check',
    },
  ],
  conclusion: [
    {
      id: 1,
      title: 'Хранить секреты — важно',
      text: [
        'Мы в «Ростелекоме» стараемся бережно относиться к конфиден-циальной и инсайдерской информации наших клиентов и партнёров. И нам важно, чтобы те, с кем мы делим наши секреты, были надёжными соратниками.',
        'Так что если вдруг сомневаетесь, относится та или иная информация к секретной, обратитесь к договору или сотруднику «Ростелекома».',
      ],
      bg: 'm4conclusion',
      nextLink: '/course/module/5',
    },
  ],
};

export default module4;
