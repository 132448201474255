import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function BasicTextFields(props) {
  return (
    <TextField
      {...props}
      value={props.value}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
}
