import { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';

import { fetchAddFile } from '../request';

export const useFile = (defaultFile) => {
  const [file, setFile] = useState([]);
  const [isFileLoading,setIsFileLoading] = useState(false)

  const handleAddFile = async () => {
    if (!isEmpty(file)) {
      if (file?.[0]?.type) {
        setIsFileLoading(true)
        const response = await fetchAddFile(file?.[0]);
        setIsFileLoading(false)
        return response;
      }
      return {};
    }
    return null;
  };

  useEffect(() => {
    if (defaultFile?.id) {
      setFile([defaultFile]);
    }
  }, [defaultFile]);

  return { file, setFile, handleAddFile ,isFileLoading};
};
