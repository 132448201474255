import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: inline;
  margin: ${({ m = 0 }) => m};
  text-decoration: ${({ underline = false }) => (underline ? 'underline' : 'none')};
  color: ${({ color = '#4897df' }) => color};
  &:visited,
  &:active {
    color: #4897df;
  }
`;

const CustomLink = (props) => {
  return <StyledLink {...props} />;
};

export default CustomLink;
