import {useState} from 'react';
import {saveParamsToUrl} from "../../../utils/saveParamsUrl";

export const useFilters = (initValues,initialFilters) => {
  const init = Object.values(initValues).every((item) => item === null) ? initialFilters : initValues
  const [filter, setFilters] = useState(init);

  const handleChangeFilters = (name, value) => {
    setFilters({...filter, [name]: value});
  };

  const resetFilters = () => {
    setFilters(initialFilters);
    saveParamsToUrl(initialFilters)
  };

  return {filter, handleChangeFilters, resetFilters};
};
