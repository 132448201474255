import React, { useState } from 'react';
import { Flex, Text, Box } from 'rebass';

import { Modal } from 'molecules';
import { useRouteMatch } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Table from './Table';
import Filter from './Filter';
import Button from './Button';
import { IDLE, LOADING, FULFILLED, EMPTY, ERROR } from '../../../store/statusConstants';

const Header = ({ title, actions }) => {
  const { url } = useRouteMatch();
  return (
    <Flex
      p="40px 0 24px 0"
      mb="40px"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderBottom: '2px solid #ccc' }}
    >
      <Text fontSize="24px" color="#333" fontWeight="500">
        {title}
      </Text>
      <Box>
        {actions.map(({ link, label, disabled }) => (
          <Button
            key={`edit${label}`}
            mr="16px"
            link={`${url}/${link}`}
            variant="contained"
            label={label}
            disabled={disabled}
          />
        ))}
      </Box>
    </Flex>
  );
};

const DataLayout = ({
  title,
  actions = [],
  headCells,
  rows,
  filterItems = [],
  accessFilter,
  status,
  handleRequestSort,
  pagination,
  setPagination,
  deleteElement,
  resetFilters,
  allElements,
  currentPage,
  setPage,
                      isDelete
}) => {
  const [itemToDelete, selectItemToDelete] = useState(null);

  const handleDelete = () => {
    selectItemToDelete(null);
    deleteElement(itemToDelete);
  };

  const contentMaper = {
    [IDLE]: () => <Flex />,
    [LOADING]: () => (
      <Flex width="100%" justifyContent="center" pt="23vh">
        <CircularProgress />
      </Flex>
    ),
    [FULFILLED]: () => (
      <Flex>
        <Table
          initPage={currentPage}
          headCells={headCells}
          rows={rows}
          selectItemToDelete={selectItemToDelete}
          handleRequestSort={handleRequestSort}
          pagination={pagination}
          setPagination={setPagination}
          deleteElement={deleteElement}
          allElements={allElements}
          page={currentPage}
          setPage={setPage}
          isDelete={isDelete}
        />
      </Flex>
    ),
    [EMPTY]: () => (
      <Flex width="100%" justifyContent="center" pt="23vh">
        По вашему запросу ничего не найдено.
      </Flex>
    ),
    [ERROR]: () => (
      <Flex width="100%" justifyContent="center" pt="23vh">
        Извините, произошла внутренняя ошибка сервера.
      </Flex>
    ),
  };

  const renderModalContent = () => {
    return (
      <Box p="24px">
        <Text fontSize="24px" mb="24px">
          Вы действительно хотите удалить запись?
        </Text>
        <Flex justifyContent="center">
          <Button
            variant="contained"
            label="Отмена"
            margin="0 16px 0 0"
            onClick={() => selectItemToDelete(null)}
            sx={{ marginRight: '16px' }}
          />
          <Button variant="outlined" color="error" label="Удалить" onClick={handleDelete} />
        </Flex>
      </Box>
    );
  };

  return (
    <Flex minHeight="calc(100vh - 60px)" flexDirection="column" p="0 40px 40px 40px" bg="#f9f9f9">
      <Header title={title} actions={actions} />
      {filterItems.length ? (
        <Filter items={filterItems} accessFilter={accessFilter} resetFilters={resetFilters} />
      ) : null}
      {contentMaper[status]?.()}
      <Modal
        isOpen={itemToDelete}
        onClose={() => selectItemToDelete(null)}
        body={renderModalContent()}
      />
    </Flex>
  );
};

export default DataLayout;
