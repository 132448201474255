import styled from "styled-components";

export const Content = styled.div`
  width: 1300px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`
