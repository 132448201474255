import React, { memo } from 'react';
import { Flex, Box } from 'rebass';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import LayoutIntro from './LayoutIntro';
import TextTitle from './TextTitle';
import TextContent from './TextContent';
import data from '../data';
import images from '../img';

const ModuleIntro = () => {
  const { moduleId, introId } = useParams();

  const module = find(data.modules, ['id', +moduleId]);
  const { title, text, bg, nextLink } = find(module.intro, ['id', +introId || 1]);

  const renderLeft = () => {
    return (
      <Box>
        <TextTitle>{title}</TextTitle>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} pr="20px">
            <TextContent>{text[0]}</TextContent>
          </Box>
          <Box width={[1, 1, 1 / 2]}>
            <TextContent>{text[1]}</TextContent>
          </Box>
        </Flex>
      </Box>
    );
  };

  return (
    <LayoutIntro
      pagetTitle={module.title}
      leftContent={renderLeft()}
      rightContent={null}
      bgImage={images[bg]}
      nextLink={nextLink}
    />
  );
};

export default memo(ModuleIntro);
