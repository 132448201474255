import module1 from './module1';
import module2 from './module2';
import module3 from './module3';
import module4 from './module4';
import module5 from './module5';
import module6 from './module6';

const modules = [module1, module2, module3, module4, module5, module6];

const data = { modules };

export default data;
