import React, { useRef, memo } from 'react';
import JoditEditor from 'jodit-react';

const Editor = ({ value, onBlur }) => {
  const editor = useRef(null);

  const handleBlur = (newContent) => {
    onBlur(newContent);
  };

  const tabIndex = 1;
  const config = {
    height: '400px',
    readonly: false,
    toolbarButtonSize: 'small',
    // buttons: [
    //   'bold',
    //   'italic',
    //   '|',
    //   'left',
    //   'center',
    //   'right',
    //   'justify',
    //   '|',
    //   'ul',
    //   'ol',
    //   '|',
    //   'link',
    //   '|',
    //   'image',
    // ],
  };

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      tabIndex={tabIndex}
      onBlur={handleBlur}
    />
  );
};

export default memo(Editor);
