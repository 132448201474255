import React, { memo } from 'react';
import styled from 'styled-components';
import { Text } from 'rebass';

const Wrapper = styled.div`
  textarea {
    border: 1px solid #d9d9d9;
    padding: 5px;
    resize: none;
    outline: none;
    width: 100%;
    height: 120px;
    &:focus {
      border-color: #7800ff;
    }
  }
`;

const Textarea = ({ label, type, required, onChange }) => (
  <Wrapper>
    <Text mb={20} color="#90949B">
      Текст сообщения
      {required ? (
        <Text display="inline" color="#7800FF">
          *
        </Text>
      ) : null}
    </Text>
    <textarea color={type === 'primary' ? '#fff' : '#7800ff'} onChange={(e) => onChange(e)}>
      {label}
    </textarea>
  </Wrapper>
);
export default memo(Textarea);
