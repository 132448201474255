import {ACCESS, ARTICLE_STATUSES, MATERIAL_TYPES, NEWS_TYPE} from '../constants';
import * as Yup from "yup";

export const initialFilters = {
  status: ARTICLE_STATUSES.ALL,
  date: [null, null],
  access: ACCESS.ALL,
  type: MATERIAL_TYPES.VIDEO,
};

export const fieldNames = {
  title: 'title',
  status: 'status',
  access: 'access',
  text: 'text',
  file: 'file',
  link: 'link',
  date: 'date'
};

export const initialState = {
  title: '',
  status: '',
  date: new Date(),
  access: '',
  type: '',
  file: null,
  preview: '',
  text: '',
  link: ''
};

export const errorMsg = {
  requiredFile: 'Загрузите файл документа',
  required: (field) => `Заполните поле "${field}"`
};

export const folderRules = Yup.object({
  [fieldNames.title]: Yup.string().required(errorMsg.required('Заголовок')),
  [fieldNames.status]: Yup.string().required(errorMsg.required('Статус')),
  [fieldNames.access]: Yup.string().required(errorMsg.required('Доступ')),
  [fieldNames.date]: Yup.string().required(errorMsg.required('Дата публикации')),
});

export const acceptFormatVideo = {
  'video/mp4': ['.mp4'],
  'video/x-flv': ['.flv'],
  'video/webm': ['.webm'],
  'rtmp/mp4': ['.rtmp']
};

export const acceptFormatArticle = {
  'image/gif': ['.gif'],
  'image/jpeg ': ['.jpg'],
  'image/png': ['.png'],
};

const filterNames = {
  status: 'status',
  date: 'date',
  access:'access',
  type: 'type'
};

export const filterList = Object.values(filterNames);
