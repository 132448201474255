import React, { memo } from 'react';
import { Box } from 'rebass';
import NewsPreviews from 'modules/News/components/NewsPreviews/NewsPreviews';
import Banner from './Banner/Banner';
import InfoBlock from './InfoBlock/InfoBlock';
import {useNews} from "../hooks/useNews";

const infoBlocks = [
  {
    id: 1,
    title: 'Честный бизнес — единые правила!',
    text: 'В своей работе Ростелеком следует принципам честности, открытости и добросовестности. Только так мы можем сохранить безупречную репутацию, оставаться лидером рынка и гарантировать финансовую и социальную защищенность каждому, с кем взаимодействуем. Сайт дает полную информацию об антикоррупционной деятельности компании и подробно рассказывает о тех принципах, которыми должен руководствоваться каждый сотрудник.',
    imgSrc: 'img1',
    imageFirst: true,
  },
  {
    id: 2,
    title: 'Под защитой честной компании',
    text: 'Департамент защиты активов (ДЗА) создан в целях реализации мероприятий, направленных на повышение уровня корпоративной культуры сотрудников компании по вопросам противодействия и профилактики коррупции, соблюдения этических норм и защите собственности для улучшения имиджа/репутации и инвестиционной привлекательности компании. Это ваша защита от недобросовестных действий сотрудников или третьих лиц в части коррупционных и иных правонарушений.',
    imgSrc: 'img2',
    imageFirst: false,
  },
  {
    id: 3,
    title: 'Защити репутацию компании — сохрани свои деньги',
    text: 'Соблюдать законодательство и Этический кодекс компании необходимо каждому: так мы защищаем наши общие интересы и сохраняем наши общие деньги. Поэтому нам важно знать о несоответствующем поведении, чтобы вовремя принять меры. Горячая линия позволяет сообщить о нарушениях анонимно и круглосуточно.',
    imgSrc: 'img3',
    imageFirst: true,
  },
  {
    id: 4,
    title: 'Работаем по правилам честного общества',
    text: `В Ростелеком действуют единые нормы и правила поведения для всех, кто так или иначе взаимодействует с компанией. Они безусловно и неукоснительно соблюдаются каждым.
    Непримиримость к любым проявлениям коррупции.
    Решение задач только в рамках закона.
    Высокие стандарты деловой этики.
    Надёжность в работе и уважение по отношению к другим.
    Сохранение коммерческих тайн компании.


    Безупречная репутация позволяет компании оставаться лидером на рынке телекоммуникационных услуг. От этого напрямую зависит стабильное финансовое положение: достойные зарплаты, карьерный рост и социальная защищенность. Хочешь работать в сильной, преуспевающей компании? Веди себя добросовестно. Репутация компании зависит от действий каждого сотрудника.

    Есть ситуация, в которой сомневаешься – обратись к коллегам из ДЗА или на «Линию Доверия» 8-800-1-811-811.`,
    imgSrc: null,
    imageFirst: false,
  },
];

const Home = () => {
  const {newsList} = useNews()

  return (
    <>
      <Banner />
      <Box p="54px 24px" width="100%" maxWidth={['100%', '100%', '100%', 1300]}>
        <InfoBlock item={infoBlocks[0]} />
        <InfoBlock item={infoBlocks[1]} />
        <NewsPreviews listNews={newsList}/>
        <InfoBlock item={infoBlocks[2]} />
        <InfoBlock item={infoBlocks[3]} />
      </Box>
    </>
  );
};

export default memo(Home);
