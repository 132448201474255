import React from 'react';
import { Box } from 'rebass';
import _Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/styles';

const CssSelect = withStyles({
  root: {
    '& label, & input': { fontFamily: 'BasisGrotesquePro,sans-serif' },
    '&': {
      borderRadius: 0,
    },
    '& fieldset': {
      borderColor: 'rgba(0,0,0,0.38)',
    },
    '&:hover fieldset': {
      borderColor: '#7800FF !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#7800FF !important',
    },
    '& .MuiMenuItem-root': {
      content: 'none',
    },
  },
})(_Select);

const CssInputLabel = withStyles({
  root: {
    '&': {
      background: 'white',
      padding: '2px',
    },
    '& span': { color: '#7800FF' },
    '&.Mui-focused': {
      color: '#90949B',
    },
    '&.Mui-error': {
      color: '#90949B',
    },
  },
})(InputLabel);

const CssMenuItem = withStyles({
  root: {
    '&::before': {
      display: 'none',
    },
  },
})(MenuItem);

const CssFormHelperText = withStyles({
  root: {
    '&': {
      color: '#d32f2f',
    },
  },
})(FormHelperText);

const Select = ({ mb, options, label, required, helperText, ...props }) => {
  return (
    <Box mb={mb}>
      <FormControl fullWidth>
        <CssInputLabel>
          {label}
          {required ? <span> *</span> : ''}
        </CssInputLabel>
        <CssSelect id="standard-basic" fullWidth autoComplete="off" {...props}>
          {options?.map((option) => (
            <CssMenuItem key={option.value} value={option.value}>
              {option.label}
            </CssMenuItem>
          ))}
        </CssSelect>
        <CssFormHelperText>{helperText || ''}</CssFormHelperText>
      </FormControl>
    </Box>
  );
};

export default Select;
