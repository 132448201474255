import React from 'react';
import { Flex, Box } from 'rebass';
import TextField from '@material-ui/core/TextField';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { ru } from 'date-fns/locale';

const BasicDateRangePicker = ({ value, onChange, startError, endError }) => {
  return (
    <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="C"
        endText="По"
        value={value}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        onChange={(newValue) => {
          onChange(newValue);
        }}
        renderInput={(startProps, endProps) => {
          return (
            <>
              <Flex alignItems="center">
                <Box bg="#fff" width="120px">
                  <TextField {...startProps} size="small" error={startError} />
                </Box>
                <Box mx="10px"> - </Box>
                <Box bg="#fff" width="120px">
                  <TextField {...endProps} size="small" error={endError} />
                </Box>
              </Flex>
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default BasicDateRangePicker;
