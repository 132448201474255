import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { fetchEditFags, fetchFaqsById } from '../requests';
import { useRouter } from '../../../../../hooks';
import { useStatuses } from '../../../../../hooks/useStatuses';

const initialState = {
  title: '',
  status: '',
  access: '',
  file: null,
  text: '',
};

export const useEditFags = (setIsModalOpen) => {
  const [{ value }, getFags] = useAsyncFn(fetchFaqsById);
  const [state, editFags] = useAsyncFn(fetchEditFags);
  const [isFileLoading,setIsFileLoading]  = useState(false)
  const {
    query: { id },
  } = useRouter();
  const [fags, setFags] = useState(initialState);

  const handleEditFaqs = async (data, handleAddFile) => {
    setIsFileLoading(true)
    setIsModalOpen(true);
    const resp = await handleAddFile(true);
    if (resp?.id || resp) {
      await editFags(id, data, resp?.id);
    }
    else {
      await editFags(id, data);
    }
    setIsFileLoading(false)
  };

  const { status } = useStatuses(state?.loading || isFileLoading, state?.value, state?.error);

  useEffect(() => {
    if (id) {
      getFags(id);
    }
  }, [id]);

  useEffect(() => {
    setFags({
      title: value?.question,
      status: value?.publishStatus,
      access: value?.accessType,
      file: { path: value?.file?.originalFilename, id: value?.file?.id },
      text: value?.answer,
    });
  }, [value]);

  return { fags, editFags, handleEditFaqs, editStatus: status };
};
