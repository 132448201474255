import {useEffect} from "react";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useForm} from "react-hook-form";
import {useAsyncFn} from "react-use";

import {initialValuesStatus, validationSchemaStatus} from "../constants";
import {fetchStatus} from "../requests";
import {getNormalizeDate} from "../../../utils";

export const useForms = (setStatus, setIsAlert) => {
  const [{value, loading, error}, execute] = useAsyncFn(fetchStatus)

  const {
    control,
    handleSubmit,
    formState: {isValid, isDirty, errors},
    getValues,
    setValue,
    reset,
    clearErrors
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValuesStatus,
    resolver: yupResolver(validationSchemaStatus),
  });

  const onSubmit = handleSubmit((values) => {
    execute(values)
    reset()
  });

  useEffect(() => {
    if (error) {
      setIsAlert(true)
    }
  }, [error])

  useEffect(() => {
    if (value) {
      setStatus(
        {
          number: value?.complianceNumber,
          isAnonymous: value?.isAnonymous,
          fullName: value?.fullName,
          email: value?.email,
          phoneNumber: value?.phoneNumber,
          text: value?.text,
          status: value?.status,
          date: getNormalizeDate(value?.createdDate),
          comments: value?.comments,
          pinCode: value?.pinCode
        }
      )
    }
  }, [value])

  return {
    control,
    handleSubmit,
    isValid,
    isDirty,
    errors,
    getValues,
    setValue,
    reset,
    clearErrors,
    onSubmit,
    loading
  }
}
