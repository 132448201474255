export const FILTER_TYPES = {
  TEXT_FIELD: 'TEXT_FIELD',
  SELECT: 'SELECT',
  DATE_RANGE: 'DATE_RANGE',
};

export const ACCESS = {
  ALL: 'ALL',
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  NOT_PUBLISHED: 'NOT_PUBLISHED'
};

export const MATERIAL_TYPES = {
  ALL: 'ALL',
  ARTICLE: 'ARTICLE',
  VIDEO: 'VIDEO',
};

export const DOCUMENT_TYPES = {
  ALL: 'ALL',
  COMPANY: 'COMPANY',
  LAWS: 'LAWS',
  ANTI_CORRUPTION: 'ANTI_CORRUPTION',
};

export const EDUCATION_TYPES = {
  ALL: 'ALL',
  MATERIALS: 'MATERIALS',
  TESTS: 'TESTS',
};

export const ARTICLE_STATUSES = {
  ALL: 'ALL',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  POSTPONED: 'POSTPONED',
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  PRIVATE:'PRIVATE',
  PUBLIC:'PUBLIC',
};

export const ARTICLE_STATUSES_TITLES = {
  [ARTICLE_STATUSES.PUBLISHED]: 'Опубликовано',
  [ARTICLE_STATUSES.UNPUBLISHED]: 'Неопубликовано',
  [ARTICLE_STATUSES.NOT_PUBLISHED]: 'Неопубликовано',
};

export const NEWS_TYPE = {
  TEXT: 'TEXT',
  FILE: 'FILE',
};

export const SOURCE_TYPE = {
  LOCAL: 'LOCAL',
  EXTERNAL: 'EXTERNAL',
};
