import React from 'react';
import { useSelector } from 'react-redux';

import DataLayout from '../DataLayout';
import { FILTER_TYPES, ARTICLE_STATUSES, ACCESS, MATERIAL_TYPES } from '../constants';
import { useVideo } from './hooks/useVideo';
import {usePagination} from "../../hooks/usePagination";
import {getInitFiltersParams} from "../../../../utils/getInitFiltersParams";
import {filterList, initialFilters} from "./constants";
import {useFilters} from "../../hooks/useFilter";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const Materials = () => {
  const { sort, dateRange } = useSelector((state) => state.materials);
  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();
  const initValues = getInitFiltersParams(filterList);
  const initDate = initValues?.date?.[0] ? [new Date(initValues?.date?.[0]), new Date(initValues?.date?.[1])] : null
  const {filter, handleChangeFilters, resetFilters} = useFilters({...initValues, date: initDate},initialFilters);

  const { listOfMaterials, updateTableData, getListVideo,handleDeleteVideo,status } = useVideo(
    filter,
    setAllPages,
    currentPage,
    pageSize,
    setAllElements,
  );

  const onDelete = async (itemId) => {
    await handleDeleteVideo(itemId)
  };

  const filterItems = [
    {
      name: 'date',
      type: FILTER_TYPES.DATE_RANGE,
      value: filter.date,
      initialValue: dateRange,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'status',
      label: 'Статус',
      type: FILTER_TYPES.SELECT,
      opts: [
        { title: 'Все', value: ARTICLE_STATUSES.ALL },
        { title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED },
        { title: 'Неопубликованные', value: ARTICLE_STATUSES.UNPUBLISHED },
      ],
      value: filter.status,
      initialValue: ARTICLE_STATUSES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'access',
      label: 'Доступ',
      type: FILTER_TYPES.SELECT,
      opts: [
        { title: 'Все', value: ACCESS.ALL },
        { title: 'Открытые', value: ACCESS.PUBLIC },
        { title: 'Закрытые', value: ACCESS.PRIVATE },
      ],
      value: filter.access,
      initialValue: ACCESS.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'type',
      label: 'Тип',
      type: FILTER_TYPES.SELECT,
      opts: [
        // { title: 'Все', value: MATERIAL_TYPES.ALL },
        { title: 'Статьи', value: MATERIAL_TYPES.ARTICLE },
        { title: 'Видео', value: MATERIAL_TYPES.VIDEO },
      ],
      value: filter.type,
      initialValue: MATERIAL_TYPES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
  ];

  const headCells = [
    {
      id: 'dateSort',
      disablePadding: true,
      label: 'Дата публикации',
      status: sort.dateSort,
    },
    { id: 'titleSort', disablePadding: false, label: 'Заголовок', status: sort.titleSort },
    { id: 'statusSort', disablePadding: false, label: 'Статус', status: sort.statusSort },
  ];

  const actions = [
    { link: 'edit/article', label: 'Добавить статью', disabled:!checkedPermission(permissions.materialsWrite)},
    { link: 'edit/video', label: 'Добавить видео', disabled:!checkedPermission(permissions.materialsWrite)},
  ];

  const handleResetFilters = async () => {
    await resetFilters();
    await getListVideo({
      startDate: '',
      endDate: '',
      accessType: '',
      publishStatus: '',
      page: 1,
      size: 10,
      sort:"publicationDate,DESC",
    },{type:filter.type});
  };

  return (
    <DataLayout
      title="Интересные материалы"
      headCells={headCells}
      rows={listOfMaterials}
      deleteElement={onDelete}
      filterItems={filterItems}
      actions={actions}
      accessFilter={updateTableData}
      resetFilters={handleResetFilters}
      status={status}
      pagination={pageSize}
      setPagination={setCountElements}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
    />
  );
};

export default Materials;
