import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import {getLocalStorage} from '../utils/localStorage';
import {fetchToken} from "../modules/Login/request";

const BASE_URL = '/api';
const {pathname} = window.location;

const interceptor = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {'content-type': 'application/json'},
});

export const setupInterceptor = (store) => {
  interceptor.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error.response ? error.response.status : 408;
      if (status >= 500) {
        window.console.error(error.toString());
      }
      if (status === 401) {
        fetchToken()
        window.console.log('logout');
      }
      return Promise.reject(error);
    },
  );
  interceptor.interceptors.request.use(async (request) => {
    // eslint-disable-next-line no-param-reassign
    if (pathname.includes('admin')) {
      request.headers.authorization = `Bearer ${getLocalStorage('accessToken')}`;
    }
    return request;
  });
};

export default interceptor;
