import React from 'react';
import styled from 'styled-components';
import { Text, Flex, Box, Image } from 'rebass';
import TextContent from '../components/TextContent';
import images from '../img';

const CustomText = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
  @media screen and (max-width: 1024px) {
     {
      width: 50%;
    }
  }
`;

const CustomHeader = styled.div`
  @media screen and (max-width: 1024px) {
  {
    font-size: 2rem;
    line-height: 30px;
  }
  }
`;

const slides = [
  {
    id: 1,
    img: 'm5t2slide1',
  },
  {
    id: 2,
    img: 'm5t2slide2',
  },
  {
    id: 3,
    img: 'm5t2slide3',
  },
  {
    id: 4,
    img: 'm5t2slide4',
  },
  {
    id: 5,
    img: 'm5t2slide5',
  },
];

const module5 = {
  id: 5,
  title: 'Модуль 5. Не бросай мусор с балкона',
  home: [
    {
      id: 1,
      title: 'Защита окружающей среды и охрана труда',
      text: 'Модуль 5. Не бросай мусор с балкона',
      bg: 'm5home1',
      nextLinkTitle: 'Пятый модуль',
      nextLink: '/course/module/5/intro',
    },
  ],
  intro: [
    {
      id: 1,
      title: 'Если каждый следит за чистотой, то и убирать нечего',
      text: [
        'Чистота — это общая ответственность: бросать мусор в урну, а не в клумбу, курить в специальном месте, не использовать ядовитые химикаты и взрывоопасные вещества при ремонте.',
        'Только общими усилиями можно создать комфортное и безопасное пространство для совместной жизни.',
      ],
      bg: 'm5intro1',
      nextLink: '/course/module/5/theory/1',
    },
  ],
  theory: [
    {
      id: 1,
      flex: 1.7,
      title: (
        <CustomHeader>
          Защита окружающей среды и охрана труда — темы, которым мы уделяем много внимания
        </CustomHeader>
      ),
      text: (
        <CustomText>
          Несмотря на то что мы непроизводственная компания, нам важно, чтобы партнёры соблюдали
          требования законодательства в сфере экологии. Мы заботимся о своих сотрудниках и
          непримиримо относимся к нарушению условий охраны труда. И побуждаем наших партнёров
          действовать так же.
        </CustomText>
      ),
      bg: 'm5t1',
      nextLink: '/course/module/5/theory/2',
    },
    {
      id: 2,
      title: 'Мы призываем поставщиков бережно относиться к природе',
      isSlider: true,
      slides,
      nextLink: '/course/module/5/theory/3',
    },
    {
      id: 3,
      flex: 0.6,
      title: 'И заботиться о сотрудниках',

      rightText: (
        <Flex flexDirection="column" justifyContent="center" p={[16, 24, 0]}>
          <Box>
            <Box
              width="100%"
              maxWidth="500px"
              p="40px"
              mb="16px"
              sx={{ background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)' }}
            >
              <Image src={images.m5t3img1} alt="" maxHeight="40px" mb="8px" />
              <CustomText>
                Соблюдать нормы охраны труда и правила техники безопасности, принятые в компании
              </CustomText>
            </Box>
            <Box
              width="100%"
              maxWidth="500px"
              p="40px"
              mb="16px"
              sx={{ background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)' }}
            >
              <Image src={images.m5t3img2} alt="" maxHeight="40px" mb="8px" />
              <CustomText>
                Создавать здоровую рабочую среду и безопасные условия труда для всех сотрудников
              </CustomText>
            </Box>
            <Box
              width="100%"
              maxWidth="500px"
              p="40px"
              sx={{ background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)' }}
            >
              <Image src={images.m5t3img3} alt="" maxHeight="40px" mb="8px" />
              <CustomText>
                Соблюдать нормы техники безопасности на объекте или на территории компании
              </CustomText>
            </Box>
          </Box>
        </Flex>
      ),
      bg: 'm5t3',
      nextLink: '/course/module/5/case/1',
    },
  ],
  case: [
    {
      id: 1,
      title: 'Кейс: деньги пахнут',
      text: 'Компания Х наняла подрядчика для укладки плитки в холле здания. Чтобы сэкономить и заработать больше, работники подрядной организации использовали некачественный клей. Когда плитка высохла, в здании появился неприятный запах. Комиссия установила, что клей выделяет ядовитые химикаты. Компания Х разорвала отношения с подрядчиком и подала на него в суд.',
      bg: 'm5case1',
      nextLink: '/course/module/5/case/2',
    },
    {
      id: 2,
      title: 'Кейс: гори, но не сгорай',
      text: (
        <>
          <TextContent color="#fff">
            «Ростелеком» нанял подрядчика для ремонта технического здания. Ремонтники спешили
            доделать работы, поэтому пренебрегли мерами безопасности. Внезапно начался пожар,
            который испортил оборудование. Материальный ущерб составил{' '}
            <Text display="inline" color="#f6c043">
              более 1,5 млн руб.
            </Text>
          </TextContent>
          <TextContent color="#fff">
            Выяснилось, что обучение по курсу пожарно-технического минимума работники подрядной
            организации не прошли. Поэтому подрядчик выплатил «Ростелекому» штрафы за нарушение
            требований противопожарного режима, предусмотренные договором, а также заплатил
            государству 350 тыс. руб. административного штрафа.
          </TextContent>
        </>
      ),
      bg: 'm5case2',
      nextLink: '/course/module/6',
    },
  ],
};

export default module5;
