import {useState} from "react";
import {useAsyncFn} from 'react-use';

import {addedFaqs} from '../requests';
import {useStatuses} from '../../../../../hooks/useStatuses';

export const useAddedFaqs = (setIsModalOpen, handleAddFile) => {
  const [{loading, value, error}, setFaqs] = useAsyncFn(addedFaqs);
  const [isFileLoading, setIsFileLoading] = useState(false)

  const handleAddedFaqs = async (data) => {
    setIsFileLoading(true)
    setIsModalOpen(true);
    const resp = await handleAddFile();
    if (resp?.id) {
      await setFaqs(data, resp?.id);
    } else {
      await setFaqs(data);
    }
    setIsFileLoading(false)
  };

  const {status} = useStatuses(loading || isFileLoading, value, error);

  const addStatus = status;
  return {handleAddedFaqs, addStatus};
};
