import {getLocalStorage} from "./localStorage";

export const checkedPermission = (data) => {
  const token = getLocalStorage('accessToken')

  function parseJwt(token) {
    if (token) {
      let base64Payload = token?.split('.')[1];
      let payload = Buffer.from(base64Payload, 'base64');
      return JSON.parse(payload?.toString());
    }
    return {permissions: []}
  }

  const {permissions} = parseJwt(token);

  return permissions?.some((item) => item === data)
}
