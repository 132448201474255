import React from 'react';

import DateRange from "../DateRange";
import Button from "../Button";
import {useDate} from "./hooks/useDate";
import * as S from './styles'
import {useReport} from "./hooks/useReport";
import {useModal} from "./hooks/useModal";
import ModalWindow from "./components/ModalWindow";

const Report = () => {
  const {isOpen, openModal, closeModal} = useModal()

  const {
    date, startError,
    endError, isValidStartDate,
    isValidEndDate, setDate
  } = useDate()

  const {getReport, loading} = useReport(date, setDate, openModal)
  const isDisabled = !date?.[0] || !date?.[1]
    || endError || startError || !isValidEndDate
    || !isValidStartDate

  return (
    <S.Content>
      <S.Header>
        Отчёт
      </S.Header>
      {loading && <S.Loader/>}
      {!loading &&
        <S.Date><DateRange
          value={date || ''}
          name={'date'}
          onChange={(date) => {
            setDate(date)
          }}
          startError={startError || !isValidStartDate}
          endError={endError || !isValidEndDate}
        />
        </S.Date>
      }
      <Button
        variant="contained"
        mr="16px"
        label="Скачать отчёт"
        disabled={isDisabled}
        onClick={getReport}
      />
      <ModalWindow
        isOpen={isOpen}
        onClose={closeModal}
      />
    </S.Content>
  );
};

export default Report;
