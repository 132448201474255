import React from 'react';
import {Link} from 'react-router-dom';
import {Text} from 'rebass';
import TextContent from '../components/TextContent';
import styled from "styled-components";

const Title = styled.div`
  @media (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-height: 540px) {
    font-size: 1.5rem;
    line-height: 30px;
  }
`

const TitleModule = styled.div`
  @media (max-width: 1024px) {
    font-size: 2.4rem;
  }
`

const slides = [
  {
    id: 1,
    img: 'm1t1slide1',
  },
  {
    id: 2,
    img: 'm1t1slide2',
  },
  {
    id: 3,
    img: 'm1t1slide3',
  },
  {
    id: 4,
    img: 'm1t1slide4',
  },
  {
    id: 5,
    img: 'm1t1slide5',
  },
  {
    id: 6,
    img: 'm1t1slide6',
  },
  {
    id: 7,
    img: 'm1t1slide7',
  },
  {
    id: 8,
    img: 'm1t1slide8',
  },
];

const module1 = {
  id: 1,
  title: 'Модуль 1. Устав дома',
  home: [
    {
      id: 1,
      title: 'Этичное ведение бизнеса',
      text: 'Модуль 1. Устав дома',
      nextLinkTitle: 'Первый модуль',
      bg: 'm1home1',
      nextLink: '/course/module/1/intro/1',
    },
  ],
  intro: [
    {
      id: 1,
      title: <TitleModule>Нам важно, с кем жить и работать</TitleModule>,
      text: [
        'Окружение очень влияет на качество нашей жизни. Приятно, когда соседи в доме добропорядочные: здороваются, поддерживают чистоту во дворе и не шумят после 23.00. Так и в бизнесе.',
        'Для нас в «Ростелекоме» важно не только, сколько денег мы зарабатываем, но и как и с кем мы это делаем. Поэтому мы стремимся взаимодействовать с партнерами, которые думают и работают так же.',
      ],
      bg: 'm1intro1',
      nextLink: '/course/module/1/intro/2',
    },
    {
      id: 2,
      title: <TitleModule>Нам важно, с кем жить и работать</TitleModule>,
      text: [
        'В компании действует Кодекс деловой этики поставщика, по которому мы строим отношения с подрядчиками. Это как устав дома - в нем подробно прописано, как нам с партнерами жить друг с другом в любви и согласии, чтобы делать взаимовыгодные проекты и получать хорошие результаты.',
        'В этом модуле вы узнаете, как именно мы выстраиваем добрососедские отношения и чего ожидаем от наших партнеров.',
      ],
      bg: 'm1intro2',
      nextLink: '/course/module/1/check',
    },
  ],
  check: [
    {
      id: 1,
      title: 'Как думаете, какие из этих пунктов есть в Кодексе деловой этики поставщика?',
      text: (
        <>
          <Text color="#6d12f5" fontSize={['16px', '16px', '16px', '20px']} mb="40px">
            Выберите несколько ответов
          </Text>
          <TextContent>
            <Link
              to='/documents/company'
              target="_blank"
            >
              <Text
                display="inline"
                fontSize={['16px', '16px', '16px', '20px']}
                color="#6d12f5"
                sx={{textDecoration: 'underline'}}
                fontWeight="bold"
                mr="8px"
              >
                Здесь
              </Text>
            </Link>
            вы можете посмотреть Кодекс полностью.
          </TextContent>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'В каком костюме нужно приходить на встречи в «Ростелеком»',
        },
        {
          id: 2,
          title: 'Антикоррупционная политика в «Ростелекоме»',
        },
        {
          id: 3,
          title: 'Способы сообщить о нарушениях в «Ростелекоме»',
        },
        {
          id: 4,
          title: 'Отношение компании к защите окружающей среды',
        },
        {
          id: 5,
          title:
            'Список автомобилей отечественного производства, на которых могут ездить партнёры компании',
        },
        {
          id: 6,
          title:
            'Обязательства поставщика по сохранению конфиденциальной и инсайдерской информации',
        },
        {
          id: 7,
          title: 'Какие подарки можно и нельзя дарить сотрудникам «Ростелекома»',
        },
      ],
      correctAnswers: [2, 3, 4, 6, 7],
      correctText:
        'Да вы знаток! В любом случае рекомендуем пройти курс, в нём много важных деталей, которые нужно знать.',
      errorTextOnAttemptsOver:
        'Не совсем так. Оставайтесь с нами, дальше расскажем обо всем подробнее.',
      nextLink: '/course/module/1/theory/1',
    },
  ],
  theory: [
    {
      id: 1,
      title: 'А это принципы, по которым мы призываем жить наших поставщиков',
      isSlider: true,
      slides,
      nextLink: '/course/module/1/theory/2',
    },
    {
      id: 2,
      title:
        <Title>
          Если вы подключаете субподряд, проверьте, чтобы ваши партнёры тоже следовали нашему кодексу
        </Title>
      ,
      text: 'Это как соседи снизу. Если наверху прорвало трубу, то пострадают не только соседи на этаж ниже, но и весь подъезд. Ваши отношения с субподрядчиками — часть наших с вами отношений.',
      bg: 'm1t2',
      nextLink: '/course/module/1/case',
    },
  ],
  case: [
    {
      id: 1,
      title: 'Кейс: задолжали не по-соседски',
      text: 'Для ремонта офиса «Ростелеком» привлёк подрядчика. А тот — субподрядчика по установке пластиковых окон. В самый разгар работы на Линию Доверия «Ростелекома» поступила информация о том, что установщик окон не может добиться от нашего подрядчика оплаты за выполненные работы, поэтому субподрядчик приостановил работы и обратился в суд.',
      bg: 'm1case',
      nextLink: '/course/module/1/conclusion',
    },
  ],
  conclusion: [
    {
      id: 1,
      title: 'Нам важно, чтобы те, кто живёт рядом, соблюдали правила',
      text: [
        'Мы в «Ростелекоме» ожидаем от своих деловых партнёров честного ведения бизнеса. Помните, что выполняя работы, вы можете повлиять на деловую репутацию как вашей компании, так и нашей.',
        'Если у вас возникают сомнения, этично ли наше взаимодействие, обращайтесь к нашему Кодексу поставщика — пусть он будет основой для принятия решений!',
      ],
      bg: 'm1conclusion',
      nextLink: '/course/module/2',
    },
  ],
};

export default module1;
