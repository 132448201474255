import { getNormalizeDate } from '../../../../../utils';
import { checkIsVideoType } from './checkIsVideoType';

export const getNormalizedVideo = (value,filters) => {
  return {
    access: value?.accessType,
    dataType: filters?.type === "VIDEO" ? 'video' : 'article',
    date: getNormalizeDate(value?.publicationDate),
    id: value?.id,
    materialType: checkIsVideoType(value) ? 'VIDEO' : 'ARTICLE',
    title: value?.title,
    status: value?.publishStatus,
  };
};
