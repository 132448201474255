import {format} from "date-fns";
import { saveAs } from 'file-saver';

import interceptor from "../../../../../helpers/API";

export const fetchReport = async (value) => {
  try {
    const {data} = await interceptor.get('admin/course/report', {
      params: {
        startDate: format(new Date(value?.[0]), 'yyyy-MM-dd'),
        endDate: format(new Date(value?.[1]), 'yyyy-MM-dd'),
      },
      responseType: 'blob'
    });

    await saveAs(data, 'Отчёт');
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
