import {useEffect, useState} from 'react';
import {useAsyncFn} from 'react-use';

import {fetchYearsList} from '../requests';

export const useYears = (filters, handleParamChange) => {
  const [listYears, setListYears] = useState([]);
  const [{value}, getListYears] = useAsyncFn(fetchYearsList);

  useEffect(() => {
    getListYears(filters?.newsType);
  }, [filters?.newsType]);

  useEffect(() => {
    const newValue = value?.map((item) => ({title: String(item), value: String(item)})) || [];
    setListYears([...newValue]);
  }, [value]);

  useEffect(() => {
    if (listYears) {
      handleParamChange(listYears?.at(-1),'year')
    }
  }, [listYears])

  return {listYears};
};
