import interceptor from "../../../../../helpers/API";

export const fetchAskList = async (params) => {
  try {
    const {data} = await interceptor.get('admin/questions', {
      params,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const deleteAsk = async (id) => {
  try {
    await interceptor.delete(`admin/questions/${id}`, {
      params: {id},
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};



export const fetchAskById = async (id) => {
  try {
    const { data } = await interceptor.get(`admin/questions/${id}`, {
      params: { id },
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
