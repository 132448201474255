import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from 'rebass';
import { find } from 'lodash';
import Layout from './Layout';
import TextTitle from './TextTitle';
import TextContent from './TextContent';
import data from '../data';
import images from '../img';

const ModuleTheoryText = () => {
  const { moduleId, theoryId } = useParams();
  const module = find(data.modules, ['id', +moduleId]);
  const { flex, title, text, nextLink, bg, rightText, modalContent,isNotBlockBackground } = find(module.theory, [
    'id',
    +theoryId || 1,
  ]);

  const renderLeft = () => {
    return (
      <Box>
        <TextTitle>{title}</TextTitle>
        <TextContent>{text}</TextContent>
      </Box>
    );
  };

  return (
    <Layout
      flex={flex}
      pagetTitle={module.title}
      leftContent={renderLeft()}
      rightContent={rightText}
      bgImage={images[bg]}
      nextLink={nextLink}
      modalContent={modalContent}
      isNotBlockBackground={isNotBlockBackground}
    />
  );
};

export default memo(ModuleTheoryText);
