// eslint-disable-next-line consistent-return
import { getLocalStorage } from './localStorage';

export const getTimeTokenUpdates = (time) => {
  const difference = (new Date().getTime() - new Date(time).getTime()) / 60000;
  if (difference > 12) {
    return true;
  }
  return false;
};

export const checkTokenLifetime = () => {
  const time = getLocalStorage('tokenTime');
  const isExpired = getTimeTokenUpdates(time);
  return isExpired;
};
