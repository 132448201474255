import React from 'react';
import {Text} from "rebass";

import * as S from './styles'
import {Modal,} from "../../../../common/molecules";
import {ModalContent, Form} from "./components";
import {useFormValue} from "./hooks/useForm";
import {useModal} from "./hooks/useModal";
import {useProfile} from "./hooks/useProfile";

const Profile = () => {
  const {isOpeModal, closeModal} = useModal()
  const {profile} = useProfile()
  const {
    control, errorsList, onSubmit, isValid,
    isDirty,
  } = useFormValue(profile)

  return (
    <S.Content>
      <Text fontSize="24px" color="#333" fontWeight="500" mb='20px'>
        Редактирование профиля
      </Text>
      <S.Line/>
      <Form control={control} onSubmit={onSubmit} isValid={isValid}
            isDirty={isDirty}/>
      <Modal
        isOpen={isOpeModal}
        onClose={closeModal}
        body={<ModalContent errorsList={errorsList} onClose={closeModal}/>}
      />
    </S.Content>
  );
};

export default Profile;
