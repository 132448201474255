import React from 'react';
import { Switch } from 'react-router-dom';
import Admin from 'modules/Admin';
import AdminLayout from 'common/layouts/AdminLayout';

const PrivateRouter = () => {
  return (
    <Switch>
      <AdminLayout path="/admin/:section?" component={Admin} />
    </Switch>
  );
};

export default PrivateRouter;
