import React from 'react';
import { Text } from 'rebass';

const TextTitle = ({ children, color }) => {
  return (
    <Text
      fontSize={['1.75rem', '1.75rem', '2.125rem', '2.75rem']}
      mb={['24px', '24px', '24px', '2.5rem']}
      lineHeight={['32px', '32px', '40px', '52px']}
      color={color}
    >
      {children}
    </Text>
  );
};

export default TextTitle;
