import React from 'react';
import styled, {css} from 'styled-components';
import {Text, Flex, Box, Image} from 'rebass';
import HelpIcon from '@material-ui/icons/Help';
import TextContent from '../components/TextContent';
import CustomLi from '../components/CustomLi';
import CustomLink from '../components/CustomLink';
import Button from '../components/Button';
import images from '../img';


const TitleModule = styled.div`
  @media (max-width: 1024px) {
    font-size: 2.4rem;
  }
`
const TitleT5 = styled.div`
  ${props => props?.isNotBlockBackground && css`
     @media only screen and (max-width: 1024px) {
       width: 50%;
     }
  `}
`;

const TitleModule2 = styled.div`
  @media (max-height: 600px) {
    font-size: 2rem;
    line-height: 30px;
  }
`

const TextModule = styled.div`
  @media (max-width: 1024px) {
    font-size: 17px;
  }

  @media (max-height: 540px) {
    font-size: 14px;
  }
`

const List = styled.ul`
  @media (max-width: 1024px) and (max-height: 600px) {
    font-size: 15px;
  }
`
const Table = styled.table`
  td,
  th {
    padding: 20px 0;
    text-align: left;
    line-height: 1.5;
  }

  th {
    font-size: 20px;
    font-weight: bold;
  }

  tr {
    border-bottom: solid 1px #e0e0e0;

    &:last-child {
      border: none;
    }
  }

  b {
    font-weight: bold;
  }
`;

const CustomText = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
`;

const CustomContent = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
  @media screen and (max-width: 1024px) { {
    width: 50%;
  }
  }
`;

const CustomHeader = styled.div`
  @media screen and (max-width: 1023px) { {
    width: 50%;
  }
  }
`;

const slides = [
  {
    id: 1,
    img: 'm2t3slide1',
  },
  {
    id: 2,
    img: 'm2t3slide2',
  },
  {
    id: 3,
    img: 'm2t3slide3',
  },
  {
    id: 4,
    img: 'm2t3slide4',
  },
  {
    id: 5,
    img: 'm2t3slide5',
  },
];

const ModalText = styled(Text)`
  margin-bottom: 16px;
  color: rgb(0 0 0 / 60%);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
`;

const modalContent = (
  <Box p={['8px', '16px', '24px']} bg="#fff" maxWidth="640px" maxHeight="90%">
    <ModalText>
      <Text color="#6d12f5">
        Статья 19.28. Незаконное вознаграждение от имени юридического лица
      </Text>
    </ModalText>
    <ModalText>
      1. Незаконные передача, предложение или обещание от имени или в интересах юридического лица
      либо в интересах связанного с ним юридического лица должностному лицу, лицу, выполняющему
      управленческие функции в коммерческой или иной организации, иностранному должностному лицу
      либо должностному лицу публичной международной организации денег, ценных бумаг или иного
      имущества, оказание ему услуг имущественного характера либо предоставление ему имущественных
      прав (в том числе в случае, если по поручению должностного лица, лица, выполняющего
      управленческие функции в коммерческой или иной организации, иностранного должностного лица
      либо должностного лица публичной международной организации деньги, ценные бумаги или иное
      имущество передаются, предлагаются или обещаются, услуги имущественного характера оказываются
      либо имущественные права предоставляются иному физическому либо юридическому лицу) за
      совершение в интересах данного юридического лица либо в интересах связанного с ним
      юридического лица должностным лицом, лицом, выполняющим управленческие функции в коммерческой
      или иной организации, иностранным должностным лицом либо должностным лицом публичной
      международной организации действия (бездействие), связанного с занимаемым им служебным
      положением, - влечет наложение административного штрафа на юридических лиц в размере до
      трехкратной суммы денежных средств, стоимости ценных бумаг, иного имущества, услуг
      имущественного характера, иных имущественных прав, незаконно переданных или оказанных либо
      обещанных или предложенных от имени юридического лица, но не менее одного миллиона рублей с
      конфискацией денег, ценных бумаг, иного имущества или стоимости услуг имущественного
      характера, иных имущественных прав.
    </ModalText>
    <ModalText>
      2. Действия, предусмотренные частью 1 настоящей статьи, совершенные в крупном размере, -
      влекут наложение административного штрафа на юридических лиц до тридцатикратного размера суммы
      денежных средств, стоимости ценных бумаг, иного имущества, услуг имущественного характера,
      иных имущественных прав, незаконно переданных или оказанных либо обещанных или предложенных от
      имени юридического лица, но не менее двадцати миллионов рублей с конфискацией денег, ценных
      бумаг, иного имущества или стоимости услуг имущественного характера, иных имущественных прав.
    </ModalText>
    <ModalText>
      3. Действия, предусмотренные частью 1 настоящей статьи, совершенные в особо крупном размере, -
      влекут наложение административного штрафа на юридических лиц в размере до стократной суммы
      денежных средств, стоимости ценных бумаг, иного имущества, услуг имущественного характера,
      иных имущественных прав, незаконно переданных или оказанных либо обещанных или предложенных от
      имени юридического лица, но не менее ста миллионов рублей с конфискацией денег, ценных бумаг,
      иного имущества или стоимости услуг имущественного характера, иных имущественных прав.
    </ModalText>
    <ModalText>
      <Text color="#6d12f5">Примечания:</Text>
    </ModalText>
    <ModalText>
      1. В настоящей статье под должностным лицом понимаются лица, указанные в примечаниях 1 - 3 к
      статье 285 Уголовного кодекса Российской Федерации.
    </ModalText>
    <ModalText>
      2. В настоящей статье под лицом, выполняющим управленческие функции в коммерческой или иной
      организации, понимается лицо, указанное в примечании 1 к статье 201 Уголовного кодекса
      Российской Федерации.
    </ModalText>
    <ModalText>
      3. В настоящей статье под иностранным должностным лицом понимается любое назначаемое или
      избираемое лицо, занимающее какую-либо должность в законодательном, исполнительном,
      административном или судебном органе иностранного государства, и любое лицо, выполняющее
      какую-либо публичную функцию для иностранного государства, в том числе для публичного
      ведомства или публичного предприятия; под должностным лицом публичной международной
      организации понимается международный гражданский служащий или любое лицо, которое уполномочено
      такой организацией действовать от ее имени.
    </ModalText>
    <ModalText>
      4. В настоящей статье крупным размером признаются сумма денег, стоимость ценных бумаг, иного
      имущества, услуг имущественного характера, иных имущественных прав, превышающие один миллион
      рублей, особо крупным размером - превышающие двадцать миллионов рублей.
    </ModalText>
    <ModalText>
      5. Юридическое лицо освобождается от административной ответственности за административное
      правонарушение, предусмотренное настоящей статьей, если оно способствовало выявлению данного
      правонарушения, проведению административного расследования и (или) выявлению, раскрытию и
      расследованию преступления, связанного с данным правонарушением, либо в отношении этого
      юридического лица имело место вымогательство.
    </ModalText>
    <ModalText>
      6. Положение, предусмотренное примечанием 5 к настоящей статье, не распространяется на
      административные правонарушения, совершенные в отношении иностранных должностных лиц и
      должностных лиц публичных международных организаций при осуществлении коммерческих сделок.
    </ModalText>
    <ModalText>
      <Text color="#6d12f5">
        Кодекс Российской Федерации об административных правонарушениях (в ред. от 15.10.2020, с
        изменениями от 16.10.2020)
      </Text>
    </ModalText>
    <Flex justifyContent="center">
      <Button title="OK" className="modalHider"/>
    </Flex>
  </Box>
);

const module2 = {
  id: 2,
  title: 'Модуль 2. Может договоримся?',
  home: [
    {
      id: 1,
      title: 'Коррупция и ненадлежащие платежи',
      text: 'Модуль 2. Может договоримся?',
      bg: 'm2home1',
      nextLinkTitle: 'Второй модуль',
      nextLink: '/course/module/2/intro/1',
    },
  ],
  intro: [
    {
      id: 1,
      title: 'Бывают ситуации, когда очень нужно решить проблему, но не получается',
      text: [
        'Например, чтобы поскорее закончить ремонт, надо пошуметь рано утром в воскресенье. Но есть закон, который это запрещает. И есть управдом, который позвонит в полицию.',
        'Может появиться мысль дать взятку управдому или участковому, но это уже совсем не про добросовестные отношения.',
      ],
      bg: 'm2intro1',
      nextLink: '/course/module/2/intro/2',
    },
    {
      id: 2,
      title: 'На языке бизнеса это называют коррупцией',
      text: [
        'Под этим словом чаще всего понимают взяточничество и незаконное использование полномочий или власти для получения личной выгоды.',
        'Из этого модуля вы узнаете, что, кроме денег, можно расценить как взятку, как и за что наказывают тех, кто даёт, берёт взятки или выступает посредником, и что такое антикоррупционная оговорка.',
      ],
      bg: 'm2intro2',
      nextLink: '/course/module/2/check',
    },
  ],
  check: [
    {
      id: 1,
      title: 'Как, на ваш взгляд, выглядит типичный коррупционер?',
      testType: 'images',
      nextLink: '/course/module/2/theory/1',
    },
    {
      id: 2,
      title: 'Проверьте себя',
      text: (
        <>
          <TextContent>
            Марина — подрядчик «Ростелекома». Она помогает получать разрешения на строительство
            объектов компании. Организация работы в муниципалитете «оставляет желать лучшего»,
            поэтому Марина договорилась с одним из служащих за небольшую компенсацию принять у неё
            документы и оформить разрешение быстрее. Законны ли действия Марины? Выберите один
            ответ.
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title:
            'Марина поступила правильно. Время ожидания в очереди можно потратить на решение других задач.',
        },
        {
          id: 2,
          title:
            'Действовать в обход системы неправильно, но можно, если от этого нет никакого вреда, а только ускорение результата.',
        },
        {
          id: 3,
          title:
            'Марина нарушает Антикоррупционное законодательство РФ. Это ненадлежащий платёж. Марину могут посадить в тюрьму, а «Ростелекому» грозят финансовые и репутационные потери.',
        },
      ],
      correctAnswers: [3],
      correctText: 'Верно! Так делать нельзя.',
      errorTextOnAttemptsOver: 'Правильные ответы: 3',
      nextLink: '/course/module/2/check/3',
    },
    {
      id: 3,
      title: 'Отметьте верные утверждения',
      questions: [
        {
          id: 1,
          title:
            'В своей работе «Ростелеком» следует принципам честности, открытости, добросовестности и нетерпимости к коррупции',
        },
        {
          id: 2,
          title: 'Физические лица несут административную ответственность за взяточничество',
        },
        {
          id: 3,
          title: 'Поставщик обязуется не давать взятки сотрудникам «Ростелекома»',
        },
        {
          id: 4,
          title:
            'Поставщик отказывается от оказания давления на сотрудников «Ростелекома» для получения необоснованной выгоды',
        },
        {
          id: 5,
          title: 'Поставщик может строить отношения с субподрядчиками так, как считает нужным',
        },
      ],
      correctAnswers: [1, 3, 4],
      correctText: '',
      errorTextOnAttemptsOver: 'Правильные ответы: 1,3,4',
      nextLink: '/course/module/2/conclusion',
    },
  ],
  theory: [
    {
      id: 1,
      title: <TitleModule2>Это совершенно обычный человек</TitleModule2>,
      text: (
        <Box>
          <List>
            <CustomLi>Мужчина 35-40 лет</CustomLi>
            <CustomLi>Активный, энергичный, коммуникабельный</CustomLi>
            <CustomLi>Высшее образование</CustomLi>
            <CustomLi>Женат, есть дети</CustomLi>
            <CustomLi>Есть работа, готов много работать</CustomLi>
            <CustomLi>Не злоупотребляет алкоголем и наркотиками</CustomLi>
            <CustomLi>Не судим, не склонен к нарушению общественного порядка</CustomLi>
          </List>

          <Text mt="24px" mb="8px" fontSize="24px">
            Источники:
          </Text>
          <CustomLink
            title="rbc.ru"
            link="https://www.rbc.ru/society/06/12/2018/5c09061d9a794760a277db66"
          />
          <CustomLink
            title="vedomosti.ru"
            link="https://www.vedomosti.ru/politics/news/2019/08/01/807821-korruptsioneri"
          />
        </Box>
      ),
      bg: 'm2t1',
      nextLink: '/course/module/2/theory/2',
    },
    {
      id: 2,
      flex: 1.8,
      title: <TitleModule>Коррупция — это не только взятки, но и ненадлежащие платежи</TitleModule>,
      text: (
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} pr="20px">
            <TextModule>
              Ненадлежащие платежи — выплаты за получение преимуществ, решение вопросов, совершение
              административных процедур, если это не предусмотрено законом.
            </TextModule>
          </Box>
          <Box width={[1, 1, 1 / 2]} pr="20px">
            <TextModule>
              Как если бы вы заплатили нашему управдому не деньгами, а, например, билетами на
              финальный матч Чемпионата мира по футболу. Такие платежи могут расцениваться как
              взятки. Их нельзя ни предлагать, ни требовать. Ни при каких условиях.
            </TextModule>
          </Box>
        </Flex>
      ),
      bg: 'm2t2',
      nextLink: '/course/module/2/theory/3',
    },
    {
      id: 3,
      title: 'Ненадлежащие платежи',
      isSlider: true,
      slides,
      nextLink: '/course/module/2/theory/4',
    },
    {
      id: 4,
      flex: 0.6,
      title: <TitleModule2>За взятки и ненадлежащие платежи все несут уголовную ответственность</TitleModule2>,
      text: (
        <>
          <Text fontSize="22px" fontWeight="bold" >
            <Text color="#6d12f5" display="inline" mr="8px">
              Взяточник
            </Text>
            — тот, кто берёт взятку
          </Text>
          <Text mt="16px">
            Срок лишения свободы за взятку свыше 1 млн — от 8 до 15 лет + штраф до 70 сумм взятки
          </Text>
        </>
      ),
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center" p={[16, 0]}>
          <Box
            p="20px 40px"
            maxWidth="500px"
            sx={{background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
          >
            <Table>
              <tr>
                <th>Размер полученной взятки</th>
                <th>Штраф</th>
              </tr>

              <tr>
                <td>
                  <b>до 25 тыс.руб.</b>
                </td>
                <td>
                  <b>до 1 млн руб.</b>
                  <br/>
                  или 10-50 сумм взятки
                </td>
              </tr>

              <tr>
                <td>
                  <b>25-150 тыс. руб.</b>
                </td>
                <td>
                  <b>штраф до 1,5 млн руб.</b>
                  <br/>
                  или 30-60 сумм взятки
                </td>
              </tr>

              <tr>
                <td>
                  <b>150 тыс. - 1 млн руб.</b>
                </td>
                <td>
                  <b>до 4 млн руб.</b>
                  <br/>
                  или 70-90 сумм взятки
                </td>
              </tr>

              <tr>
                <td>
                  <b>свыше 1 млн руб.</b>
                </td>
                <td>
                  <b>до 5 млн руб.</b>
                  <br/>
                  или 80-100 сумм взятки
                </td>
              </tr>
            </Table>
          </Box>
        </Flex>
      ),
      bg: 'm2t4',
      nextLink: '/course/module/2/theory/5',
    },
    {
      id: 5,
      title: <TitleT5 isNotBlockBackground>За взятки и ненадлежащие платежи все несут уголовную ответственность</TitleT5>,
      bg: 'm2t5',
      nextLink: '/course/module/2/theory/6',
      isNotBlockBackground:true,
    },
    {
      id: 6,
      title:  <TitleT5 isNotBlockBackground>За взятки и ненадлежащие платежи все несут уголовную ответственность</TitleT5>,
      bg: 'm2t6',
      nextLink: '/course/module/2/theory/7',
      isNotBlockBackground:true,
    },
    {
      id: 7,
      title: 'За незаконное вознаграждение наказать* могут и компанию',
      modalContent,
      text: (
        <Flex alignItems="flex-start">
          <Text>* Кодекс об административных правонарушениях РФ, статья 19.28 </Text>
          <Box ml="8px" display="inline">
            <HelpIcon style={{fill: '#6d12f5', cursor: 'pointer'}} className="modalOpener"/>
          </Box>
        </Flex>
      ),
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center" p={[16, 24, 0]}>
          <Box
            p="40px"
            maxWidth="640px"
            sx={{background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
          >
            <Text color="#6d12f5" fontSize="22px" mb="20px">
              Незаконное вознаграждение
            </Text>
            <CustomText>
              Это не только сама передача, но и <strong>предложение или обещание</strong> от имени
              или в интересах юридического лица:
            </CustomText>
            <CustomText>
              <ul>
                <CustomLi>денег, ценных бумаг или иного имущества,</CustomLi>
                <CustomLi>оказание услуг имущественного характера,</CustomLi>
                <CustomLi>предоставление имущественных прав.</CustomLi>
              </ul>
            </CustomText>
            <Box my="24px">
              <CustomText>
                В том случае, если это вознаграждение за действия или бездействия с использованием
                служебного положения в интересах юр. лица.
              </CustomText>
            </Box>
            <CustomText>
              Кому запрещено передавать, предлагать или обещать вознаграждение:
            </CustomText>
            <CustomText>
              <ul>
                <CustomLi>любым должностным лицам, в том числе иностранным,</CustomLi>
                <CustomLi>
                  сотрудникам, выполняющим управленческие функции в коммерческой или иной
                  организации,
                </CustomLi>
                <CustomLi>должностным лицам публичной международной организации.</CustomLi>
              </ul>
            </CustomText>
          </Box>
        </Flex>
      ),
      nextLink: '/course/module/2/theory/8',
    },
    {
      id: 8,
      flex: 0.9,
      title: 'За незаконное вознаграждение наказать* могут и компанию',
      bg: 'm2t8',
      modalContent,
      text: (
        <Flex alignItems="flex-start">
          <Text>* Кодекс об административных правонарушениях РФ, статья 19.28 </Text>
          <Box ml="8px" display="inline">
            <HelpIcon style={{fill: '#6d12f5', cursor: 'pointer'}} className="modalOpener"/>
          </Box>
        </Flex>
      ),
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center">
          <Flex flexWrap="wrap" padding={[16, 16, 16, 0]}>
            <Box width="100%" mb="20px">
              <Box
                width={[1, 1, 1 / 2]}
                p={[24, 24, 40]}
                sx={{background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
              >
                <Image src={images.m2t8img1} alt="" maxHeight="40px"/>
                <Text fontSize="22px" m="20px 0 10px">
                  Конфискация
                </Text>
                <CustomText>
                  денег, ценных бумаг, иного имущества или стоимости услуг имущественного характера,
                  иных имущественных прав
                </CustomText>
              </Box>
            </Box>

            <Box
              width={[1, 1, 1 / 2]}
              p={[24, 24, 40]}
              mb="20px"
              sx={{background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
            >
              <Image src={images.m2t8img2} alt="" maxHeight="40px"/>
              <Text fontSize="22px" m="20px 0 10px">
                Штраф
              </Text>
              <CustomText>
                до 100 сумм взятки — не менее 1 млн рублей (не менее 100 млн рублей, если взятка
                более 20 млн рублей)
              </CustomText>
            </Box>

            <Box
              width={[1, 1, 'calc(50% - 20px)']}
              p={[24, 24, 40]}
              mb="20px"
              ml={[0, 0, 20]}
              sx={{background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
            >
              <Image src={images.m2t8img3} alt="" maxHeight="40px"/>
              <Text fontSize="22px" m="20px 0 10px">
                Запрет
              </Text>
              <CustomText>
                на участие в закупках для государственных и муниципальных нужд на 2 года
              </CustomText>
            </Box>
          </Flex>
        </Flex>
      ),
      nextLink: '/course/module/2/theory/9',
    },
    {
      id: 9,
      flex: 1.7,
      title: <CustomHeader>Нам важно быть уверенными, что коррупционный платёж не заложен в стоимость
        услуг</CustomHeader>,
      text: (
        <CustomContent>
          <Text fontWeight="bold" mb="16px">
            Наш договор включает антикоррупционную оговорку. Значит, поставщик подтверждает, что он
          </Text>
          <ul>
            <CustomLi>знает, что «Ростелеком» ведёт антикоррупционную политику;</CustomLi>
            <CustomLi>ознакомился с Кодексом деловой этики поставщика «Ростелекома»;</CustomLi>
            <CustomLi>
              полностью понимает положения Кодекса и обязуется обеспечивать соблюдение его
              требований как со своей стороны, так и со стороны субподрядчиков
            </CustomLi>
          </ul>
        </CustomContent>
      ),
      bg: 'm2t9',
      nextLink: '/course/module/2/case/1',
    },
  ],
  case: [
    {
      id: 1,
      title: 'Кейс: взятки не гладки',
      text: 'В 2010 году известная скандинавская сеть по продаже мебели привлекла посредника для строительства ТЦ в Санкт-Петербурге. Компания-подрядчик заплатила взятку «для разрешения ситуации с энергоснабжением торгового центра». В результате пострадала репутация мебельной сети, так как о взятке знали топ-менеджеры, но никак не отреагировали, и их пришлось уволить.',
      bg: 'm2case1',
      nextLink: '/course/module/2/case/2',
    },
    {
      id: 2,
      title: 'Кейс: наказание по двойной ставке',
      text: (
        <>
          <Text color="#fff" mb="16px">
            Сотрудник компании-подрядчика, ошибочно полагая, что действует во благо подрядчика и в
            интересах «Заказчика», передал представителю министерства деньги, чтобы ускорить
            получение разрешения на строительство объекта.
          </Text>
          <Text color="#fff">
            Руководство подрядчика об этом знало, поэтому наказали сразу всех виновных: сотрудника
            привлекли к уголовной ответственности, а компанию-подрядчика — к административной.
            «Заказчик» понес репутационные потери.
          </Text>
        </>
      ),
      bg: 'm2case2',
      nextLink: '/course/module/2/check/2',
    },
  ],
  conclusion: [
    {
      id: 1,
      title: 'Решая проблемы, важно всегда оставаться в рамках закона',
      text: [
        'Мы в «Ростелекоме» нетерпимо относимся к любым проявлениям коррупции. И побуждаем наших партнёров работать так же.',
        'Никогда не берите и не предлагайте взятки сотрудникам «Ростелекома», госслужащим или иным лицам. Так победим!',
      ],
      bg: 'm2conclusion',
      nextLink: '/course/module/3',
    },
  ],
};

export default module2;
