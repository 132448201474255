import { useCallback } from 'react';
import { useToggle } from 'react-use';
import { useDropzone } from 'react-dropzone';
import { isEmpty } from 'ramda';

export const errorMessages = (maxSize) => ({
  'file-too-large': `Максимально допустимый размер файла ${(maxSize / Math.pow(1024, 2)).toFixed(
    0
  )} мб`,
  'file-invalid-type':
    'Пожалуйста, выберите файл следующих типов: pdf, doc, docx.',
});

const onDropRejected = (maxSize) => (files) => {
  if (files.length > 1 || isEmpty(files)) return;

  const code = files[0]?.errors[0]?.code;
  throw new Error(errorMessages(maxSize)[code]);
};



export const useDropZone = ({ disabled, accept, onChange, multiple, maxSize,setFileError }) => {
  const [isDropFile, toggle] = useToggle(false);
  const onError = (err) => setFileError(err);

  const onDropAccepted = useCallback((acceptedFiles) => {
    toggle(false);
    onChange?.(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    noClick: true,
    onDragLeave: () => toggle(false),
    onDragEnter: () => toggle(true),
    accept,
    disabled,
    maxSize,
    onDropRejected: onDropRejected(maxSize),
    multiple,
    onError,
  });

  return { getRootProps, getInputProps, open, isDropFile };
};
