import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Flex} from 'rebass';
import {makeStyles} from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HttpsIcon from '@material-ui/icons/Https';
import Paper from '@material-ui/core/Paper';

import {ACCESS, ARTICLE_STATUSES_TITLES} from './constants';

function EnhancedTableHead(props) {
  const {classes, onRequestSort, headCells} = props;
  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" className={classes.headCell}>
            <TableSortLabel
              // active={headCell.status !== null}
              // onClick={() => onRequestSort(headCell.id)}
              direction={headCell.status ? 'asc' : 'desc'}
              hideSortIcon
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="del" className={classes.headCell}/>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  head: {},
  headCell: {
    fontWeight: '700',
    padding: '16px 24px',
  },
  row: {},
  cell: {
    padding: '0 24px',
  },
  cellTitle: {
    color: '#0088cc',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
                                        rows,
                                        headCells,
                                        handleRequestSort,
                                        pagination = 0,
                                        setPagination,
                                        selectItemToDelete,
                                        allElements = 0,
                                        page = 0,
                                        setPage = () => {
                                        },
                                        onPageChange = () => {
                                        },
                                        isDelete = true
                                      }) {
  const classes = useStyles();

  const location = useLocation().pathname;

  const handleChangePagination = (event) => {
    setPagination(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={2}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow className={classes.row} tabIndex={-1} key={row.id}>
                    {Object.keys(row).map((propKey) => {
                      if (
                        [
                          'id',
                          'access',
                          'type',
                          'materialType',
                          'docCategory',
                          'dataType',
                        ].includes(propKey)
                      ) {
                        return null;
                      }
                      if (['title', 'login'].includes(propKey)) {
                        return (
                          <TableCell
                            className={classes.cell}
                            align="left"
                            key={propKey}
                            width={propKey === 'title' ? '480px' : 'auto'}
                          >
                            <Link
                              to={`${location}/edit/${row.dataType ? `${row.dataType}/` : ''}${
                                row.id
                              }`}
                              className={classes.cellTitle}
                            >
                              <Flex alignItems="flex-start">
                                {row.access === ACCESS.PRIVATE ? (
                                  <HttpsIcon
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      marginRight: '8px',
                                      fill: '#888',
                                    }}
                                  />
                                ) : null}
                                {row[propKey]}
                              </Flex>
                            </Link>
                          </TableCell>
                        );
                      }
                      if (propKey === 'name') {
                        return (
                          <TableCell
                            className={classes.cell}
                            align="left"
                            key={propKey}
                            width={propKey === 'name' ? '480px' : 'auto'}
                          >
                            <Link
                              to={`${location}/${row.id}`}
                              className={classes.cellTitle}
                            >
                              <Flex alignItems="flex-start">
                                {row[propKey]}
                              </Flex>
                            </Link>
                          </TableCell>
                        );
                      }
                      if (propKey === 'number') {
                        return (
                          <TableCell key={propKey} className={classes.cell} align="left">
                            {row?.number}
                          </TableCell>
                        );
                      }
                      if (propKey === 'date') {
                        return (
                          <TableCell key={propKey} className={classes.cell} align="left">
                            {row?.date}
                          </TableCell>
                        );
                      }
                      if (propKey === 'status') {
                        return (
                          <TableCell key={propKey} className={classes.cell} align="left">
                            {ARTICLE_STATUSES_TITLES[row[propKey]]}
                          </TableCell>
                        );
                      }
                      if (propKey === 'feedbackStatus') {
                        return (
                          <TableCell key={propKey} className={classes.cell} align="left">
                            {row?.feedbackStatus}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={propKey} className={classes.cell} align="left">
                          {row[propKey]}
                        </TableCell>
                      );
                    })}

                    <TableCell align="center">  {isDelete ?
                      <DeleteForeverIcon
                        style={{
                          fill: '#f44336',
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                        }}
                        onClick={() => selectItemToDelete(row.id)}
                      />: <div  style={{
                        width: '24px',
                        height: '24px',
                      }}/>
                   } </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Отображать по:"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={allElements}
          rowsPerPage={pagination}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleChangePagination}
          backIconButtonProps={{
            onClick: () => {
              setPage((prevState) => prevState - 1);
            },
          }}
          nextIconButtonProps={{
            onClick: () => {
              setPage((prevState) => prevState + 1);
            },
          }}
          labelDisplayedRows={({from, to, count}) =>
            `${from}-${to} из ${count !== -1 ? count : to}`
          }
        />
      </Paper>
    </div>
  );
}
