import styled from "styled-components";

export const Content = styled.div`
  display: grid;
  font-weight: normal;
  grid-template-columns:340px  240px 240px;
  grid-gap: 2px;

  div {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    box-sizing: border-box;
    padding-left: 3px;
    border-style: solid;
    border-color: #fcfcfc #aeaeae #aeaeae #fcfcfc;

  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
