import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { find, get } from 'lodash';
import { Icon } from 'atoms';

const RequiredLabel = styled.span`
  margin-left: 4px;
  color: #2f9aff;
`;

const Label = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 12px;
  line-height: 16px;
  color: #90949b;
`;

const Wrapper = styled.div`
  background: ${({ forAdminPage }) => (forAdminPage ? '#fff' : 'auto')};
  padding: ${({ forAdminPage }) => (forAdminPage ? '0 16px' : '0')};
  display: flex;
  position: relative;
  width: ${({ width = 'auto' }) => width};
  margin: ${({ margin = 0 }) => margin};
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #d9d9d9;
`;

const LabelWrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: ${({ hasSelected }) => (hasSelected ? '20px 46px 4px 0' : '12px 0')};
  ${Label} {
    display: ${({ hasSelected }) => (hasSelected ? 'block' : 'none')};
  }
`;

const Selected = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: ${({ isDisabled }) => (isDisabled ? 'rgba(16, 24, 40, 0.5)' : '#101828')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  padding: 4px 0;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(28, 41, 61, 0.1), 0px 36px 36px rgba(28, 41, 61, 0.1);
  z-index: 9999;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding: 4px 0;
  width: 100%;
  overflow: auto;
  max-height: ${({ listHeight }) => listHeight};
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const ListItem = styled.div`
  z-index: 9999;
  padding: 8px 12px;
  color: ${({ selected }) => (selected ? '#fff' : '#101828')};
  background: ${({ selected }) => (selected ? '#7800ff' : 'transparent')};
  user-select: none;
  word-break: break-word;
  &:hover {
    color: #fff;
    background: #a04dff;
  }
`;

const OuterWrapper = styled.div`
  width: 100%;
`;

const NewSelect = ({
  label,
  selected = null,
  disabled,
  options = [],
  onChange,
  margin,
  name,
  listHeight = '200px',
  required,
  width,
  forAdminPage = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selected);
  const iconName = isOpen ? 'arrow-up' : 'arrow-down';
  const isDisabled = disabled || options.length === 0;
  const hasSelected = selectedOption !== null && find(options, ['value', selectedOption]);

  const selectedTitle = selectedOption?.title || label;

  const toggle = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  const onOptionClicked = (option, itemName) => () => {
    onChange(option, itemName);
    setIsOpen(false);
  };

  return (
    <OuterWrapper>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <Wrapper margin={margin} width={width} forAdminPage={forAdminPage}>
          <LabelWrap onClick={toggle} hasSelected={hasSelected}>
            <Label>
              {label}
              {required ? <RequiredLabel>*</RequiredLabel> : null}
            </Label>
            <Selected isDisabled={isDisabled}>
              {selectedTitle} {required && !hasSelected ? <RequiredLabel>*</RequiredLabel> : null}
            </Selected>
            <StyledIcon name={iconName} />
          </LabelWrap>
          {isOpen && (
            <ListWrapper>
              <List listHeight={listHeight}>
                {options.map((option) => (
                  <ListItem
                    onClick={onOptionClicked(option, name)}
                    selected={selectedOption === option.options}
                    key={option.title}
                  >
                    {option.title}
                  </ListItem>
                ))}
              </List>
            </ListWrapper>
          )}
        </Wrapper>
      </OutsideClickHandler>
    </OuterWrapper>
  );
};

export default memo(NewSelect);
