import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { IDLE, LOADING, FULFILLED, ERROR, EMPTY } from '../statusConstants';
import { ACCESS, MATERIAL_TYPES, ARTICLE_STATUSES } from '../../modules/Admin/components/constants';

export const fetchFaqs = createAsyncThunk('Faqs/fetchFaqs', async (arg, { getState }) => {
  const state = getState();
  await new Promise((resolve) => setTimeout(resolve, 2000));
  return mokeFaqs;
});

export const createFaqs = createAsyncThunk('Faqs/createFaqs', async (data) => {
  console.log(data);
});

export const fetchFaqsById = createAsyncThunk('article/fetchFaqsById', async (id) => {
  //тут будет запрос на данные конкретной статьи
  return mokeData;
});

export const deleteFaqsById = createAsyncThunk('Faqs/deleteFaqsById', async (id) => {
  // отправляем данные
  return mokeFaqs.filter((item) => item.id !== id);
});

const FaqsSlice = createSlice({
  name: 'faqs',
  initialState: {
    listOfFaqs: [],
    dateRange: [1627761600, 1643107212],
    filter: {
      status: ARTICLE_STATUSES.ALL,
      date: [1627761600, 1643107212],
      access: ACCESS.ALL,
      type: MATERIAL_TYPES.ALL,
    },
    status: IDLE,
    pageNum: 10,
    sort: {
      dateSort: true,
      titleSort: null,
      statusSort: null,
    },
    data: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setPagination: (state, action) => {
      state.pageNum = action.payload;
    },
    setSort: (state, action) => {
      for (const key in state.sort) {
        if (action.payload === key) {
          state.sort[key] = !state.sort[key];
        } else {
          state.sort[key] = null;
        }
      }
    },
  },
  extraReducers: {
    [fetchFaqs.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchFaqs.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.listOfFaqs = action.payload;
        state.status = FULFILLED;
      } else {
        state.status = EMPTY;
      }
    },
    [fetchFaqs.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [createFaqs.pending]: (state, action) => {
      state.status = LOADING;
    },
    [createFaqs.fulfilled]: (state, action) => {},
    [createFaqs.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [deleteFaqsById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [deleteFaqsById.fulfilled]: (state, action) => {
      state.listOfFaqs = action.payload;
      state.status = FULFILLED;
    },
    [deleteFaqsById.rejected]: (state, action) => {
      state.status = ERROR;
    },

    [fetchFaqsById.pending]: (state, action) => {
      state.status = LOADING;
    },
    [fetchFaqsById.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.data = action.payload;
      state.status = FULFILLED;
    },
    [fetchFaqsById.rejected]: (state, action) => {
      state.status = ERROR;
    },
  },
});

export const { setFilter, setPagination, setSort } = FaqsSlice.actions;

export default FaqsSlice.reducer;

const mokeData = {
  title: 'QQQQQQ',
  status: 'UNPUBLISHED',
  files: [],
  text: '<p>aasass</p>',
  access: 'PRIVATE',
};

const mokeFaqs = [
  {
    id: 1,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 2,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 3,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 4,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 5,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 6,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 7,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 8,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 9,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 10,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 11,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 12,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
  {
    id: 13,
    date: 1627761600,
    title: 'Как дарить и принимать подарки? По-честному, соблюдая закон.',
    status: 'UNPUBLISHED',
    access: 'PUBLIC',
  },
];
