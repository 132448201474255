import { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';

import { EMPTY, ERROR, FULFILLED, LOADING } from '../store/statusConstants';

export const useStatuses = (loading, value, error, empty = false) => {
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (loading) {
      setStatus(LOADING);
    }
    if (value) {
      setStatus(FULFILLED);
    }
    if (empty && isEmpty(value)) {
      setStatus(EMPTY);
    }
    if (error) {
      setStatus(ERROR);
    }
  }, [loading, value, error]);

  return { status };
};
