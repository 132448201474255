import React, { memo } from 'react';
import styled from 'styled-components';

const getPath = (name, props) => {
  switch (name) {
    case 'menu-news':
      return (
        <g id="Group_132" data-name="Group 132" transform="translate(6121 86)" fill="#fff">
          <rect
            id="Rectangle_14"
            data-name="Rectangle 14"
            width="24"
            height="24"
            transform="translate(-6121 -86)"
          />
          <g
            id="Rectangle_15"
            data-name="Rectangle 15"
            stroke="#7800ff"
            transform="translate(-6121 -86)"
          >
            <path d="M0,0H20a0,0,0,0,1,0,0V24a0,0,0,0,1,0,0H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z" />
            <path d="M1.5.75h17a.75.75,0,0,1,.75.75v21a.75.75,0,0,1-.75.75H3A2.25,2.25,0,0,1,.75,21V1.5A.75.75,0,0,1,1.5.75Z" />
          </g>
          <line
            id="Line_3"
            data-name="Line 3"
            stroke="#7800ff"
            strokeWidth="1.5"
            x2="13"
            transform="translate(-6117.5 -80)"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            stroke="#7800ff"
            strokeWidth="1.5"
            x2="13"
            transform="translate(-6117.5 -76)"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            stroke="#7800ff"
            strokeWidth="1.5"
            x2="13"
            transform="translate(-6117.5 -72)"
          />
          <line
            id="Line_6"
            data-name="Line 6"
            stroke="#7800ff"
            strokeWidth="1.5"
            x2="13"
            transform="translate(-6117.5 -68)"
          />
          <g
            id="Rectangle_16"
            data-name="Rectangle 16"
            stroke="#7800ff"
            transform="translate(-6102.5 -70)"
          >
            <path d="M0,0H5A0,0,0,0,1,5,0V5A3,3,0,0,1,2,8H0A0,0,0,0,1,0,8V0A0,0,0,0,1,0,0Z" />
            <path d="M1.5.75h2a.75.75,0,0,1,.75.75V5A2.25,2.25,0,0,1,2,7.25H1.5A.75.75,0,0,1,.75,6.5v-5A.75.75,0,0,1,1.5.75Z" />
          </g>
        </g>
      );

    case 'menu-materials':
      return (
        <g id="Group_138" data-name="Group 138" transform="translate(6121.239 86.21)" fill="#fff">
          <rect
            id="Rectangle_14"
            data-name="Rectangle 14"
            width="24"
            height="24"
            transform="translate(-6121 -86)"
          />
          <g id="Group_147" data-name="Group 147">
            <path
              id="Path_9"
              data-name="Path 9"
              stroke="#7800ff"
              strokeWidth="1.5px"
              d="M14.3,14.9,8.4,20.8a10.328,10.328,0,0,1,.5-7c.1-.2,0-.3-.1-.5L2.9,6.5c0-.1-.2-.2-.3-.2H2.5A4.579,4.579,0,0,1,0,5.9L5.9,0a3.842,3.842,0,0,1,.4,2.6c0,.2,0,.3.1.4l6.9,5.9a.749.749,0,0,0,.5.1,9.891,9.891,0,0,1,6.9-.4Z"
              transform="translate(-6120 -85)"
            />
            <line
              id="Line_16"
              data-name="Line 16"
              stroke="#7800ff"
              strokeWidth="1.5px"
              x2="7"
              y2="7"
              transform="translate(-6105.5 -70.5)"
            />
          </g>
        </g>
      );

    case 'menu-faqs':
      return (
        <g id="Group_136" data-name="Group 136" transform="translate(6121 86)" fill="#fff">
          <rect
            id="Rectangle_14"
            data-name="Rectangle 14"
            width="24"
            height="24"
            transform="translate(-6121 -86)"
          />
          <g
            id="Rectangle_17"
            data-name="Rectangle 17"
            stroke="#7800ff"
            strokeWidth="1.5px"
            transform="translate(-6121 -86)"
          >
            <path d="M4,0H18a4,4,0,0,1,4,4v8a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z" />
            <path d="M4,.75H18A3.25,3.25,0,0,1,21.25,4v8A3.25,3.25,0,0,1,18,15.25H1.5a.75.75,0,0,1-.75-.75V4A3.25,3.25,0,0,1,4,.75Z" />
          </g>
          <g
            id="Rectangle_15"
            data-name="Rectangle 15"
            stroke="#7800ff"
            strokeWidth="1.5px"
            transform="translate(-6117 -80)"
          >
            <path
              stroke="none"
              d="M4,0H16a4,4,0,0,1,4,4V16a0,0,0,0,1,0,0H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
            />
            <path d="M4,.75H16A3.25,3.25,0,0,1,19.25,4V14.5a.75.75,0,0,1-.75.75H4A3.25,3.25,0,0,1,.75,12V4A3.25,3.25,0,0,1,4,.75Z" />
          </g>
          <path
            id="Path_6"
            data-name="Path 6"
            fill="#7800ff"
            d="M3.936-2.5H2.424v-.192a1.944,1.944,0,0,1,.222-.954,3.149,3.149,0,0,1,.81-.87,4.271,4.271,0,0,0,.858-.858,1.421,1.421,0,0,0,.246-.81.9.9,0,0,0-.348-.744,1.4,1.4,0,0,0-.9-.276,1.461,1.461,0,0,0-.966.324,1.066,1.066,0,0,0-.39.864,1.215,1.215,0,0,0,.084.444L.66-5.28a2.03,2.03,0,0,1-.168-.864A2.272,2.272,0,0,1,1.266-7.83a2.793,2.793,0,0,1,2.01-.738A3.058,3.058,0,0,1,5.3-7.944a2.053,2.053,0,0,1,.744,1.656,2.434,2.434,0,0,1-.324,1.266A5.861,5.861,0,0,1,4.5-3.708a1.608,1.608,0,0,0-.444.546A1.639,1.639,0,0,0,3.936-2.5ZM2.388,0V-1.656H3.972V0Z"
            transform="translate(-6110 -68)"
          />
        </g>
      );

    case 'menu-documents':
      return (
        <g id="Group_139" data-name="Group 139" transform="translate(6121 86)" fill="#fff">
          <rect
            id="Rectangle_14"
            data-name="Rectangle 14"
            width="24"
            height="24"
            transform="translate(-6121 -86)"
          />
          <g id="Group_145" data-name="Group 145" transform="translate(3 -1)">
            <g
              id="Path_8"
              data-name="Path 8"
              strokeLinejoin="round"
              transform="translate(-6121 -84)"
            >
              <path stroke="none" d="M0,0H12l6,7V22H0Z" />
              <path
                fill="#7800ff"
                stroke="none"
                d="M 1.5 1.5 L 1.5 20.5 L 16.5 20.5 L 16.5 7.554883003234863 L 11.31010055541992 1.5 L 1.5 1.5 M 0 0 L 12 0 L 18 7 L 18 22 L 0 22 L 0 0 Z"
              />
            </g>
            <line
              id="Line_8"
              data-name="Line 8"
              stroke="#7800ff"
              strokeWidth="1.5px"
              x2="11"
              transform="translate(-6117.5 -73)"
            />
            <line
              id="Line_9"
              data-name="Line 9"
              stroke="#7800ff"
              strokeWidth="1.5px"
              x2="11"
              transform="translate(-6117.5 -70)"
            />
            <line
              id="Line_10"
              data-name="Line 10"
              stroke="#7800ff"
              strokeWidth="1.5px"
              x2="6"
              transform="translate(-6117.5 -67)"
            />
            <g id="Path_7" data-name="Path 7" stroke="none" transform="translate(-6110 -84)">
              <path
                stroke="none"
                d="M 5.369338989257813 7.41666316986084 L 0.75 7.41666316986084 L 0.75 2.027438402175903 L 5.369338989257813 7.41666316986084 Z"
              />
              <path
                stroke="none"
                fill="#7800ff"
                d="M 1.5 4.05488395690918 L 1.5 6.66666316986084 L 3.738669633865356 6.66666316986084 L 1.5 4.05488395690918 M 0 2.86102294921875e-06 L 7 8.16666316986084 L 0 8.16666316986084 L 0 2.86102294921875e-06 Z"
              />
            </g>
          </g>
        </g>
      );

    case 'menu-education':
      return (
        <g id="Group_143" data-name="Group 143" transform="translate(6121.25 86)" fill="#fff">
          <rect
            id="Rectangle_14"
            data-name="Rectangle 14"
            fill="none"
            width="24"
            height="24"
            transform="translate(-6121 -86)"
          />
          <g
            id="Rectangle_19"
            data-name="Rectangle 19"
            stroke="#7800ff"
            strokeWidth="1.5px"
            fill="#fff"
            transform="translate(-6121 -83)"
          >
            <rect stroke="none" width="24" height="16" rx="2" />
            <rect fill="none" x="0.75" y="0.75" width="22.5" height="14.5" rx="1.25" />
          </g>
          <line
            id="Line_15"
            data-name="Line 15"
            stroke="#7800ff"
            strokeWidth="1.5px"
            strokeLinecap="round"
            fill="none"
            x2="23"
            transform="translate(-6120.5 -65)"
          />
          <g id="Group_146" data-name="Group 146" transform="translate(-7007 -164)">
            <path
              id="Subtraction_1"
              data-name="Subtraction 1"
              stroke="#7800ff"
              strokeWidth="1.5px"
              fill="#fff"
              d="M6244,8a4,4,0,1,1,0-8V4h4A4,4,0,0,1,6244,8Z"
              transform="translate(-5347 86)"
            />
            <g
              id="Intersection_1"
              data-name="Intersection 1"
              fill="#fff"
              transform="translate(-5346 83)"
            >
              <path
                stroke="none"
                d="M 6249.18359375 4.250518798828125 L 6245.74951171875 4.250518798828125 L 6245.74951171875 0.8162516355514526 C 6247.49365234375 1.127908945083618 6248.8720703125 2.506567239761353 6249.18359375 4.250518798828125 Z"
              />
              <path
                stroke="none"
                fill="#7800ff"
                d="M 6248.162109375 3.500518798828125 C 6247.81591796875 2.773708343505859 6247.22607421875 2.184064149856567 6246.49951171875 1.837921261787415 L 6246.49951171875 3.500518798828125 L 6248.162109375 3.500518798828125 M 6250 5.000518798828125 L 6244.99951171875 5.000518798828125 L 6244.99951171875 -1.315841700488818e-06 C 6247.76171875 -1.315841700488818e-06 6250 2.238308668136597 6250 5.000518798828125 Z"
              />
            </g>
          </g>
        </g>
      );

    case 'menu-feedback':
      return (
        <g id="Group_141" data-name="Group 141" transform="translate(6121 86)" fill="#fff">
          <rect
            id="Rectangle_14"
            data-name="Rectangle 14"
            fill="none"
            width="24"
            height="24"
            transform="translate(-6121 -86)"
          />
          <g
            id="Rectangle_18"
            data-name="Rectangle 18"
            stroke="#7800ff"
            strokeWidth="1.5px"
            fill="#fff"
            transform="translate(-6121 -83)"
          >
            <rects stroke="none" width="24" height="18" rx="2" />
            <rect fill="none" x="0.75" y="0.75" width="22.5" height="16.5" rx="1.25" />
          </g>
          <line
            id="Line_11"
            data-name="Line 11"
            stroke="#7800ff"
            strokeWidth="1.5px"
            strokeLinecap="square"
            fill="none"
            x1="9"
            y2="9"
            transform="translate(-6109 -81.5)"
          />
          <line
            id="Line_12"
            data-name="Line 12"
            stroke="#7800ff"
            strokeWidth="1.5px"
            strokeLinecap="square"
            fill="none"
            x2="9"
            y2="9"
            transform="translate(-6118 -81.5)"
          />
          <line
            id="Line_13"
            data-name="Line 13"
            stroke="#7800ff"
            strokeWidth="1.5px"
            fill="none"
            y1="6"
            x2="6"
            transform="translate(-6119.5 -72.5)"
          />
          <line
            id="Line_14"
            data-name="Line 14"
            stroke="#7800ff"
            strokeWidth="1.5px"
            fill="none"
            x1="6"
            y1="6"
            transform="translate(-6104.5 -72.5)"
          />
        </g>
      );

    case 'menu-quiz':
      return (
        <g id="Group_221" data-name="Group 221" transform="translate(-15200 -6006)" fill="#fff">
          <rect
            id="Rectangle_64"
            data-name="Rectangle 64"
            fill="#fff"
            width="24"
            height="24"
            transform="translate(15200 6006)"
          />
          <g
            id="Ellipse_93"
            data-name="Ellipse 93"
            stroke="#7800ff"
            strokeWidth="1.5px"
            fill="none"
            transform="translate(15201 6007)"
          >
            <circle stroke="none" cx="11" cy="11" r="11" />
            <circle fill="none" cx="11" cy="11" r="10.25" />
          </g>
          <path
            id="Path_509"
            data-name="Path 509"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#7800ff"
            strokeWidth="1.5px"
            fill="none"
            d="M16191.168,5979.809l4.434,4.433,11.441-11.441"
            transform="translate(-984 37)"
          />
        </g>
      );

    case 'burger':
      return (
        <g {...props}>
          <rect x="0" y="277.333" width="384" height="42.667" />
          <rect x="0" y="170.667" width="384" height="42.667" />
          <rect x="0" y="64" width="384" height="42.667" />
        </g>
      );

    case 'cancel':
      return (
        <path
          d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
          {...props}
        />
      );

    case 'arrow-up':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7056 14.2957L17.2914 15.71L12.9985 11.4171L8.70557 15.71L7.29135 14.2957L12.9985 8.58864L18.7056 14.2957Z"
          fill="#101828"
          {...props}
        />
      );

    case 'arrow-down':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29297 9.70718L7.70718 8.29297L12.0001 12.5859L16.293 8.29297L17.7072 9.70718L12.0001 15.4143L6.29297 9.70718Z"
          fill="#101828"
          {...props}
        />
      );

    case 'filter':
      return (
        <path
          d="M178.5,382.5h102v-51h-102V382.5z M0,76.5v51h459v-51H0z M76.5,255h306v-51h-306V255z"
          {...props}
        />
      );

    case 'play':
      return (
        <path
          fill="#ff4f13"
          d="M295.84,146.049l-256-144c-4.96-2.784-11.008-2.72-15.904,0.128C19.008,5.057,16,10.305,16,16.001v288  c0,5.696,3.008,10.944,7.936,13.824c2.496,1.44,5.28,2.176,8.064,2.176c2.688,0,5.408-0.672,7.84-2.048l256-144  c5.024-2.848,8.16-8.16,8.16-13.952S300.864,148.897,295.84,146.049z"
          {...props}
        />
      );

    case 'document':
      return (
        <g>
          <rect fill="none" width="20" height="20" />
          <g fill="none" strokeMiterlimit="10" transform="translate(2)">
            <path
              stroke="none"
              d="M14,20H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0h8l6,6V18A2,2,0,0,1,14,20ZM9,1.5V7h5.5Z"
            />
            <path
              stroke="none"
              fill="#7800ff"
              d="M 13.9991283416748 17.99979972839355 C 13.99955272674561 17.99949836730957 13.99991703033447 17.99927711486816 14.00012397766113 17.99927711486816 C 14.00015544891357 17.99927711486816 14.00018215179443 17.99928283691406 14.00020599365234 17.99929237365723 L 14.00020599365234 9.000200271606445 L 9.000005722045898 9.000200271606445 L 7.000005722045898 9.000200271606445 L 7.000005722045898 7.000199794769287 L 7.000005722045898 1.999485969543457 L 2.003015756607056 1.998629808425903 C 2.002056121826172 1.999178290367126 2.000596523284912 2.000523328781128 2.000087261199951 2.000523328781128 C 2.000056266784668 2.000523328781128 2.000029325485229 2.000517845153809 2.000005722045898 2.000507354736328 L 1.99863588809967 17.99678993225098 C 1.999217629432678 17.9978084564209 2.000695466995239 17.99938774108887 2.000513792037964 17.99979972839355 L 13.9991283416748 17.99979972839355 M 14.00040626525879 19.99979972839355 L 1.999805808067322 19.99979972839355 C 0.8971158862113953 19.99979972839355 5.857848918822128e-06 19.10268974304199 5.857848918822128e-06 18 L 5.857848918822128e-06 1.999799847602844 C 5.857848918822128e-06 0.8971098661422729 0.8971158862113953 -1.358032193365943e-07 1.999805808067322 -1.358032193365943e-07 L 9.999896049499512 -1.358032193365943e-07 L 16.00020599365234 6.00029993057251 L 16.00020599365234 18 C 16.00020599365234 19.10268974304199 15.10309600830078 19.99979972839355 14.00040626525879 19.99979972839355 Z M 9.000005722045898 1.50029981136322 L 9.000005722045898 7.000199794769287 L 14.49990558624268 7.000199794769287 L 9.000005722045898 1.50029981136322 Z"
            />
          </g>
        </g>
      );

    case 'correct':
      return (
        <g transform="translate(9200.043 4831.22)">
          <g transform="translate(0 59)">
            <path
              fill="none"
              stroke="#57d9b7"
              strokeWidth="2px"
              d="M-9047.463-4844.13l6.1,6.1,11.514-11.514"
              transform="translate(-151.873 -39.973)"
            />
          </g>
        </g>
      );

    case 'wrong':
      return (
        <g transform="translate(9194.131 4901.906)">
          <path
            fill="none"
            stroke="#bc104b"
            strokeWidth="2px"
            d="M-9043.482-4818.915l-12.658,12.658"
            transform="translate(-137.283 -82.284)"
          />
          <path
            fill="none"
            stroke="#bc104b"
            strokeWidth="2px"
            d="M12.658,0,0,12.658"
            transform="translate(-9193.424 -4888.541) rotate(-90)"
          />
        </g>
      );

    default:
      return <path />;
  }
};

const Wrapper = styled.div`
  display: inline-flex;
  cursor: ${({ clickable = true }) => (clickable ? 'pointer' : 'auto')};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
`;

const Icon = ({
  name = '',
  style = {},
  w = 24,
  className = '',
  h = 24,
  viewBox = `0 0 ${w} ${h}`,
  onClick,
  clickable,
  m,
  ...otherProps
}) => (
  <Wrapper clickable={clickable} margin={m}>
    <svg
      onClick={onClick}
      width={w}
      style={style}
      height={h}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {getPath(name, otherProps)}
    </svg>
  </Wrapper>
);
export default memo(Icon);
