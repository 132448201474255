import React from 'react';
import {Text, Flex, Box, Image} from 'rebass';
import TextContent from '../components/TextContent';
import CustomLi from '../components/CustomLi';
import images from '../img';
import {Img} from '../styles';
import styled from "styled-components";

const TitleModule = styled.div`
  @media (max-width: 1024px)and ( max-height: 600px) {
    font-size: 2rem;
    line-height: 30px;
  }
`

const module3 = {
  id: 3,
  title: 'Модуль 3. А кто сосед?',
  home: [
    {
      id: 1,
      title: 'Подарки и конфликт интересов',
      text: 'Модуль 3. А кто сосед?',
      bg: 'm3home1',
      nextLinkTitle: 'Третий модуль',
      nextLink: '/course/module/3/intro/1',
    },
  ],
  intro: [
    {
      id: 1,
      title: 'А как же подарки?',
      text: [
        'В году больше 100 дней — выходные и праздники. Поводов для подарков много: Новый год, 23 февраля, 8 марта, профессиональные праздники, дни рождения.',
        'Что если хочется от души поздравить человека? Чисто по-соседски подарить бутылку шампанского или цветы? Разве можно запретить кого-то искренне порадовать? Конечно, нет, но нужно соблюдать правила.',
      ],
      bg: 'm3intro1',
      nextLink: '/course/module/3/intro/2',
    },
    {
      id: 2,
      title: 'А как же подарки?',
      text: [
        'В первой части модуля расскажем, какие подарки уместно и неуместно дарить и что делать, если очень хочется, но нельзя.',
      ],
      bg: 'm3intro1',
      nextLink: '/course/module/3/theory/1',
    },
  ],
  check: [
    {
      id: 1,
      title: 'Проверьте себя',
      text: (
        <>
          <TextContent>
            У менеджера «Ростелекома», с которым вы взаимодействуете, сегодня день рождения. Вы
            решаете отправить ему подарок с благодарностью за сотрудничество. Что подарите?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'Отправлю билет на развлекательное мероприятие до 4000 руб',
        },
        {
          id: 2,
          title: 'Отправлю сертификат на ужин в дорогом ресторане на 5 персон',
        },
        {
          id: 3,
          title: 'Отправлю ежедневник с логотипом нашей компании',
        },
      ],
      correctAnswers: [3],
      correctText: 'Верно! Такой подарок не принесет беды.',
      errorTextOnAttemptsOver: 'Правильные ответы: 3',
      nextLink: '/course/module/3/check/2',
    },
    {
      id: 2,
      title: 'Проверьте себя',
      text: (
        <>
          <TextContent>
            Вы стали победителем закупки «Ростелекома», заключили договор и узнаёте, что
            подразделение, с которым вам предстоит работать, возглавляет ваш двоюродный брат. Что
            сделаете в этой ситуации?
          </TextContent>
          <Text color="#6d12f5">Выберите один ответ.</Text>
        </>
      ),
      questions: [
        {
          id: 1,
          title: 'Ничего не буду делать, никто же не узнает',
        },
        {
          id: 2,
          title: 'Начну работать, не смешивая личное с рабочим, я же профессионал',
        },
        {
          id: 3,
          title: 'Сообщу о потенциальном конфликте интересов в «Ростелеком»',
        },
      ],
      correctAnswers: [3],
      correctText: 'Всё правильно! Контакты вы найдёте в конце курса.',
      errorTextOnAttemptsOver: 'Правильные ответы: 3',
      nextLink: '/course/module/3/conclusion/1',
    },
  ],
  theory: [
    {
      id: 1,
      flex: 1.7,
      title: <TitleModule>Подарок должен соответствовать нашим принципам принятия</TitleModule>,
      text: (
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} pr="8px">
            <ul>
              <CustomLi>Стоит не дороже 3 000 рублей</CustomLi>
              <CustomLi>
                Дарится открыто в рамках официального мероприятия или служебной командировки
              </CustomLi>
              <CustomLi>Это не деньги: независимо от валюты и суммы</CustomLi>
            </ul>
          </Box>
          <Box width={[1, 1, 1 / 2]}>
            <ul>
              <CustomLi>
                Не влияет на объективность решения: то есть его подарили не ради выгоды, а просто
                так
              </CustomLi>
              <CustomLi>Вручается не чаще двух раз в год от одного человека</CustomLi>
              <CustomLi>Передаётся не во время закупки или проверочных мероприятий</CustomLi>
            </ul>
          </Box>
        </Flex>
      ),
      bg: 'm3t1',
      nextLink: '/course/module/3/theory/2',
    },
    {
      id: 2,
      flex: 1.7,
      title: 'Это касается и госслужащих',
      text: (
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} pr="20px">
            <TextContent>
              Ведь если вдруг подарок расценят как взятку, это отразится не только на репутации
              вашей компании, но и на «Ростелекоме».
            </TextContent>
          </Box>
          <Box width={[1, 1, 1 / 2]}>
            <TextContent>
              Если сомневаетесь, стоит ли дарить подарок или как его могут воспринять, лучше ничего
              не дарите.
            </TextContent>
          </Box>
        </Flex>
      ),
      bg: 'm3t2',
      nextLink: '/course/module/3/theory/3',
    },
    {
      id: 3,
      flex: 0.7,
      title: 'Не подвергайте получателя подарка риску',
      text: (
        <>
          <TextContent>
            А чтобы подтвердить, что подарок действительно стоит меньше 3 000 рублей, можно
            отправить его с чеком.
          </TextContent>

          <TextContent>
            Если сомневаетесь, стоит ли дарить подарок или как его могут воспринять, лучше ничего не
            дарите.
          </TextContent>
        </>
      ),
      bg: 'm3t3',
      nextLink: '/course/module/3/theory/4',
    },
    {
      id: 4,
      text: (
        <Img>
          <Image width="100%" maxHeight="480px" src={images.m3t4img1} alt="" className="img1"/>
        </Img>
      ),
      rightText: (
        <Flex alignItems="center" p="16px">
          <Img>
            <Image width="100%" maxHeight="480px" src={images.m3t4img2} alt="" className="img2"/>
          </Img>
        </Flex>
      ),

      nextLink: '/course/module/3/theory/5',
    },
    {
      id: 5,
      flex: 0.7,
      title: <TitleModule>Конфликт интересов хуже ссоры</TitleModule>,
      text: (
        <>
          <TextContent>
            Худой мир лучше доброй ссоры — так гласит народная пословица. Но хуже доброй ссоры
            только конфликт интересов.
          </TextContent>
          <TextContent>
            Кажется, что работать с родственниками или друзьями проще и приятнее, чем с чужими
            людьми. И это действительно так, если не возникает конфликт интересов. Дальше расскажем,
            что это такое и как с этим быть.
          </TextContent>
        </>
      ),
      bg: 'm3t5',
      nextLink: '/course/module/3/theory/6',
    },
    {
      id: 6,
      title: 'Конфликт интересов',
      text: (
        <TextContent>
          это ситуация, при которой личная заинтересованность может повлиять на исполнение рабочих
          обязанностей
        </TextContent>
      ),
      rightText: (
        <Flex flexDirection="column" justifyContent="center" padding={[16, 16, 16, 0]}>
          <Box>
            <Box
              width="100%"
              maxWidth="500px"
              p="40px"
              mb="16px"
              sx={{background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
            >
              <Image src={images.m3t6img1} alt="" maxHeight="40px"/>
              <Text fontSize="22px" m="20px 0 10px">
                Связанная организация:
              </Text>
              <ul>
                <CustomLi>Состоит в деловых отношениях с компанией</CustomLi>
                <CustomLi>Собирается их установить</CustomLi>
                <CustomLi>Является конкурентом</CustomLi>
              </ul>
            </Box>
            <Box
              width="100%"
              maxWidth="500px"
              p="40px"
              sx={{background: 'rgba(255,255,255,.9)', boxShadow: '0 0 7px rgb(0 0 0 / 17%)'}}
            >
              <Image src={images.m3t6img2} alt="" maxHeight="40px"/>
              <Text fontSize="22px" m="20px 0 10px">
                Связанные лица:
              </Text>
              <ul>
                <CustomLi>Близкие родственники сотрудника</CustomLi>
                <CustomLi>
                  Имеют с сотрудником общее имущество, или другие близкие отношения
                </CustomLi>
              </ul>
            </Box>
          </Box>
        </Flex>
      ),
      bg: 'm3t6',
      nextLink: '/course/module/3/theory/7',
    },
    {
      id: 7,
      title: 'Связанными лицами могут быть не только родственники',
      rightText: (
        <Flex width="100%" alignItems="center" justifyContent="center" p="40px">
          <Image src={images.m3t7}/>
        </Flex>
      ),
      nextLink: '/course/module/3/theory/8',
    },
    {
      id: 8,
      flex: 0.7,
      title: 'Конфликт интересов — это не конец света',
      bg: 'm3t8',
      text: (
        <>
          <TextContent>
            Сама по себе ситуация конфликта интересов не всегда является нарушением.
          </TextContent>
          <TextContent>
            Если сообщить о ней сразу, урегулировать в моменте и предотвратить возможные последствия
            — ничего страшного не случится.
          </TextContent>
        </>
      ),
      nextLink: '/course/module/3/check/1',
    },
  ],
  conclusion: [
    {
      id: 1,
      title: 'Сомневаетесь — не дарите подарок',
      text: [
        'Лучше совсем не подарить подарок, чем поставить человека в неудобное положение и подвергнуть компанию репутационным рискам.',
      ],
      bg: 'm3conclusion1',
      nextLink: '/course/module/3/conclusion/2',
    },
    {
      id: 2,
      title: 'Думаете, что есть конфликт интересов — сообщите об этом',
      text: [
        'Ситуации конфликта интересов не нужно бояться. Главное — вовремя её урегулировать. Для этого сразу сообщайте о ваших сомнениях в «Ростелеком». Мы поможем разобраться и выстроить взаимодействие правильно.',
      ],
      bg: 'm3conclusion2',
      nextLink: '/course/module/4',
    },
  ],
};

export default module3;
