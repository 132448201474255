import { getNormalizeDate } from '../../../utils/getNormalizedDate';

export const getNormalizedNews = (data) => {
  return {
    id: data?.id,
    date: getNormalizeDate(data?.publicationDate),
    title: data?.title,
    text: data?.previewText,
  };
};
