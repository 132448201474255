import React from 'react';

import * as S from './styles'
import MaterialElement from "../MaterilElement";

const ListMaterial = ({listMaterials}) => {
  return (
    <S.Content>
      {listMaterials?.map((item) => <MaterialElement key={item.id} material={item}/>)}
    </S.Content>
  );
};

export default ListMaterial;
