import interceptor from '../../../../../helpers/API';
import {format} from "date-fns";

export const addedFaqs = async (value, id) => {
  const data = {
    question: value?.title,
    answer: value?.text,
    accessType: value?.access,
    publishStatus: value?.status,
    publicationDate: new Date().toISOString(),
    fileId: id,
  };
  try {
    await interceptor.post('admin/questions-answers', data);
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchFaqs = async (params,filters) => {
  try {
    const newParams = {
      ...params,
      startDate: filters?.date?.[0] ? format(new Date(filters?.date?.[0]), 'yyyy-MM-dd') : '',
      endDate: filters?.date?.[1] ? format(new Date(filters?.date?.[1]), 'yyyy-MM-dd') : '',
    };
    const { data } = await interceptor.get('admin/questions-answers', {
      params:newParams,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchFaqsById = async (id) => {
  try {
    const { data } = await interceptor.get(`admin/questions-answers/${id}`, {
      params: { id },
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const fetchEditFags = async (id, value, fileId) => {
  const data = {
    question: value?.title,
    answer: value?.text,
    accessType: value?.access,
    publishStatus: value?.status,
    publicationDate: new Date().toISOString(),
    fileId: fileId || value?.file?.id || null,
  };
  try {
    await interceptor.put(`admin/questions-answers/${id}`, data);
    return {};
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};

export const deleteFaqs = async (id) => {
  try {
    await interceptor.delete(`admin/questions-answers/${id}`, {
      params: { id },
    });
  } catch (e) {
    throw new Error(e?.response?.data?.message);
  }
};
