import React, { memo } from 'react';
import styled from 'styled-components';

const Bar = styled.div`
  width: 25px;
  height: 4px;

  margin: 5px 0;
  transition: 0.4s;
`;

const Wrapper = styled.div`
  ${Bar} {
    background-color: ${({ color = '#333' }) => color};
  }
  ${Bar}:first-child {
    transform: ${({ isOpened }) => (isOpened ? 'translateY(9px) rotateZ(45deg)' : 'none')};
  }
  ${Bar}:last-child {
    transform: ${({ isOpened }) => (isOpened ? 'translateY(-9px) rotateZ(-45deg)' : 'none')};
  }
  ${Bar}:nth-child(2n) {
    opacity: ${({ isOpened }) => (isOpened ? 0 : 1)};
  }
`;

const CancelIconAnimated = ({ color, onClick, isOpened }) => (
  <Wrapper color={color} isOpened={isOpened} onClick={onClick}>
    <Bar />
    <Bar />
    <Bar />
  </Wrapper>
);
export default memo(CancelIconAnimated);
