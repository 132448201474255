import React, {memo} from 'react';
import {Flex, Box} from 'rebass';
import {PageTitle} from 'atoms';

import ListMaterial from "../ListMaterials";
import {Tabs} from 'molecules';
import * as S from './styles'
import {Paginator} from "../../../../common/molecules";
import {usePagination} from "../../../../hooks";
import {useMaterials} from "../../hooks/useMaterials";

const tabs = [
  {title: 'Учебные материалы', path: '/education/materials'},
  {title: 'Курс деловой этики для поставщиков', path: '/education/tests'},
];

const EducationalMaterials = () => {
  const {currentPage, setAllPages, handlePageChange, allPages} = usePagination();
  const {listMaterials} = useMaterials(setAllPages, currentPage)

  return (
    <Box width="100%" p="40px 0 0px">
      <Flex justifyContent="center">
        <Box width="100%" maxWidth={['100%', '100%', '100%', 1300]} px={[16, 32, 48]}>
          <PageTitle>Обучение</PageTitle>
          <Box mb="24px">
            <Tabs tabs={tabs}/>
          </Box>
        </Box>
      </Flex>
      <Flex bg="#fff" flexDirection="column" alignItems="center" pt={[24, 24, 40]} pb="80px">
        <ListMaterial listMaterials={listMaterials}/>
        <S.Pagination>
          <Paginator
            initialPage={currentPage}
            currentPage={currentPage}
            pageCount={allPages}
            onPageChange={(value) => {
              handlePageChange(value);
            }}
          />
        </S.Pagination>
      </Flex>
    </Box>
  );
};

export default memo(EducationalMaterials);
