import React, { memo } from 'react';
import { Box, Flex } from 'rebass';
import { Paginator } from 'molecules';

import ArticlePreview from './ArticlePreview';
import {useArticlesList} from "../hooks/useArticlesList";
import {usePagination} from "../../../hooks";

const Articles = () => {
  const { currentPage, setAllPages, handlePageChange, allPages ,countElements} = usePagination();
  const {listArticle} = useArticlesList(setAllPages,currentPage,countElements)

  return (
    <Box width="100%">
      <Flex flexWrap="wrap" width="100%" mb="32px">
        {listArticle.map((article) => (
          <ArticlePreview key={article.id} {...article} />
        ))}
      </Flex>
      <Box>
        <Paginator
          initialPage={currentPage}
          currentPage={currentPage}
          pageCount={allPages}
          onPageChange={(value) => {
            handlePageChange(value);
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(Articles);
