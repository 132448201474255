import m1intro1 from './m1intro1.jpg';
import m1intro2 from './m1intro2.jpg';
import m2intro1 from './m2intro1.jpg';
import m2intro2 from './m2intro2.jpg';
import m3intro1 from './m3intro1.jpg';
import m4intro1 from './m4intro1.jpg';
import m5intro1 from './m5intro1.jpg';
import m6intro1 from './m6intro1.jpg';
import m6intro2 from './m6intro2.jpg';

import contentBg from './content_bg.jpg';
import end from './end.jpg';

import m1case from './m1case.jpg';
import m2case1 from './m2case1.jpg';
import m2case2 from './m2case2.jpg';
import m4case1 from './m4case1.jpg';

import m5case1 from './m5case1.jpg';
import m5case2 from './m5case2.jpg';

import m2check1 from './m2check1.png';
import m2check2 from './m2check2.png';
import m2check3 from './m2check3.png';
import m2check4 from './m2check4.png';

import m1conclusion from './m1conclusion.jpg';
import m2conclusion from './m2conclusion.jpg';
import m3conclusion1 from './m3conclusion1.jpg';
import m3conclusion2 from './m3conclusion2.jpg';
import m4conclusion from './m4conclusion.jpg';

import m1t1slide1 from './m1t1slide1.png';
import m1t1slide2 from './m1t1slide2.png';
import m1t1slide3 from './m1t1slide3.png';
import m1t1slide4 from './m1t1slide4.png';
import m1t1slide5 from './m1t1slide5.png';
import m1t1slide6 from './m1t1slide6.png';
import m1t1slide7 from './m1t1slide7.png';
import m1t1slide8 from './m1t1slide8.png';

import m2t3slide1 from './m2t3slide1.png';
import m2t3slide2 from './m2t3slide2.png';
import m2t3slide3 from './m2t3slide3.png';
import m2t3slide4 from './m2t3slide4.png';
import m2t3slide5 from './m2t3slide5.png';

import m4t3slide1 from './m4t3slide1.png';
import m4t3slide2 from './m4t3slide2.png';
import m4t3slide3 from './m4t3slide3.png';
import m4t3slide4 from './m4t3slide4.png';
import m4t3slide5 from './m4t3slide5.png';

import m5t2slide1 from './m5t2slide1.png';
import m5t2slide2 from './m5t2slide2.png';
import m5t2slide3 from './m5t2slide3.png';
import m5t2slide4 from './m5t2slide4.png';
import m5t2slide5 from './m5t2slide5.png';

import m1t2 from './m1t2.jpg';
import m2t1 from './m2t1.jpg';
import m2t2 from './m2t2.jpg';
import m2t4 from './m2t4.jpg';
import m2t5 from './m2t5.jpg';
import m2t6 from './m2t6.jpg';
import m2t8 from './m2t8.jpg';
import m2t9 from './m2t9.jpg';
import m3t1 from './m3t1.jpg';
import m3t2 from './m3t2.jpg';
import m3t3 from './m3t3.jpg';
import m3t5 from './m3t5.jpg';
import m3t6 from './m3t6.jpg';
import m3t7 from './m3t7v.2.png';
import m3t8 from './m3t8.jpg';
import m4t1 from './m4t1.jpg';
import m4t2 from './m4t2.jpg';
import m5t1 from './m5t1.jpg';
import m5t3 from './m5t3.jpg';
import m6t1 from './m6t1.png';
import m6t2 from './m6t2.jpg';

import m1home1 from './m1home1.jpg';
import m2home1 from './m2home1.jpg';
import m3home1 from './m3home1.jpg';
import m4home1 from './m4home1.jpg';
import m5home1 from './m5home1.jpg';
import m6home1 from './m6home1.jpg';

import m2t8img1 from './m2t8img1.png';
import m2t8img2 from './m2t8img2.png';
import m2t8img3 from './m2t8img3.png';

import m3t4img1 from './m3t4img1.png';
import m3t4img2 from './m3t4img2.png';

import m3t6img1 from './m3t6img1.png';
import m3t6img2 from './m3t6img2.png';

import m5t3img1 from './m5t3img1.png';
import m5t3img2 from './m5t3img2.png';
import m5t3img3 from './m5t3img3.png';

export default {
  m1intro1,
  m1intro2,
  m2intro1,
  m2intro2,
  m3intro1,
  m4intro1,
  m5intro1,
  m6intro1,
  m6intro2,

  contentBg,
  end,

  m1case,
  m2case1,
  m2case2,
  m4case1,

  m5case1,
  m5case2,

  m1conclusion,
  m2conclusion,
  m3conclusion1,
  m3conclusion2,
  m4conclusion,

  m1t1slide1,
  m1t1slide2,
  m1t1slide3,
  m1t1slide4,
  m1t1slide5,
  m1t1slide6,
  m1t1slide7,
  m1t1slide8,

  m2t3slide1,
  m2t3slide2,
  m2t3slide3,
  m2t3slide4,
  m2t3slide5,

  m4t3slide1,
  m4t3slide2,
  m4t3slide3,
  m4t3slide4,
  m4t3slide5,

  m5t2slide1,
  m5t2slide2,
  m5t2slide3,
  m5t2slide4,
  m5t2slide5,

  m1t2,
  m2t1,
  m2t2,
  m2t4,
  m2t5,
  m2t6,
  m2t8,
  m2t9,
  m3t1,
  m3t2,
  m3t3,
  m3t5,
  m3t6,
  m3t7,
  m3t8,
  m4t1,
  m4t2,
  m5t1,
  m5t3,
  m6t1,
  m6t2,

  m1home1,
  m2home1,
  m3home1,
  m4home1,
  m5home1,
  m6home1,

  m2check1,
  m2check2,
  m2check3,
  m2check4,

  m2t8img1,
  m2t8img2,
  m2t8img3,

  m3t4img1,
  m3t4img2,
  m3t6img1,
  m3t6img2,

  m5t3img1,
  m5t3img2,
  m5t3img3,
};
