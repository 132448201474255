import {fetchFile} from "../../../request";
import {useAsyncFn} from "react-use";

export const useFile = (id, originalFilename) => {
  const [{error}, getFile] = useAsyncFn(fetchFile)

  const downLoadFile = async () => {
    await getFile(id, originalFilename);
  };

  return {fileError:error,downLoadFile}
}
