import React, { memo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Arrow = styled.div`
  display: block;
  position: relative;
  width: 100px;
  height: 2px;
  background: ${({ disabled }) => `#fff${disabled ? ' !important' : ''}`};
  margin: 20px 0 35px;

  &:after {
    content: '';
    position: absolute;
    left: ${({ isLeft }) => (isLeft ? '-2px' : 'auto')};
    top: -3px;
    right: ${({ isLeft }) => (isLeft ? 'auto' : '-2px')};
    border-style: solid;
    border-color: ${({ disabled }) => `transparent #fff${disabled ? ' !important' : ''}`};
    border-width: ${({ isLeft }) => (isLeft ? '4px 8px 4px 0' : '4px 0 4px 8px')};
  }
`;

const Wrapper = styled.button`
  display: inline-block;
  white-space: nowrap;
  width: 180px;
  margin: ${({ margin = 0 }) => margin};
  padding: 20px;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: ${({ disabled }) => `#fff${disabled ? ' !important' : ''}`};
  background: ${({ isLeft, disabled }) =>
    `${isLeft ? '#f6c043' : '#6d12f5'}${disabled ? ' !important' : ''}`};
  outline: none;
  user-select: none;
  transition: opacity, background-color 0.4s ease;
  border-style: none;
  opacity: ${({ disabled }) => (disabled ? '0.49' : '1')};
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    color: ${({ isLeft }) => (isLeft ? '#f6c043' : '#6d12f5')};
    background: #fff;
    border-color: ${({ isLeft }) => (isLeft ? '#f6c043' : '#6d12f5')};
    ${Arrow} {
      background: ${({ isLeft }) => (isLeft ? '#f6c043' : '#6d12f5')};
      &:after {
        border-color: transparent ${({ isLeft }) => (isLeft ? '#f6c043' : '#6d12f5')};
      }
    }
  }
  @media (max-width: 900px) {
    font-size: 12px;
    width: 140px;
  }
`;

const ArrowButton = ({ title, isLeft = false, link = '/#', disabled, onClick, history }) => {
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
    } else if (onClick) {
      onClick();
    }

    if (isLeft) {
      history.goBack();
    }
  };

  return (
    <Link to={link} onClick={handleClick}>
      <Wrapper isLeft={isLeft} disabled={disabled}>
        <>{title || (isLeft ? 'Назад' : 'Вперед')}</>
        <Arrow isLeft={isLeft} disabled={disabled} />
      </Wrapper>
    </Link>
  );
};

export default memo(withRouter(ArrowButton));
