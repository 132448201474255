import React from 'react';

import DataLayout from '../DataLayout';
import {FILTER_TYPES, ARTICLE_STATUSES, ACCESS, DOCUMENT_TYPES} from '../constants';
import {useDocuments} from './hooks/useDocuments';
import {filterList, initialFilters} from './constants';
import {getInitFiltersParams} from "../../../../utils/getInitFiltersParams";
import {useFilters} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const Documents = () => {
  const {
    currentPage,
    setAllPages,
    pageSize,
    setCountElements,
    setAllElements,
    allElements,
    setPage,
  } = usePagination();
  const initValues = getInitFiltersParams(filterList);
  const initDate = initValues?.date?.[0] ? [new Date(initValues?.date?.[0]), new Date(initValues?.date?.[1])] : null

  const {filter, handleChangeFilters, resetFilters} = useFilters({...initValues, date: initDate},initialFilters);

  const {listDocuments, getDocuments, handleGetDocuments, status, handleDeleteDocs} =
    useDocuments(filter, pageSize, currentPage, setAllPages, setAllElements);

  const filterItems = [
    {
      name: 'date',
      type: FILTER_TYPES.DATE_RANGE,
      value: filter.date,
      initialValue: filter.date,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'status',
      label: 'Статус',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ARTICLE_STATUSES.ALL},
        {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
        {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
      ],
      value: filter.status,
      // initialValue: ARTICLE_STATUSES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'access',
      label: 'Доступ',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: ACCESS.ALL},
        {title: 'Открытые', value: ACCESS.PUBLIC},
        {title: 'Закрытые', value: ACCESS.PRIVATE},
      ],
      value: filter.access,
      // initialValue: ACCESS.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
    {
      name: 'docCategory',
      label: 'Тип',
      type: FILTER_TYPES.SELECT,
      opts: [
        {title: 'Все', value: DOCUMENT_TYPES.ALL},
        {title: 'Антикоррупционное законодательство', value: DOCUMENT_TYPES.ANTI_CORRUPTION},
        {title: 'Документы компании', value: DOCUMENT_TYPES.COMPANY},
      ],
      value: filter.docCategory,
      // initialValue: DOCUMENT_TYPES.ALL,
      onChange: (name, value) => {
        handleChangeFilters(name, value);
      },
    },
  ];

  const headCells = [
    {id: 'dateSort', disablePadding: true, label: 'Дата публикации'},
    {id: 'titleSort', disablePadding: false, label: 'Заголовок'},
    {id: 'statusSort', disablePadding: false, label: 'Статус'},
  ];

  const actions = [{link: 'edit', label: 'Добавить документ', disabled:!checkedPermission(permissions.docsWrite)}];

  const handleResetFilters = async () => {
    await resetFilters();
    await getDocuments({
      accessType: '',
      publishStatus: '',
      page: 1,
      size: 10,
      startDate: '',
      endDate: '',
      sort:"publicationDate,DESC",
    });
  };

  return (
    checkedPermission(permissions.docsRead) &&
    <DataLayout
      title="Документы"
      headCells={headCells}
      rows={listDocuments}
      deleteElement={handleDeleteDocs}
      filterItems={filterItems}
      actions={actions}
      accessFilter={handleGetDocuments}
      status={status}
      pagination={pageSize}
      setPagination={setCountElements}
      resetFilters={handleResetFilters}
      allElements={allElements}
      currentPage={currentPage}
      setPage={setPage}
    />
  );
};

export default Documents;
