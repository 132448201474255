import { pathOr, mergeDeepRight } from 'ramda';

import { useRouter } from '../hooks';
import { parseQueryString } from './queryString';

/**
 * Утиилита для получения изначальных параметров фильтров
 * делает выборку из query параметров
 * @param fields : Список имен полей
 * @returns
 */
export const getInitFiltersParams = (fields) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { location } = useRouter();

  let result = [];

  const queryParams = parseQueryString(location.search);

  result = fields.reduce(
    (acc, key) => mergeDeepRight(acc, { [key]: pathOr(null, [key], queryParams) }),
    {},
  );

  return result;
};
