import {saveAs} from 'file-saver';

import interceptor from '../helpers/API';
import {convertBlobToBase64} from "../utils/convertBlobToBase64";

const uploadChunk = async (fileId, file) => {
  const CHUNK_SIZE = 1024 * 1024 * 4; // размер чанка в байтах
  const fileSize = file?.size;
  const chunks = Math.ceil(fileSize / CHUNK_SIZE);
  let currentChunk = 0;

  const upload = async () => {
    const start = currentChunk * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, fileSize);

    try {
      const chunk = file?.slice(start, end);
      const base64 = await convertBlobToBase64(chunk)
      await interceptor.put(`/files/${fileId}`, {data: base64})
      currentChunk++;
      if (currentChunk < chunks) {
        await upload();
      }
    } catch (e) {
      throw new Error(e?.response?.data?.message);
    }
  }
  await upload()
}

export const fetchAddFile = async (file) => {
  try {
    const newFile = {
      originalFilename: file?.name,
      contentType: file?.type,
      size: file?.size
    }
    const {data} = await interceptor.post('admin/files', newFile);

    await uploadChunk(data.id, file);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getFile = async (id) => {
  try {
    const {data} = await interceptor.get(`admin/files/${id}`, {
      params: {id},
      responseType: 'blob',
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const fetchFile = async (id, name) => {
  try {
    const {data} = await interceptor.get(`files/${id}`, {params: {id}, responseType: 'blob'});
    await saveAs(data, name);
  } catch (e) {
    throw new Error(e);
  }
};

export const checkVpn = async () => {
  try {
    const {data} = await interceptor.get('accessType/hasPrivate');
    return {access:data}
  } catch (e) {
    throw new Error(e);
  }
};
