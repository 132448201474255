import styled, {keyframes} from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .img {
    width: 200px;
  }

  .update {
    cursor: pointer;
  }

  .captcha {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
  }

  .error {
    color: red;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    margin-left: 14px;
  }
`

const spin = keyframes`
  to {
    -webkit-transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: deepskyblue;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
`;
