import React, { memo } from 'react';
import { Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import { PageTitle, Button } from 'atoms';
import { ReadMoreFeedback, Prohibited } from 'molecules';

const ContentWrapper = styled(Text)`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 24px;
  margin-bottom: 32px;
  p {
    margin-bottom: 1.25rem;
  }
`;

const Feedback = () => {
  return (
    <Box width="100%" p="40px 0 80px">
      <Flex justifyContent="center">
        <Box width="100%" maxWidth={['100%', '100%', '100%', 1300]} px={[16, 32, 48]}>
          <PageTitle>Обратная связь</PageTitle>
        </Box>
      </Flex>
      <Flex bg="#fff" height="100%" alignItems="center" pt="40px" flexDirection="column">
        <Flex
          width="100%"
          flexWrap="wrap"
          maxWidth={['100%', '100%', '100%', 1300]}
          px={[16, 32, 48]}
        >
          <Flex width={[1, 1, 1, 2 / 3]} flexWrap="wrap">
            <ContentWrapper
              fontSize={['0.875rem', '0.875rem', '1rem']}
              lineHeight={['1.25rem', '1.25rem', '1.5rem']}
            >
              <Text
                fontSize={['1.25rem', '1.25rem', '2.25rem']}
                fontWeight="bold"
                lineHeight="1rem"
                mb="2rem"
              >
                Каналы для приема обращений
              </Text>
              <p>
                В Ростелекоме работают линии для приема обращений сотрудников, партнеров,
                поставщиков компании по вопросам нарушения законодательства и Этического кодекса. Вы
                можете сообщить о нарушениях любым удобным способом.
              </p>

              <Text
                fontSize={['1rem', '1rem', '1.5rem']}
                fontWeight="bold"
                lineHeight="1rem"
                mb="1.5rem"
              >
                «Линия доверия» Ростелеком
              </Text>

              <p>
                Для сообщений о нарушениях законодательства сотрудниками, партнерами и поставщиками
                Ростелеком. Звонки принимаются операторами с 09.00 до 21.00 (МСК) в рабочие дни, в
                другое время можно оставить обращение на автоответчике.
              </p>

              <Text fontSize={['0.875rem', '0.875rem', '1rem']} fontWeight="bold" mb="1.5rem">
                Звоните по номеру:
                <Box display={['none', 'none', 'inline']}>
                  <Text fontWeight="bold" display="inline" ml="4px">
                    8-800-1-811-811
                  </Text>
                </Box>
                <Box display={['inline', 'inline', 'none']} ml="4px">
                  <a href="tel:8-800-1-811-811">8-800-1-811-811</a>
                </Box>
              </Text>

              <Text
                fontSize={['1rem', '1rem', '1.5rem']}
                fontWeight="bold"
                lineHeight="1rem"
                mb="1.5rem"
              >
                Горячая линия по соблюдению Этического кодекса
              </Text>

              <p>
                Для сообщения о потенциальных или совершенных нарушениях положений Этического
                кодекса компании.
              </p>

              <Text fontSize={['0.875rem', '0.875rem', '1rem']} fontWeight="bold" mb="1.5rem">
                Пишите по адресу: ethics@rt.ru
              </Text>

              <Text
                fontSize={['1rem', '1rem', '1.5rem']}
                lineHeight={['1.25rem', '1.25rem', '2.25rem']}
                fontWeight="bold"
                mb="1.5rem"
              >
                Форма обратной связи по приему сообщений о нарушениях
              </Text>

              <p>
                Вы можете сообщить о фактах коррупции прямо сейчас: заполните простую форму прямо на
                сайте.
              </p>

              <Flex flexWrap="wrap">
                <Box mr={[0, '1rem']} mb={['1rem', 0]} width={[1, 'auto']}>
                  <Button
                    variant="primary"
                    title="Сообщить о нарушениях"
                    width="240px"
                    link="/feedback/violation"
                  />
                </Box>
                <Box width={[1, 'auto']}>
                  <Button
                    variant="warn"
                    title="Узнать статус обращения"
                    width="240px"
                    link="/feedback/violation-status"
                  />
                </Box>
              </Flex>

              <Text
                fontSize={['1rem', '1rem', '1.5rem']}
                lineHeight={['1.25rem', '1.25rem', '2.25rem']}
                fontWeight="bold"
                my="2rem"
              >
                Онлайн-приемная Департамента защиты активов
              </Text>

              <p>
                Мы готовы обсудить любые вопросы, связанные с антикоррупционной политикой компании.
                Ответы будут отправлены на вашу электронную почту.
              </p>

              <Button
                variant="secondary"
                title="Задать вопрос"
                width="240px"
                link="/feedback/question"
              />
            </ContentWrapper>
          </Flex>

          <Box width={[1, 1, 1, 1 / 3]} pl={[0, 0, 0, 24]} mb={[24, 24, 24, 0]}>
            <ReadMoreFeedback />
          </Box>
        </Flex>
        <Flex
          width="100%"
          flexWrap="wrap"
          maxWidth={['100%', '100%', '100%', 1300]}
          px={[16, 32, 48]}
        >
          <Flex width={[1, 1, 1, 2 / 3]} flexWrap="wrap">
            <Text
              fontSize={['0.875rem', '0.875rem', '1rem']}
              lineHeight={['1.25rem', '1.25rem', '1.5rem']}
            >
              <Text
                fontSize={['1.25rem', '1.25rem', '2.25rem']}
                fontWeight="bold"
                lineHeight="1rem"
                mb="2rem"
              >
                Конфиденциальность
              </Text>

              <p>
                При приеме и рассмотрении обращений компания гарантирует конфиденциальность и
                неразглашение персональных данных обратившихся. Компания обязуется не допускать
                ответных действий в отношении добросовестных лиц, сообщающих о нарушениях. Вы также
                можете сообщить о нарушениях анонимно. При этом следует помнить, что не допускается
                умышленное предоставление ложной или вводящей в заблуждение информации.
              </p>
            </Text>
          </Flex>

          <Box width={[1, 1, 1, 1 / 3]} pl={[0, 0, 0, 24]} my={[24, 24, 24, 0]}>
            <Prohibited />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default memo(Feedback);
