import React, {useEffect, useState} from 'react';
import {Flex, Text, Box} from 'rebass';
import {Button, Input} from 'atoms';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {IconButton, InputAdornment} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";

import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {fetchLogin} from './request';
import getAdminState from '../../store/reducers/Admin/getter';
import {getLocalStorage} from '../../utils/localStorage';
import {changeIsAuthorized} from '../../store/reducers/Admin/Admin';
import {useRouter} from '../../hooks';
import {useCheckVpn} from "../../hooks/useCheckVpn";

const validationSchema = yup.object({
  email: yup.string().required('Поле обязательно для заполнения'),
  password: yup.string().required('Поле обязательно для заполнения'),
});
const onSubmit = async (value) => {
  await fetchLogin(value);
};

const Login = () => {
  const {isAuthorized} = useSelector(getAdminState);
  const dispatch = useDispatch();
  const token = getLocalStorage('accessToken');
  let {location} = useRouter();
  const [showPassword, setIsShowPassword] = useState(false);
  const {accessible} = useCheckVpn()

  useEffect(() => {
    if (token) {
      dispatch(changeIsAuthorized(true));
    }
  }, [token]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
    validationSchema,
  });

  const handleClickShowPassword = () => {
    setIsShowPassword(true)
  }

  const handleMouseDownPassword = () => {
    setIsShowPassword(false)
  }

  return (
    <>
      {accessible === null && <div/>}
      {accessible === false && <Redirect to={'/'}/>}
      {accessible && <>
        {!isAuthorized ? (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bg="#f3f3f3"
            width="100vw"
            height="100vh"
          >
            <Text fontSize="40px" mb="80px" color="#333">
              Антикоррупционный сайт
            </Text>
            <form onSubmit={formik.handleSubmit}>
              <Box p="40px 60px" width="420px" bg="#fff" sx={{border: '1px solid #ddd'}}>
                <Text fontSize="28px" mb="32px">
                  Авторизация
                </Text>
                <Text color="#aa4400" mb="32px">
                  Вы не авторизованы в системе
                </Text>
                <Input
                  id="email"
                  name="email"
                  value={formik.values.email}
                  label="Логин"
                  required
                  variant="outlined"
                  mb="16px"
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <Input
                  id="password"
                  name="password"
                  value={formik.values.password}
                  label="Пароль"
                  required
                  variant="outlined"
                  mb="32px"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  variant="primary"
                  disabled={!formik.isValid || !formik.dirty}
                  title="Войти"
                  type="submit"
                />
              </Box>
            </form>
          </Flex>
        ) : (
          <Redirect to={`${location?.state?.from?.pathname}` || '/admin'}/>
        )}
      </>}
    </>
  );
};

export default Login;
