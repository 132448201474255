export const getNormalizeDate = (date) =>
  date
    ? new Date(date).toLocaleString('ru', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : '';

export const getNormalizeDateAndTime = (date) =>
  date
    ? new Date(date)
      .toLocaleString('ru', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(',', '')
    : '';
