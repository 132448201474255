const filterNames = {
  month: 'month',
  year: 'year',
  newsType: 'newsType'
};

export const filterList = Object.values(filterNames);

export const defaultFilterValue = { title: 'Все', value: '' };

const currentYear = new Date().getFullYear();

export const initFilterYear = { title: currentYear, value: currentYear };
