import React, { createRef } from 'react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';

import {useDropZone} from "../../../hooks/useDropZone";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  border-right: none;
  li {
    margin-bottom: 0;
  }
`;

const StyledButton = styled.div`
  padding: 8px 16px;
  color: #fff;
  background-color: #1976d2;
  padding: 6px 16px;
  border-radius: 0 4px 4px 0;
  font-size: 0.875rem;
  line-height: 1.75;
  cursor: pointer;
`;

const dropzoneRef = createRef();

const openDialog = () => {
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

const CustomDropzone = ({onChange, files ,validFormats,maxSize,setFileError}) => {
  const handleChange = (files) => {
    onChange(files);
  };

  const {getInputProps, open} = useDropZone({
    onChange: handleChange,
    accept:validFormats,
    multiple: false,
    maxSize,
    setFileError,
  });

  const clear = () => {
    onChange([]);
  };

  return (
    <Wrapper>
      <div className={'dropzone'} style={{ width: '100%' }}>
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Box pr="16px" maxWidth="80%" sx={{ wordBreak: 'break-all' }} px="16px">
            {files?.length
              ? files?.map((file) => <div key={file.path}>{file.path}</div>)
              : 'Выберите файл'}
          </Box>
          <Box sx={{ position: 'relative' }}>
            <input {...getInputProps()} />
            {files?.length ? (
              <ClearIcon
                onClick={clear}
                style={{
                  position: 'absolute',
                  fill: '#f44336',
                  left: '-28px',
                  top: '0',
                  transform: 'translateY(25%)',
                  cursor: 'pointer',
                }}
              />
            ) : null}
            <StyledButton type="button" onClick={open}>
              Выбрать
            </StyledButton>
          </Box>
        </Flex>
      </div>
    </Wrapper>
  );
};
export default CustomDropzone;
