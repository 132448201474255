import React, {useState} from 'react';
import {Flex, Box, Text} from 'rebass';
import {useParams} from 'react-router-dom';
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {ru} from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import {DesktopDatePicker} from "@material-ui/lab";
import {Controller} from "react-hook-form";

import EditLayout from '../EditLayout';
import {ARTICLE_STATUSES, ACCESS} from '../constants';
import Button from '../Button';
import Select from '../Select';
import CustomTextField from '../TextField';
import TextField from '@material-ui/core/TextField';
import File from '../File';
import TextEditor from '../TextEditor';
import ModalStatus from '../ModalStatus';
import {useAddedArticle} from "./hooks/useAddedArticle";
import {useFile} from "../../../../hooks/useFile";
import {useEditArticle} from "./hooks/useEditArticle";
import {useFormValue} from "./hooks/useForm";
import {LOADING} from "../../../../store/statusConstants";
import {acceptFormatArticle} from "./constants";
import {checkedPermission} from "../../../../utils/checkedPermission";
import {permissions} from "../../constants";

const statuses = [
  {title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED},
  {title: 'Неопубликованные', value: ARTICLE_STATUSES.NOT_PUBLISHED},
  // {title: 'Ожидает публикации', value: ARTICLE_STATUSES.POSTPONED},
];

const access = [
  {title: 'Закрытый', value: ACCESS.PRIVATE},
  {title: 'Открытый', value: ACCESS.PUBLIC},
];

const ArticleEdit = () => {
  const {id} = useParams();
  const [isModalOpen, setIsModalOpen] = useState();
  const {article, handleEditArticle, editStatus} = useEditArticle(setIsModalOpen)

  const {file, setFile, handleAddFile} = useFile(article?.file);
  const {handleAddedArticle, addStatus} = useAddedArticle(setIsModalOpen, handleAddFile)

  const {
    control,
    getValues,
    setValue,
    onSubmit,
    errorsList,
    validationStatus,
    clearErrors
  } = useFormValue(article, handleEditArticle, handleAddedArticle, handleAddFile, setIsModalOpen)

  const formValues = getValues();

  const changeValue = (name, value) => {
    setValue(name, value)
  };

  return (
    <EditLayout title={id ? 'Редактировать статью' : 'Добавить статью'}>
      <form onSubmit={onSubmit}>
        <Flex mb="24px">
          <Box width="50%" pr="16px">
            <Controller
              name={'title'}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomTextField
                  value={value || ''}
                  label="Заголовок"
                  size="small"
                  sx={{width: '100%', marginBottom: '24px'}}
                  onChange={(value) => changeValue('title', value)}
                  name={'title'}
                />
              )}
            />
            <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                mask="__.__.____"
                inputFormat="dd.MM.yyyy"
                value={formValues?.date || ''}
                label='Дата публикации'
                onChange={(value) => changeValue('date', value)}
                disableOpenPicker
                renderInput={(params) => <TextField  {...params} size="small"
                                                     sx={{width: '100%', marginBottom: '24px'}}
                                                     helperText={params.error && "Некорректный формат даты"}
                />}
              />
            </LocalizationProvider>
            <File onChange={setFile} files={file} validFormats={acceptFormatArticle}/>
            <Text mt="8px" fontSize="14px" color="#777">
              допустимый формат файла: jpg, gif, png размер изображения должен быть 280×158px
            </Text>
          </Box>
          <Flex flexDirection="column" width="50%">
            <Box mb="24px">
              <Controller
                name={'status'}
                control={control}
                render={({field: {value}}) => (
                  <Select
                    required
                    label="Статус"
                    value={value || ''}
                    options={statuses}
                    onChange={(name, value) => changeValue(name, value)}
                    width="100%"
                    name="status"
                  />
                )}
              />

            </Box>
            <Box mb="24px">
              <Controller
                name={'access'}
                control={control}
                render={({field: {value}}) => (
                  <Select
                    required
                    label="Доступ"
                    value={value || ''}
                    options={access}
                    onChange={(name, value) => changeValue(name, value)}
                    width="100%"
                    name="access"
                  />
                )}
              />

            </Box>
            <Controller
              name={'link'}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomTextField
                  value={value || ''}
                  label="Ссылка на источник"
                  size="small"
                  sx={{width: '100%', marginBottom: '24px'}}
                  onChange={(value) => changeValue('link', value)}
                />
              )}
            />

          </Flex>
        </Flex>
        <Box mb="40px">
          <Text mb="16px">Текст статьи:</Text>
          <TextEditor value={formValues.text || ''} onBlur={(value) => changeValue('text', value)}/>
        </Box>
        <Flex>
          <Button variant="contained" label="Сохранить" type='onsubmit' disabled={!checkedPermission(permissions.materialsWrite)}/>
        </Flex>
      </form>
      <ModalStatus
        status={addStatus || editStatus || validationStatus || LOADING}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        access="Статья успешно добавлена"
        path='/admin/materials'
        errorsList={errorsList}
        clearErrors={clearErrors}
      />
    </EditLayout>
  );
};

export default ArticleEdit;
