import React, { useState, memo } from 'react';
import { Box, Text } from 'rebass';
import { find } from 'lodash';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import OutsideClickHandler from 'react-outside-click-handler';

const points = [
  {
    id: 1,
    posX: '5%',
    posY: '57%',
    title: 'Законопослушный',
    text: 'Соблюдает требования законодательства и условия договора',
  },
  {
    id: 2,
    posX: '35%',
    posY: '45%',
    title: 'Предусмотрительный',
    text: 'Своевременно проходит комплаенс-обучение',
  },
  {
    id: 3,
    posX: '70%',
    posY: '35%',
    title: 'Честный',
    text: 'Сообщает, если что-либо в его собственной деятельности или субподрядчика противоречит кодексу, а также о предполагаемых нарушениях.',
  },
  {
    id: 4,
    posX: '50%',
    posY: '38%',
    title: 'Этичный',
    text: 'Принимает и соблюдает положения Кодекса деловой этики поставщика «Ростелекома» и транслирует его своим субподрядчикам',
  },
  {
    id: 5,
    posX: '40%',
    posY: '19%',
    title: 'Внимательный',
    text: 'С особой осторожностью подходит к расходам на подарки и деловое гостеприимство',
  },
  {
    id: 6,
    posX: '45%',
    posY: '14%',
    title: 'Добросовестный',
    text: 'Предоставляет качественные услуги вовремя и полном объеме. Публикует в общедоступных источниках только достоверную и проверенную информацию.',
  },
];

const PointWrap = styled.div`
  position: absolute;
  left: ${({ posX }) => posX};
  top: ${({ posY }) => posY};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6a27f5;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const TootlipWrap = styled.div`
  position: absolute;
  left: ${({ posX }) => posX};
  top: ${({ posY }) => `calc(${posY} + 40px)`};
  width: 300px;
  padding: 20px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.9);
  border: solid 1px rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  transform: ${({ id }) => (id === 1 ? 'none' : 'translateX(-50%)')};
  @media (max-width: 420px) {
    left: 16px;
    transform: none;
  }
`;

const Tooltip = ({ title, text, ...rest }) => {
  return (
    <TootlipWrap {...rest}>
      <Text fontSize="22px" mb="10px">
        {title}
      </Text>
      <Text lineHeight="1.5">{text}</Text>
    </TootlipWrap>
  );
};

const Point = ({ id, posX, posY, channgeOpenedTooltip }) => {
  return (
    <OutsideClickHandler onOutsideClick={() => channgeOpenedTooltip(null)}>
      <PointWrap posX={posX} posY={posY} onClick={() => channgeOpenedTooltip(id)}>
        <AddIcon style={{ fill: '#fff', width: '20px', height: '20px' }} />
      </PointWrap>
    </OutsideClickHandler>
  );
};

const Points = () => {
  const [openedTooltip, channgeOpenedTooltip] = useState(null);
  const channgeOpened = (id) => {
    channgeOpenedTooltip(openedTooltip !== id ? id : null);
  };

  const tooltip = find(points, ['id', openedTooltip]);

  return (
    <Box>
      {points.map(({ id, posX, posY, title, text }) => (
        <Point
          id={id}
          key={id}
          posX={posX}
          posY={posY}
          title={title}
          text={text}
          channgeOpenedTooltip={channgeOpened}
        />
      ))}
      {openedTooltip ? <Tooltip {...tooltip} /> : null}
    </Box>
  );
};

export default memo(Points);
