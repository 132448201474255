import React from 'react';
import {useParams} from "react-router-dom";

import * as S from "./styles";
import {useStatus} from "../../hooks/useStatus";

const DetailedFeedBack = () => {
  const {id} = useParams();
  const {status, error} = useStatus(id)

  return (
    <S.Content>
      <S.Header>
        <h1>Информация по обращению:</h1>
      </S.Header>
      {error && <S.Error>Извините, произошла внутренняя ошибка сервера.</S.Error>}
      {!error && <>
        <div className='text'><span>Номер обращения:</span><span>{status?.number}</span></div>
        <div className='text'><span>Состояние:</span><span>{status?.status}</span></div>
        <div className='text'><span>Дата создания:</span><span>{status?.date}</span></div>
        {status?.isAnonymous ? <div className="anonymous">Обращение подано анонимно</div> : <S.User>
          <div>
            <div className='title'>Контактные данные:</div>
            <div className='text'><span>Имя:</span><span>{status?.fullName}</span></div>
            <div className='text'><span>Телефон:</span><span>{status?.phoneNumber}</span></div>
            <div className='text'><span>E-Mail:</span><span>{status?.email}</span></div>
          </div>
        </S.User>}

        <div>
          <div className='title'>Текст обращения:</div>
          <div className='appeal'>{status?.text}</div>
        </div>
      </>}
    </S.Content>
  );
};

export default DetailedFeedBack;
