import {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useAsyncFn} from "react-use";

import {setViolation} from "../requests";
import {initialValuesViolation, validationSchemaViolation} from "../constants";

export const useViolation = (setIsModalOpen) => {
  const [{value, error, loading}, execute] = useAsyncFn(setViolation)
  const [isUpdateCaptcha, setIsUpdateCaptcha] = useState(false)
  const [isAlert, setIsAlert] = useState(false)
  const [fileError,setFilesError] = useState('')

  const onSubmit = async (value) => {
    execute(value)
  }

  const formik = useFormik({
    initialValues: initialValuesViolation,
    validationSchema: validationSchemaViolation,
    onSubmit
  });

  const changeFile = (file) => {
    formik.setFieldValue
    ('file', file?.[0])
  }

  const setFileError =  (error) => {
    setFilesError(error?.message)
    setIsAlert(true)
  }

  const closeAlert = ()=>{
    setIsAlert(false)
    setFilesError('')
  }

  useEffect(() => {
    if (value) {
      setIsModalOpen(true)
      formik.setValues(initialValuesViolation)
      setIsUpdateCaptcha(true)
    }
    if (error) {
      setIsAlert(true)
    }
  }, [value, error])


  useEffect(() => {
    if (formik.values.isAnonymous === 2) {
      formik.setFieldValue('name', '')
      formik.setFieldValue('email', '')
      formik.setFieldValue('phone', '')
    }
  }, [formik.values.isAnonymous])

  return {
    formik,
    changeFile,
    file: formik.values?.file,
    isUpdateCaptcha,
    setIsUpdateCaptcha,
    pin: value,
    loading,
    isAlert,
    setIsAlert,
    setFileError,
    closeAlert,
    fileError
  }
}
