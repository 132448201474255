import styled from 'styled-components';

export const Pagination = styled.div`
  width: 100%;
  padding-top: 20px;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: rgba(10, 159, 245, 1);

  :hover {
    color: rgba(10, 159, 242, 0.4);
  }
`;
