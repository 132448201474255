import {useAsyncFn} from "react-use";

import {setVideo} from "../requests";
import {SOURCE_TYPE} from "../../constants";
import {useStatuses} from "../../../../../hooks/useStatuses";

export const useAddedVideo = (handleAddFile) => {
  const [{value, error,loading}, addVideo] = useAsyncFn(setVideo)

  const addedVideo = async (data) => {
    if (data?.fileSourceType === SOURCE_TYPE.LOCAL) {
      const resp = await handleAddFile();
      if (resp?.id) {
        await addVideo(data, resp?.id);
      }
    } else {
      await addVideo(data);
    }
  }

  const { status } = useStatuses(loading, value, error);

  const addStatus = status;

  return {addedVideo,addStatus}
}
