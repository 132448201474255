import React, { useState, useEffect } from 'react';
import { Flex, Box, Text } from 'rebass';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createAsk, fetchAskById } from '../../../../store/reducers/Ask.reducers';
import EditLayout from '../EditLayout';
import { ARTICLE_STATUSES } from '../constants';
import Button from '../Button';
import Select from '../Select';
import TextField from '../TextField';
import TextEditor from '../TextEditor';
import ModalStatus from '../ModalStatus';

const statuses = [
  { title: 'Опубликованные', value: ARTICLE_STATUSES.PUBLISHED },
  { title: 'Неопубликованные', value: ARTICLE_STATUSES.UNPUBLISHED },
];

const initialState = {
  title: '',
  status: ARTICLE_STATUSES.UNPUBLISHED,
  question: '',
  answer: '',
};

const AskEdit = () => {
  const { status, data } = useSelector((state) => state.ask);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState();
  const [formValues, setFormValues] = useState(initialState);

  useEffect(() => {
    if (id) {
      dispatch(fetchAskById(id));
    }
    if (data) {
      setFormValues(data);
    }
  }, [dispatch, id, data]);

  const changeValue = (name, value) => {
    const newValues = { ...formValues };
    newValues[name] = value;
    setFormValues(newValues);
  };

  const handleCreate = () => {
    dispatch(createAsk(formValues));
    setIsModalOpen(true);
  };

  return (
    <EditLayout title={id ? 'Редактировать документ' : 'Добавить документ'}>
      <Flex mb="24px" flexDirection="column">
        <Box width="50%" pr="16px">
          <TextField
            required
            value={formValues.title}
            label="Название"
            size="small"
            sx={{ width: '100%', marginBottom: '24px' }}
            onChange={(value) => changeValue('title', value)}
          />
        </Box>
        <Box mb="24px">
          <Select
            required
            label="Статус"
            value={formValues.status}
            options={statuses}
            onChange={(name, value) => changeValue(name, value)}
            width="100%"
            name="status"
          />
        </Box>

        <Box mb="40px">
          <Text mb="16px">Текст вопроса:</Text>
          <TextEditor
            value={formValues.question}
            onBlur={(value) => changeValue('question', value)}
          />
        </Box>
        <Box mb="40px">
          <Text mb="16px">Текст ответа:</Text>
          <TextEditor value={formValues.answer} onBlur={(value) => changeValue('answer', value)} />
        </Box>
      </Flex>
      <Flex>
        <Button variant="contained" label="Сохранить" onClick={handleCreate} />
      </Flex>
      <ModalStatus
        status={status}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        access="Баннер успешно добавлен"
      />
    </EditLayout>
  );
};

export default AskEdit;
