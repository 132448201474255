export const getLocalStorage = (key) => {
  return window.localStorage.getItem(key);
};

export const setLocalStorage = (tokenKey, token) => {
  return window.localStorage.setItem(tokenKey, token);
};

export const deleteLocalStorage = (key) => {
  return window.localStorage.removeItem(key);
};
