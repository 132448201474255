import styled from "styled-components";

export const Content = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;

  .button {
    width: max-content;
    cursor: pointer;
    margin-left: 160px;
  }
`

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const Label = styled.div`
  display: flex;
  position: relative;
  padding-top: 10px;

  .required {
    position: absolute;
    color: red;
    left: -10px
  }
`
