import {useAsyncFn} from "react-use";
import {fetchArticle} from "../requests";
import {useEffect, useState} from "react";
import {useRouter} from "../../../hooks";
import {getNormalizedDetailedArticle} from "../utils/getNormalizedDetailedArticle";

export const useArticle = () => {
  const {
    query: {articleId},
  } = useRouter();

  const [{value}, getArticle] = useAsyncFn(fetchArticle)
  const [article, setArticle] = useState()

  useEffect(() => {
    if (articleId) {
      getArticle(articleId)
    }
  }, [articleId])

  useEffect(() => {
    if (value) {
      setArticle(getNormalizedDetailedArticle(value))
    }
  }, [value])

  return {article}
}
