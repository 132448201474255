import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from 'modules/Home';
import Login from 'modules/Login';
import News from 'modules/News';
import Faqs from 'modules/Faqs';
import Documents from 'modules/Documents';

import Course from 'modules/Course';
import Feedback from 'modules/Feedback';
import NewsDetail from 'modules/News/components/NewsDetail';
import { Department } from 'modules/Department';
import { Quiz } from 'modules/Quiz';
import MainLayout from 'common/layouts/MainLayout';
import Materials from 'modules/Materials';
import ArticleDetail from 'modules/Materials/components/ArticleDetail';
import PrivateRoute from './PrivateRoute';
import PrivateRouter from './PrivateRouter';
import NotFound from './NotFound';
import ScrollToTop from './ScrollToTop';
import Tests from "../Education/components/Course";
import EducationalMaterials from "../Education/components/Materials";
import Education from "../Education/components/Education";

const MainRouter = () => {
  return (
    <Router>
      <>
        <ScrollToTop />
        <Switch>
          <PrivateRoute path="/admin" component={PrivateRouter} />
          <Route path="/login" component={Login} />
          <MainLayout path="/news/:id" component={NewsDetail} />
          <MainLayout path="/news" component={News} />
          <MainLayout path="/faqs" component={Faqs} />
          <MainLayout path="/documents/:section?" component={Documents} />
          <MainLayout path="/education" exact component={Education} />
          <MainLayout path="/education/materials" exact component={EducationalMaterials} />
          <MainLayout path="/education/tests" exact component={Tests} />
          <Route path="/course" component={Course} />
          <MainLayout path="/feedback" component={Feedback} />
          <MainLayout path="/department" component={Department} />
          {/*<MainLayout path="/quiz" component={Quiz} />*/}
          <MainLayout path="/materials/articles/:articleId" component={ArticleDetail} />
          <MainLayout path="/materials/:section?" component={Materials} />
          <MainLayout path="/" exact component={Home} />
          <MainLayout component={NotFound} />
        </Switch>
      </>
    </Router>
  );
};

export default MainRouter;
