import React, { memo } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Link } from 'react-router-dom';
import Burger from './Burger';

const links = [
  { id: 1, title: 'Новости', path: '/news' },
  { id: 2, title: 'Документы', path: '/documents' },
  { id: 3, title: 'Интересные материалы', path: '/materials' },
  { id: 4, title: 'Обучение', path: '/education' },
  { id: 5, title: 'Вопрос-ответ', path: '/faqs' },
  { id: 6, title: 'Обратная связь', path: '/feedback' },
];

const bottomMenuLinks = [
  { id: 1, title: 'Раскрытие информации', path: 'https://www.company.rt.ru/about/disclosure/' },
  { id: 2, title: 'Лицензии и сертификаты', path: 'https://www.company.rt.ru/about/lic_and_cert/licence/' },
  { id: 3, title: 'Закупки', path: 'https://zakupki.rostelecom.ru/' },
];

const renderMenuItem = ({ id, title, path }) => {
  return (
    <Box key={id} width={[1, 1, 1 / 2, 1 / 2]} mb="14px">
      <Link to={path}>
        <Text fontSize="14px" color="#fff">
          {title}
        </Text>
      </Link>
    </Box>
  );
};

const renderBottomMenuItem = ({ id, title, path }) => {
  return (
    <Box key={id} mr="20px">
      <a href={path} color="#fff">
        <Text fontSize="14px" lineHeight="2rem" color="#fff">
          {title}
        </Text>
      </a>
    </Box>
  );
};

const Footer = () => (
  <Flex
    alignItems="center"
    width="100%"
    flexDirection="column"
    bg="#272F3D"
    color="#fff"
    flexWrap="wrap"
  >
    <Flex maxWidth="1300px" width="100%" flexDirection="column" px="24px">
      <Flex width={[1, 1, 1, 1]} flexDirection="column" py="32px">
        <Flex width="100%">
          <Flex width={[3 / 4, 3 / 4, 1]}>
            <Box mb="32px">
              <Text fontSize={['1.3rem', '1.5rem']} color="#898C93" sx={{ whiteSpace: 'nowrap' }}>
                Антикоррупционный сайт
              </Text>
            </Box>
          </Flex>
          <Flex width={[1 / 4, 1 / 4, 1]} justifyContent="flex-end" sx={{ position: 'relative' }}>
            <Box display={['flex', 'flex', 'none']} mb="16px">
              <Burger>{links.map(renderMenuItem)}</Burger>
            </Box>
          </Flex>
        </Flex>

        <Flex flexWrap="wrap" width="100%">
          <Flex
            width={[1, 1, 1, 1 / 2]}
            flexWrap="wrap"
            justifyContent={['flex-end', 'flex-end', 'flex-start']}
          >
            <Box display={['none', 'none', 'flex']} flexWrap="wrap">
              {links.map(renderMenuItem)}
            </Box>
          </Flex>
          <Flex flexDirection="column" width={[1, 1, 1, 1 / 2]} alignItems="flex-end">
            <Box mb="8px">
              <Text fontSize="24px" color="#BFBFBF">
                Линия доверия
              </Text>
            </Box>
            <Box>
              <Text fontSize={[32, 48]} color="#fff">
                8 800 1 811 811
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justify1Content="center"
        widt1h="100%"
        py="24px"
        sx={{ borderTop: '1px solid rgba(216, 216, 216, 0.1)' }}
      >
        <Flex flexWrap="wrap" width="100%" flexDirection={['column', 'row-reverse']}>
          <Flex
            width={[1, 2 / 3]}
            flexWrap="wrap"
            justifyContent={['flex-end', 'flex-end']}
            pb={[16, 16, 16, 0]}
            flexDirection={['column', 'row']}
            alignItems={['flex-end', 'center']}
          >
            {bottomMenuLinks.map(renderBottomMenuItem)}
          </Flex>
          <Flex
            width={[1, 1 / 3]}
            justifyContent={['flex-start', 'flex-start']}
            alignItems={['flex-end', 'center']}
          >
            <Box>
              <Text color="rgba(255, 255, 255, 0.5)" fontSize="1rem">
                © {new Date().getFullYear()} ПАО «Ростелеком». 18+
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

export default memo(Footer);
