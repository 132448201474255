import React, {memo} from 'react';

import {Input} from 'atoms';
import {useCaptcha} from "../../../modules/Feedback/hooks/useCaptcha";
import * as S from './styles'

const Captcha = ({
                   onChange = () => {
                   }, value = '', name = '', error = '', helperText = '',isUpdateCaptcha,setIsUpdateCaptcha
                 }) => {
  const {captchaLink, updateCaptcha, loading} = useCaptcha(isUpdateCaptcha,setIsUpdateCaptcha)

  return (

    <S.Content>
      <div>
        <Input value={value} name={name} label="Текст с картинки" required onChange={onChange}/>
        {error && <span className='error'>{helperText}</span>}
      </div>

      <div className='captcha'>
        {loading && <S.Loader/>}
        {captchaLink && !loading && <img width="100%" src={captchaLink} alt="captcha" className={'img'}/>}
      </div>
      <div onClick={updateCaptcha} className={'update'}>обновить</div>
    </S.Content>
  );
};

export default memo(Captcha);
