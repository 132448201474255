import {ACCESS, ARTICLE_STATUSES} from "../constants";

export const fieldNames = {
  title: 'title',
  status: 'status',
  access: 'access',
  text: 'text',
  file: 'file',
};
export const initialFilters = {
  status: ARTICLE_STATUSES.ALL,
  date: [null, null],
  access: ACCESS.ALL,
};

export const initialState = {
  title: '',
  status: '',
  access: '',
  file: null,
  text: '',
};
const filterNames = {
  status: 'status',
  date: 'date',
  access:'access'
};

export const filterList = Object.values(filterNames);

export const errorMsg = {
  requiredFile: 'Загрузите файл документа',
  required: (field) => `Заполните поле "${field}"`
};
