import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import articleReducer from './Article.reducers';
import bannerReducer from './Banner.reducers';
import newsReducer from './News.reducers';
import materialsReducer from './Materials.reducers';
import faqsReducer from './Faqs.reducers';
import documentsReducer from './Document.reducers';
import educationReducer from './Education.reducers';
import quizReducer from './Quiz.reducers';
import feedbackReducer from './Feedback.reducers';
import AskReducer from './Ask.reducers';
import AccountReducer from './Account.reducers';
import CourseReducer from './Course.reducers';
import adminReducer from './Admin/Admin';
import history from '../../utils/history';

export default combineReducers({
  article: articleReducer,
  banner: bannerReducer,
  news: newsReducer,
  materials: materialsReducer,
  faqs: faqsReducer,
  documents: documentsReducer,
  education: educationReducer,
  quiz: quizReducer,
  feedback: feedbackReducer,
  ask: AskReducer,
  account: AccountReducer,
  course: CourseReducer,
  admin: adminReducer,
  router: connectRouter(history),
});
